import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";


class Playgame extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeSlide: 0 };
  }

  render() {
  //   const settings = {
  //     infinite: true,
  //     speed: 500,
  //     slidesToShow: 3,
  //     slidesToScroll: 1,
  //     afterChange: (current) => this.setState({ activeSlide: current }),
  //     responsive: [
  //       {
  //         breakpoint: 768,
  //         settings: {
  //           slidesToShow: 1,
  //           slidesToScroll: 1,
  //           centerMode: true,
  //           centerPadding: "80px",
  //           arrows: false,
  //         },
  //       },
  //       {
  //         breakpoint: 400,
  //         settings: {
  //           centerPadding: "40px",
  //           slidesToShow: 1,
  //           slidesToScroll: 1,
  //           centerMode: true,
  //           arrows: false,
  //         },
  //       },
  //     ],
  //   };

    return (
      <div
        id="slider"
        className={
          this.props.darkTheme ? "second_slider" : "second_slider lightTheme"
        }
      >
        <link
          rel="stylesheet"
          type="text/css"
          charSet="UTF-8"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          type="text/css"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <div className="container">
          <div className="main_second_slider">
            <p className="">BUILD YOUR OWN MAP</p>
            <div className="content">
              <div className="select_menu">
                <div className="main_second_slide">
                  <h5>Play tower defense</h5>
                  <Link exact to="/towers" ><img src="img/card-2.png" /></Link>
                </div>
                <div className="main_second_slide">
                  <h5>Breed Creatures</h5>
                  <Link exact to="/creatures" ><img src="img/card-1.png" width="341" height="193" /></Link>
                </div>
                <div className="main_second_slide">
                  <h5>Create a map &nbsp;</h5>
                  <Link exact to="/maps" ><img src="img/card-3.png" /></Link>
                </div>
              </div>
              <div className="background">
                <span>Users can create their own custom maps that will be saved as an NFT, these can be used to earn Greater mana potion (GMP) tokens or simply be sold as an NFT .</span>
              </div>
            </div>
            {/* <Slider {...settings}>
              <div>
                <div className="main_second_slide">
                  <h5>Breed Creatures</h5>
                  <Link exact to="/creatures" ><img src="img/card-1.png" /></Link>
                  <Link exact to="/creatures" className="download_button">
                    <svg
                      width="43"
                      height="43"
                      viewBox="0 0 43 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M42.2764 28.8395C41.7945 28.3573 41.2083 28.116 40.5195 28.116H28.5288L25.0144 31.6303C24.0151 32.5948 22.8437 33.0774 21.5 33.0774C20.1565 33.0774 18.9848 32.5955 17.9856 31.6303L14.497 28.116H2.48074C1.79176 28.116 1.20607 28.3575 0.723549 28.8395C0.241122 29.3218 0 29.9076 0 30.597V38.8659C0 39.5551 0.241122 40.1408 0.723549 40.6231C1.20597 41.1054 1.79167 41.3463 2.48074 41.3463H40.5196C41.2084 41.3463 41.7946 41.1054 42.2765 40.6231C42.7591 40.1408 43 39.5551 43 38.8659V30.597C43 29.9076 42.7591 29.3218 42.2764 28.8395ZM32.5864 37.548C32.2586 37.8754 31.8709 38.0389 31.4229 38.0389C30.975 38.0389 30.5878 37.8754 30.2602 37.548C29.933 37.2206 29.7695 36.8331 29.7695 36.3855C29.7695 35.9376 29.933 35.5495 30.2602 35.2227C30.5878 34.895 30.975 34.7311 31.4229 34.7311C31.8709 34.7311 32.2586 34.895 32.5864 35.2227C32.9134 35.5494 33.077 35.9375 33.077 36.3855C33.077 36.8331 32.9134 37.2206 32.5864 37.548ZM39.2013 37.548C38.8742 37.8754 38.4865 38.0389 38.0384 38.0389C37.5906 38.0389 37.2031 37.8754 36.8756 37.548C36.5486 37.2206 36.3851 36.8331 36.3851 36.3855C36.3851 35.9376 36.5486 35.5495 36.8756 35.2227C37.203 34.895 37.5905 34.7311 38.0384 34.7311C38.4864 34.7311 38.8741 34.895 39.2013 35.2227C39.5287 35.5494 39.6925 35.9375 39.6925 36.3855C39.6925 36.8331 39.529 37.2206 39.2013 37.548Z"
                        fill="#CB7BD7"
                      />
                      <path
                        d="M20.3367 29.2788C20.647 29.6058 21.0346 29.7694 21.4995 29.7694C21.9648 29.7694 22.352 29.6058 22.6623 29.2788L34.2394 17.7016C34.7733 17.2021 34.8941 16.599 34.6011 15.8926C34.3088 15.221 33.8004 14.8851 33.0766 14.8847H26.461V3.30773C26.461 2.85988 26.2971 2.47221 25.9703 2.14492C25.6427 1.81754 25.2552 1.65381 24.8075 1.65381H18.1915C17.7438 1.65381 17.3562 1.81754 17.0287 2.14492C16.7015 2.47221 16.5378 2.85979 16.5378 3.30773V14.8848H9.92238C9.19847 14.8848 8.69052 15.2211 8.39763 15.8927C8.10491 16.5988 8.22538 17.2022 8.75958 17.7017L20.3367 29.2788Z"
                        fill="#CB7BD7"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
              <div>
                <div className="main_second_slide">
                  <h5>Play tower defense</h5>
                  <Link exact to="/towers" ><img src="img/card-2.png" /></Link>
                  <Link exact to="/towers" className="download_button">
                    <svg
                      width="43"
                      height="43"
                      viewBox="0 0 43 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M42.2764 28.8395C41.7945 28.3573 41.2083 28.116 40.5195 28.116H28.5288L25.0144 31.6303C24.0151 32.5948 22.8437 33.0774 21.5 33.0774C20.1565 33.0774 18.9848 32.5955 17.9856 31.6303L14.497 28.116H2.48074C1.79176 28.116 1.20607 28.3575 0.723549 28.8395C0.241122 29.3218 0 29.9076 0 30.597V38.8659C0 39.5551 0.241122 40.1408 0.723549 40.6231C1.20597 41.1054 1.79167 41.3463 2.48074 41.3463H40.5196C41.2084 41.3463 41.7946 41.1054 42.2765 40.6231C42.7591 40.1408 43 39.5551 43 38.8659V30.597C43 29.9076 42.7591 29.3218 42.2764 28.8395ZM32.5864 37.548C32.2586 37.8754 31.8709 38.0389 31.4229 38.0389C30.975 38.0389 30.5878 37.8754 30.2602 37.548C29.933 37.2206 29.7695 36.8331 29.7695 36.3855C29.7695 35.9376 29.933 35.5495 30.2602 35.2227C30.5878 34.895 30.975 34.7311 31.4229 34.7311C31.8709 34.7311 32.2586 34.895 32.5864 35.2227C32.9134 35.5494 33.077 35.9375 33.077 36.3855C33.077 36.8331 32.9134 37.2206 32.5864 37.548ZM39.2013 37.548C38.8742 37.8754 38.4865 38.0389 38.0384 38.0389C37.5906 38.0389 37.2031 37.8754 36.8756 37.548C36.5486 37.2206 36.3851 36.8331 36.3851 36.3855C36.3851 35.9376 36.5486 35.5495 36.8756 35.2227C37.203 34.895 37.5905 34.7311 38.0384 34.7311C38.4864 34.7311 38.8741 34.895 39.2013 35.2227C39.5287 35.5494 39.6925 35.9375 39.6925 36.3855C39.6925 36.8331 39.529 37.2206 39.2013 37.548Z"
                        fill="#CB7BD7"
                      />
                      <path
                        d="M20.3367 29.2788C20.647 29.6058 21.0346 29.7694 21.4995 29.7694C21.9648 29.7694 22.352 29.6058 22.6623 29.2788L34.2394 17.7016C34.7733 17.2021 34.8941 16.599 34.6011 15.8926C34.3088 15.221 33.8004 14.8851 33.0766 14.8847H26.461V3.30773C26.461 2.85988 26.2971 2.47221 25.9703 2.14492C25.6427 1.81754 25.2552 1.65381 24.8075 1.65381H18.1915C17.7438 1.65381 17.3562 1.81754 17.0287 2.14492C16.7015 2.47221 16.5378 2.85979 16.5378 3.30773V14.8848H9.92238C9.19847 14.8848 8.69052 15.2211 8.39763 15.8927C8.10491 16.5988 8.22538 17.2022 8.75958 17.7017L20.3367 29.2788Z"
                        fill="#CB7BD7"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
              <div>
                <div className="main_second_slide">
                  <h5>Create a map &nbsp;</h5>
                  <Link exact to="/maps" ><img src="img/card-3.png" /></Link>
                  <Link exact to="/maps" className="download_button">
                    <svg
                      width="43"
                      height="43"
                      viewBox="0 0 43 43"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M42.2764 28.8395C41.7945 28.3573 41.2083 28.116 40.5195 28.116H28.5288L25.0144 31.6303C24.0151 32.5948 22.8437 33.0774 21.5 33.0774C20.1565 33.0774 18.9848 32.5955 17.9856 31.6303L14.497 28.116H2.48074C1.79176 28.116 1.20607 28.3575 0.723549 28.8395C0.241122 29.3218 0 29.9076 0 30.597V38.8659C0 39.5551 0.241122 40.1408 0.723549 40.6231C1.20597 41.1054 1.79167 41.3463 2.48074 41.3463H40.5196C41.2084 41.3463 41.7946 41.1054 42.2765 40.6231C42.7591 40.1408 43 39.5551 43 38.8659V30.597C43 29.9076 42.7591 29.3218 42.2764 28.8395ZM32.5864 37.548C32.2586 37.8754 31.8709 38.0389 31.4229 38.0389C30.975 38.0389 30.5878 37.8754 30.2602 37.548C29.933 37.2206 29.7695 36.8331 29.7695 36.3855C29.7695 35.9376 29.933 35.5495 30.2602 35.2227C30.5878 34.895 30.975 34.7311 31.4229 34.7311C31.8709 34.7311 32.2586 34.895 32.5864 35.2227C32.9134 35.5494 33.077 35.9375 33.077 36.3855C33.077 36.8331 32.9134 37.2206 32.5864 37.548ZM39.2013 37.548C38.8742 37.8754 38.4865 38.0389 38.0384 38.0389C37.5906 38.0389 37.2031 37.8754 36.8756 37.548C36.5486 37.2206 36.3851 36.8331 36.3851 36.3855C36.3851 35.9376 36.5486 35.5495 36.8756 35.2227C37.203 34.895 37.5905 34.7311 38.0384 34.7311C38.4864 34.7311 38.8741 34.895 39.2013 35.2227C39.5287 35.5494 39.6925 35.9375 39.6925 36.3855C39.6925 36.8331 39.529 37.2206 39.2013 37.548Z"
                        fill="#CB7BD7"
                      />
                      <path
                        d="M20.3367 29.2788C20.647 29.6058 21.0346 29.7694 21.4995 29.7694C21.9648 29.7694 22.352 29.6058 22.6623 29.2788L34.2394 17.7016C34.7733 17.2021 34.8941 16.599 34.6011 15.8926C34.3088 15.221 33.8004 14.8851 33.0766 14.8847H26.461V3.30773C26.461 2.85988 26.2971 2.47221 25.9703 2.14492C25.6427 1.81754 25.2552 1.65381 24.8075 1.65381H18.1915C17.7438 1.65381 17.3562 1.81754 17.0287 2.14492C16.7015 2.47221 16.5378 2.85979 16.5378 3.30773V14.8848H9.92238C9.19847 14.8848 8.69052 15.2211 8.39763 15.8927C8.10491 16.5988 8.22538 17.2022 8.75958 17.7017L20.3367 29.2788Z"
                        fill="#CB7BD7"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </Slider> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Playgame;
