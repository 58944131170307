import React, { useEffect, useState, useRef, useMemo } from "react";
import style from "./Testmap.css";
import { withRouter } from "react-router-dom";
import { factoryABI, nftABI } from '../../utils/contract';
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { getData } from '../../store/appStoreSlice';
import { connect, web3, setReady, checkNFT } from '../../utils/util'
import { useSelector } from "react-redux";
import { useStateIfMounted } from "use-state-if-mounted";

const Testmap = (props) => {
  const mapId = props.match.params.id;
  const mount = useRef();
  const { accountAddress, mapsAddress, mainNetId, netId, gameFactoryAddress } = useSelector(getData);
  const isReady = useMemo(() => accountAddress && netId == mainNetId, [accountAddress, netId, mainNetId]);
  const mouse = new THREE.Vector2();
  const raycaster = new THREE.Raycaster();
  const camera = new THREE.PerspectiveCamera( 30, 1, 0.1, 1000 );
  const selectedToBePlacedObject = null;
  const selectedObject = null;
  const selectedObjectOnMap = null;
  const currentobject = null;
  const [config, setConfig] = useStateIfMounted({
    scene: new THREE.Scene(),
    controls: null,
    mapId:mapId,
    mapX:8,
    mapY:8,
    mapGround:0,
    fbxloader: new FBXLoader(),
    mapobjcts:[
        {name:'Stair_el7', position:{x:63.18, y:4.3, z:79.81}, rotation:{x:0, y:0.7639952, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el7.fbx'},
        {name:'Block2', position:{x:81.85, y:2.76, z:86.29}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Block2.fbx'},
        {name:'Tree_Pine_3', position:{x:83.10512, y:3.720469, z:84.68773}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_3.fbx'},
        {name:'Bush_08', position:{x:59.47066, y:3.429039, z:104.8817}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_08.fbx'},
        {name:'Tree_Pine_1', position:{x:87.01257, y:1.966355, z:86.24902}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Column3A', position:{x:70.36627, y:5.05758, z:102.1252}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Column3A.fbx'},
        {name:'Tree_Pine_1', position:{x:74.2984, y:5.491668, z:99.57084}, rotation:{x:0, y:-0.8123382, z:0}, scale:{x:1, y:0.774695, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Fence2', position:{x:78.61802, y:3.398569, z:91.51213}, rotation:{x:0, y:-0.998154, z:0}, scale:{x:1, y:1, z:1}, file:'Fence2.fbx'},
        {name:'Mushroom3B', position:{x:69.40081, y:3.401477, z:79.99416}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom3B.fbx'},
        {name:'Stair_el2', position:{x:63.26, y:4.3, z:77.72}, rotation:{x:0, y:0.6932201, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el2.fbx'},
        {name:'Ground_Decal_grass1', position:{x:63.89347, y:3.405359, z:103.7437}, rotation:{x:0, y:-0.4224538, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_grass1.fbx'},
        {name:'Stair_el8', position:{x:78.8664, y:2.128297, z:84.28519}, rotation:{x:0, y:-0.9508293, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el8.fbx'},
        {name:'Fence2', position:{x:83.50697, y:3.405817, z:76.3111}, rotation:{x:0, y:0.3777235, z:0}, scale:{x:1, y:1, z:1}, file:'Fence2.fbx'},
        {name:'Tree_Pine_2', position:{x:76.08925, y:2.186034, z:79.15456}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_2.fbx'},
        {name:'Rock_15', position:{x:83.58846, y:2.021745, z:85.63272}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_15.fbx'},
        {name:'Sparks_pink', position:{x:84.13446, y:9.484919, z:96.84034}, rotation:{x:-0.590996, y:0.4316109, z:0.3882317}, scale:{x:1, y:1, z:1}, file:'Sparks_pink.fbx'},
        {name:'Tree_09', position:{x:84.13804, y:5.491668, z:96.82999}, rotation:{x:-0.02184666, y:0.5797161, z:-0.0306737}, scale:{x:1, y:1, z:1}, file:'Tree_09.fbx'},
        {name:'Floor5', position:{x:60.03194, y:5.491151, z:77.23545}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Floor5.fbx'},
        {name:'Rock_14B', position:{x:73.20877, y:5.491543, z:108.6678}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_14B.fbx'},
        {name:'Bush_05', position:{x:67.45815, y:3.429039, z:77.15488}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_05.fbx'},
        {name:'Bush_06', position:{x:64.20143, y:3.429039, z:89.92351}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_06.fbx'},
        {name:'Rock_06', position:{x:97.7, y:2.54, z:96.25}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Tree_Small_02', position:{x:90.8556, y:3.371016, z:102.4462}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Small_02.fbx'},
        {name:'Block1', position:{x:88.84155, y:3.87, z:110.992}, rotation:{x:0, y:0.909279, z:0}, scale:{x:1, y:1, z:1}, file:'Block1.fbx'},
        {name:'Bush_08', position:{x:88.3151, y:3.317497, z:84.45899}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_08.fbx'},
        {name:'Fence1', position:{x:68.85726, y:3.405359, z:87.77923}, rotation:{x:0, y:-0.3853284, z:0}, scale:{x:1, y:1, z:1}, file:'Fence1.fbx'},
        {name:'Point_light', position:{x:64.59174, y:5.32909, z:102.0131}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Point_light.fbx'},
        {name:'Particle_Glow', position:{x:64.60741, y:5.577087, z:102.005}, rotation:{x:-0.6532816, y:-0.2705981, z:-0.2705981}, scale:{x:1, y:1, z:1}, file:'Particle_Glow.fbx'},
        {name:'Lantern1', position:{x:65.39301, y:3.405359, z:101.2194}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Lantern1.fbx'},
        {name:'Ground_Decal_Leaves2', position:{x:80.80984, y:5.491673, z:101.437}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_Leaves2.fbx'},
        {name:'Tree_Pine_2', position:{x:63.81, y:3.41, z:86.37}, rotation:{x:0, y:-0.5664616, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_2.fbx'},
        {name:'Ivy1', position:{x:82.64, y:5.74, z:72.76}, rotation:{x:0, y:-0.2786228, z:0}, scale:{x:1, y:1, z:1}, file:'Ivy1.fbx'},
        {name:'Block2', position:{x:88.38864, y:4.19, z:110.2598}, rotation:{x:0, y:0.909279, z:0}, scale:{x:1, y:1, z:1}, file:'Block2.fbx'},
        {name:'Block4', position:{x:81.54, y:1.81, z:86.98}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Block4.fbx'},
        {name:'Tree_06', position:{x:84.57555, y:5.475119, z:100.5954}, rotation:{x:0, y:0.9998423, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_06.fbx'},
        {name:'Tree_Pine_2', position:{x:58.96, y:5.5, z:87.94}, rotation:{x:0, y:-0.5389422, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_2.fbx'},
        {name:'Block2', position:{x:68.38754, y:4.187246, z:103.6782}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Block2.fbx'},
        {name:'Bush_04', position:{x:67.62614, y:3.429039, z:91.00629}, rotation:{x:0, y:-0.6161284, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_04.fbx'},
        {name:'Rock_13', position:{x:80.06325, y:2.95686, z:94.55245}, rotation:{x:0, y:-0.7809149, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_13.fbx'},
        {name:'Tree_Pine_3', position:{x:89.05943, y:3.642256, z:81.83836}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_3.fbx'},
        {name:'Bush_09', position:{x:87.96555, y:5.514168, z:103.7293}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_09.fbx'},
        {name:'Point_light', position:{x:77.42, y:4.945734, z:80.75}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Point_light.fbx'},
        {name:'Fire2', position:{x:77.42, y:4.659169, z:80.75}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire2.fbx'},
        {name:'Fire_long', position:{x:77.42, y:5.732012, z:80.73159}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire_long.fbx'},
        {name:'Fire_sparks', position:{x:77.42, y:4.93136, z:80.75}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire_sparks.fbx'},
        {name:'Fire2', position:{x:77.42, y:4.78, z:80.75}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire2.fbx'},
        {name:'Fire_and_Light', position:{x:77.42, y:4.78, z:80.75}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire_and_Light.fbx'},
        {name:'Mushroom2A', position:{x:60.46161, y:3.405042, z:104.3931}, rotation:{x:0, y:0.9722606, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom2A.fbx'},
        {name:'Bush_03', position:{x:61, y:5.42368, z:88.32}, rotation:{x:0, y:-0.5389422, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_03.fbx'},
        {name:'Tree_06', position:{x:76.03093, y:5.479548, z:69.81396}, rotation:{x:0, y:0.9998423, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_06.fbx'},
        {name:'Plant_04', position:{x:83.31608, y:3.429913, z:83.49403}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_04.fbx'},
        {name:'Ground_Decal1', position:{x:64.25189, y:3.405359, z:101.7012}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal1.fbx'},
        {name:'Ground_Decal3', position:{x:70.07489, y:3.405359, z:89.20958}, rotation:{x:0, y:0.9628423, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal3.fbx'},
        {name:'Bush_01', position:{x:65.32202, y:3.429118, z:87.45953}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_01.fbx'},
        {name:'Bush_09', position:{x:93.75432, y:3.304708, z:75.78059}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_09.fbx'},
        {name:'Floor3', position:{x:79.70186, y:3.405803, z:80.09951}, rotation:{x:0, y:0.7552071, z:0}, scale:{x:1, y:1, z:1}, file:'Floor3.fbx'},
        {name:'Stair_el2', position:{x:70.89064, y:5.190954, z:104.0067}, rotation:{x:0, y:-0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el2.fbx'},
        {name:'Plant_06', position:{x:74.65571, y:1.997678, z:77.8494}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_06.fbx'},
        {name:'Brickwall2', position:{x:65.95818, y:3.405359, z:104.1396}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Brickwall2.fbx'},
        {name:'Stair_el1', position:{x:69.57751, y:3.900102, z:105.2956}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el1.fbx'},
        {name:'Rock_07', position:{x:77.59642, y:3.593332, z:94.61562}, rotation:{x:-0.3009391, y:0.8542622, z:0.05359961}, scale:{x:1, y:1, z:1}, file:'Rock_07.fbx'},
        {name:'Rock_17A', position:{x:72.71012, y:5.41707, z:104.8315}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_17A.fbx'},
        {name:'Rock_04', position:{x:73.21869, y:5.294924, z:98.79738}, rotation:{x:-0.2309292, y:0.7261968, z:-0.07988318}, scale:{x:0.7286443, y:0.7286444, z:0.7286444}, file:'Rock_04.fbx'},
        {name:'Rock_02', position:{x:73.12453, y:2.903845, z:84.89567}, rotation:{x:-0.1906262, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_02.fbx'},
        {name:'Crystal1', position:{x:74.02025, y:5.018944, z:96.85677}, rotation:{x:0.0672859, y:-0.5892065, z:0.1194303}, scale:{x:1, y:1, z:1}, file:'Crystal1.fbx'},
        {name:'Crystal1', position:{x:77.0581, y:4.769909, z:94.86255}, rotation:{x:-0.0468334, y:-0.6389721, z:0.07331691}, scale:{x:1, y:1, z:1}, file:'Crystal1.fbx'},
        {name:'Block1', position:{x:77.44855, y:3.198416, z:80.79225}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Block1.fbx'},
        {name:'Rock_07', position:{x:58.90368, y:4.988098, z:92.32307}, rotation:{x:0.1183347, y:0.1270392, z:-0.04716057}, scale:{x:1, y:1, z:1}, file:'Rock_07.fbx'},
        {name:'Block3', position:{x:68.83767, y:4.505215, z:103.035}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Block3.fbx'},
        {name:'Plant_05', position:{x:67.10846, y:3.429193, z:96.95354}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_05.fbx'},
        {name:'Mushroom2B', position:{x:61.9293, y:3.463374, z:105.4311}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom2B.fbx'},
        {name:'Rock_03', position:{x:72.38176, y:5.408159, z:99.84015}, rotation:{x:0.2152323, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Fern1', position:{x:79.19819, y:3.148712, z:90.97133}, rotation:{x:-0.03488905, y:-0.4533167, z:0.02985975}, scale:{x:1.069844, y:1.069844, z:1.069844}, file:'Fern1.fbx'},
        {name:'Fence1', position:{x:70.11834, y:3.405875, z:87.7085}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Fence1.fbx'},
        {name:'Stair_el6', position:{x:66.71, y:3.5, z:57.89}, rotation:{x:0, y:0.9255019, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el6.fbx'},
        {name:'Tree_Dead1', position:{x:82.58588, y:1.973998, z:90.54741}, rotation:{x:-0.03372036, y:-0.8027714, z:0.04560395}, scale:{x:1, y:1, z:1}, file:'Tree_Dead1.fbx'},
        {name:'Tree_Pine_1', position:{x:89.12122, y:3.409858, z:85.02432}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Rock_17B', position:{x:75.61092, y:3.405387, z:91.69499}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_17B.fbx'},
        {name:'Bush_03', position:{x:44.93235, y:5.413786, z:132.3505}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_03.fbx'},
        {name:'Bush_07', position:{x:90.30247, y:3.354398, z:81.50985}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_07.fbx'},
        {name:'Plant_07', position:{x:80.15394, y:2.43316, z:88.93951}, rotation:{x:0, y:0.797651, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_07.fbx'},
        {name:'Sparks', position:{x:69.50364, y:8.722502, z:106.3744}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Sparks.fbx'},
        {name:'Swirling_Petals', position:{x:71.30707, y:8.362472, z:81.2}, rotation:{x:-0.7032332, y:0.07391278, z:0.07391278}, scale:{x:1, y:1, z:1}, file:'Swirling_Petals.fbx'},
        {name:'RigRUpWing03Gizmo', position:{x:71.60867, y:9.471382, z:81.05865}, rotation:{x:-0.1628699, y:0.639195, z:0.688094}, scale:{x:1, y:1, z:1}, file:'RigRUpWing03Gizmo.fbx'},
        {name:'RigRUpWing02Gizmo', position:{x:71.45135, y:9.342834, z:81.09209}, rotation:{x:-0.1628699, y:0.639195, z:0.688094}, scale:{x:1, y:1, z:1}, file:'RigRUpWing02Gizmo.fbx'},
        {name:'RigRUpWing01Gizmo', position:{x:71.3026, y:9.22129, z:81.12372}, rotation:{x:-0.1628699, y:0.639195, z:0.688094}, scale:{x:1, y:1, z:1}, file:'RigRUpWing01Gizmo.fbx'},
        {name:'RigRLegPlatform', position:{x:71.36787, y:8.26, z:81.22824}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'RigRLegPlatform.fbx'},
        {name:'RigRibcageGizmo', position:{x:71.30707, y:9.165751, z:81.2}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'RigRibcageGizmo.fbx'},
        {name:'RigRDownWing3Gizmo', position:{x:71.56301, y:9.067695, z:81.06836}, rotation:{x:0.2387103, y:0.7006813, z:0.6655957}, scale:{x:1, y:1, z:1}, file:'RigRDownWing3Gizmo.fbx'},
        {name:'RigRDownWing2Gizmo', position:{x:71.42821, y:9.139311, z:81.09702}, rotation:{x:0.2387103, y:0.7006813, z:0.6655957}, scale:{x:1, y:1, z:1}, file:'RigRDownWing2Gizmo.fbx'},
        {name:'RigRDownWing1Gizmo', position:{x:71.3034, y:9.205624, z:81.12354}, rotation:{x:0.2387103, y:0.7006813, z:0.6655957}, scale:{x:1, y:1, z:1}, file:'RigRDownWing1Gizmo.fbx'},
        {name:'RigRArmThumb2Gizmo', position:{x:71.72832, y:9.199834, z:81.13911}, rotation:{x:0.005911909, y:0.9201598, z:-0.03641741}, scale:{x:0.9999992, y:0.9999998, z:1.000001}, file:'RigRArmThumb2Gizmo.fbx'},
        {name:'RigRArmThumb1Gizmo', position:{x:71.71625, y:9.199046, z:81.12496}, rotation:{x:-0.004695729, y:0.9070457, z:-0.04034924}, scale:{x:0.9999993, y:0.9999998, z:1.000001}, file:'RigRArmThumb1Gizmo.fbx'},
        {name:'RigRArmRest3Gizmo', position:{x:71.77878, y:9.199679, z:81.07967}, rotation:{x:0.01021314, y:0.9954535, z:-0.002692719}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmRest3Gizmo.fbx'},
        {name:'RigRArmRest2Gizmo', position:{x:71.76366, y:9.200001, z:81.08257}, rotation:{x:0.01021314, y:0.9954535, z:-0.002692719}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmRest2Gizmo.fbx'},
        {name:'RigRArmRest1Gizmo', position:{x:71.74855, y:9.200321, z:81.08546}, rotation:{x:0.01021314, y:0.9954535, z:-0.002692719}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmRest1Gizmo.fbx'},
        {name:'RigRArmPalmGizmo', position:{x:71.68254, y:9.19852, z:81.10556}, rotation:{x:0.01021314, y:0.9954535, z:-0.002692719}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmPalmGizmo.fbx'},
        {name:'RigRArmIndex3Gizmo', position:{x:71.78444, y:9.200114, z:81.10245}, rotation:{x:0.01019855, y:0.9949304, z:-0.00274617}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArmIndex3Gizmo.fbx'},
        {name:'RigRArmIndex2Gizmo', position:{x:71.76792, y:9.200745, z:81.10616}, rotation:{x:0.01839051, y:0.9937406, z:-0.00311289}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRArmIndex2Gizmo.fbx'},
        {name:'RigRArmIndex1Gizmo', position:{x:71.7525, y:9.201077, z:81.10911}, rotation:{x:0.01033776, y:0.9954636, z:-0.002704319}, scale:{x:1, y:1, z:1}, file:'RigRArmIndex1Gizmo.fbx'},
        {name:'RigRArm2Gizmo', position:{x:71.53446, y:9.197538, z:81.13232}, rotation:{x:0.06034329, y:0.709417, z:0.6991021}, scale:{x:1, y:1, z:1}, file:'RigRArm2Gizmo.fbx'},
        {name:'RigRArm1Gizmo', position:{x:71.37683, y:9.196066, z:81.16958}, rotation:{x:0.07832412, y:0.7049103, z:0.699768}, scale:{x:1, y:1, z:1}, file:'RigRArm1Gizmo.fbx'},
        {name:'RigPelvisGizmo', position:{x:71.30707, y:8.948057, z:81.2}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigRUpWing03', position:{x:71.60867, y:9.471382, z:81.05865}, rotation:{x:0.16287, y:-0.6391949, z:-0.6880941}, scale:{x:1, y:1, z:1}, file:'RigRUpWing03.fbx'},
        {name:'RigRUpWing02', position:{x:71.45135, y:9.342834, z:81.09209}, rotation:{x:0.16287, y:-0.6391949, z:-0.6880941}, scale:{x:1, y:1, z:1}, file:'RigRUpWing02.fbx'},
        {name:'RigRUpWing01', position:{x:71.3026, y:9.22129, z:81.12372}, rotation:{x:0.16287, y:-0.6391949, z:-0.6880941}, scale:{x:1, y:1, z:1}, file:'RigRUpWing01.fbx'},
        {name:'RigRDownWing3', position:{x:71.56301, y:9.067695, z:81.06836}, rotation:{x:-0.2387103, y:-0.7006812, z:-0.6655957}, scale:{x:1, y:1, z:1}, file:'RigRDownWing3.fbx'},
        {name:'RigRDownWing2', position:{x:71.42821, y:9.139311, z:81.09702}, rotation:{x:-0.2387103, y:-0.7006812, z:-0.6655957}, scale:{x:1, y:1, z:1}, file:'RigRDownWing2.fbx'},
        {name:'RigRDownWing1', position:{x:71.3034, y:9.205624, z:81.12354}, rotation:{x:-0.2387103, y:-0.7006812, z:-0.6655957}, scale:{x:1, y:1, z:1}, file:'RigRDownWing1.fbx'},
        {name:'RigRArmThumb2', position:{x:71.72832, y:9.199834, z:81.13911}, rotation:{x:-0.00590747, y:-0.9201602, z:0.03641346}, scale:{x:0.9999985, y:0.9999996, z:1.000002}, file:'RigRArmThumb2.fbx'},
        {name:'RigRArmThumb1', position:{x:71.71625, y:9.199046, z:81.12496}, rotation:{x:0.004698868, y:-0.9070461, z:0.04034517}, scale:{x:0.9999986, y:0.9999996, z:1.000002}, file:'RigRArmThumb1.fbx'},
        {name:'RigRArmRest3', position:{x:71.77878, y:9.199679, z:81.07967}, rotation:{x:-0.01021368, y:-0.9954535, z:0.002688635}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmRest3.fbx'},
        {name:'RigRArmRest2', position:{x:71.76366, y:9.200001, z:81.08257}, rotation:{x:-0.01021348, y:-0.9954536, z:0.002689992}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmRest2.fbx'},
        {name:'RigRArmRest1', position:{x:71.74855, y:9.200321, z:81.08546}, rotation:{x:-0.0102133, y:-0.9954536, z:0.002691262}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmRest1.fbx'},
        {name:'RigRArmIndex3', position:{x:71.78444, y:9.200114, z:81.10245}, rotation:{x:-0.0101981, y:-0.9949306, z:0.002750376}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRArmIndex3.fbx'},
        {name:'RigRArmIndex2', position:{x:71.76792, y:9.200745, z:81.10616}, rotation:{x:-0.01839021, y:-0.9937407, z:0.003115636}, scale:{x:0.9999998, y:0.9999999, z:1}, file:'RigRArmIndex2.fbx'},
        {name:'RigRArmIndex1', position:{x:71.7525, y:9.201077, z:81.10911}, rotation:{x:-0.01033768, y:-0.9954637, z:0.002705683}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmIndex1.fbx'},
        {name:'RigRArmPalm', position:{x:71.68254, y:9.19852, z:81.10556}, rotation:{x:-0.01021315, y:-0.9954537, z:0.002692564}, scale:{x:0.9999999, y:1, z:1}, file:'RigRArmPalm.fbx'},
        {name:'RigRArm2', position:{x:71.53446, y:9.197538, z:81.13232}, rotation:{x:-0.0603433, y:-0.709417, z:-0.6991022}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRArm2.fbx'},
        {name:'RigRArm1', position:{x:71.37683, y:9.196066, z:81.16958}, rotation:{x:-0.07832413, y:-0.7049102, z:-0.6997681}, scale:{x:1, y:1, z:1}, file:'RigRArm1.fbx'},
        {name:'RigRArmCollarbone', position:{x:71.33056, y:9.194792, z:81.23779}, rotation:{x:-0.3240798, y:-0.6263016, z:-0.623233}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArmCollarbone.fbx'},
        {name:'RigHair10', position:{x:71.21548, y:9.779634, z:80.84255}, rotation:{x:0.03711492, y:-0.8874478, z:-0.1711702}, scale:{x:0.9999965, y:1.000004, z:0.9999999}, file:'RigHair10.fbx'},
        {name:'RigHair9', position:{x:71.2178, y:9.750734, z:80.89571}, rotation:{x:0.1873938, y:-0.6744542, z:-0.1614156}, scale:{x:0.9999958, y:1.000004, z:1}, file:'RigHair9.fbx'},
        {name:'RigHair8', position:{x:71.24343, y:9.697692, z:80.93929}, rotation:{x:0.4354949, y:-0.5404955, z:-0.1816078}, scale:{x:0.9999982, y:1.000002, z:0.9999997}, file:'RigHair8.fbx'},
        {name:'RigHair7', position:{x:71.29386, y:9.653818, z:80.9591}, rotation:{x:0.5747013, y:-0.3300276, z:-0.1714766}, scale:{x:1.000001, y:0.9999998, z:0.9999995}, file:'RigHair7.fbx'},
        {name:'RigHair6', position:{x:71.30909, y:9.582858, z:80.97138}, rotation:{x:0.2084082, y:-0.2699223, z:-0.5689353}, scale:{x:0.9999998, y:1, z:1}, file:'RigHair6.fbx'},
        {name:'RigHair5', position:{x:71.32639, y:9.530996, z:81.02628}, rotation:{x:-0.04055376, y:-0.4301979, z:-0.4508938}, scale:{x:0.9999998, y:1, z:1}, file:'RigHair5.fbx'},
        {name:'RigHair4', position:{x:71.3103, y:9.519653, z:81.09428}, rotation:{x:-0.0875659, y:-0.746765, z:-0.2364215}, scale:{x:1, y:1, z:1}, file:'RigHair4.fbx'},
        {name:'RigHair3', position:{x:71.30121, y:9.496049, z:81.12473}, rotation:{x:0.09662873, y:-0.6870485, z:-0.3779952}, scale:{x:0.9999999, y:1, z:1}, file:'RigHair3.fbx'},
        {name:'RigHair2', position:{x:71.29622, y:9.463733, z:81.14894}, rotation:{x:0.1913984, y:-0.6033106, z:-0.444251}, scale:{x:1, y:0.9999999, z:1}, file:'RigHair2.fbx'},
        {name:'RigHair1', position:{x:71.30209, y:9.426041, z:81.17657}, rotation:{x:0.2815735, y:-0.562815, z:-0.3477142}, scale:{x:1, y:1, z:1}, file:'RigHair1.fbx'},
        {name:'RigHead', position:{x:71.30707, y:9.365434, z:81.2}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck', position:{x:71.30707, y:9.230247, z:81.2}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'RigNeck.fbx'},
        {name:'RigLUpWing03', position:{x:70.97405, y:9.471382, z:81.19355}, rotation:{x:0.6391951, y:-0.16287, z:-0.3023735}, scale:{x:1, y:1, z:1}, file:'RigLUpWing03.fbx'},
        {name:'RigLUpWing02', position:{x:71.13138, y:9.342834, z:81.16011}, rotation:{x:0.6391951, y:-0.16287, z:-0.3023735}, scale:{x:1, y:1, z:1}, file:'RigLUpWing02.fbx'},
        {name:'RigLUpWing01', position:{x:71.28013, y:9.22129, z:81.12849}, rotation:{x:0.6391951, y:-0.16287, z:-0.3023735}, scale:{x:1, y:1, z:1}, file:'RigLUpWing01.fbx'},
        {name:'RigLDownWing03', position:{x:71.01971, y:9.067695, z:81.18385}, rotation:{x:0.7006814, y:0.2387102, z:0.09510893}, scale:{x:1, y:1, z:1}, file:'RigLDownWing03.fbx'},
        {name:'RigLDownWing02', position:{x:71.15451, y:9.139311, z:81.15519}, rotation:{x:0.7006814, y:0.2387102, z:0.09510893}, scale:{x:1, y:1, z:1}, file:'RigLDownWing02.fbx'},
        {name:'RigLDownWing01', position:{x:71.27933, y:9.205624, z:81.12866}, rotation:{x:0.7006814, y:0.2387102, z:0.09510893}, scale:{x:1, y:1, z:1}, file:'RigLDownWing01.fbx'},
        {name:'RigLArmThumb2', position:{x:70.89746, y:9.199834, z:81.31571}, rotation:{x:-0.03439396, y:0.5725946, z:0.01335978}, scale:{x:1.000002, y:1, z:0.9999984}, file:'RigLArmThumb2.fbx'},
        {name:'RigLArmThumb1', position:{x:70.90274, y:9.199046, z:81.29788}, rotation:{x:-0.04044559, y:0.5984956, z:0.003800247}, scale:{x:1.000001, y:1, z:0.9999985}, file:'RigLArmThumb1.fbx'},
        {name:'RigLArmRest3', position:{x:70.82719, y:9.199679, z:81.28193}, rotation:{x:-0.0005129908, y:0.1143771, z:0.01054977}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArmRest3.fbx'},
        {name:'RigLArmRest2', position:{x:70.84219, y:9.200001, z:81.27843}, rotation:{x:-0.0005116477, y:0.1143762, z:0.01054972}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArmRest2.fbx'},
        {name:'RigLArmRest1', position:{x:70.85717, y:9.200321, z:81.27493}, rotation:{x:-0.0005103569, y:0.1143753, z:0.0105496}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArmRest1.fbx'},
        {name:'RigLArmIndex3', position:{x:70.83129, y:9.200114, z:81.30505}, rotation:{x:-0.0005628455, y:0.1090309, z:0.01054644}, scale:{x:1, y:0.9999997, z:1}, file:'RigLArmIndex3.fbx'},
        {name:'RigLArmIndex2', position:{x:70.84789, y:9.200745, z:81.30171}, rotation:{x:0.0007801849, y:0.09887206, z:0.01863571}, scale:{x:1, y:0.9999998, z:1}, file:'RigLArmIndex2.fbx'},
        {name:'RigLArmIndex1', position:{x:70.86317, y:9.201077, z:81.29814}, rotation:{x:-0.0004957982, y:0.1144924, z:0.010674}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArmIndex1.fbx'},
        {name:'RigLArmPalm', position:{x:70.92564, y:9.19852, z:81.26644}, rotation:{x:-0.000508974, y:0.1143745, z:0.01054949}, scale:{x:1, y:1, z:1}, file:'RigLArmPalm.fbx'},
        {name:'RigLArm2', position:{x:71.07181, y:9.197538, z:81.23067}, rotation:{x:0.6963712, y:0.08303779, z:-0.08632691}, scale:{x:1, y:1, z:0.9999999}, file:'RigLArm2.fbx'},
        {name:'RigLArm1', position:{x:71.23096, y:9.196066, z:81.20058}, rotation:{x:0.7007609, y:0.06303374, z:-0.06887735}, scale:{x:1, y:1, z:1}, file:'RigLArm1.fbx'},
        {name:'RigLArmCollarbone', position:{x:71.30098, y:9.194792, z:81.24408}, rotation:{x:0.6769938, y:-0.2004785, z:0.1874206}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArmCollarbone.fbx'},
        {name:'RigRibcage', position:{x:71.30707, y:9.165751, z:81.2}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'RigRibcage.fbx'},
        {name:'RigSpine6', position:{x:71.30707, y:9.098951, z:81.2}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'RigSpine6.fbx'},
        {name:'RigSpine5', position:{x:71.30707, y:9.075087, z:81.2}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'RigSpine5.fbx'},
        {name:'RigSpine4', position:{x:71.30707, y:9.051502, z:81.2}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'RigSpine4.fbx'},
        {name:'RigSpine3', position:{x:71.30707, y:9.01883, z:81.2}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'RigSpine3.fbx'},
        {name:'RigSpine2', position:{x:71.30707, y:8.981806, z:81.2}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'RigSpine2.fbx'},
        {name:'RigSpine1', position:{x:71.30707, y:8.948057, z:81.2}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'RigSpine1.fbx'},
        {name:'RigRLegToes2', position:{x:71.36356, y:8.29431, z:81.20588}, rotation:{x:0.11693, y:0.768299, z:0.09468809}, scale:{x:1, y:1, z:1}, file:'RigRLegToes2.fbx'},
        {name:'RigRLegToes1', position:{x:71.35622, y:8.338752, z:81.17133}, rotation:{x:0.3377305, y:0.6999242, z:0.2734889}, scale:{x:1, y:1, z:1}, file:'RigRLegToes1.fbx'},
        {name:'RigRLegAnkle', position:{x:71.35744, y:8.4185, z:81.17435}, rotation:{x:0.5619865, y:0.5366685, z:0.4520131}, scale:{x:1, y:1, z:0.9999998}, file:'RigRLegAnkle.fbx'},
        {name:'RigRLeg2', position:{x:71.36353, y:8.69028, z:81.18375}, rotation:{x:0.5526564, y:0.5247487, z:0.4619856}, scale:{x:0.9999998, y:0.9999999, z:0.9999999}, file:'RigRLeg2.fbx'},
        {name:'RigRLeg1', position:{x:71.36833, y:8.948057, z:81.18697}, rotation:{x:0.541562, y:0.5258227, z:0.4628242}, scale:{x:0.9999999, y:1, z:1}, file:'RigRLeg1.fbx'},
        {name:'RigLLegToes2', position:{x:71.25785, y:8.29431, z:81.22835}, rotation:{x:0.1169302, y:0.7682989, z:0.09468815}, scale:{x:1, y:1, z:1}, file:'RigLLegToes2.fbx'},
        {name:'RigLLegToes1', position:{x:71.25051, y:8.338752, z:81.19379}, rotation:{x:0.3377306, y:0.6999242, z:0.2734888}, scale:{x:1, y:1, z:1}, file:'RigLLegToes1.fbx'},
        {name:'RigLLegAnkle', position:{x:71.25062, y:8.4185, z:81.19705}, rotation:{x:0.558979, y:0.5400064, z:0.4557271}, scale:{x:1, y:1, z:1}, file:'RigLLegAnkle.fbx'},
        {name:'RigLLeg2', position:{x:71.24889, y:8.69028, z:81.20812}, rotation:{x:0.5668032, y:0.5528072, z:0.4445173}, scale:{x:0.9999999, y:0.9999997, z:1}, file:'RigLLeg2.fbx'},
        {name:'RigLLeg1', position:{x:71.2458, y:8.948057, z:81.21302}, rotation:{x:0.5652964, y:0.5639527, z:0.4335124}, scale:{x:1, y:1, z:1}, file:'RigLLeg1.fbx'},
        {name:'RigPelvis', position:{x:71.30707, y:8.948057, z:81.2}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigLUpWing03Gizmo', position:{x:70.97405, y:9.471382, z:81.19355}, rotation:{x:0.639195, y:-0.16287, z:-0.3023736}, scale:{x:1, y:1, z:1}, file:'RigLUpWing03Gizmo.fbx'},
        {name:'RigLUpWing02Gizmo', position:{x:71.13138, y:9.342834, z:81.16011}, rotation:{x:0.639195, y:-0.16287, z:-0.3023736}, scale:{x:1, y:1, z:1}, file:'RigLUpWing02Gizmo.fbx'},
        {name:'RigLUpWing01Gizmo', position:{x:71.28013, y:9.22129, z:81.12849}, rotation:{x:0.639195, y:-0.16287, z:-0.3023736}, scale:{x:1, y:1, z:1}, file:'RigLUpWing01Gizmo.fbx'},
        {name:'RigLLegPlatform', position:{x:71.26301, y:8.26, z:81.25053}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'RigLLegPlatform.fbx'},
        {name:'RigLDownWing03Gizmo', position:{x:71.01971, y:9.067695, z:81.18385}, rotation:{x:0.7006814, y:0.2387102, z:0.09510887}, scale:{x:1, y:1, z:1}, file:'RigLDownWing03Gizmo.fbx'},
        {name:'RigLDownWing02Gizmo', position:{x:71.15451, y:9.139311, z:81.15519}, rotation:{x:0.7006814, y:0.2387102, z:0.09510887}, scale:{x:1, y:1, z:1}, file:'RigLDownWing02Gizmo.fbx'},
        {name:'RigLDownWing01Gizmo', position:{x:71.27933, y:9.205624, z:81.12866}, rotation:{x:0.7006814, y:0.2387102, z:0.09510887}, scale:{x:1, y:1, z:1}, file:'RigLDownWing01Gizmo.fbx'},
        {name:'RigLArmThumb2Gizmo', position:{x:70.89746, y:9.199834, z:81.31571}, rotation:{x:-0.03439112, y:0.5725945, z:0.01335466}, scale:{x:1.000001, y:1, z:0.9999992}, file:'RigLArmThumb2Gizmo.fbx'},
        {name:'RigLArmThumb1Gizmo', position:{x:70.90274, y:9.199046, z:81.29788}, rotation:{x:-0.0404425, y:0.5984955, z:0.00379636}, scale:{x:1.000001, y:1, z:0.9999992}, file:'RigLArmThumb1Gizmo.fbx'},
        {name:'RigLArmRest3Gizmo', position:{x:70.82719, y:9.199679, z:81.28193}, rotation:{x:-0.0005090755, y:0.1143745, z:0.01054953}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArmRest3Gizmo.fbx'},
        {name:'RigLArmRest2Gizmo', position:{x:70.84219, y:9.200001, z:81.27843}, rotation:{x:-0.0005090755, y:0.1143745, z:0.01054953}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArmRest2Gizmo.fbx'},
        {name:'RigLArmRest1Gizmo', position:{x:70.85717, y:9.200321, z:81.27493}, rotation:{x:-0.0005090755, y:0.1143745, z:0.01054953}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArmRest1Gizmo.fbx'},
        {name:'RigLArmPalmGizmo', position:{x:70.92564, y:9.19852, z:81.26644}, rotation:{x:-0.0005090755, y:0.1143745, z:0.01054953}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArmPalmGizmo.fbx'},
        {name:'RigLArmIndex3Gizmo', position:{x:70.83129, y:9.200114, z:81.30505}, rotation:{x:-0.0005671731, y:0.1090317, z:0.01054685}, scale:{x:1, y:0.9999998, z:1}, file:'RigLArmIndex3Gizmo.fbx'},
        {name:'RigLArmIndex2Gizmo', position:{x:70.84789, y:9.200745, z:81.30171}, rotation:{x:0.000777341, y:0.09887204, z:0.01863599}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArmIndex2Gizmo.fbx'},
        {name:'RigLArmIndex1Gizmo', position:{x:70.86317, y:9.201077, z:81.29814}, rotation:{x:-0.0004973453, y:0.1144915, z:0.01067423}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArmIndex1Gizmo.fbx'},
        {name:'RigLArm2Gizmo', position:{x:71.07181, y:9.197538, z:81.23067}, rotation:{x:0.6963711, y:0.08303782, z:-0.08632681}, scale:{x:1, y:1, z:1}, file:'RigLArm2Gizmo.fbx'},
        {name:'RigLArm1Gizmo', position:{x:71.23096, y:9.196066, z:81.20058}, rotation:{x:0.7007609, y:0.06303377, z:-0.0688773}, scale:{x:1, y:1, z:1}, file:'RigLArm1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:71.30707, y:9.365434, z:81.2}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'RigHair10Gizmo', position:{x:71.21549, y:9.779635, z:80.84255}, rotation:{x:-0.03711532, y:0.887449, z:0.1711706}, scale:{x:0.9999983, y:1.000002, z:0.9999999}, file:'RigHair10Gizmo.fbx'},
        {name:'RigHair9Gizmo', position:{x:71.2178, y:9.750734, z:80.89571}, rotation:{x:0.1873938, y:-0.6744565, z:-0.1614144}, scale:{x:0.9999979, y:1.000002, z:1}, file:'RigHair9Gizmo.fbx'},
        {name:'RigHair8Gizmo', position:{x:71.24343, y:9.697692, z:80.93929}, rotation:{x:0.4354947, y:-0.5404966, z:-0.1816069}, scale:{x:0.9999992, y:1.000001, z:0.9999999}, file:'RigHair8Gizmo.fbx'},
        {name:'RigHair7Gizmo', position:{x:71.29386, y:9.653818, z:80.9591}, rotation:{x:0.5747, y:-0.3300272, z:-0.1714774}, scale:{x:1, y:0.9999998, z:0.9999998}, file:'RigHair7Gizmo.fbx'},
        {name:'RigHair6Gizmo', position:{x:71.30909, y:9.582858, z:80.97138}, rotation:{x:0.2084087, y:-0.2699227, z:-0.568935}, scale:{x:0.9999999, y:1, z:1}, file:'RigHair6Gizmo.fbx'},
        {name:'RigHair5Gizmo', position:{x:71.32639, y:9.530996, z:81.02628}, rotation:{x:-0.04055378, y:-0.4301978, z:-0.4508937}, scale:{x:0.9999999, y:1, z:1}, file:'RigHair5Gizmo.fbx'},
        {name:'RigHair4Gizmo', position:{x:71.3103, y:9.519652, z:81.09428}, rotation:{x:-0.08756594, y:-0.7467649, z:-0.2364214}, scale:{x:1, y:1, z:1}, file:'RigHair4Gizmo.fbx'},
        {name:'RigHair3Gizmo', position:{x:71.30121, y:9.496049, z:81.12473}, rotation:{x:0.09662876, y:-0.6870484, z:-0.3779951}, scale:{x:0.9999999, y:1, z:1}, file:'RigHair3Gizmo.fbx'},
        {name:'RigHair2Gizmo', position:{x:71.29622, y:9.463733, z:81.14894}, rotation:{x:0.1913984, y:-0.6033107, z:-0.4442509}, scale:{x:1, y:0.9999999, z:1}, file:'RigHair2Gizmo.fbx'},
        {name:'RigHair1Gizmo', position:{x:71.30209, y:9.426041, z:81.17657}, rotation:{x:0.2815734, y:-0.562815, z:-0.3477141}, scale:{x:1, y:1, z:1}, file:'RigHair1Gizmo.fbx'},
        {name:'Rig', position:{x:71.30707, y:8.26, z:81.2}, rotation:{x:0.4449968, y:-0.4449968, z:-0.5495251}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Nymph_Fairy', position:{x:71.30707, y:8.353361, z:81.2}, rotation:{x:-0.7032332, y:0.07391278, z:0.07391278}, scale:{x:1, y:1, z:1}, file:'Nymph_Fairy.fbx'},
        {name:'Nymph_Fairy_Purple_(3)', position:{x:71.30707, y:8.26, z:81.2}, rotation:{x:0, y:0.1045285, z:0}, scale:{x:1, y:1, z:1}, file:'Nymph_Fairy_Purple_(3).fbx'},
        {name:'Tower_Base1_2', position:{x:65.41661, y:3.405359, z:91.70208}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Tower_Base1_2.fbx'},
        {name:'TailVfx', position:{x:69.42594, y:7.652985, z:87.49553}, rotation:{x:-0.004337889, y:-0.5877693, z:0.005970759}, scale:{x:1, y:1, z:1}, file:'TailVfx.fbx'},
        {name:'RigTail5Gizmo', position:{x:69.44428, y:7.653861, z:87.55197}, rotation:{x:-0.004337889, y:-0.5877693, z:0.005970759}, scale:{x:1, y:0.9999999, z:1}, file:'RigTail5Gizmo.fbx'},
        {name:'RigTail4Gizmo', position:{x:69.45797, y:7.654514, z:87.59409}, rotation:{x:-0.004337889, y:-0.5877693, z:0.005970759}, scale:{x:1, y:0.9999999, z:1}, file:'RigTail4Gizmo.fbx'},
        {name:'RigTail3Gizmo', position:{x:69.46902, y:7.655043, z:87.62811}, rotation:{x:-0.004337889, y:-0.5877693, z:0.005970759}, scale:{x:1, y:0.9999999, z:1}, file:'RigTail3Gizmo.fbx'},
        {name:'RigTail2Gizmo', position:{x:69.48008, y:7.655571, z:87.66215}, rotation:{x:-0.004337889, y:-0.5877693, z:0.005970759}, scale:{x:1, y:0.9999999, z:1}, file:'RigTail2Gizmo.fbx'},
        {name:'RigTail1Gizmo', position:{x:69.48835, y:7.656876, z:87.6876}, rotation:{x:-0.01432336, y:-0.5876107, z:0.01971458}, scale:{x:0.9999999, y:1, z:1}, file:'RigTail1Gizmo.fbx'},
        {name:'RigRArm2Gizmo', position:{x:69.96993, y:7.926985, z:87.84632}, rotation:{x:0.5505149, y:-0.2583348, z:-0.4496213}, scale:{x:1.000001, y:0.9999999, z:0.9999995}, file:'RigRArm2Gizmo.fbx'},
        {name:'RigRArm1Gizmo', position:{x:69.85638, y:7.82783, z:87.88306}, rotation:{x:0.1518143, y:-0.06749201, z:0.928089}, scale:{x:1, y:1, z:1}, file:'RigRArm1Gizmo.fbx'},
        {name:'RigPelvisGizmo', position:{x:69.58094, y:7.536391, z:87.97256}, rotation:{x:0.415627, y:-0.415627, z:-0.5720614}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigTail5', position:{x:69.44428, y:7.653861, z:87.55197}, rotation:{x:-0.004337858, y:-0.5877693, z:0.005970705}, scale:{x:1, y:1, z:1}, file:'RigTail5.fbx'},
        {name:'RigTail4', position:{x:69.45797, y:7.654514, z:87.59409}, rotation:{x:-0.004337858, y:-0.5877693, z:0.005970705}, scale:{x:1, y:1, z:1}, file:'RigTail4.fbx'},
        {name:'RigTail3', position:{x:69.46902, y:7.655043, z:87.62811}, rotation:{x:-0.004337858, y:-0.5877693, z:0.005970705}, scale:{x:1, y:1, z:1}, file:'RigTail3.fbx'},
        {name:'RigTail2', position:{x:69.48008, y:7.655571, z:87.66215}, rotation:{x:-0.004337858, y:-0.5877693, z:0.005970705}, scale:{x:1, y:1, z:1}, file:'RigTail2.fbx'},
        {name:'RigTail1', position:{x:69.48835, y:7.656876, z:87.6876}, rotation:{x:-0.01432333, y:-0.5876107, z:0.01971453}, scale:{x:1, y:1, z:1}, file:'RigTail1.fbx'},
        {name:'RigRArm2', position:{x:69.96993, y:7.926985, z:87.84632}, rotation:{x:0.5505154, y:-0.2583342, z:-0.449622}, scale:{x:1.000001, y:0.9999999, z:0.9999991}, file:'RigRArm2.fbx'},
        {name:'RigRArm1', position:{x:69.85638, y:7.82783, z:87.88306}, rotation:{x:-0.1518147, y:0.06749351, z:-0.928089}, scale:{x:1, y:1, z:1}, file:'RigRArm1.fbx'},
        {name:'RigLArm2', position:{x:69.19203, y:7.926984, z:88.09908}, rotation:{x:0.2574972, y:-0.5423945, z:-0.6625104}, scale:{x:0.999999, y:1, z:1.000001}, file:'RigLArm2.fbx'},
        {name:'RigLArm1', position:{x:69.3055, y:7.82783, z:88.06206}, rotation:{x:0.9295788, y:-0.3377838, z:-0.1424108}, scale:{x:1, y:1, z:1}, file:'RigLArm1.fbx'},
        {name:'RigHead', position:{x:69.58094, y:7.79283, z:87.97256}, rotation:{x:0.415627, y:-0.415627, z:-0.5720614}, scale:{x:1, y:1, z:1}, file:'RigHead.fbx'},
        {name:'RigSpine2', position:{x:69.58094, y:7.657201, z:87.97256}, rotation:{x:0.415627, y:-0.415627, z:-0.5720614}, scale:{x:1, y:1, z:1}, file:'RigSpine2.fbx'},
        {name:'RigSpine1', position:{x:69.58094, y:7.536391, z:87.97256}, rotation:{x:0.415627, y:-0.415627, z:-0.5720614}, scale:{x:1, y:1, z:1}, file:'RigSpine1.fbx'},
        {name:'RigPelvis', position:{x:69.58094, y:7.536391, z:87.97256}, rotation:{x:0.415627, y:-0.415627, z:-0.5720614}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigLArm2Gizmo', position:{x:69.19204, y:7.926985, z:88.09908}, rotation:{x:-0.2574976, y:0.5423944, z:0.6625109}, scale:{x:0.9999995, y:1, z:1}, file:'RigLArm2Gizmo.fbx'},
        {name:'RigLArm1Gizmo', position:{x:69.3055, y:7.82783, z:88.06206}, rotation:{x:0.9295789, y:-0.3377839, z:-0.1424103}, scale:{x:1, y:1, z:1}, file:'RigLArm1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:69.58094, y:7.792831, z:87.97256}, rotation:{x:0.415627, y:-0.415627, z:-0.5720614}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'Rig', position:{x:69.5611, y:7.491755, z:87.91149}, rotation:{x:0.415627, y:-0.415627, z:-0.5720614}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Mini_Devil', position:{x:69.58134, y:7.491755, z:87.9738}, rotation:{x:-0.6984012, y:0.1106159, z:0.1106159}, scale:{x:1, y:1, z:1}, file:'Mini_Devil.fbx'},
        {name:'Forest_Bat_Green_(1)', position:{x:69.58134, y:7.491755, z:87.9738}, rotation:{x:0, y:0.1564345, z:0}, scale:{x:1, y:1, z:1}, file:'Forest_Bat_Green_(1).fbx'},
        {name:'Crystal4', position:{x:74.94442, y:3.510327, z:96.34767}, rotation:{x:-0.2278275, y:-0.165407, z:0.1967504}, scale:{x:1.178284, y:1.178284, z:1.178284}, file:'Crystal4.fbx'},
        {name:'Tesla_part', position:{x:68.35783, y:5.381933, z:79.61115}, rotation:{x:0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tesla_part.fbx'},
        {name:'Tesla_glow_up', position:{x:68.35783, y:3.656618, z:79.61115}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tesla_glow_up.fbx'},
        {name:'Tesla_glow', position:{x:68.35783, y:4.893579, z:79.61115}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tesla_glow.fbx'},
        {name:'Tow_Tesla3', position:{x:68.35783, y:3.518393, z:79.61115}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tow_Tesla3.fbx'},
        {name:'Ivy2', position:{x:75.51774, y:5.924901, z:95.06777}, rotation:{x:0, y:-0.531368, z:0}, scale:{x:1.112361, y:1.112361, z:1.112361}, file:'Ivy2.fbx'},
        {name:'Fence2', position:{x:67.70831, y:3.405799, z:86.58112}, rotation:{x:0, y:-0.3833469, z:0}, scale:{x:1, y:1, z:1}, file:'Fence2.fbx'},
        {name:'Rune_particle2', position:{x:78.52692, y:5.632711, z:103.4958}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_particle2.fbx'},
        {name:'Rune_particle1', position:{x:78.52692, y:5.612352, z:103.4958}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_particle1.fbx'},
        {name:'Rune_01', position:{x:78.61496, y:5.492013, z:103.5052}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_01.fbx'},
        {name:'Rock_06', position:{x:78.20473, y:5.505301, z:73.13804}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Tree_Pine_2', position:{x:83.54343, y:3.411572, z:74.28679}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_2.fbx'},
        {name:'Wagon_Tracks1', position:{x:92.48, y:3.4, z:80.91}, rotation:{x:0, y:0.9221426, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_Tracks1.fbx'},
        {name:'Crystal5', position:{x:73.68556, y:3.395465, z:74.15327}, rotation:{x:0, y:0, z:0}, scale:{x:0.5454153, y:0.5454153, z:0.5454153}, file:'Crystal5.fbx'},
        {name:'Tree_05', position:{x:58.31014, y:3.405359, z:96.27293}, rotation:{x:0, y:0.2241993, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_05.fbx'},
        {name:'Stair_el4', position:{x:75.85287, y:2.332726, z:86.2957}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el4.fbx'},
        {name:'Block4', position:{x:94.74, y:3.5, z:79.6}, rotation:{x:0, y:0.7477763, z:0}, scale:{x:1, y:1, z:1}, file:'Block4.fbx'},
        {name:'Bush_08', position:{x:77.84686, y:3.318777, z:76.91458}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_08.fbx'},
        {name:'Plant_01', position:{x:65.52877, y:3.43448, z:75.84209}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_01.fbx'},
        {name:'Tree_Pine_1', position:{x:60.76904, y:3.405359, z:98.1169}, rotation:{x:0, y:-0.003596854, z:0}, scale:{x:1, y:0.611823, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Plant_02', position:{x:91.60577, y:3.426406, z:89.7377}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_02.fbx'},
        {name:'Rock_12', position:{x:83.26, y:5.72, z:95.01}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_12.fbx'},
        {name:'Rock_13', position:{x:84.5, y:2, z:69.64}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_13.fbx'},
        {name:'Poison_Breath', position:{x:69.2675, y:3.405359, z:93.52393}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Poison_Breath.fbx'},
        {name:'Rock_03', position:{x:73.62844, y:2.179282, z:84.59933}, rotation:{x:-0.02898568, y:0.6264628, z:0.04405151}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Fern1', position:{x:71.48345, y:3.429348, z:87.13197}, rotation:{x:0, y:0, z:0}, scale:{x:1.069844, y:1.069844, z:1.069844}, file:'Fern1.fbx'},
        {name:'Stair_el7', position:{x:74.62239, y:2.401285, z:85.20042}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el7.fbx'},
        {name:'Tree_Pine_2', position:{x:88.26199, y:3.400675, z:97.48295}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_2.fbx'},
        {name:'Tree_Dead1', position:{x:71.49023, y:1.969059, z:82.01852}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Dead1.fbx'},
        {name:'TreantGuard', position:{x:80.46, y:2.3741, z:85.075}, rotation:{x:-0.5000001, y:-0.5000001, z:-0.5000001}, scale:{x:1, y:1, z:1}, file:'TreantGuard.fbx'},
        {name:'RigRLegPlatform', position:{x:80.3668, y:2.3741, z:85.43247}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigRLegPlatform.fbx'},
        {name:'RigRibcageGizmo', position:{x:80.48076, y:4.077638, z:85.075}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigRibcageGizmo.fbx'},
        {name:'RigRArmPalmGizmo', position:{x:80.46463, y:4.027826, z:86.55344}, rotation:{x:-0.4946384, y:0.4911258, z:0.4976987}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArmPalmGizmo.fbx'},
        {name:'RigRArmDigit12Gizmo', position:{x:80.45881, y:3.977615, z:86.89152}, rotation:{x:-0.4946384, y:0.4911258, z:0.4976987}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRArmDigit12Gizmo.fbx'},
        {name:'RigRArmDigit11Gizmo', position:{x:80.45964, y:3.978665, z:86.77815}, rotation:{x:-0.4957293, y:0.4995652, z:0.4967481}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArmDigit11Gizmo.fbx'},
        {name:'RigRArm2Gizmo', position:{x:80.50138, y:4.052697, z:86.12042}, rotation:{x:-0.5051434, y:0.464864, z:0.491614}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRArm2Gizmo.fbx'},
        {name:'RigRArm1Gizmo', position:{x:80.48076, y:4.077638, z:85.75278}, rotation:{x:-0.4687202, y:0.4964881, z:0.5305045}, scale:{x:0.9999999, y:1, z:1}, file:'RigRArm1Gizmo.fbx'},
        {name:'RigPelvisGizmo', position:{x:80.48076, y:3.172441, z:85.075}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigRArmDigit12', position:{x:80.45881, y:3.977614, z:86.89152}, rotation:{x:0.4946317, y:-0.4911306, z:-0.4977041}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRArmDigit12.fbx'},
        {name:'RigRArmDigit11', position:{x:80.45964, y:3.978665, z:86.77815}, rotation:{x:0.4957272, y:-0.4995669, z:-0.4967502}, scale:{x:1, y:0.9999998, z:1}, file:'RigRArmDigit11.fbx'},
        {name:'Mace', position:{x:80.46152, y:3.905267, z:86.6786}, rotation:{x:2.528811E-07, y:-0.7071039, z:-9.272308E-07}, scale:{x:1.183825, y:1.183825, z:1.183825}, file:'Mace.fbx'},
        {name:'DummyWeapon', position:{x:80.45999, y:3.871095, z:86.68059}, rotation:{x:2.528811E-07, y:-0.7071039, z:-9.272308E-07}, scale:{x:1.000001, y:1, z:0.9999996}, file:'DummyWeapon.fbx'},
        {name:'RigRArmPalm', position:{x:80.46463, y:4.027826, z:86.55344}, rotation:{x:0.4946382, y:-0.4911259, z:-0.4976992}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArmPalm.fbx'},
        {name:'RigRArm2', position:{x:80.50138, y:4.052697, z:86.12042}, rotation:{x:0.5051434, y:-0.464864, z:-0.4916141}, scale:{x:1, y:1, z:1}, file:'RigRArm2.fbx'},
        {name:'RigRArm1', position:{x:80.48076, y:4.077639, z:85.75278}, rotation:{x:0.4687203, y:-0.496488, z:-0.5305045}, scale:{x:1, y:1, z:1}, file:'RigRArm1.fbx'},
        {name:'RigRArmCollarbone', position:{x:80.02976, y:4.077639, z:85.32529}, rotation:{x:0.2618343, y:-0.6568432, z:-0.6568431}, scale:{x:1, y:1, z:1}, file:'RigRArmCollarbone.fbx'},
        {name:'RigMouth', position:{x:80.22459, y:4.308259, z:85.075}, rotation:{x:0.9999316, y:0.01170242, z:0}, scale:{x:1, y:1, z:1}, file:'RigMouth.fbx'},
        {name:'Fireflies', position:{x:80.48076, y:4.999874, z:85.075}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'Fireflies.fbx'},
        {name:'RigHead', position:{x:80.48076, y:4.469011, z:85.075}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck', position:{x:80.48076, y:4.077639, z:85.075}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigNeck.fbx'},
        {name:'RigLArmDigit12', position:{x:80.45881, y:3.977615, z:83.25848}, rotation:{x:0.4946394, y:-0.4911251, z:0.4976982}, scale:{x:1, y:1, z:0.9999998}, file:'RigLArmDigit12.fbx'},
        {name:'RigLArmDigit11', position:{x:80.45964, y:3.978666, z:83.37184}, rotation:{x:0.4957315, y:-0.4995639, z:0.4967467}, scale:{x:1, y:1, z:0.9999999}, file:'RigLArmDigit11.fbx'},
        {name:'RigLArmPalm', position:{x:80.46463, y:4.027826, z:83.59655}, rotation:{x:0.4946394, y:-0.4911251, z:0.4976982}, scale:{x:1, y:1, z:0.9999999}, file:'RigLArmPalm.fbx'},
        {name:'RigLArm2', position:{x:80.50138, y:4.052697, z:84.02957}, rotation:{x:0.5051438, y:-0.4648639, z:0.4916139}, scale:{x:1, y:1, z:1}, file:'RigLArm2.fbx'},
        {name:'RigLArm1', position:{x:80.48076, y:4.077639, z:84.39721}, rotation:{x:0.4687202, y:-0.4964882, z:0.5305046}, scale:{x:1, y:1, z:1}, file:'RigLArm1.fbx'},
        {name:'RigLArmCollarbone', position:{x:80.02976, y:4.077639, z:84.8247}, rotation:{x:0.2618342, y:-0.6568432, z:0.6568432}, scale:{x:1, y:1, z:1}, file:'RigLArmCollarbone.fbx'},
        {name:'RigRibcage', position:{x:80.48076, y:4.077639, z:85.075}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigRibcage.fbx'},
        {name:'RigSpine3', position:{x:80.48076, y:3.696636, z:85.075}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigSpine3.fbx'},
        {name:'RigSpine2', position:{x:80.48076, y:3.454613, z:85.075}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigSpine2.fbx'},
        {name:'RigSpine1', position:{x:80.48076, y:3.172441, z:85.075}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigSpine1.fbx'},
        {name:'RigRLegfoot1', position:{x:80.36905, y:2.417193, z:85.43253}, rotation:{x:-8.297661E-08, y:5.960464E-08, z:2.572275E-06}, scale:{x:1, y:0.9999999, z:1}, file:'RigRLegfoot1.fbx'},
        {name:'RigRLegAnkle', position:{x:80.45461, y:2.566243, z:85.43249}, rotation:{x:-8.454919E-05, y:8.049607E-05, z:0.547586}, scale:{x:1.000013, y:0.9999864, z:1}, file:'RigRLegAnkle.fbx'},
        {name:'RigRLeg2', position:{x:80.4351, y:2.755325, z:85.42693}, rotation:{x:-0.01073065, y:0.009895802, z:0.7424263}, scale:{x:1.000004, y:0.9999967, z:1}, file:'RigRLeg2.fbx'},
        {name:'RigRLeg1', position:{x:80.48074, y:3.013895, z:85.41926}, rotation:{x:-0.002067864, y:0.01733115, z:0.6425132}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigRLeg1.fbx'},
        {name:'RigLLegfoot1', position:{x:80.36906, y:2.4172, z:84.71746}, rotation:{x:-8.165955E-08, y:5.960464E-08, z:-2.736911E-06}, scale:{x:1, y:0.9999999, z:1}, file:'RigLLegfoot1.fbx'},
        {name:'RigLLegAnkle', position:{x:80.45462, y:2.566244, z:84.71751}, rotation:{x:8.442998E-05, y:-8.046627E-05, z:0.5475814}, scale:{x:0.9999869, y:1.000013, z:0.9999995}, file:'RigLLegAnkle.fbx'},
        {name:'RigLLeg2', position:{x:80.43511, y:2.755324, z:84.72308}, rotation:{x:0.01077345, y:-0.009915769, z:0.7424307}, scale:{x:0.9999964, y:1.000004, z:1}, file:'RigLLeg2.fbx'},
        {name:'RigLLeg1', position:{x:80.48074, y:3.013895, z:84.73074}, rotation:{x:0.002043575, y:-0.01730874, z:0.6425184}, scale:{x:0.9999999, y:1, z:1}, file:'RigLLeg1.fbx'},
        {name:'RigPelvis', position:{x:80.48076, y:3.172441, z:85.075}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigMouthGizmo', position:{x:80.22459, y:4.308259, z:85.075}, rotation:{x:-0.9999316, y:-0.0117023, z:0}, scale:{x:1, y:0.9999999, z:1}, file:'RigMouthGizmo.fbx'},
        {name:'RigLLegPlatform', position:{x:80.3668, y:2.3741, z:84.71752}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigLLegPlatform.fbx'},
        {name:'RigLArmPalmGizmo', position:{x:80.46463, y:4.027826, z:83.59655}, rotation:{x:0.4946389, y:-0.4911256, z:0.4976985}, scale:{x:1, y:1, z:0.9999999}, file:'RigLArmPalmGizmo.fbx'},
        {name:'RigLArmDigit12Gizmo', position:{x:80.45881, y:3.977616, z:83.25848}, rotation:{x:0.4946389, y:-0.4911255, z:0.4976985}, scale:{x:1, y:1, z:0.9999999}, file:'RigLArmDigit12Gizmo.fbx'},
        {name:'RigLArmDigit11Gizmo', position:{x:80.45964, y:3.978666, z:83.37184}, rotation:{x:0.4957321, y:-0.4995633, z:0.4967461}, scale:{x:1, y:1, z:0.9999999}, file:'RigLArmDigit11Gizmo.fbx'},
        {name:'RigLArm2Gizmo', position:{x:80.50138, y:4.052697, z:84.02957}, rotation:{x:0.5051438, y:-0.4648638, z:0.4916137}, scale:{x:1, y:1, z:0.9999999}, file:'RigLArm2Gizmo.fbx'},
        {name:'RigLArm1Gizmo', position:{x:80.48076, y:4.077638, z:84.39721}, rotation:{x:0.4687201, y:-0.4964882, z:0.5305046}, scale:{x:1, y:1, z:1}, file:'RigLArm1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:80.48076, y:4.469011, z:85.075}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'Rig', position:{x:80.48076, y:2.3741, z:85.075}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'TreantGuard_Blue', position:{x:80.46, y:2.3741, z:85.075}, rotation:{x:0, y:-0.7071068, z:0}, scale:{x:1.1965, y:1.1965, z:1.1965}, file:'TreantGuard_Blue.fbx'},
        {name:'Crystal1', position:{x:93.4151, y:3.743075, z:78.98325}, rotation:{x:-0.2631057, y:0.3207543, z:0.4804994}, scale:{x:1, y:1, z:1}, file:'Crystal1.fbx'},
        {name:'Wagon_wagon', position:{x:74.32363, y:3.46635, z:94.67554}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_wagon.fbx'},
        {name:'Rock_17B', position:{x:62.95356, y:3.405179, z:100.0487}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_17B.fbx'},
        {name:'Stair_el5', position:{x:62.59, y:4.8, z:79.59}, rotation:{x:0, y:0.6932201, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el5.fbx'},
        {name:'Crystal5', position:{x:92.49168, y:3.405747, z:79.22677}, rotation:{x:0, y:-0.5153139, z:0}, scale:{x:0.5454153, y:0.5454153, z:0.5454153}, file:'Crystal5.fbx'},
        {name:'Bush_03', position:{x:75.22385, y:5.515959, z:107.3711}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_03.fbx'},
        {name:'Tree_Small_01', position:{x:87.55914, y:5.491552, z:102.9213}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Small_01.fbx'},
        {name:'Ground_Decal_Leaves1', position:{x:76.44469, y:5.492392, z:105.1273}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_Leaves1.fbx'},
        {name:'Mushroom1A', position:{x:85.78904, y:3.405649, z:82.36559}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom1A.fbx'},
        {name:'Floor6', position:{x:74.50994, y:3.405623, z:91.14948}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Floor6.fbx'},
        {name:'Floor4', position:{x:72.74946, y:3.405847, z:87.72002}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Floor4.fbx'},
        {name:'Jar2B', position:{x:77.82807, y:3.914639, z:90.80018}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Jar2B.fbx'},
        {name:'Plant_03', position:{x:66.95327, y:3.429039, z:97.67604}, rotation:{x:0, y:0.7148612, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_03.fbx'},
        {name:'Bush_01', position:{x:77.68543, y:5.399721, z:70.2733}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_01.fbx'},
        {name:'Rock_14A', position:{x:71.31049, y:3.405832, z:97.02116}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_14A.fbx'},
        {name:'Rock_11', position:{x:80.93, y:5.52, z:71.08}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_11.fbx'},
        {name:'Stair_el5', position:{x:74.24381, y:3.065399, z:86.13245}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el5.fbx'},
        {name:'Plant_07', position:{x:88.83092, y:1.989657, z:88.96104}, rotation:{x:0, y:0.6107043, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_07.fbx'},
        {name:'Fire2', position:{x:71.70714, y:3.16547, z:95.71835}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire2.fbx'},
        {name:'Fire_long', position:{x:71.70714, y:4.284951, z:95.69994}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire_long.fbx'},
        {name:'Fire_sparks', position:{x:71.70714, y:3.43766, z:95.71835}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire_sparks.fbx'},
        {name:'Fire2', position:{x:71.70714, y:3.286301, z:95.71835}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire2.fbx'},
        {name:'Campfire_particle', position:{x:71.70714, y:3.286301, z:95.71835}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Campfire_particle.fbx'},
        {name:'Point_light', position:{x:71.87714, y:4.5143, z:95.72234}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Point_light.fbx'},
        {name:'Campfire', position:{x:71.79424, y:3.40547, z:95.68973}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Campfire.fbx'},
        {name:'Rock_17B', position:{x:76.63, y:5.49, z:106}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_17B.fbx'},
        {name:'Crystal1', position:{x:83.91312, y:3.657628, z:93.84229}, rotation:{x:-0.1988504, y:-0.2824835, z:-0.2097036}, scale:{x:1, y:1, z:1}, file:'Crystal1.fbx'},
        {name:'Rock_04', position:{x:72.05624, y:2.930651, z:84.4827}, rotation:{x:-0.25277, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_04.fbx'},
        {name:'Crystal5', position:{x:73.35045, y:3.405675, z:95.0008}, rotation:{x:0, y:-0.8446676, z:0}, scale:{x:0.5454153, y:0.5454153, z:0.5454153}, file:'Crystal5.fbx'},
        {name:'Tree_Pine_3', position:{x:68.96906, y:3.405799, z:80.93157}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_3.fbx'},
        {name:'Plant_06', position:{x:83.45199, y:1.997678, z:89.95533}, rotation:{x:0, y:0.6319531, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_06.fbx'},
        {name:'Floor5', position:{x:72.86419, y:3.405869, z:90.26986}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Floor5.fbx'},
        {name:'Bush_06', position:{x:93.82825, y:3.351763, z:85.7327}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_06.fbx'},
        {name:'Crystal5', position:{x:74.57712, y:3.405754, z:93.32267}, rotation:{x:0, y:0, z:0}, scale:{x:0.5454153, y:0.5454153, z:0.5454153}, file:'Crystal5.fbx'},
        {name:'Rock_17B', position:{x:84.60176, y:3.40597, z:82.32252}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_17B.fbx'},
        {name:'Tree_03', position:{x:61.49238, y:3.405532, z:107.2643}, rotation:{x:0, y:-0.4450598, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_03.fbx'},
        {name:'Rock_12', position:{x:82.43, y:5.37, z:70.06}, rotation:{x:0, y:0.4063953, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_12.fbx'},
        {name:'Fern1', position:{x:68.63441, y:3.543947, z:95.77998}, rotation:{x:-0.110877, y:0.5420622, z:-0.05051435}, scale:{x:1.069844, y:1.069844, z:1.069844}, file:'Fern1.fbx'},
        {name:'Ivy2', position:{x:70.56989, y:8.178499, z:101.9265}, rotation:{x:0, y:0, z:0}, scale:{x:1.112361, y:1.112361, z:1.112361}, file:'Ivy2.fbx'},
        {name:'Rock_07', position:{x:72.1264, y:3.4693, z:99.24614}, rotation:{x:-0.2368198, y:-0.1026981, z:0.1876362}, scale:{x:1, y:1, z:1}, file:'Rock_07.fbx'},
        {name:'Tree_Pine_2', position:{x:92.12279, y:3.414131, z:87.32602}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_2.fbx'},
        {name:'Rock_02', position:{x:57.88361, y:3.43962, z:107.4567}, rotation:{x:0.02160607, y:0.1197675, z:0.1377964}, scale:{x:1, y:1, z:1}, file:'Rock_02.fbx'},
        {name:'Ivy2', position:{x:75.95483, y:6.305859, z:96.53854}, rotation:{x:-0.04283405, y:0.5755119, z:-0.1553269}, scale:{x:1.112361, y:1.112361, z:1.112361}, file:'Ivy2.fbx'},
        {name:'Rock_05', position:{x:74.51715, y:5.580506, z:97.7301}, rotation:{x:-0.04467429, y:0.3566035, z:0.1840819}, scale:{x:1, y:1, z:1}, file:'Rock_05.fbx'},
        {name:'Crystal5', position:{x:93.15405, y:3.405738, z:80.1187}, rotation:{x:0, y:0.9652208, z:0}, scale:{x:0.5454153, y:0.5454153, z:0.5454153}, file:'Crystal5.fbx'},
        {name:'Ivy4', position:{x:89.31779, y:3.411325, z:72.65352}, rotation:{x:0, y:-0.3377295, z:0}, scale:{x:1, y:1, z:1}, file:'Ivy4.fbx'},
        {name:'Stair_el6', position:{x:70.31229, y:3.506064, z:107.0391}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el6.fbx'},
        {name:'Wagon_Tracks1', position:{x:88.88, y:3.4, z:77.41}, rotation:{x:0, y:0.9221426, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_Tracks1.fbx'},
        {name:'Rock_13', position:{x:69.96521, y:2.70461, z:111.3841}, rotation:{x:0, y:-0.8972162, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_13.fbx'},
        {name:'Ground_Decal_Leaves1', position:{x:65.19901, y:3.405359, z:97.81879}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_Leaves1.fbx'},
        {name:'Wagon_Tracks1', position:{x:85.523, y:3.4, z:91.552}, rotation:{x:0, y:0.3785093, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_Tracks1.fbx'},
        {name:'Mushroom4A', position:{x:65.55415, y:3.405359, z:89.4469}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom4A.fbx'},
        {name:'Stair_el4', position:{x:62.68, y:4.8, z:77.39}, rotation:{x:0, y:0.6932201, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el4.fbx'},
        {name:'Tree_05', position:{x:58.84, y:5.5, z:84.8}, rotation:{x:0, y:0.7077274, z:0}, scale:{x:1, y:0.7169965, z:1}, file:'Tree_05.fbx'},
        {name:'Crystal1', position:{x:74.34013, y:2.747287, z:75.33177}, rotation:{x:0.1392921, y:0.9536462, z:0.2571615}, scale:{x:1, y:1, z:1}, file:'Crystal1.fbx'},
        {name:'Rock_14B', position:{x:73.95723, y:5.583142, z:99.03471}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_14B.fbx'},
        {name:'RigRLegPlatform', position:{x:69.3112, y:3.405357, z:92.7144}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigRLegPlatform.fbx'},
        {name:'RigRibcageGizmo', position:{x:69.3112, y:3.751809, z:92.59109}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigRibcageGizmo.fbx'},
        {name:'RigRArmPalmGizmo', position:{x:69.31532, y:3.810839, z:93.01724}, rotation:{x:-0.4988712, y:0.4946345, z:0.49762}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRArmPalmGizmo.fbx'},
        {name:'RigRArm2Gizmo', position:{x:69.3137, y:3.810437, z:92.90948}, rotation:{x:-0.4936366, y:0.5082295, z:0.4992271}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRArm2Gizmo.fbx'},
        {name:'RigRArm1Gizmo', position:{x:69.3112, y:3.812167, z:92.79854}, rotation:{x:-0.4908561, y:0.5012473, z:0.5098788}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArm1Gizmo.fbx'},
        {name:'RigPelvisGizmo', position:{x:69.3112, y:3.621806, z:92.59109}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigRArmDigit12', position:{x:69.314, y:3.809742, z:93.14575}, rotation:{x:0.4988683, y:-0.4946353, z:-0.4976238}, scale:{x:0.9999998, y:1, z:0.9999999}, file:'RigRArmDigit12.fbx'},
        {name:'RigRArmDigit11', position:{x:69.31432, y:3.810008, z:93.08205}, rotation:{x:0.49847, y:-0.4994245, z:-0.4980667}, scale:{x:0.9999998, y:0.9999999, z:1}, file:'RigRArmDigit11.fbx'},
        {name:'RigRArmPalm', position:{x:69.31532, y:3.810839, z:93.01724}, rotation:{x:0.4988711, y:-0.4946344, z:-0.49762}, scale:{x:0.9999998, y:0.9999999, z:1}, file:'RigRArmPalm.fbx'},
        {name:'RigRArm2', position:{x:69.3137, y:3.810437, z:92.90948}, rotation:{x:0.4936367, y:-0.5082295, z:-0.4992272}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRArm2.fbx'},
        {name:'RigRArm1', position:{x:69.3112, y:3.812167, z:92.79854}, rotation:{x:0.4908561, y:-0.5012472, z:-0.5098788}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArm1.fbx'},
        {name:'RigRArmCollarbone', position:{x:69.17525, y:3.792542, z:92.67524}, rotation:{x:0.2750199, y:-0.7114595, z:-0.6017506}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmCollarbone.fbx'},
        {name:'RigMouth', position:{x:69.20563, y:3.824298, z:92.59109}, rotation:{x:0.9999857, y:-0.005374939, z:-2.980232E-08}, scale:{x:1, y:1, z:1}, file:'RigMouth.fbx'},
        {name:'RigHead', position:{x:69.3112, y:3.974377, z:92.59109}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck', position:{x:69.3112, y:3.751809, z:92.59109}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigNeck.fbx'},
        {name:'RigLArmDigit12', position:{x:69.314, y:3.809744, z:92.03642}, rotation:{x:0.4988756, y:-0.4946334, z:0.4976145}, scale:{x:0.9999998, y:1, z:0.9999998}, file:'RigLArmDigit12.fbx'},
        {name:'RigLArmDigit11', position:{x:69.31432, y:3.810009, z:92.10013}, rotation:{x:0.4984742, y:-0.4994234, z:0.4980611}, scale:{x:0.9999998, y:1, z:0.9999998}, file:'RigLArmDigit11.fbx'},
        {name:'RigLArmPalm', position:{x:69.31532, y:3.81084, z:92.16493}, rotation:{x:0.4988727, y:-0.4946343, z:0.4976182}, scale:{x:0.9999998, y:1, z:0.9999999}, file:'RigLArmPalm.fbx'},
        {name:'RigLArm2', position:{x:69.3137, y:3.810437, z:92.2727}, rotation:{x:0.4936366, y:-0.5082297, z:0.4992271}, scale:{x:1, y:1, z:1}, file:'RigLArm2.fbx'},
        {name:'RigLArm1', position:{x:69.3112, y:3.812167, z:92.38364}, rotation:{x:0.4908561, y:-0.5012474, z:0.5098788}, scale:{x:1, y:1, z:1}, file:'RigLArm1.fbx'},
        {name:'RigLArmCollarbone', position:{x:69.17525, y:3.792542, z:92.50694}, rotation:{x:0.2750198, y:-0.7114595, z:0.6017506}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArmCollarbone.fbx'},
        {name:'RigRibcage', position:{x:69.3112, y:3.751809, z:92.59109}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigRibcage.fbx'},
        {name:'RigSpineUp', position:{x:69.3112, y:3.686808, z:92.59109}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigSpineUp.fbx'},
        {name:'RigSpineLow', position:{x:69.3112, y:3.621806, z:92.59109}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigSpineLow.fbx'},
        {name:'RigRLegAnkle', position:{x:69.30833, y:3.420168, z:92.71439}, rotation:{x:1.892447E-05, y:-3.20375E-05, z:0.510391}, scale:{x:0.9999998, y:1, z:1}, file:'RigRLegAnkle.fbx'},
        {name:'RigRLeg2', position:{x:69.30479, y:3.486302, z:92.71481}, rotation:{x:0.01488021, y:0.01119578, z:0.7256587}, scale:{x:1, y:0.9999999, z:1}, file:'RigRLeg2.fbx'},
        {name:'RigRLeg1', position:{x:69.3112, y:3.5446, z:92.71531}, rotation:{x:0.0043073, y:-0.001985371, z:0.6673764}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLeg1.fbx'},
        {name:'RigLLegAnkle', position:{x:69.30833, y:3.420168, z:92.46778}, rotation:{x:-1.558661E-05, y:2.616644E-05, z:0.5105393}, scale:{x:0.9999998, y:1, z:1}, file:'RigLLegAnkle.fbx'},
        {name:'RigLLeg2', position:{x:69.3048, y:3.486302, z:92.46738}, rotation:{x:-0.01483908, y:-0.01121858, z:0.7256567}, scale:{x:1, y:0.9999999, z:0.9999996}, file:'RigLLeg2.fbx'},
        {name:'RigLLeg1', position:{x:69.3112, y:3.5446, z:92.46687}, rotation:{x:-0.004345208, y:0.002020657, z:0.6673784}, scale:{x:1, y:1, z:1}, file:'RigLLeg1.fbx'},
        {name:'RigPelvis', position:{x:69.3112, y:3.621806, z:92.59109}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigMouthGizmo', position:{x:69.20563, y:3.824298, z:92.59109}, rotation:{x:-0.9999856, y:0.005375014, z:0}, scale:{x:1, y:1, z:1}, file:'RigMouthGizmo.fbx'},
        {name:'RigLLegPlatform', position:{x:69.3112, y:3.405357, z:92.46777}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigLLegPlatform.fbx'},
        {name:'RigLArmPalmGizmo', position:{x:69.31532, y:3.81084, z:92.16493}, rotation:{x:0.4988726, y:-0.4946342, z:0.4976181}, scale:{x:0.9999999, y:1, z:1}, file:'RigLArmPalmGizmo.fbx'},
        {name:'RigLArm2Gizmo', position:{x:69.3137, y:3.810437, z:92.2727}, rotation:{x:0.4936365, y:-0.5082297, z:0.499227}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLArm2Gizmo.fbx'},
        {name:'RigLArm1Gizmo', position:{x:69.3112, y:3.812167, z:92.38364}, rotation:{x:0.490856, y:-0.5012473, z:0.5098787}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArm1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:69.3112, y:3.974377, z:92.59109}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'Rig', position:{x:69.3112, y:3.405357, z:92.59109}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Evil_Mushroom', position:{x:69.3112, y:3.405357, z:92.59109}, rotation:{x:-0.5000001, y:-0.5000001, z:-0.5000001}, scale:{x:1, y:1, z:1}, file:'Evil_Mushroom.fbx'},
        {name:'Evil_Mushroom_Purple', position:{x:69.3112, y:3.405357, z:92.59109}, rotation:{x:0, y:-0.7071068, z:0}, scale:{x:1, y:1, z:1}, file:'Evil_Mushroom_Purple.fbx'},
        {name:'Bush_01', position:{x:66.48958, y:3.429039, z:102.3906}, rotation:{x:0, y:-0.9741029, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_01.fbx'},
        {name:'Bush_05', position:{x:83.24072, y:6.097446, z:95.72634}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_05.fbx'},
        {name:'Ground_Decal1_(1)', position:{x:86.93442, y:3.405968, z:80.44353}, rotation:{x:0, y:0.9984967, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal1_(1).fbx'},
        {name:'Wagon_Tracks1', position:{x:65.56157, y:3.406834, z:82.37539}, rotation:{x:0, y:0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_Tracks1.fbx'},
        {name:'Plant_01', position:{x:75.90392, y:3.738484, z:92.98844}, rotation:{x:-0.1225732, y:0.06688824, z:0.09815945}, scale:{x:1, y:1, z:1}, file:'Plant_01.fbx'},
        {name:'Tree_06', position:{x:95.21535, y:3.392318, z:86.90849}, rotation:{x:0, y:0.9998423, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_06.fbx'},
        {name:'Ground_Decal_Leaves1', position:{x:82.90952, y:3.405518, z:77.26871}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_Leaves1.fbx'},
        {name:'Wagon_wagon', position:{x:84.944, y:3.495, z:92.104}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_wagon.fbx'},
        {name:'RigTail6Gizmo', position:{x:77.79662, y:3.068463, z:81.79692}, rotation:{x:0.178363, y:-0.6842417, z:-0.1783631}, scale:{x:1, y:1, z:1}, file:'RigTail6Gizmo.fbx'},
        {name:'RigTail5Gizmo', position:{x:77.79662, y:3.042938, z:81.87614}, rotation:{x:0.1097588, y:-0.6985364, z:-0.1097589}, scale:{x:1, y:1, z:1}, file:'RigTail5Gizmo.fbx'},
        {name:'RigTail4Gizmo', position:{x:77.79662, y:3.089758, z:82.05163}, rotation:{x:-0.09191881, y:-0.701107, z:0.09191876}, scale:{x:1, y:1, z:1}, file:'RigTail4Gizmo.fbx'},
        {name:'RigTail3Gizmo', position:{x:77.79662, y:3.144811, z:82.16458}, rotation:{x:-0.1589731, y:-0.6890048, z:0.158973}, scale:{x:1, y:0.9999999, z:1}, file:'RigTail3Gizmo.fbx'},
        {name:'RigTail2Gizmo', position:{x:77.79662, y:3.188532, z:82.28048}, rotation:{x:-0.1268487, y:-0.695636, z:0.1268487}, scale:{x:0.9999999, y:1, z:1}, file:'RigTail2Gizmo.fbx'},
        {name:'RigRLegPlatform_1', position:{x:77.96705, y:2.496923, z:82.96882}, rotation:{x:0.4889005, y:-0.4889005, z:-0.5108584}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigRLegPlatform_1.fbx'},
        {name:'RigRLegPlatform', position:{x:77.95926, y:2.496923, z:82.30416}, rotation:{x:0.4981787, y:-0.4981787, z:-0.5018147}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLegPlatform.fbx'},
        {name:'RigRLegPalmGizmo_1', position:{x:77.94343, y:2.754649, z:82.20934}, rotation:{x:0.3753951, y:0.5603338, z:0.4680451}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigRLegPalmGizmo_1.fbx'},
        {name:'RigRLegPalmGizmo', position:{x:77.96702, y:2.603072, z:82.85851}, rotation:{x:0.2728069, y:0.6514624, z:0.2628601}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLegPalmGizmo.fbx'},
        {name:'RigRLeg2Gizmo_1', position:{x:77.95589, y:2.849162, z:82.81618}, rotation:{x:0.4649611, y:0.569722, z:0.4446055}, scale:{x:1.000001, y:0.9999985, z:0.9999999}, file:'RigRLeg2Gizmo_1.fbx'},
        {name:'RigRLeg2Gizmo', position:{x:77.95031, y:2.851818, z:82.29833}, rotation:{x:0.6885519, y:0.3358441, z:0.6009607}, scale:{x:1, y:1, z:1}, file:'RigRLeg2Gizmo.fbx'},
        {name:'RigRLeg1Gizmo_1', position:{x:77.92795, y:3.113397, z:82.31833}, rotation:{x:0.5350664, y:0.5465714, z:0.493681}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRLeg1Gizmo_1.fbx'},
        {name:'RigRLeg1Gizmo', position:{x:77.96785, y:3.053978, z:83.05224}, rotation:{x:0.6875911, y:0.2772123, z:0.635634}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigRLeg1Gizmo.fbx'},
        {name:'RigRibcageGizmo', position:{x:77.79662, y:3.181686, z:82.97733}, rotation:{x:0.7040229, y:-0.06596842, z:-0.7040228}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRibcageGizmo.fbx'},
        {name:'RigPelvisGizmo', position:{x:77.79662, y:3.183868, z:82.38}, rotation:{x:0.6960415, y:-0.1246048, z:-0.6960413}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigTail6', position:{x:77.79662, y:3.068462, z:81.79692}, rotation:{x:0.178363, y:-0.6842417, z:-0.1783631}, scale:{x:1, y:1, z:1}, file:'RigTail6.fbx'},
        {name:'RigTail5', position:{x:77.79662, y:3.042938, z:81.87614}, rotation:{x:0.1097588, y:-0.6985364, z:-0.1097589}, scale:{x:1, y:1, z:1}, file:'RigTail5.fbx'},
        {name:'RigTail4', position:{x:77.79662, y:3.089758, z:82.05163}, rotation:{x:-0.09191881, y:-0.701107, z:0.09191877}, scale:{x:1, y:1, z:1}, file:'RigTail4.fbx'},
        {name:'RigTail3', position:{x:77.79662, y:3.144811, z:82.16458}, rotation:{x:-0.158973, y:-0.6890048, z:0.158973}, scale:{x:1, y:1, z:1}, file:'RigTail3.fbx'},
        {name:'RigTail2', position:{x:77.79662, y:3.188532, z:82.28048}, rotation:{x:-0.1268487, y:-0.695636, z:0.1268487}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigTail2.fbx'},
        {name:'RigTail1', position:{x:77.79662, y:3.18188, z:82.37926}, rotation:{x:0.02376616, y:-0.7067073, z:-0.02376621}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigTail1.fbx'},
        {name:'RigRLegDigit02_1', position:{x:77.9627, y:2.528107, z:83.01748}, rotation:{x:0.03794467, y:-0.7085945, z:-0.040165}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLegDigit02_1.fbx'},
        {name:'RigRLegDigit01_1', position:{x:77.96561, y:2.526905, z:82.93597}, rotation:{x:-0.00179045, y:-0.69432, z:0.01197048}, scale:{x:1, y:1, z:1}, file:'RigRLegDigit01_1.fbx'},
        {name:'RigRLegPalm_1', position:{x:77.96702, y:2.603072, z:82.85851}, rotation:{x:-0.2728064, y:-0.6514626, z:-0.2628596}, scale:{x:0.9999979, y:1.000001, z:1.000002}, file:'RigRLegPalm_1.fbx'},
        {name:'RigRLeg2_1', position:{x:77.95589, y:2.849162, z:82.81618}, rotation:{x:-0.464977, y:-0.5697359, z:-0.4445878}, scale:{x:1.000001, y:0.9999986, z:0.9999999}, file:'RigRLeg2_1.fbx'},
        {name:'RigRLeg1_1', position:{x:77.96785, y:3.053978, z:83.05224}, rotation:{x:-0.687591, y:-0.2772119, z:-0.6356341}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRLeg1_1.fbx'},
        {name:'RigRLegCollarbone', position:{x:77.81608, y:3.061272, z:83.08234}, rotation:{x:-0.09837016, y:-0.03295835, z:-0.9943945}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigRLegCollarbone.fbx'},
        {name:'DummyUpMouth', position:{x:77.79662, y:2.99842, z:83.49142}, rotation:{x:-0.1398767, y:-0.6931341, z:-0.1398765}, scale:{x:1.47288, y:0.4398545, z:0.9999999}, file:'DummyUpMouth.fbx'},
        {name:'DummyLowMouth', position:{x:77.79662, y:2.882699, z:83.39068}, rotation:{x:-0.2075865, y:-0.6759498, z:-0.2075863}, scale:{x:1.47288, y:0.4398545, z:0.9999999}, file:'DummyLowMouth.fbx'},
        {name:'RigHead', position:{x:77.79662, y:3.093256, z:83.36011}, rotation:{x:0.6821335, y:0.1862635, z:-0.6821334}, scale:{x:1, y:0.9999999, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck5', position:{x:77.79662, y:3.137573, z:83.29213}, rotation:{x:0.6778147, y:0.2014137, z:-0.6778145}, scale:{x:0.9999998, y:0.9999999, z:1}, file:'RigNeck5.fbx'},
        {name:'RigNeck4', position:{x:77.79662, y:3.170771, z:83.21807}, rotation:{x:0.6914659, y:0.1479025, z:-0.6914658}, scale:{x:1, y:1, z:1}, file:'RigNeck4.fbx'},
        {name:'RigNeck3', position:{x:77.79662, y:3.18881, z:83.13895}, rotation:{x:0.7026705, y:0.07908528, z:-0.7026703}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigNeck3.fbx'},
        {name:'RigNeck2', position:{x:77.79662, y:3.191772, z:83.05785}, rotation:{x:0.706989, y:0.01290952, z:-0.706989}, scale:{x:1, y:1, z:1}, file:'RigNeck2.fbx'},
        {name:'RigNeck1', position:{x:77.79662, y:3.181685, z:82.97733}, rotation:{x:0.7057347, y:-0.04402959, z:-0.7057346}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigNeck1.fbx'},
        {name:'RigLLegDigit02_1', position:{x:77.62371, y:2.528734, z:83.01655}, rotation:{x:-0.005325315, y:0.714429, z:-0.005586334}, scale:{x:1, y:1, z:1}, file:'RigLLegDigit02_1.fbx'},
        {name:'RigLLegDigit01_1', position:{x:77.62291, y:2.526904, z:82.93594}, rotation:{x:-0.01592016, y:0.7105839, z:-4.453212E-05}, scale:{x:1, y:1, z:1}, file:'RigLLegDigit01_1.fbx'},
        {name:'RigLLegPalm_1', position:{x:77.61967, y:2.60331, z:82.85876}, rotation:{x:0.2679993, y:0.6649662, z:0.2697295}, scale:{x:0.9999985, y:1.000002, z:1}, file:'RigLLegPalm_1.fbx'},
        {name:'RigLLeg2_1', position:{x:77.63258, y:2.849242, z:82.81604}, rotation:{x:0.4472523, y:0.5112122, z:0.4613414}, scale:{x:0.9999968, y:1.000003, z:1}, file:'RigLLeg2_1.fbx'},
        {name:'RigLLeg1_1', position:{x:77.62226, y:3.053978, z:83.05224}, rotation:{x:0.6368628, y:0.2131366, z:0.6863438}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLLeg1_1.fbx'},
        {name:'RigLLegCollarbone', position:{x:77.77715, y:3.061272, z:83.08234}, rotation:{x:0.9945908, y:0.02001803, z:0.09644717}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigLLegCollarbone.fbx'},
        {name:'RigRibcage', position:{x:77.79662, y:3.181685, z:82.97733}, rotation:{x:0.704023, y:-0.06596839, z:-0.7040229}, scale:{x:1, y:1, z:1}, file:'RigRibcage.fbx'},
        {name:'RigSpine6', position:{x:77.79662, y:3.168163, z:82.87821}, rotation:{x:0.7054825, y:-0.04790193, z:-0.7054824}, scale:{x:1, y:1, z:1}, file:'RigSpine6.fbx'},
        {name:'RigSpine5', position:{x:77.79662, y:3.161843, z:82.77837}, rotation:{x:0.7067537, y:-0.02234772, z:-0.7067536}, scale:{x:1, y:0.9999999, z:1}, file:'RigSpine5.fbx'},
        {name:'RigSpine4', position:{x:77.79662, y:3.172969, z:82.69534}, rotation:{x:0.7055392, y:0.04705842, z:-0.7055392}, scale:{x:0.9999998, y:0.9999998, z:0.9999999}, file:'RigSpine4.fbx'},
        {name:'RigSpine3', position:{x:77.79662, y:3.184562, z:82.57951}, rotation:{x:0.7062275, y:0.03525468, z:-0.7062274}, scale:{x:0.9999998, y:1, z:1}, file:'RigSpine3.fbx'},
        {name:'RigSpine2', position:{x:77.79662, y:3.191222, z:82.47977}, rotation:{x:0.706714, y:0.02356722, z:-0.7067138}, scale:{x:1, y:1, z:1}, file:'RigSpine2.fbx'},
        {name:'RigSpine1', position:{x:77.79662, y:3.183868, z:82.38}, rotation:{x:0.7066284, y:-0.02600864, z:-0.7066283}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigSpine1.fbx'},
        {name:'RigRLegDigit02', position:{x:77.95644, y:2.532117, z:82.38873}, rotation:{x:-0.004983648, y:0.7012779, z:0.007562384}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRLegDigit02.fbx'},
        {name:'RigRLegDigit01', position:{x:77.95907, y:2.528643, z:82.2776}, rotation:{x:-0.008047592, y:0.6985784, z:-0.0139759}, scale:{x:0.9999997, y:1, z:0.9999999}, file:'RigRLegDigit01.fbx'},
        {name:'RigRLegPalm', position:{x:77.94343, y:2.754649, z:82.20934}, rotation:{x:0.3753939, y:0.560335, z:0.4680439}, scale:{x:0.9999974, y:1.000002, z:0.9999998}, file:'RigRLegPalm.fbx'},
        {name:'RigRLeg2', position:{x:77.95031, y:2.851818, z:82.29833}, rotation:{x:0.688552, y:0.3358442, z:0.6009606}, scale:{x:1, y:1, z:1}, file:'RigRLeg2.fbx'},
        {name:'RigRLeg1', position:{x:77.92795, y:3.113397, z:82.31833}, rotation:{x:0.5350664, y:0.5465714, z:0.4936811}, scale:{x:1, y:0.9999999, z:1}, file:'RigRLeg1.fbx'},
        {name:'RigLLegDigit02', position:{x:77.62924, y:2.530197, z:82.38838}, rotation:{x:-0.01120621, y:0.7073312, z:-0.001600951}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLLegDigit02.fbx'},
        {name:'RigLLegDigit01', position:{x:77.63062, y:2.528642, z:82.2776}, rotation:{x:-0.006963097, y:0.7026938, z:-0.002982482}, scale:{x:0.9999999, y:1, z:1}, file:'RigLLegDigit01.fbx'},
        {name:'RigLLegPalm', position:{x:77.65186, y:2.754483, z:82.21031}, rotation:{x:0.4806279, y:0.5671622, z:0.3652841}, scale:{x:0.9999989, y:1.000002, z:0.9999993}, file:'RigLLegPalm.fbx'},
        {name:'RigLLeg2', position:{x:77.64802, y:2.851122, z:82.30006}, rotation:{x:0.609803, y:0.2239428, z:0.6814875}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLLeg2.fbx'},
        {name:'RigLLeg1', position:{x:77.66216, y:3.113397, z:82.31833}, rotation:{x:0.4846811, y:0.4226074, z:0.5427068}, scale:{x:1, y:1, z:1}, file:'RigLLeg1.fbx'},
        {name:'RigPelvis', position:{x:77.79662, y:3.183868, z:82.38}, rotation:{x:0.6960415, y:-0.1246048, z:-0.6960413}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigLLegPlatform_1', position:{x:77.63085, y:2.496923, z:82.30416}, rotation:{x:0.4977681, y:-0.4977681, z:-0.502222}, scale:{x:1, y:1, z:0.9999999}, file:'RigLLegPlatform_1.fbx'},
        {name:'RigLLegPlatform', position:{x:77.62306, y:2.496923, z:82.96882}, rotation:{x:0.4988438, y:-0.4988438, z:-0.5011535}, scale:{x:1, y:1, z:1}, file:'RigLLegPlatform.fbx'},
        {name:'RigLLegPalmGizmo_1', position:{x:77.61967, y:2.60331, z:82.85876}, rotation:{x:0.2679986, y:0.6649664, z:0.2697287}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLLegPalmGizmo_1.fbx'},
        {name:'RigLLegPalmGizmo', position:{x:77.65186, y:2.754483, z:82.21031}, rotation:{x:0.4806286, y:0.5671619, z:0.3652848}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigLLegPalmGizmo.fbx'},
        {name:'RigLLeg2Gizmo_1', position:{x:77.63258, y:2.849242, z:82.81604}, rotation:{x:0.447257, y:0.5112165, z:0.4613368}, scale:{x:0.9999963, y:1.000003, z:1}, file:'RigLLeg2Gizmo_1.fbx'},
        {name:'RigLLeg2Gizmo', position:{x:77.64802, y:2.851122, z:82.30006}, rotation:{x:0.6098043, y:0.2239454, z:0.6814867}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLLeg2Gizmo.fbx'},
        {name:'RigLLeg1Gizmo_1', position:{x:77.66216, y:3.113397, z:82.31833}, rotation:{x:0.4846816, y:0.4226078, z:0.5427065}, scale:{x:1, y:1, z:1}, file:'RigLLeg1Gizmo_1.fbx'},
        {name:'RigLLeg1Gizmo', position:{x:77.62226, y:3.053978, z:83.05224}, rotation:{x:0.6368627, y:0.2131365, z:0.6863437}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLLeg1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:77.79662, y:3.093256, z:83.36011}, rotation:{x:0.6821334, y:0.1862635, z:-0.6821333}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'Rig', position:{x:77.79662, y:2.496923, z:82.7047}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Forest_Wolf', position:{x:77.79662, y:2.496923, z:82.7047}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Forest_Wolf.fbx'},
        {name:'Forest_Wolf___Grey_(2)', position:{x:77.79662, y:2.496923, z:82.7047}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Forest_Wolf___Grey_(2).fbx'},
        {name:'Fern1', position:{x:82.28124, y:5.515532, z:110.3291}, rotation:{x:0, y:0, z:0}, scale:{x:1.069844, y:1.069844, z:1.069844}, file:'Fern1.fbx'},
        {name:'Stair_el7', position:{x:69.45409, y:4.347209, z:104.2658}, rotation:{x:0, y:-0.2851512, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el7.fbx'},
        {name:'Crystal3', position:{x:74.37114, y:3.307069, z:73.53381}, rotation:{x:0.1718173, y:0.004591206, z:0.1575994}, scale:{x:1, y:1, z:1}, file:'Crystal3.fbx'},
        {name:'Plant_06', position:{x:83.61643, y:1.997678, z:87.19375}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_06.fbx'},
        {name:'Mushroom4A', position:{x:66.28913, y:3.405359, z:83.36021}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom4A.fbx'},
        {name:'RigTail6Gizmo', position:{x:78.48955, y:2.922289, z:82.76427}, rotation:{x:0.178363, y:-0.6842417, z:-0.1783631}, scale:{x:1, y:1, z:1}, file:'RigTail6Gizmo.fbx'},
        {name:'RigTail5Gizmo', position:{x:78.48955, y:2.896764, z:82.84348}, rotation:{x:0.1097588, y:-0.6985364, z:-0.1097589}, scale:{x:1, y:1, z:1}, file:'RigTail5Gizmo.fbx'},
        {name:'RigTail4Gizmo', position:{x:78.48955, y:2.943584, z:83.01897}, rotation:{x:-0.09191881, y:-0.701107, z:0.09191876}, scale:{x:1, y:1, z:1}, file:'RigTail4Gizmo.fbx'},
        {name:'RigTail3Gizmo', position:{x:78.48955, y:2.998638, z:83.13193}, rotation:{x:-0.1589731, y:-0.6890048, z:0.158973}, scale:{x:1, y:0.9999999, z:1}, file:'RigTail3Gizmo.fbx'},
        {name:'RigTail2Gizmo', position:{x:78.48955, y:3.042358, z:83.24783}, rotation:{x:-0.1268487, y:-0.695636, z:0.1268487}, scale:{x:0.9999999, y:1, z:1}, file:'RigTail2Gizmo.fbx'},
        {name:'RigRLegPlatform_1', position:{x:78.65998, y:2.35075, z:83.93616}, rotation:{x:0.4889005, y:-0.4889005, z:-0.5108584}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigRLegPlatform_1.fbx'},
        {name:'RigRLegPlatform', position:{x:78.65219, y:2.35075, z:83.27151}, rotation:{x:0.4981787, y:-0.4981787, z:-0.5018147}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLegPlatform.fbx'},
        {name:'RigRLegPalmGizmo_1', position:{x:78.63636, y:2.608475, z:83.17668}, rotation:{x:0.3753951, y:0.5603338, z:0.4680451}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigRLegPalmGizmo_1.fbx'},
        {name:'RigRLegPalmGizmo', position:{x:78.65995, y:2.456898, z:83.82585}, rotation:{x:0.2728069, y:0.6514624, z:0.2628601}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLegPalmGizmo.fbx'},
        {name:'RigRLeg2Gizmo_1', position:{x:78.64882, y:2.702989, z:83.78353}, rotation:{x:0.4649611, y:0.569722, z:0.4446055}, scale:{x:1.000001, y:0.9999985, z:0.9999999}, file:'RigRLeg2Gizmo_1.fbx'},
        {name:'RigRLeg2Gizmo', position:{x:78.64324, y:2.705644, z:83.26568}, rotation:{x:0.6885519, y:0.3358441, z:0.6009607}, scale:{x:1, y:1, z:1}, file:'RigRLeg2Gizmo.fbx'},
        {name:'RigRLeg1Gizmo_1', position:{x:78.62088, y:2.967224, z:83.28568}, rotation:{x:0.5350664, y:0.5465714, z:0.493681}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRLeg1Gizmo_1.fbx'},
        {name:'RigRLeg1Gizmo', position:{x:78.66078, y:2.907804, z:84.01958}, rotation:{x:0.6875911, y:0.2772123, z:0.635634}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigRLeg1Gizmo.fbx'},
        {name:'RigRibcageGizmo', position:{x:78.48955, y:3.035512, z:83.94467}, rotation:{x:0.7040229, y:-0.06596842, z:-0.7040228}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRibcageGizmo.fbx'},
        {name:'RigPelvisGizmo', position:{x:78.48955, y:3.037694, z:83.34735}, rotation:{x:0.6960415, y:-0.1246048, z:-0.6960413}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigTail6', position:{x:78.48955, y:2.922289, z:82.76427}, rotation:{x:0.178363, y:-0.6842417, z:-0.1783631}, scale:{x:1, y:1, z:1}, file:'RigTail6.fbx'},
        {name:'RigTail5', position:{x:78.48955, y:2.896764, z:82.84348}, rotation:{x:0.1097588, y:-0.6985364, z:-0.1097589}, scale:{x:1, y:1, z:1}, file:'RigTail5.fbx'},
        {name:'RigTail4', position:{x:78.48955, y:2.943584, z:83.01897}, rotation:{x:-0.09191881, y:-0.701107, z:0.09191877}, scale:{x:1, y:1, z:1}, file:'RigTail4.fbx'},
        {name:'RigTail3', position:{x:78.48955, y:2.998638, z:83.13193}, rotation:{x:-0.158973, y:-0.6890048, z:0.158973}, scale:{x:1, y:1, z:1}, file:'RigTail3.fbx'},
        {name:'RigTail2', position:{x:78.48955, y:3.042358, z:83.24783}, rotation:{x:-0.1268487, y:-0.695636, z:0.1268487}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigTail2.fbx'},
        {name:'RigTail1', position:{x:78.48955, y:3.035707, z:83.34661}, rotation:{x:0.02376616, y:-0.7067073, z:-0.02376621}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigTail1.fbx'},
        {name:'RigRLegDigit02_1', position:{x:78.65563, y:2.381933, z:83.98483}, rotation:{x:0.03794467, y:-0.7085945, z:-0.040165}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLegDigit02_1.fbx'},
        {name:'RigRLegDigit01_1', position:{x:78.65854, y:2.380731, z:83.90332}, rotation:{x:-0.00179045, y:-0.69432, z:0.01197048}, scale:{x:1, y:1, z:1}, file:'RigRLegDigit01_1.fbx'},
        {name:'RigRLegPalm_1', position:{x:78.65995, y:2.456898, z:83.82585}, rotation:{x:-0.2728064, y:-0.6514626, z:-0.2628596}, scale:{x:0.9999979, y:1.000001, z:1.000002}, file:'RigRLegPalm_1.fbx'},
        {name:'RigRLeg2_1', position:{x:78.64882, y:2.702989, z:83.78353}, rotation:{x:-0.464977, y:-0.5697359, z:-0.4445878}, scale:{x:1.000001, y:0.9999986, z:0.9999999}, file:'RigRLeg2_1.fbx'},
        {name:'RigRLeg1_1', position:{x:78.66078, y:2.907804, z:84.01958}, rotation:{x:-0.687591, y:-0.2772119, z:-0.6356341}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRLeg1_1.fbx'},
        {name:'RigRLegCollarbone', position:{x:78.50901, y:2.915099, z:84.04968}, rotation:{x:-0.09837016, y:-0.03295835, z:-0.9943945}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigRLegCollarbone.fbx'},
        {name:'DummyUpMouth', position:{x:78.48955, y:2.852246, z:84.45876}, rotation:{x:-0.1398767, y:-0.6931341, z:-0.1398765}, scale:{x:1.47288, y:0.4398545, z:0.9999999}, file:'DummyUpMouth.fbx'},
        {name:'DummyLowMouth', position:{x:78.48955, y:2.736526, z:84.35802}, rotation:{x:-0.2075865, y:-0.6759498, z:-0.2075863}, scale:{x:1.47288, y:0.4398545, z:0.9999999}, file:'DummyLowMouth.fbx'},
        {name:'RigHead', position:{x:78.48955, y:2.947083, z:84.32745}, rotation:{x:0.6821335, y:0.1862635, z:-0.6821334}, scale:{x:1, y:0.9999999, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck5', position:{x:78.48955, y:2.991399, z:84.25948}, rotation:{x:0.6778147, y:0.2014137, z:-0.6778145}, scale:{x:0.9999998, y:0.9999999, z:1}, file:'RigNeck5.fbx'},
        {name:'RigNeck4', position:{x:78.48955, y:3.024597, z:84.18542}, rotation:{x:0.6914659, y:0.1479025, z:-0.6914658}, scale:{x:1, y:1, z:1}, file:'RigNeck4.fbx'},
        {name:'RigNeck3', position:{x:78.48955, y:3.042636, z:84.10629}, rotation:{x:0.7026705, y:0.07908528, z:-0.7026703}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigNeck3.fbx'},
        {name:'RigNeck2', position:{x:78.48955, y:3.045599, z:84.0252}, rotation:{x:0.706989, y:0.01290952, z:-0.706989}, scale:{x:1, y:1, z:1}, file:'RigNeck2.fbx'},
        {name:'RigNeck1', position:{x:78.48955, y:3.035512, z:83.94467}, rotation:{x:0.7057347, y:-0.04402959, z:-0.7057346}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigNeck1.fbx'},
        {name:'RigLLegDigit02_1', position:{x:78.31664, y:2.38256, z:83.98389}, rotation:{x:-0.005325315, y:0.714429, z:-0.005586334}, scale:{x:1, y:1, z:1}, file:'RigLLegDigit02_1.fbx'},
        {name:'RigLLegDigit01_1', position:{x:78.31584, y:2.380731, z:83.90329}, rotation:{x:-0.01592016, y:0.7105839, z:-4.453212E-05}, scale:{x:1, y:1, z:1}, file:'RigLLegDigit01_1.fbx'},
        {name:'RigLLegPalm_1', position:{x:78.3126, y:2.457136, z:83.8261}, rotation:{x:0.2679993, y:0.6649662, z:0.2697295}, scale:{x:0.9999985, y:1.000002, z:1}, file:'RigLLegPalm_1.fbx'},
        {name:'RigLLeg2_1', position:{x:78.32551, y:2.703068, z:83.78339}, rotation:{x:0.4472523, y:0.5112122, z:0.4613414}, scale:{x:0.9999968, y:1.000003, z:1}, file:'RigLLeg2_1.fbx'},
        {name:'RigLLeg1_1', position:{x:78.31519, y:2.907804, z:84.01958}, rotation:{x:0.6368628, y:0.2131366, z:0.6863438}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLLeg1_1.fbx'},
        {name:'RigLLegCollarbone', position:{x:78.47009, y:2.915099, z:84.04968}, rotation:{x:0.9945908, y:0.02001803, z:0.09644717}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigLLegCollarbone.fbx'},
        {name:'RigRibcage', position:{x:78.48955, y:3.035512, z:83.94467}, rotation:{x:0.704023, y:-0.06596839, z:-0.7040229}, scale:{x:1, y:1, z:1}, file:'RigRibcage.fbx'},
        {name:'RigSpine6', position:{x:78.48955, y:3.02199, z:83.84556}, rotation:{x:0.7054825, y:-0.04790193, z:-0.7054824}, scale:{x:1, y:1, z:1}, file:'RigSpine6.fbx'},
        {name:'RigSpine5', position:{x:78.48955, y:3.01567, z:83.74572}, rotation:{x:0.7067537, y:-0.02234772, z:-0.7067536}, scale:{x:1, y:0.9999999, z:1}, file:'RigSpine5.fbx'},
        {name:'RigSpine4', position:{x:78.48955, y:3.026796, z:83.66268}, rotation:{x:0.7055392, y:0.04705842, z:-0.7055392}, scale:{x:0.9999998, y:0.9999998, z:0.9999999}, file:'RigSpine4.fbx'},
        {name:'RigSpine3', position:{x:78.48955, y:3.038389, z:83.54685}, rotation:{x:0.7062275, y:0.03525468, z:-0.7062274}, scale:{x:0.9999998, y:1, z:1}, file:'RigSpine3.fbx'},
        {name:'RigSpine2', position:{x:78.48955, y:3.045048, z:83.44712}, rotation:{x:0.706714, y:0.02356722, z:-0.7067138}, scale:{x:1, y:1, z:1}, file:'RigSpine2.fbx'},
        {name:'RigSpine1', position:{x:78.48955, y:3.037694, z:83.34735}, rotation:{x:0.7066284, y:-0.02600864, z:-0.7066283}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigSpine1.fbx'},
        {name:'RigRLegDigit02', position:{x:78.64938, y:2.385944, z:83.35607}, rotation:{x:-0.004983648, y:0.7012779, z:0.007562384}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRLegDigit02.fbx'},
        {name:'RigRLegDigit01', position:{x:78.652, y:2.38247, z:83.24494}, rotation:{x:-0.008047592, y:0.6985784, z:-0.0139759}, scale:{x:0.9999997, y:1, z:0.9999999}, file:'RigRLegDigit01.fbx'},
        {name:'RigRLegPalm', position:{x:78.63636, y:2.608475, z:83.17668}, rotation:{x:0.3753939, y:0.560335, z:0.4680439}, scale:{x:0.9999974, y:1.000002, z:0.9999998}, file:'RigRLegPalm.fbx'},
        {name:'RigRLeg2', position:{x:78.64324, y:2.705644, z:83.26568}, rotation:{x:0.688552, y:0.3358442, z:0.6009606}, scale:{x:1, y:1, z:1}, file:'RigRLeg2.fbx'},
        {name:'RigRLeg1', position:{x:78.62088, y:2.967224, z:83.28568}, rotation:{x:0.5350664, y:0.5465714, z:0.4936811}, scale:{x:1, y:0.9999999, z:1}, file:'RigRLeg1.fbx'},
        {name:'RigLLegDigit02', position:{x:78.32217, y:2.384024, z:83.35573}, rotation:{x:-0.01120621, y:0.7073312, z:-0.001600951}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLLegDigit02.fbx'},
        {name:'RigLLegDigit01', position:{x:78.32355, y:2.382469, z:83.24494}, rotation:{x:-0.006963097, y:0.7026938, z:-0.002982482}, scale:{x:0.9999999, y:1, z:1}, file:'RigLLegDigit01.fbx'},
        {name:'RigLLegPalm', position:{x:78.3448, y:2.608309, z:83.17766}, rotation:{x:0.4806279, y:0.5671622, z:0.3652841}, scale:{x:0.9999989, y:1.000002, z:0.9999993}, file:'RigLLegPalm.fbx'},
        {name:'RigLLeg2', position:{x:78.34095, y:2.704948, z:83.2674}, rotation:{x:0.609803, y:0.2239428, z:0.6814875}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLLeg2.fbx'},
        {name:'RigLLeg1', position:{x:78.35509, y:2.967224, z:83.28568}, rotation:{x:0.4846811, y:0.4226074, z:0.5427068}, scale:{x:1, y:1, z:1}, file:'RigLLeg1.fbx'},
        {name:'RigPelvis', position:{x:78.48955, y:3.037694, z:83.34735}, rotation:{x:0.6960415, y:-0.1246048, z:-0.6960413}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigLLegPlatform_1', position:{x:78.32378, y:2.35075, z:83.27151}, rotation:{x:0.4977681, y:-0.4977681, z:-0.502222}, scale:{x:1, y:1, z:0.9999999}, file:'RigLLegPlatform_1.fbx'},
        {name:'RigLLegPlatform', position:{x:78.31599, y:2.35075, z:83.93616}, rotation:{x:0.4988438, y:-0.4988438, z:-0.5011535}, scale:{x:1, y:1, z:1}, file:'RigLLegPlatform.fbx'},
        {name:'RigLLegPalmGizmo_1', position:{x:78.3126, y:2.457136, z:83.8261}, rotation:{x:0.2679986, y:0.6649664, z:0.2697287}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLLegPalmGizmo_1.fbx'},
        {name:'RigLLegPalmGizmo', position:{x:78.3448, y:2.608309, z:83.17766}, rotation:{x:0.4806286, y:0.5671619, z:0.3652848}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigLLegPalmGizmo.fbx'},
        {name:'RigLLeg2Gizmo_1', position:{x:78.32551, y:2.703068, z:83.78339}, rotation:{x:0.447257, y:0.5112165, z:0.4613368}, scale:{x:0.9999963, y:1.000003, z:1}, file:'RigLLeg2Gizmo_1.fbx'},
        {name:'RigLLeg2Gizmo', position:{x:78.34095, y:2.704948, z:83.2674}, rotation:{x:0.6098043, y:0.2239454, z:0.6814867}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLLeg2Gizmo.fbx'},
        {name:'RigLLeg1Gizmo_1', position:{x:78.35509, y:2.967224, z:83.28568}, rotation:{x:0.4846816, y:0.4226078, z:0.5427065}, scale:{x:1, y:1, z:1}, file:'RigLLeg1Gizmo_1.fbx'},
        {name:'RigLLeg1Gizmo', position:{x:78.31519, y:2.907804, z:84.01958}, rotation:{x:0.6368627, y:0.2131365, z:0.6863437}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLLeg1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:78.48955, y:2.947083, z:84.32745}, rotation:{x:0.6821334, y:0.1862635, z:-0.6821333}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'Rig', position:{x:78.48955, y:2.35075, z:83.67205}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Forest_Wolf', position:{x:78.48955, y:2.35075, z:83.67205}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Forest_Wolf.fbx'},
        {name:'Forest_Wolf___Grey_(1)', position:{x:78.48955, y:2.35075, z:83.67205}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Forest_Wolf___Grey_(1).fbx'},
        {name:'Ground_Decal_grass1', position:{x:71.07944, y:3.4063, z:87.28072}, rotation:{x:0, y:0.6086334, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_grass1.fbx'},
        {name:'Tree_Pine_1', position:{x:59.97075, y:5.5, z:67.44}, rotation:{x:0, y:-0.5389422, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Tree_Small_02', position:{x:84.15286, y:5.74997, z:67.0635}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Small_02.fbx'},
        {name:'Fence_elements1', position:{x:77.66278, y:3.284336, z:92.61745}, rotation:{x:0, y:0.5237935, z:0}, scale:{x:1, y:1, z:1}, file:'Fence_elements1.fbx'},
        {name:'Treasure_Globin', position:{x:70.40205, y:3.405539, z:95.09071}, rotation:{x:0, y:-0.7071068, z:-0.7071068}, scale:{x:1, y:1, z:1}, file:'Treasure_Globin.fbx'},
        {name:'RigRope03Gizmo', position:{x:70.50702, y:4.072023, z:94.96564}, rotation:{x:0.5729329, y:0.3461634, z:0.6094056}, scale:{x:1, y:1, z:1}, file:'RigRope03Gizmo.fbx'},
        {name:'RigRope02Gizmo', position:{x:70.50822, y:4.132805, z:94.99771}, rotation:{x:0.5873479, y:0.3247841, z:0.6210632}, scale:{x:1, y:1, z:0.9999999}, file:'RigRope02Gizmo.fbx'},
        {name:'RigRope01Gizmo', position:{x:70.5217, y:4.190596, z:95.08723}, rotation:{x:-0.701099, y:-0.1376103, z:-0.6468001}, scale:{x:1, y:1, z:0.9999999}, file:'RigRope01Gizmo.fbx'},
        {name:'RigRLegPlatform', position:{x:70.30044, y:3.404588, z:95.03571}, rotation:{x:0.5, y:-0.5, z:0.5000001}, scale:{x:1, y:1, z:1}, file:'RigRLegPlatform.fbx'},
        {name:'RigRibcageGizmo', position:{x:70.40205, y:4.130075, z:95.11226}, rotation:{x:0.5, y:-0.5, z:0.5000001}, scale:{x:1, y:1, z:1}, file:'RigRibcageGizmo.fbx'},
        {name:'RigREar02Gizmo', position:{x:70.70716, y:4.470316, z:95.08273}, rotation:{x:0.03195635, y:-0.5037233, z:0.8219613}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigREar02Gizmo.fbx'},
        {name:'RigREar01Gizmo', position:{x:70.61426, y:4.429446, z:95.06009}, rotation:{x:0.06861562, y:-0.7030492, z:0.6726243}, scale:{x:1, y:0.9999999, z:1}, file:'RigREar01Gizmo.fbx'},
        {name:'RigRArmUpperarmGizmo', position:{x:70.26556, y:4.107481, z:95.09482}, rotation:{x:-0.7007002, y:-0.007135387, z:0.009442291}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArmUpperarmGizmo.fbx'},
        {name:'RigRArmPalmGizmo', position:{x:69.98771, y:4.102407, z:95.09446}, rotation:{x:-0.005486502, y:-0.006563067, z:-0.0004816727}, scale:{x:1, y:1, z:1}, file:'RigRArmPalmGizmo.fbx'},
        {name:'RigRArmForearmGizmo', position:{x:70.12656, y:4.104217, z:95.09525}, rotation:{x:-0.716756, y:-0.006647901, z:0.002515672}, scale:{x:0.9999999, y:1, z:1}, file:'RigRArmForearmGizmo.fbx'},
        {name:'RigPelvisGizmo', position:{x:70.40205, y:3.792596, z:95.1098}, rotation:{x:0.4991269, y:-0.5008715, z:0.499127}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigRope03', position:{x:70.50702, y:4.072023, z:94.96564}, rotation:{x:-0.5729329, y:-0.3461635, z:-0.6094056}, scale:{x:1, y:1, z:1}, file:'RigRope03.fbx'},
        {name:'RigRope02', position:{x:70.50822, y:4.132805, z:94.99771}, rotation:{x:-0.5873479, y:-0.3247841, z:-0.6210632}, scale:{x:1, y:0.9999999, z:1}, file:'RigRope02.fbx'},
        {name:'RigRope01', position:{x:70.5217, y:4.190596, z:95.08723}, rotation:{x:-0.701099, y:-0.1376104, z:-0.6468001}, scale:{x:1, y:1, z:1}, file:'RigRope01.fbx'},
        {name:'RigRArmThumb2', position:{x:69.91389, y:4.102273, z:94.99793}, rotation:{x:0.007278383, y:0.5516808, z:0.009477109}, scale:{x:0.9999992, y:0.9999999, z:1.000001}, file:'RigRArmThumb2.fbx'},
        {name:'RigRArmThumb1', position:{x:69.92393, y:4.101923, z:95.02649}, rotation:{x:0.006211936, y:0.578041, z:0.01148492}, scale:{x:0.9999989, y:0.9999999, z:1.000001}, file:'RigRArmThumb1.fbx'},
        {name:'RigRArmMiddle3', position:{x:69.77266, y:4.102583, z:95.09187}, rotation:{x:0.00548692, y:0.006563246, z:0.0004826784}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmMiddle3.fbx'},
        {name:'RigRArmMiddle2', position:{x:69.81124, y:4.102556, z:95.09225}, rotation:{x:0.005486995, y:0.005102739, z:0.0003758222}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRArmMiddle2.fbx'},
        {name:'RigRArmMiddle1', position:{x:69.85017, y:4.102529, z:95.09265}, rotation:{x:0.005486906, y:0.005061284, z:0.0003715754}, scale:{x:1, y:1, z:1}, file:'RigRArmMiddle1.fbx'},
        {name:'RigRArmLast3', position:{x:69.78956, y:4.103102, z:95.1347}, rotation:{x:0.005486742, y:0.006559283, z:0.0004820526}, scale:{x:1, y:1, z:1}, file:'RigRArmLast3.fbx'},
        {name:'RigRArmLast2', position:{x:69.81956, y:4.103076, z:95.13509}, rotation:{x:0.005486801, y:0.006560221, z:0.0004819036}, scale:{x:1, y:1, z:1}, file:'RigRArmLast2.fbx'},
        {name:'RigRArmLast1', position:{x:69.84955, y:4.103049, z:95.13548}, rotation:{x:0.005486771, y:0.006561205, z:0.0004816949}, scale:{x:1, y:1, z:1}, file:'RigRArmLast1.fbx'},
        {name:'RigRArmIndex3', position:{x:69.78085, y:4.102063, z:95.04454}, rotation:{x:0.00548692, y:0.006563172, z:0.000482738}, scale:{x:1, y:1, z:1}, file:'RigRArmIndex3.fbx'},
        {name:'RigRArmIndex2', position:{x:69.81255, y:4.102036, z:95.04494}, rotation:{x:0.005486831, y:0.006207481, z:0.000456214}, scale:{x:1, y:1, z:1}, file:'RigRArmIndex2.fbx'},
        {name:'RigRArmIndex1', position:{x:69.85079, y:4.102009, z:95.04533}, rotation:{x:0.005486995, y:0.005152896, z:0.0003783256}, scale:{x:1, y:1, z:1}, file:'RigRArmIndex1.fbx'},
        {name:'Dagger', position:{x:69.87099, y:4.075247, z:95.09068}, rotation:{x:-1.514971E-06, y:-0.7071072, z:-0.7071066}, scale:{x:1.09675, y:0.583243, z:1.09675}, file:'Dagger.fbx'},
        {name:'DummyWeapon', position:{x:69.86897, y:4.074919, z:95.0868}, rotation:{x:-2.533197E-07, y:-1, z:4.61936E-07}, scale:{x:0.9117848, y:0.9117851, z:1.714552}, file:'DummyWeapon.fbx'},
        {name:'RigRArmPalm', position:{x:69.98771, y:4.102407, z:95.09446}, rotation:{x:0.005486801, y:0.006562129, z:0.0004815459}, scale:{x:0.9999999, y:1, z:1}, file:'RigRArmPalm.fbx'},
        {name:'RigRArmForearm', position:{x:70.12656, y:4.104217, z:95.09525}, rotation:{x:0.7167563, y:0.006648064, z:-0.002515882}, scale:{x:0.9999999, y:1, z:1}, file:'RigRArmForearm.fbx'},
        {name:'RigRArmUpperarm', position:{x:70.26556, y:4.107481, z:95.09482}, rotation:{x:0.7007004, y:0.007135391, z:-0.009442359}, scale:{x:1, y:1, z:1}, file:'RigRArmUpperarm.fbx'},
        {name:'RigREar02', position:{x:70.70716, y:4.470316, z:95.08273}, rotation:{x:0.03195643, y:-0.5037234, z:0.8219615}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigREar02.fbx'},
        {name:'RigREar01', position:{x:70.61426, y:4.429446, z:95.06009}, rotation:{x:0.06861565, y:-0.7030494, z:0.6726245}, scale:{x:1, y:1, z:1}, file:'RigREar01.fbx'},
        {name:'RigLEar02', position:{x:70.09695, y:4.470316, z:95.08273}, rotation:{x:0.8219615, y:0.2638583, z:0.03195637}, scale:{x:0.9999999, y:1, z:1}, file:'RigLEar02.fbx'},
        {name:'RigLEar01', position:{x:70.18985, y:4.429446, z:95.06009}, rotation:{x:0.6726243, y:-0.2204319, z:0.06861567}, scale:{x:1, y:1, z:1}, file:'RigLEar01.fbx'},
        {name:'RigHead', position:{x:70.40205, y:4.378253, z:95.06009}, rotation:{x:0.5, y:-0.5000001, z:0.5000001}, scale:{x:1, y:1, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck2', position:{x:70.40205, y:4.254164, z:95.08617}, rotation:{x:0.5490248, y:-0.4456139, z:0.5490249}, scale:{x:1, y:1, z:1}, file:'RigNeck2.fbx'},
        {name:'RigNeck1', position:{x:70.40205, y:4.130075, z:95.11226}, rotation:{x:0.5490248, y:-0.4456139, z:0.5490249}, scale:{x:1, y:1, z:1}, file:'RigNeck1.fbx'},
        {name:'RigLArmThumb2', position:{x:70.89021, y:4.102274, z:94.99793}, rotation:{x:0.00947687, y:-0.8339675, z:0.007278442}, scale:{x:1.000001, y:1, z:0.9999989}, file:'RigLArmThumb2.fbx'},
        {name:'RigLArmThumb1', position:{x:70.88018, y:4.101924, z:95.02649}, rotation:{x:0.01148474, y:-0.815902, z:0.006210886}, scale:{x:1.000001, y:0.9999999, z:0.9999987}, file:'RigLArmThumb1.fbx'},
        {name:'RigLArmMiddle3', position:{x:71.03145, y:4.102583, z:95.09187}, rotation:{x:0.0004805326, y:-0.9999635, z:0.005485892}, scale:{x:0.9999999, y:1, z:1}, file:'RigLArmMiddle3.fbx'},
        {name:'RigLArmMiddle2', position:{x:70.99287, y:4.102556, z:95.09225}, rotation:{x:0.0003743172, y:-0.999972, z:0.005486056}, scale:{x:0.9999999, y:1, z:1}, file:'RigLArmMiddle2.fbx'},
        {name:'RigLArmMiddle1', position:{x:70.95393, y:4.10253, z:95.09265}, rotation:{x:0.0003711283, y:-0.9999722, z:0.005486116}, scale:{x:0.9999999, y:1, z:1}, file:'RigLArmMiddle1.fbx'},
        {name:'RigLArmLast3', position:{x:71.01455, y:4.103102, z:95.1347}, rotation:{x:0.0004812777, y:-0.9999636, z:0.00548622}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLArmLast3.fbx'},
        {name:'RigLArmLast2', position:{x:70.98455, y:4.103076, z:95.13509}, rotation:{x:0.0004814863, y:-0.9999634, z:0.005486086}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLArmLast2.fbx'},
        {name:'RigLArmLast1', position:{x:70.95455, y:4.103049, z:95.13548}, rotation:{x:0.0004815757, y:-0.9999635, z:0.005486146}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLArmLast1.fbx'},
        {name:'RigLArmIndex3', position:{x:71.02325, y:4.102063, z:95.04454}, rotation:{x:0.0004805326, y:-0.9999635, z:0.005485892}, scale:{x:1, y:1, z:1}, file:'RigLArmIndex3.fbx'},
        {name:'RigLArmIndex2', position:{x:70.99155, y:4.102036, z:95.04494}, rotation:{x:0.0004547536, y:-0.9999657, z:0.005486056}, scale:{x:1, y:1, z:1}, file:'RigLArmIndex2.fbx'},
        {name:'RigLArmIndex1', position:{x:70.95332, y:4.10201, z:95.04533}, rotation:{x:0.000377804, y:-0.9999719, z:0.005486205}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLArmIndex1.fbx'},
        {name:'RigLArmPalm', position:{x:70.8164, y:4.102407, z:95.09446}, rotation:{x:0.0004816651, y:-0.9999635, z:0.005486131}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLArmPalm.fbx'},
        {name:'RigLArmForearm', position:{x:70.67754, y:4.104218, z:95.09525}, rotation:{x:-0.002514899, y:-0.6972882, z:0.7167559}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLArmForearm.fbx'},
        {name:'RigLArmUpperarm', position:{x:70.53854, y:4.107481, z:95.09482}, rotation:{x:-0.009442002, y:-0.713358, z:0.7007}, scale:{x:1, y:1, z:1}, file:'RigLArmUpperarm.fbx'},
        {name:'Glitter', position:{x:70.36538, y:3.915463, z:95.45745}, rotation:{x:9.604264E-09, y:-0.7071069, z:-0.7071068}, scale:{x:1, y:1, z:1}, file:'Glitter.fbx'},
        {name:'RigBag', position:{x:70.40205, y:4.060743, z:95.18678}, rotation:{x:-0.09621687, y:-0.7005302, z:-0.09621671}, scale:{x:1, y:1, z:1}, file:'RigBag.fbx'},
        {name:'RigRibcage', position:{x:70.40205, y:4.130075, z:95.11226}, rotation:{x:0.5, y:-0.5000001, z:0.5000001}, scale:{x:1, y:1, z:1}, file:'RigRibcage.fbx'},
        {name:'RigSpine4', position:{x:70.40205, y:4.002851, z:95.1004}, rotation:{x:0.4762233, y:-0.5226962, z:0.4762233}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigSpine4.fbx'},
        {name:'RigSpine3', position:{x:70.40205, y:3.943482, z:95.09158}, rotation:{x:0.4618171, y:-0.535467, z:0.4618172}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigSpine3.fbx'},
        {name:'RigSpine2', position:{x:70.40205, y:3.879157, z:95.08979}, rotation:{x:0.4929982, y:-0.5069051, z:0.4929982}, scale:{x:1, y:1, z:1}, file:'RigSpine2.fbx'},
        {name:'RigSpine1', position:{x:70.40205, y:3.836388, z:95.1133}, rotation:{x:0.608643, y:-0.3599357, z:0.608643}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigSpine1.fbx'},
        {name:'RigRLegToes1', position:{x:70.30042, y:3.404871, z:94.97842}, rotation:{x:-2.592802E-06, y:-0.7071068, z:2.572349E-06}, scale:{x:0.9999999, y:1, z:1}, file:'RigRLegToes1.fbx'},
        {name:'RigRLegFoot', position:{x:70.30074, y:3.438586, z:95.09557}, rotation:{x:-0.09883288, y:-0.6992574, z:0.09864371}, scale:{x:1.000002, y:0.9999979, z:0.9999998}, file:'RigRLegFoot.fbx'},
        {name:'RigRLegCalf', position:{x:70.30556, y:3.622866, z:95.08769}, rotation:{x:-0.5219586, y:-0.4876046, z:0.4991817}, scale:{x:1, y:0.9999998, z:1}, file:'RigRLegCalf.fbx'},
        {name:'RigRLegThigh', position:{x:70.31037, y:3.809254, z:95.08888}, rotation:{x:-0.5094627, y:-0.4995953, z:0.4873334}, scale:{x:1, y:0.9999999, z:1}, file:'RigRLegThigh.fbx'},
        {name:'RigLLegToes1', position:{x:70.50368, y:3.404954, z:94.97839}, rotation:{x:2.592802E-06, y:-0.7071068, z:-2.582058E-06}, scale:{x:1, y:1, z:1}, file:'RigLLegToes1.fbx'},
        {name:'RigLLegFoot', position:{x:70.50337, y:3.438586, z:95.09558}, rotation:{x:-0.09839797, y:-0.7011256, z:0.09858529}, scale:{x:0.9999979, y:1.000002, z:0.9999998}, file:'RigLLegFoot.fbx'},
        {name:'RigLLegCalf', position:{x:70.49858, y:3.622866, z:95.08769}, rotation:{x:-0.4992266, y:-0.4904919, z:0.5219167}, scale:{x:0.9999997, y:1, z:1}, file:'RigLLegCalf.fbx'},
        {name:'RigLLegThigh', position:{x:70.49374, y:3.809254, z:95.08888}, rotation:{x:-0.4872945, y:-0.5033836, z:0.5095049}, scale:{x:1, y:1, z:1}, file:'RigLLegThigh.fbx'},
        {name:'RigPelvis', position:{x:70.40205, y:3.792596, z:95.1098}, rotation:{x:0.4991269, y:-0.5008715, z:0.499127}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigLUpperarmGizmo', position:{x:70.53854, y:4.107481, z:95.09482}, rotation:{x:-0.00944214, y:-0.7133577, z:0.7007001}, scale:{x:1, y:1, z:1}, file:'RigLUpperarmGizmo.fbx'},
        {name:'RigLPalmGizmo', position:{x:70.8164, y:4.102407, z:95.09446}, rotation:{x:0.0004816237, y:-0.9999633, z:0.005486488}, scale:{x:1, y:1, z:0.9999999}, file:'RigLPalmGizmo.fbx'},
        {name:'RigLLegPlatform', position:{x:70.50367, y:3.404588, z:95.03571}, rotation:{x:0.5, y:-0.5, z:0.5000001}, scale:{x:1, y:1, z:1}, file:'RigLLegPlatform.fbx'},
        {name:'RigLForearmGizmo', position:{x:70.67754, y:4.104218, z:95.09525}, rotation:{x:-0.00251515, y:-0.6972879, z:0.716756}, scale:{x:1, y:1, z:1}, file:'RigLForearmGizmo.fbx'},
        {name:'RigLEar02Gizmo', position:{x:70.09695, y:4.470316, z:95.08273}, rotation:{x:-0.8219613, y:-0.2638583, z:-0.03195644}, scale:{x:0.9999998, y:1, z:0.9999999}, file:'RigLEar02Gizmo.fbx'},
        {name:'RigLEar01Gizmo', position:{x:70.18985, y:4.429446, z:95.06009}, rotation:{x:-0.6726243, y:0.2204319, z:-0.06861572}, scale:{x:1, y:1, z:1}, file:'RigLEar01Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:70.40205, y:4.378253, z:95.06009}, rotation:{x:0.5, y:-0.5, z:0.5000001}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'RigBagGizmo', position:{x:70.40205, y:4.060743, z:95.18678}, rotation:{x:-0.09621691, y:-0.7005301, z:-0.09621677}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigBagGizmo.fbx'},
        {name:'Rig', position:{x:70.40205, y:3.404588, z:95.03157}, rotation:{x:0.5, y:-0.5, z:0.5000001}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Treasure_Goblin___Red_(1)', position:{x:70.40205, y:3.405539, z:95.09071}, rotation:{x:0, y:-1, z:0}, scale:{x:1, y:1, z:1}, file:'Treasure_Goblin___Red_(1).fbx'},
        {name:'Rock_13', position:{x:73.61, y:2.36, z:71.65}, rotation:{x:0, y:0.315531, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_13.fbx'},
        {name:'Plant_07', position:{x:62.66186, y:1.987398, z:57.84881}, rotation:{x:0, y:-0.8706084, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_07.fbx'},
        {name:'Trunk_01', position:{x:80.21153, y:5.49265, z:104.9388}, rotation:{x:0, y:0, z:0}, scale:{x:2.629081, y:2.629081, z:2.629081}, file:'Trunk_01.fbx'},
        {name:'Bush_02', position:{x:88.37952, y:3.429694, z:100.1571}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_02.fbx'},
        {name:'Block1', position:{x:95.59, y:3.9, z:84.26}, rotation:{x:0, y:0.007107861, z:0}, scale:{x:1, y:1, z:1}, file:'Block1.fbx'},
        {name:'Bush_06', position:{x:85.08409, y:5.515894, z:108.6102}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_06.fbx'},
        {name:'Bush_04', position:{x:96.46606, y:3.330136, z:86.04579}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_04.fbx'},
        {name:'Tree_Small_02', position:{x:89.83059, y:5.492178, z:104.9028}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Small_02.fbx'},
        {name:'Block4', position:{x:62.13, y:5.1, z:76.07}, rotation:{x:0, y:0.9998109, z:0}, scale:{x:1, y:1, z:1}, file:'Block4.fbx'},
        {name:'Ivy1', position:{x:70.06916, y:7.967696, z:102.0691}, rotation:{x:0, y:-0.8055472, z:0}, scale:{x:1.112361, y:1.112361, z:1.112361}, file:'Ivy1.fbx'},
        {name:'Rock_11', position:{x:72.34352, y:2.000379, z:83.90993}, rotation:{x:0.01969223, y:0.9971675, z:0.06700411}, scale:{x:1, y:1, z:1}, file:'Rock_11.fbx'},
        {name:'Crystal5', position:{x:92.07661, y:3.405747, z:79.12614}, rotation:{x:0, y:-0.9009106, z:0}, scale:{x:0.5454153, y:0.5454153, z:0.5454153}, file:'Crystal5.fbx'},
        {name:'Wagon_Tracks1', position:{x:85.28, y:3.4, z:73.91}, rotation:{x:0, y:0.9221426, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_Tracks1.fbx'},
        {name:'Stair_el3', position:{x:81.25811, y:3.272928, z:84.60207}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el3.fbx'},
        {name:'Rock_15', position:{x:77.60854, y:4.715599, z:95.94151}, rotation:{x:-0.2935226, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_15.fbx'},
        {name:'Rock_10', position:{x:71.2056, y:2.564689, z:100.5902}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_10.fbx'},
        {name:'Rock_04', position:{x:73.67294, y:4.577657, z:97.10732}, rotation:{x:-0.1992554, y:-0.1215662, z:0.3906941}, scale:{x:1, y:1, z:1}, file:'Rock_04.fbx'},
        {name:'RigPelvisGizmo', position:{x:81.64697, y:5.673162, z:103.5609}, rotation:{x:0.6590784, y:-0.6590784, z:-0.2561558}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigMouthUp', position:{x:81.82665, y:6.862201, z:103.3963}, rotation:{x:0.9320778, y:2.088896E-07, z:-0.3622593}, scale:{x:1, y:1, z:1}, file:'RigMouthUp.fbx'},
        {name:'RigTongue07', position:{x:81.2567, y:6.481674, z:103.9182}, rotation:{x:0.9179175, y:-0.1618535, z:-0.3567558}, scale:{x:1, y:1, z:1}, file:'RigTongue07.fbx'},
        {name:'RigTongue06', position:{x:81.32039, y:6.481674, z:103.8599}, rotation:{x:0.9320776, y:2.481737E-07, z:-0.3622593}, scale:{x:1, y:1, z:1}, file:'RigTongue06.fbx'},
        {name:'RigTongue05', position:{x:81.39068, y:6.536697, z:103.7955}, rotation:{x:0.9003179, y:0.2412397, z:-0.3499156}, scale:{x:1, y:1, z:1}, file:'RigTongue05.fbx'},
        {name:'RigTongue04', position:{x:81.48841, y:6.58493, z:103.706}, rotation:{x:0.9179173, y:0.1618538, z:-0.3567557}, scale:{x:1, y:1, z:1}, file:'RigTongue04.fbx'},
        {name:'RigTongue03', position:{x:81.58006, y:6.618224, z:103.6221}, rotation:{x:0.9241035, y:0.1216608, z:-0.3591601}, scale:{x:1, y:1, z:1}, file:'RigTongue03.fbx'},
        {name:'RigTongue02', position:{x:81.68875, y:6.631118, z:103.5226}, rotation:{x:0.9311905, y:0.04065689, z:-0.3619145}, scale:{x:1, y:1, z:1}, file:'RigTongue02.fbx'},
        {name:'RigTongue01', position:{x:81.79525, y:6.578561, z:103.4251}, rotation:{x:0.9179173, y:-0.1618534, z:-0.3567557}, scale:{x:1, y:1, z:1}, file:'RigTongue01.fbx'},
        {name:'RigMouthLow', position:{x:81.82365, y:6.625069, z:103.3991}, rotation:{x:0.9179173, y:0.1618538, z:-0.3567557}, scale:{x:1, y:1, z:1}, file:'RigMouthLow.fbx'},
        {name:'RigHead', position:{x:81.97664, y:6.703576, z:103.259}, rotation:{x:0.9320778, y:2.088896E-07, z:-0.3622593}, scale:{x:1, y:1, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck9', position:{x:82.04281, y:6.649673, z:103.1984}, rotation:{x:0.898181, y:-0.2490779, z:-0.3490846}, scale:{x:1, y:0.9999993, z:1}, file:'RigNeck9.fbx'},
        {name:'RigNeck8', position:{x:82.09943, y:6.555896, z:103.1466}, rotation:{x:0.8423353, y:-0.3990487, z:-0.3273802}, scale:{x:0.9999983, y:1.000001, z:1}, file:'RigNeck8.fbx'},
        {name:'RigNeck7', position:{x:82.12505, y:6.388922, z:103.1231}, rotation:{x:0.7231005, y:-0.5881277, z:-0.2810392}, scale:{x:0.9999999, y:1, z:1}, file:'RigNeck7.fbx'},
        {name:'RigNeck6', position:{x:82.08524, y:6.246176, z:103.1596}, rotation:{x:0.5298555, y:-0.7668258, z:-0.2059324}, scale:{x:0.9999992, y:1.000001, z:1}, file:'RigNeck6.fbx'},
        {name:'RigNeck5', position:{x:82.01015, y:6.124306, z:103.2283}, rotation:{x:0.3948444, y:-0.8443142, z:-0.1534593}, scale:{x:1.000001, y:0.999999, z:1}, file:'RigNeck5.fbx'},
        {name:'RigNeck4', position:{x:81.90384, y:6.049765, z:103.3257}, rotation:{x:0.220312, y:-0.9056661, z:-0.08562595}, scale:{x:0.9999996, y:1, z:1}, file:'RigNeck4.fbx'},
        {name:'RigNeck3', position:{x:81.82094, y:5.98397, z:103.4016}, rotation:{x:0.2439243, y:-0.8995941, z:-0.09480301}, scale:{x:1, y:1, z:1}, file:'RigNeck3.fbx'},
        {name:'RigNeck2', position:{x:81.73746, y:5.932251, z:103.478}, rotation:{x:0.1982163, y:-0.9107572, z:-0.07703819}, scale:{x:0.9999998, y:0.9999998, z:1}, file:'RigNeck2.fbx'},
        {name:'RigNeck1', position:{x:81.68876, y:5.827145, z:103.5226}, rotation:{x:0.4508967, y:-0.8157577, z:-0.1752445}, scale:{x:1, y:1, z:1}, file:'RigNeck1.fbx'},
        {name:'RigLegMidRight10', position:{x:83.08129, y:5.562062, z:105.1829}, rotation:{x:-0.4029214, y:-0.000946231, z:0.9152316}, scale:{x:0.9999998, y:0.9999998, z:1}, file:'RigLegMidRight10.fbx'},
        {name:'RigLegMidRight9', position:{x:82.91474, y:5.56303, z:105.001}, rotation:{x:-0.4029217, y:-0.0007906654, z:0.9152324}, scale:{x:0.9999998, y:0.9999998, z:1}, file:'RigLegMidRight9.fbx'},
        {name:'RigLegMidRight8', position:{x:82.75935, y:5.563999, z:104.8313}, rotation:{x:-0.4029217, y:-0.0008478732, z:0.9152322}, scale:{x:0.9999998, y:0.9999998, z:1}, file:'RigLegMidRight8.fbx'},
        {name:'RigLegMidRight7', position:{x:82.63013, y:5.564991, z:104.6901}, rotation:{x:-0.4029213, y:-0.001044561, z:0.9152312}, scale:{x:0.9999998, y:0.9999998, z:1}, file:'RigLegMidRight7.fbx'},
        {name:'RigLegMidRight6', position:{x:82.47108, y:5.565956, z:104.5164}, rotation:{x:-0.4029217, y:-0.000825768, z:0.9152322}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight6.fbx'},
        {name:'RigLegMidRight5', position:{x:82.32314, y:5.566984, z:104.3549}, rotation:{x:-0.4029214, y:-0.000946231, z:0.9152316}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight5.fbx'},
        {name:'RigLegMidRight4', position:{x:82.18201, y:5.567966, z:104.2007}, rotation:{x:-0.4029214, y:-0.000946231, z:0.9152316}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight4.fbx'},
        {name:'RigLegMidRight3', position:{x:82.04088, y:5.568947, z:104.0466}, rotation:{x:-0.4029214, y:-0.000946231, z:0.9152316}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight3.fbx'},
        {name:'RigLegMidRight2', position:{x:81.91885, y:5.569921, z:103.9133}, rotation:{x:-0.4029211, y:-0.001085277, z:0.9152309}, scale:{x:1, y:1, z:1}, file:'RigLegMidRight2.fbx'},
        {name:'RigLegMidRight1', position:{x:81.77524, y:5.570395, z:103.7565}, rotation:{x:-0.4029222, y:-0.0004494815, z:0.9152336}, scale:{x:1, y:1, z:1}, file:'RigLegMidRight1.fbx'},
        {name:'RigLegMidLeft10', position:{x:80.15851, y:5.554415, z:101.9908}, rotation:{x:-0.9152251, y:-0.004077565, z:-0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft10.fbx'},
        {name:'RigLegMidLeft9', position:{x:80.32626, y:5.556285, z:102.174}, rotation:{x:-0.9152277, y:-0.003445564, z:-0.4029195}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft9.fbx'},
        {name:'RigLegMidLeft8', position:{x:80.46823, y:5.558158, z:102.329}, rotation:{x:-0.9152251, y:-0.004077565, z:-0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft8.fbx'},
        {name:'RigLegMidLeft7', position:{x:80.61021, y:5.560031, z:102.4841}, rotation:{x:-0.9152251, y:-0.004077565, z:-0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft7.fbx'},
        {name:'RigLegMidLeft6', position:{x:80.75217, y:5.561905, z:102.6391}, rotation:{x:-0.9152251, y:-0.004077565, z:-0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft6.fbx'},
        {name:'RigLegMidLeft5', position:{x:80.89414, y:5.563778, z:102.7942}, rotation:{x:-0.9152251, y:-0.004077565, z:-0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft5.fbx'},
        {name:'RigLegMidLeft4', position:{x:81.03612, y:5.565651, z:102.9492}, rotation:{x:-0.9152251, y:-0.004077565, z:-0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft4.fbx'},
        {name:'RigLegMidLeft3', position:{x:81.17809, y:5.567525, z:103.1043}, rotation:{x:-0.9152251, y:-0.004077565, z:-0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft3.fbx'},
        {name:'RigLegMidLeft2', position:{x:81.32006, y:5.569398, z:103.2593}, rotation:{x:-0.9152251, y:-0.004077565, z:-0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft2.fbx'},
        {name:'RigLegMidLeft1', position:{x:81.46341, y:5.570395, z:103.4159}, rotation:{x:-0.9152317, y:-0.002148981, z:-0.4029213}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft1.fbx'},
        {name:'RigLegFrontRight10', position:{x:81.52142, y:5.562384, z:105.7477}, rotation:{x:0.7233598, y:0.00162488, z:-0.690468}, scale:{x:1, y:1, z:1}, file:'RigLegFrontRight10.fbx'},
        {name:'RigLegFrontRight9', position:{x:81.53336, y:5.563315, z:105.4867}, rotation:{x:0.7230802, y:0.001283938, z:-0.690762}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight9.fbx'},
        {name:'RigLegFrontRight8', position:{x:81.5431, y:5.56426, z:105.2775}, rotation:{x:0.7233599, y:0.001624852, z:-0.6904678}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight8.fbx'},
        {name:'RigLegFrontRight7', position:{x:81.55284, y:5.565204, z:105.0683}, rotation:{x:0.7233597, y:0.001624874, z:-0.690468}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight7.fbx'},
        {name:'RigLegFrontRight6', position:{x:81.56258, y:5.566148, z:104.859}, rotation:{x:0.7233595, y:0.00162488, z:-0.6904683}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight6.fbx'},
        {name:'RigLegFrontRight5', position:{x:81.57232, y:5.567092, z:104.6498}, rotation:{x:0.7233593, y:0.001624874, z:-0.6904684}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight5.fbx'},
        {name:'RigLegFrontRight4', position:{x:81.58206, y:5.568036, z:104.4406}, rotation:{x:0.723359, y:0.001624852, z:-0.6904687}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight4.fbx'},
        {name:'RigLegFrontRight3', position:{x:81.5918, y:5.56898, z:104.2314}, rotation:{x:0.7233589, y:0.001624874, z:-0.6904689}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight3.fbx'},
        {name:'RigLegFrontRight2', position:{x:81.59525, y:5.569924, z:104.0365}, rotation:{x:0.7133253, y:0.001768414, z:-0.7008291}, scale:{x:1, y:1, z:1}, file:'RigLegFrontRight2.fbx'},
        {name:'RigLegFrontRight1', position:{x:81.60673, y:5.570395, z:103.8184}, rotation:{x:0.7254639, y:0.000768507, z:-0.6882595}, scale:{x:1, y:1, z:1}, file:'RigLegFrontRight1.fbx'},
        {name:'RigLegFrontLeft10', position:{x:79.46046, y:5.554415, z:103.4937}, rotation:{x:0.9997481, y:0.004454139, z:0.0220035}, scale:{x:1, y:0.9999999, z:0.9999998}, file:'RigLegFrontLeft10.fbx'},
        {name:'RigLegFrontLeft9', position:{x:79.70995, y:5.556289, z:103.5007}, rotation:{x:0.9998947, y:0.003751494, z:0.01402977}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft9.fbx'},
        {name:'RigLegFrontLeft8', position:{x:79.91998, y:5.558162, z:103.51}, rotation:{x:0.999748, y:0.004454167, z:0.02200359}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft8.fbx'},
        {name:'RigLegFrontLeft7', position:{x:80.13001, y:5.560035, z:103.5192}, rotation:{x:0.9997481, y:0.0044542, z:0.02200416}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft7.fbx'},
        {name:'RigLegFrontLeft6', position:{x:80.34004, y:5.561909, z:103.5285}, rotation:{x:0.999748, y:0.004454178, z:0.02200472}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft6.fbx'},
        {name:'RigLegFrontLeft5', position:{x:80.55007, y:5.563782, z:103.5377}, rotation:{x:0.999748, y:0.004454167, z:0.02200532}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft5.fbx'},
        {name:'RigLegFrontLeft4', position:{x:80.76009, y:5.565655, z:103.547}, rotation:{x:0.999748, y:0.004454245, z:0.02200589}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft4.fbx'},
        {name:'RigLegFrontLeft3', position:{x:80.97012, y:5.567529, z:103.5562}, rotation:{x:0.9997479, y:0.004454239, z:0.02200651}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft3.fbx'},
        {name:'RigLegFrontLeft2', position:{x:81.16688, y:5.569402, z:103.5692}, rotation:{x:0.9994485, y:0.004750905, z:0.03286904}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft2.fbx'},
        {name:'RigLegFrontLeft1', position:{x:81.38691, y:5.570395, z:103.5783}, rotation:{x:0.9997821, y:0.002253491, z:0.02075696}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft1.fbx'},
        {name:'RigLegFront10', position:{x:80.02531, y:5.568303, z:105.0457}, rotation:{x:0.9320774, y:-2.996736E-06, z:-0.3622591}, scale:{x:1, y:1, z:1}, file:'RigLegFront10.fbx'},
        {name:'RigLegFront9', position:{x:80.20524, y:5.568303, z:104.881}, rotation:{x:0.9320774, y:-1.700057E-06, z:-0.3622591}, scale:{x:1, y:1, z:1}, file:'RigLegFront9.fbx'},
        {name:'RigLegFront8', position:{x:80.36506, y:5.568302, z:104.7346}, rotation:{x:0.9320775, y:-3.913722E-07, z:-0.3622591}, scale:{x:1, y:1, z:1}, file:'RigLegFront8.fbx'},
        {name:'RigLegFront7', position:{x:80.51923, y:5.568302, z:104.5934}, rotation:{x:0.9320774, y:8.013762E-08, z:-0.3622592}, scale:{x:1, y:1, z:1}, file:'RigLegFront7.fbx'},
        {name:'RigLegFront6', position:{x:80.68034, y:5.568302, z:104.4459}, rotation:{x:0.9320775, y:-1.101054E-07, z:-0.3622591}, scale:{x:1, y:1, z:1}, file:'RigLegFront6.fbx'},
        {name:'RigLegFront5', position:{x:80.83288, y:5.568302, z:104.3063}, rotation:{x:0.9320774, y:-1.324392E-07, z:-0.3622591}, scale:{x:1, y:1, z:1}, file:'RigLegFront5.fbx'},
        {name:'RigLegFront4', position:{x:80.99252, y:5.568302, z:104.1601}, rotation:{x:0.9320774, y:-1.634717E-07, z:-0.3622592}, scale:{x:1, y:1, z:1}, file:'RigLegFront4.fbx'},
        {name:'RigLegFront3', position:{x:81.15002, y:5.568302, z:104.0159}, rotation:{x:0.9320774, y:-1.651298E-07, z:-0.3622592}, scale:{x:1, y:1, z:1}, file:'RigLegFront3.fbx'},
        {name:'RigLegFront2', position:{x:81.28541, y:5.568302, z:103.8919}, rotation:{x:0.9320338, y:0.003501854, z:-0.3622423}, scale:{x:1, y:1, z:1}, file:'RigLegFront2.fbx'},
        {name:'RigLegFront1', position:{x:81.4586, y:5.570395, z:103.7333}, rotation:{x:0.9320682, y:0.004152658, z:-0.3622555}, scale:{x:1, y:1, z:1}, file:'RigLegFront1.fbx'},
        {name:'RigLegBackRight10', position:{x:83.78244, y:5.554414, z:103.679}, rotation:{x:-0.02200633, y:-9.825267E-05, z:0.9997481}, scale:{x:0.9999998, y:1, z:1}, file:'RigLegBackRight10.fbx'},
        {name:'RigLegBackRight9', position:{x:83.52692, y:5.556287, z:103.6757}, rotation:{x:-0.00662306, y:-0.0001356247, z:0.9999714}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegBackRight9.fbx'},
        {name:'RigLegBackRight8', position:{x:83.2945, y:5.558161, z:103.6615}, rotation:{x:-0.03030217, y:-5.171623E-05, z:0.9995327}, scale:{x:1, y:1, z:1}, file:'RigLegBackRight8.fbx'},
        {name:'RigLegBackRight7', position:{x:83.08447, y:5.560034, z:103.6523}, rotation:{x:-0.02200651, y:-9.822601E-05, z:0.9997479}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegBackRight7.fbx'},
        {name:'RigLegBackRight6', position:{x:82.87444, y:5.561907, z:103.643}, rotation:{x:-0.02200651, y:-9.82004E-05, z:0.999748}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegBackRight6.fbx'},
        {name:'RigLegBackRight5', position:{x:82.66441, y:5.56378, z:103.6338}, rotation:{x:-0.02200657, y:-9.824673E-05, z:0.999748}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegBackRight5.fbx'},
        {name:'RigLegBackRight4', position:{x:82.45438, y:5.565653, z:103.6245}, rotation:{x:-0.02200657, y:-9.821949E-05, z:0.9997479}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegBackRight4.fbx'},
        {name:'RigLegBackRight3', position:{x:82.24436, y:5.567527, z:103.6153}, rotation:{x:-0.02200663, y:-9.818899E-05, z:0.9997479}, scale:{x:1, y:1, z:1}, file:'RigLegBackRight3.fbx'},
        {name:'RigLegBackRight2', position:{x:82.06986, y:5.5694, z:103.6116}, rotation:{x:-0.01060402, y:-0.0001841527, z:0.9999293}, scale:{x:1, y:1, z:1}, file:'RigLegBackRight2.fbx'},
        {name:'RigLegBackRight1', position:{x:81.91425, y:5.570395, z:103.5988}, rotation:{x:-0.0410676, y:1.464796E-05, z:0.9991513}, scale:{x:1, y:1, z:1}, file:'RigLegBackRight1.fbx'},
        {name:'RigLegBackLeft10', position:{x:81.71701, y:5.554412, z:101.4233}, rotation:{x:0.6913682, y:0.003080562, z:0.7224891}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegBackLeft10.fbx'},
        {name:'RigLegBackLeft9', position:{x:81.69791, y:5.556287, z:101.6781}, rotation:{x:0.680173, y:0.002575338, z:0.7330424}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegBackLeft9.fbx'},
        {name:'RigLegBackLeft8', position:{x:81.69154, y:5.55816, z:101.9109}, rotation:{x:0.6973408, y:0.002754746, z:0.7167284}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegBackLeft8.fbx'},
        {name:'RigLegBackLeft7', position:{x:81.68228, y:5.560033, z:102.1209}, rotation:{x:0.6913679, y:0.003080431, z:0.7224892}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegBackLeft7.fbx'},
        {name:'RigLegBackLeft6', position:{x:81.67303, y:5.561907, z:102.3309}, rotation:{x:0.6913677, y:0.003080349, z:0.7224894}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegBackLeft6.fbx'},
        {name:'RigLegBackLeft5', position:{x:81.66378, y:5.56378, z:102.5409}, rotation:{x:0.6913677, y:0.003080346, z:0.7224895}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegBackLeft5.fbx'},
        {name:'RigLegBackLeft4', position:{x:81.65453, y:5.565653, z:102.751}, rotation:{x:0.6913676, y:0.003080305, z:0.7224895}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegBackLeft4.fbx'},
        {name:'RigLegBackLeft3', position:{x:81.64528, y:5.567527, z:102.961}, rotation:{x:0.6913676, y:0.00308029, z:0.7224896}, scale:{x:1, y:1, z:1}, file:'RigLegBackLeft3.fbx'},
        {name:'RigLegBackLeft2', position:{x:81.63363, y:5.5694, z:103.1351}, rotation:{x:0.6830803, y:0.003758717, z:0.7303237}, scale:{x:1, y:1, z:1}, file:'RigLegBackLeft2.fbx'},
        {name:'RigLegBackLeft1', position:{x:81.63271, y:5.570395, z:103.2913}, rotation:{x:0.7050228, y:0.002142471, z:0.7091775}, scale:{x:1, y:1, z:1}, file:'RigLegBackLeft1.fbx'},
        {name:'RigLegBack10', position:{x:83.21536, y:5.568292, z:102.1248}, rotation:{x:0.3622594, y:-2.186759E-06, z:0.9320774}, scale:{x:1, y:1, z:1}, file:'RigLegBack10.fbx'},
        {name:'RigLegBack9', position:{x:83.03543, y:5.568293, z:102.2896}, rotation:{x:0.3622594, y:-9.099203E-07, z:0.9320775}, scale:{x:1, y:1, z:1}, file:'RigLegBack9.fbx'},
        {name:'RigLegBack8', position:{x:82.87562, y:5.568293, z:102.4359}, rotation:{x:0.3622593, y:3.003532E-07, z:0.9320775}, scale:{x:1, y:1, z:1}, file:'RigLegBack8.fbx'},
        {name:'RigLegBack7', position:{x:82.72144, y:5.568293, z:102.5771}, rotation:{x:0.3622593, y:7.364871E-07, z:0.9320775}, scale:{x:1, y:1, z:1}, file:'RigLegBack7.fbx'},
        {name:'RigLegBack6', position:{x:82.56033, y:5.568293, z:102.7246}, rotation:{x:0.3622593, y:5.581061E-07, z:0.9320775}, scale:{x:1, y:1, z:1}, file:'RigLegBack6.fbx'},
        {name:'RigLegBack5', position:{x:82.4078, y:5.568293, z:102.8642}, rotation:{x:0.3622593, y:5.452236E-07, z:0.9320775}, scale:{x:1, y:1, z:1}, file:'RigLegBack5.fbx'},
        {name:'RigLegBack4', position:{x:82.24816, y:5.568293, z:103.0104}, rotation:{x:0.3622593, y:4.798889E-07, z:0.9320775}, scale:{x:1, y:1, z:1}, file:'RigLegBack4.fbx'},
        {name:'RigLegBack3', position:{x:82.09066, y:5.568293, z:103.1546}, rotation:{x:0.3622593, y:4.833623E-07, z:0.9320775}, scale:{x:1, y:1, z:1}, file:'RigLegBack3.fbx'},
        {name:'RigLegBack2', position:{x:81.95527, y:5.568293, z:103.2786}, rotation:{x:0.3622424, y:-0.00901042, z:0.9320339}, scale:{x:1, y:1, z:1}, file:'RigLegBack2.fbx'},
        {name:'RigLegBack1', position:{x:81.82037, y:5.570395, z:103.4021}, rotation:{x:0.3622533, y:0.00208197, z:0.9320621}, scale:{x:1, y:1, z:1}, file:'RigLegBack1.fbx'},
        {name:'RigPelvis', position:{x:81.64697, y:5.673162, z:103.5609}, rotation:{x:0.6590784, y:-0.6590784, z:-0.2561558}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigMouthUpGizmo', position:{x:81.82665, y:6.862202, z:103.3963}, rotation:{x:0.9320775, y:-3.526724E-08, z:-0.3622592}, scale:{x:1, y:1, z:1}, file:'RigMouthUpGizmo.fbx'},
        {name:'RigMouthLowGizmo', position:{x:81.82365, y:6.62507, z:103.3991}, rotation:{x:-0.9179171, y:-0.1618535, z:0.3567557}, scale:{x:1, y:0.9999999, z:1}, file:'RigMouthLowGizmo.fbx'},
        {name:'RigLegMidRight10Gizmo', position:{x:83.08129, y:5.562063, z:105.1829}, rotation:{x:-0.4029214, y:-0.0009461263, z:0.9152316}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight10Gizmo.fbx'},
        {name:'RigLegMidRight9Gizmo', position:{x:82.91474, y:5.56303, z:105.001}, rotation:{x:-0.4029217, y:-0.0007905904, z:0.9152324}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight9Gizmo.fbx'},
        {name:'RigLegMidRight8Gizmo', position:{x:82.75935, y:5.563999, z:104.8313}, rotation:{x:-0.4029216, y:-0.000847757, z:0.9152321}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight8Gizmo.fbx'},
        {name:'RigLegMidRight7Gizmo', position:{x:82.63013, y:5.564991, z:104.6901}, rotation:{x:-0.4029212, y:-0.001044496, z:0.9152311}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight7Gizmo.fbx'},
        {name:'RigLegMidRight6Gizmo_1', position:{x:82.47108, y:5.565956, z:104.5164}, rotation:{x:-0.4029216, y:-0.0008256486, z:0.9152322}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight6Gizmo_1.fbx'},
        {name:'RigLegMidRight6Gizmo', position:{x:82.47108, y:5.565956, z:104.5164}, rotation:{x:-0.4029216, y:-0.0008256486, z:0.9152322}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight6Gizmo.fbx'},
        {name:'RigLegMidRight5Gizmo', position:{x:82.32314, y:5.566985, z:104.3549}, rotation:{x:-0.4029214, y:-0.0009461262, z:0.9152316}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight5Gizmo.fbx'},
        {name:'RigLegMidRight4Gizmo_1', position:{x:82.18201, y:5.567966, z:104.2007}, rotation:{x:-0.4029214, y:-0.0009461262, z:0.9152316}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight4Gizmo_1.fbx'},
        {name:'RigLegMidRight4Gizmo', position:{x:82.18201, y:5.567966, z:104.2007}, rotation:{x:-0.4029214, y:-0.0009461262, z:0.9152316}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight4Gizmo.fbx'},
        {name:'RigLegMidRight3Gizmo', position:{x:82.04088, y:5.568947, z:104.0466}, rotation:{x:-0.4029214, y:-0.0009461262, z:0.9152316}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight3Gizmo.fbx'},
        {name:'RigLegMidRight2Gizmo', position:{x:81.91885, y:5.569921, z:103.9133}, rotation:{x:-0.4029211, y:-0.001085241, z:0.9152308}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegMidRight2Gizmo.fbx'},
        {name:'RigLegMidRight1Gizmo', position:{x:81.77524, y:5.570395, z:103.7565}, rotation:{x:-0.4029222, y:-0.0004493841, z:0.9152336}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegMidRight1Gizmo.fbx'},
        {name:'RigLegMidLeft10Gizmo', position:{x:80.15851, y:5.554415, z:101.9908}, rotation:{x:0.9152251, y:0.004077581, z:0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft10Gizmo.fbx'},
        {name:'RigLegMidLeft9Gizmo', position:{x:80.32626, y:5.556285, z:102.174}, rotation:{x:0.9152277, y:0.003445586, z:0.4029195}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegMidLeft9Gizmo.fbx'},
        {name:'RigLegMidLeft8Gizmo', position:{x:80.46823, y:5.558158, z:102.329}, rotation:{x:0.9152251, y:0.004077581, z:0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft8Gizmo.fbx'},
        {name:'RigLegMidLeft7Gizmo', position:{x:80.61021, y:5.560031, z:102.4841}, rotation:{x:0.9152251, y:0.004077581, z:0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft7Gizmo.fbx'},
        {name:'RigLegMidLeft6Gizmo', position:{x:80.75217, y:5.561905, z:102.6391}, rotation:{x:0.9152251, y:0.004077581, z:0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft6Gizmo.fbx'},
        {name:'RigLegMidLeft5Gizmo', position:{x:80.89414, y:5.563778, z:102.7942}, rotation:{x:0.9152251, y:0.004077581, z:0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft5Gizmo.fbx'},
        {name:'RigLegMidLeft4Gizmo', position:{x:81.03612, y:5.565651, z:102.9492}, rotation:{x:0.9152251, y:0.004077581, z:0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft4Gizmo.fbx'},
        {name:'RigLegMidLeft3Gizmo', position:{x:81.17809, y:5.567525, z:103.1043}, rotation:{x:0.9152251, y:0.004077581, z:0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft3Gizmo.fbx'},
        {name:'RigLegMidLeft2Gizmo', position:{x:81.32006, y:5.569398, z:103.2593}, rotation:{x:0.9152251, y:0.004077581, z:0.4029184}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft2Gizmo.fbx'},
        {name:'RigLegMidLeft1Gizmo_1', position:{x:81.46341, y:5.570395, z:103.4159}, rotation:{x:0.9152317, y:0.002148954, z:0.4029213}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft1Gizmo_1.fbx'},
        {name:'RigLegMidLeft1Gizmo', position:{x:81.46341, y:5.570395, z:103.4159}, rotation:{x:0.9152317, y:0.002148954, z:0.4029213}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft1Gizmo.fbx'},
        {name:'RigLegFrontRight10Gizmo', position:{x:81.52143, y:5.562385, z:105.7477}, rotation:{x:-0.7233592, y:-0.001624791, z:0.6904683}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontRight10Gizmo.fbx'},
        {name:'RigLegFrontRight9Gizmo', position:{x:81.53336, y:5.563315, z:105.4867}, rotation:{x:-0.7230788, y:-0.001283833, z:0.6907633}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegFrontRight9Gizmo.fbx'},
        {name:'RigLegFrontRight8Gizmo', position:{x:81.54311, y:5.56426, z:105.2775}, rotation:{x:-0.7233588, y:-0.001624784, z:0.6904688}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight8Gizmo.fbx'},
        {name:'RigLegFrontRight7Gizmo', position:{x:81.55284, y:5.565204, z:105.0683}, rotation:{x:-0.7233588, y:-0.001624784, z:0.6904688}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight7Gizmo.fbx'},
        {name:'RigLegFrontRight6Gizmo', position:{x:81.56258, y:5.566148, z:104.859}, rotation:{x:-0.7233588, y:-0.001624784, z:0.6904688}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight6Gizmo.fbx'},
        {name:'RigLegFrontRight5Gizmo', position:{x:81.57232, y:5.567092, z:104.6498}, rotation:{x:-0.7233588, y:-0.001624784, z:0.6904688}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight5Gizmo.fbx'},
        {name:'RigLegFrontRight4Gizmo', position:{x:81.58206, y:5.568036, z:104.4406}, rotation:{x:-0.7233588, y:-0.001624784, z:0.6904688}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight4Gizmo.fbx'},
        {name:'RigLegFrontRight3Gizmo', position:{x:81.5918, y:5.56898, z:104.2314}, rotation:{x:-0.7233588, y:-0.001624784, z:0.6904688}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight3Gizmo.fbx'},
        {name:'RigLegFrontRight2Gizmo', position:{x:81.59525, y:5.569924, z:104.0365}, rotation:{x:-0.7133251, y:-0.001768367, z:0.7008291}, scale:{x:1, y:1, z:1}, file:'RigLegFrontRight2Gizmo.fbx'},
        {name:'RigLegFrontRight1Gizmo', position:{x:81.60673, y:5.570395, z:103.8184}, rotation:{x:-0.7254639, y:-0.0007684562, z:0.6882595}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegFrontRight1Gizmo.fbx'},
        {name:'RigLegFrontLeft10Gizmo', position:{x:79.46046, y:5.554416, z:103.4937}, rotation:{x:0.999748, y:0.004454117, z:0.02200505}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigLegFrontLeft10Gizmo.fbx'},
        {name:'RigLegFrontLeft9Gizmo', position:{x:79.70995, y:5.556289, z:103.5007}, rotation:{x:0.9998946, y:0.003751406, z:0.01403296}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft9Gizmo.fbx'},
        {name:'RigLegFrontLeft8Gizmo', position:{x:79.91998, y:5.558162, z:103.51}, rotation:{x:0.999748, y:0.004454115, z:0.02200618}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft8Gizmo.fbx'},
        {name:'RigLegFrontLeft7Gizmo', position:{x:80.13001, y:5.560036, z:103.5192}, rotation:{x:0.999748, y:0.004454115, z:0.02200618}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft7Gizmo.fbx'},
        {name:'RigLegFrontLeft6Gizmo', position:{x:80.34004, y:5.561909, z:103.5285}, rotation:{x:0.999748, y:0.004454115, z:0.02200618}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft6Gizmo.fbx'},
        {name:'RigLegFrontLeft5Gizmo', position:{x:80.55007, y:5.563782, z:103.5377}, rotation:{x:0.999748, y:0.004454115, z:0.02200618}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft5Gizmo.fbx'},
        {name:'RigLegFrontLeft4Gizmo', position:{x:80.76009, y:5.565655, z:103.547}, rotation:{x:0.999748, y:0.004454115, z:0.02200618}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft4Gizmo.fbx'},
        {name:'RigLegFrontLeft3Gizmo', position:{x:80.97012, y:5.567529, z:103.5562}, rotation:{x:0.999748, y:0.004454115, z:0.02200618}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft3Gizmo.fbx'},
        {name:'RigLegFrontLeft2Gizmo', position:{x:81.16688, y:5.569402, z:103.5692}, rotation:{x:0.9994485, y:0.00475082, z:0.03286904}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegFrontLeft2Gizmo.fbx'},
        {name:'RigLegFrontLeft1Gizmo', position:{x:81.38691, y:5.570395, z:103.5783}, rotation:{x:0.9997821, y:0.002253415, z:0.02075693}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft1Gizmo.fbx'},
        {name:'RigLegFront10Gizmo', position:{x:80.02531, y:5.568303, z:105.0457}, rotation:{x:0.9320775, y:9.480396E-08, z:-0.3622592}, scale:{x:1, y:1, z:1}, file:'RigLegFront10Gizmo.fbx'},
        {name:'RigLegFront9Gizmo', position:{x:80.20524, y:5.568303, z:104.881}, rotation:{x:0.9320775, y:9.480388E-08, z:-0.3622592}, scale:{x:1, y:1, z:1}, file:'RigLegFront9Gizmo.fbx'},
        {name:'RigLegFront8Gizmo', position:{x:80.36506, y:5.568303, z:104.7346}, rotation:{x:0.9320775, y:9.480383E-08, z:-0.3622592}, scale:{x:1, y:1, z:1}, file:'RigLegFront8Gizmo.fbx'},
        {name:'RigLegFront7Gizmo', position:{x:80.51923, y:5.568303, z:104.5934}, rotation:{x:0.9320775, y:-2.99931E-07, z:-0.3622592}, scale:{x:1, y:1, z:1}, file:'RigLegFront7Gizmo.fbx'},
        {name:'RigLegFront6Gizmo', position:{x:80.68034, y:5.568303, z:104.4459}, rotation:{x:0.9320775, y:-1.771246E-07, z:-0.3622592}, scale:{x:1, y:1, z:1}, file:'RigLegFront6Gizmo.fbx'},
        {name:'RigLegFront5Gizmo', position:{x:80.83288, y:5.568303, z:104.3063}, rotation:{x:0.9320775, y:-2.385278E-07, z:-0.3622592}, scale:{x:1, y:1, z:1}, file:'RigLegFront5Gizmo.fbx'},
        {name:'RigLegFront4Gizmo', position:{x:80.99252, y:5.568303, z:104.1601}, rotation:{x:0.9320775, y:-1.946684E-07, z:-0.3622592}, scale:{x:1, y:1, z:1}, file:'RigLegFront4Gizmo.fbx'},
        {name:'RigLegFront3Gizmo', position:{x:81.15002, y:5.568303, z:104.0159}, rotation:{x:0.9320775, y:-2.034403E-07, z:-0.3622592}, scale:{x:1, y:1, z:1}, file:'RigLegFront3Gizmo.fbx'},
        {name:'RigLegFront2Gizmo', position:{x:81.28541, y:5.568303, z:103.8919}, rotation:{x:0.9320339, y:0.003501924, z:-0.3622422}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFront2Gizmo.fbx'},
        {name:'RigLegFront1Gizmo', position:{x:81.4586, y:5.570395, z:103.7333}, rotation:{x:-0.9320682, y:-0.004152547, z:0.3622556}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFront1Gizmo.fbx'},
        {name:'RigLegBackRight10Gizmo', position:{x:83.78244, y:5.554413, z:103.679}, rotation:{x:-0.02200657, y:-9.820098E-05, z:0.999748}, scale:{x:0.9999998, y:1, z:0.9999999}, file:'RigLegBackRight10Gizmo.fbx'},
        {name:'RigLegBackRight9Gizmo', position:{x:83.52692, y:5.556287, z:103.6757}, rotation:{x:-0.006623089, y:-0.0001354439, z:0.9999714}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegBackRight9Gizmo.fbx'},
        {name:'RigLegBackRight8Gizmo', position:{x:83.2945, y:5.55816, z:103.6615}, rotation:{x:-0.03030232, y:-5.16827E-05, z:0.9995328}, scale:{x:1, y:1, z:1}, file:'RigLegBackRight8Gizmo.fbx'},
        {name:'RigLegBackRight7Gizmo', position:{x:83.08447, y:5.560034, z:103.6523}, rotation:{x:-0.02200657, y:-9.813544E-05, z:0.999748}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegBackRight7Gizmo.fbx'},
        {name:'RigLegBackRight6Gizmo', position:{x:82.87444, y:5.561907, z:103.643}, rotation:{x:-0.02200657, y:-9.813544E-05, z:0.999748}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegBackRight6Gizmo.fbx'},
        {name:'RigLegBackRight5Gizmo', position:{x:82.66441, y:5.56378, z:103.6338}, rotation:{x:-0.02200657, y:-9.813544E-05, z:0.999748}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegBackRight5Gizmo.fbx'},
        {name:'RigLegBackRight4Gizmo', position:{x:82.45438, y:5.565653, z:103.6245}, rotation:{x:-0.02200657, y:-9.813544E-05, z:0.999748}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegBackRight4Gizmo.fbx'},
        {name:'RigLegBackRight3Gizmo', position:{x:82.24436, y:5.567527, z:103.6153}, rotation:{x:-0.02200657, y:-9.813544E-05, z:0.999748}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegBackRight3Gizmo.fbx'},
        {name:'RigLegBackRight2Gizmo', position:{x:82.06986, y:5.5694, z:103.6116}, rotation:{x:-0.01060405, y:-0.0001841185, z:0.9999294}, scale:{x:1, y:1, z:1}, file:'RigLegBackRight2Gizmo.fbx'},
        {name:'RigLegBackRight1Gizmo', position:{x:81.91425, y:5.570395, z:103.5988}, rotation:{x:-0.04106763, y:1.469546E-05, z:0.9991513}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegBackRight1Gizmo.fbx'},
        {name:'RigLegBackLeft10Gizmo', position:{x:81.71701, y:5.554413, z:101.4233}, rotation:{x:0.6913675, y:0.003080033, z:0.7224897}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegBackLeft10Gizmo.fbx'},
        {name:'RigLegBackLeft9Gizmo', position:{x:81.69792, y:5.556287, z:101.6781}, rotation:{x:0.6801727, y:0.002575371, z:0.7330428}, scale:{x:1, y:1, z:1}, file:'RigLegBackLeft9Gizmo.fbx'},
        {name:'RigLegBackLeft8Gizmo', position:{x:81.69154, y:5.55816, z:101.9109}, rotation:{x:0.6973405, y:0.002754566, z:0.7167288}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegBackLeft8Gizmo.fbx'},
        {name:'RigLegBackLeft7Gizmo', position:{x:81.68228, y:5.560033, z:102.1209}, rotation:{x:0.6913676, y:0.003080298, z:0.7224896}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLegBackLeft7Gizmo.fbx'},
        {name:'RigLegBackLeft6Gizmo', position:{x:81.67303, y:5.561907, z:102.3309}, rotation:{x:0.6913676, y:0.003080298, z:0.7224896}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLegBackLeft6Gizmo.fbx'},
        {name:'RigLegBackLeft5Gizmo', position:{x:81.66378, y:5.56378, z:102.5409}, rotation:{x:0.6913676, y:0.003080298, z:0.7224896}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLegBackLeft5Gizmo.fbx'},
        {name:'RigLegBackLeft4Gizmo', position:{x:81.65453, y:5.565653, z:102.751}, rotation:{x:0.6913676, y:0.003080298, z:0.7224896}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLegBackLeft4Gizmo.fbx'},
        {name:'RigLegBackLeft3Gizmo', position:{x:81.64528, y:5.567527, z:102.961}, rotation:{x:0.6913676, y:0.003080298, z:0.7224896}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLegBackLeft3Gizmo.fbx'},
        {name:'RigLegBackLeft2Gizmo', position:{x:81.63362, y:5.5694, z:103.1351}, rotation:{x:0.6830802, y:0.003758715, z:0.7303237}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegBackLeft2Gizmo.fbx'},
        {name:'RigLegBackLeft1Gizmo', position:{x:81.63271, y:5.570395, z:103.2913}, rotation:{x:0.7050228, y:0.002142451, z:0.7091775}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegBackLeft1Gizmo.fbx'},
        {name:'RigLegBack10Gizmo', position:{x:83.21536, y:5.568293, z:102.1248}, rotation:{x:0.3622592, y:1.276056E-06, z:0.9320775}, scale:{x:1, y:1, z:1}, file:'RigLegBack10Gizmo.fbx'},
        {name:'RigLegBack9Gizmo', position:{x:83.03543, y:5.568293, z:102.2896}, rotation:{x:0.3622592, y:1.276056E-06, z:0.9320775}, scale:{x:1, y:1, z:1}, file:'RigLegBack9Gizmo.fbx'},
        {name:'RigLegBack8Gizmo', position:{x:82.87562, y:5.568293, z:102.4359}, rotation:{x:0.3622592, y:1.276055E-06, z:0.9320775}, scale:{x:1, y:1, z:1}, file:'RigLegBack8Gizmo.fbx'},
        {name:'RigLegBack7Gizmo', position:{x:82.72144, y:5.568293, z:102.5771}, rotation:{x:0.3622592, y:2.604194E-07, z:0.9320775}, scale:{x:1, y:1, z:1}, file:'RigLegBack7Gizmo.fbx'},
        {name:'RigLegBack6Gizmo', position:{x:82.56033, y:5.568293, z:102.7246}, rotation:{x:0.3622592, y:5.763951E-07, z:0.9320775}, scale:{x:1, y:1, z:1}, file:'RigLegBack6Gizmo.fbx'},
        {name:'RigLegBack5Gizmo', position:{x:82.4078, y:5.568293, z:102.8642}, rotation:{x:0.3622592, y:4.184072E-07, z:0.9320775}, scale:{x:1, y:1, z:1}, file:'RigLegBack5Gizmo.fbx'},
        {name:'RigLegBack4Gizmo', position:{x:82.24816, y:5.568293, z:103.0104}, rotation:{x:0.3622592, y:5.312556E-07, z:0.9320775}, scale:{x:1, y:1, z:1}, file:'RigLegBack4Gizmo.fbx'},
        {name:'RigLegBack3Gizmo', position:{x:82.09066, y:5.568293, z:103.1546}, rotation:{x:0.3622592, y:5.08686E-07, z:0.9320775}, scale:{x:1, y:1, z:1}, file:'RigLegBack3Gizmo.fbx'},
        {name:'RigLegBack2Gizmo', position:{x:81.95527, y:5.568293, z:103.2786}, rotation:{x:0.3622422, y:-0.009010321, z:0.9320339}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegBack2Gizmo.fbx'},
        {name:'RigLegBack1Gizmo', position:{x:81.82037, y:5.570395, z:103.4021}, rotation:{x:0.3622533, y:0.002082009, z:0.932062}, scale:{x:1, y:1, z:1}, file:'RigLegBack1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:81.97664, y:6.703577, z:103.259}, rotation:{x:0.9320775, y:-3.526724E-08, z:-0.3622592}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'Rig', position:{x:81.62053, y:5.490776, z:103.5851}, rotation:{x:0.6590784, y:-0.6590784, z:-0.2561558}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Carnivorous_Plant', position:{x:81.62053, y:5.490776, z:103.5851}, rotation:{x:-0.6868104, y:-0.1682011, z:-0.1682011}, scale:{x:1, y:1, z:1}, file:'Carnivorous_Plant.fbx'},
        {name:'Carnivorous_Plant_Green', position:{x:81.62053, y:5.490776, z:103.5851}, rotation:{x:0, y:-0.4029224, z:0}, scale:{x:1, y:1, z:1}, file:'Carnivorous_Plant_Green.fbx'},
        {name:'Tree_04', position:{x:62.74038, y:3.405359, z:93.95841}, rotation:{x:0, y:-0.7730263, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_04.fbx'},
        {name:'Crystal2', position:{x:74.03039, y:3.320799, z:74.81844}, rotation:{x:0.177499, y:0.2052339, z:-0.0948009}, scale:{x:1, y:1, z:1}, file:'Crystal2.fbx'},
        {name:'Stair_el5', position:{x:77.76025, y:2.518855, z:82.61602}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el5.fbx'},
        {name:'Crystal', position:{x:69.36002, y:4.425363, z:97.41896}, rotation:{x:0, y:0.5406407, z:0}, scale:{x:1, y:1, z:1}, file:'Crystal.fbx'},
        {name:'Base', position:{x:69.35796, y:3.405506, z:97.42545}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Base.fbx'},
        {name:'Bush_01', position:{x:71.65913, y:3.372119, z:86.29365}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_01.fbx'},
        {name:'Plant_04', position:{x:63.8704, y:3.429039, z:103.5214}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_04.fbx'},
        {name:'Stair_el1', position:{x:77.27335, y:2.712874, z:88.58683}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el1.fbx'},
        {name:'Block1', position:{x:79.12296, y:2.032419, z:89.64697}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Block1.fbx'},
        {name:'Stair_el4', position:{x:71.53865, y:4.764298, z:105.5608}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el4.fbx'},
        {name:'Crystal1', position:{x:73.82124, y:3.650944, z:95.73518}, rotation:{x:-0.1627635, y:-0.05072318, z:0.07798163}, scale:{x:1, y:1, z:1}, file:'Crystal1.fbx'},
        {name:'Rock_14A', position:{x:73.76244, y:3.492884, z:95.75401}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_14A.fbx'},
        {name:'Mushroom3A', position:{x:70.66705, y:3.405359, z:88.29597}, rotation:{x:0, y:-0.9928591, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom3A.fbx'},
        {name:'Block4', position:{x:67.16534, y:3.45176, z:104.8165}, rotation:{x:0, y:0.9425884, z:0}, scale:{x:1, y:1, z:1}, file:'Block4.fbx'},
        {name:'Block4', position:{x:87.31834, y:5.1, z:109.1083}, rotation:{x:0, y:0.909279, z:0}, scale:{x:1, y:1, z:1}, file:'Block4.fbx'},
        {name:'Plant_07', position:{x:97.34313, y:1.997678, z:97.73859}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_07.fbx'},
        {name:'Wagon_gate1', position:{x:75.45137, y:3.405989, z:96.07937}, rotation:{x:0, y:-0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_gate1.fbx'},
        {name:'Tree_Pine_3', position:{x:73.73604, y:5.492279, z:109.3235}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_3.fbx'},
        {name:'Block4', position:{x:94.81, y:3.5, z:84.34}, rotation:{x:0, y:0.7477763, z:0}, scale:{x:1, y:1, z:1}, file:'Block4.fbx'},
        {name:'Ivy4', position:{x:67.91153, y:3.405359, z:93.58652}, rotation:{x:0, y:0.2004099, z:0}, scale:{x:1, y:1, z:1}, file:'Ivy4.fbx'},
        {name:'Tree_02', position:{x:88.97265, y:3.411798, z:67.92957}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_02.fbx'},
        {name:'Block4', position:{x:78.41881, y:2.769405, z:90.21233}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Block4.fbx'},
        {name:'Floor5', position:{x:68.57935, y:3.40589, z:92.22805}, rotation:{x:0, y:0.960065, z:0}, scale:{x:1, y:1, z:1}, file:'Floor5.fbx'},
        {name:'Tree_Pine_3', position:{x:90.97073, y:3.642495, z:83.37268}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_3.fbx'},
        {name:'Stair_el2', position:{x:74.99825, y:2.712874, z:86.31172}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el2.fbx'},
        {name:'Ivy3', position:{x:68.08353, y:5.036321, z:103.2353}, rotation:{x:0, y:-0.8194512, z:0}, scale:{x:1, y:1, z:1}, file:'Ivy3.fbx'},
        {name:'Tower_Base1_3', position:{x:93.49, y:3.53, z:73.19}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Tower_Base1_3.fbx'},
        {name:'Block4', position:{x:76.58611, y:1.806866, z:82.03157}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Block4.fbx'},
        {name:'Tree_05', position:{x:93.90625, y:3.406835, z:90.05888}, rotation:{x:0, y:0.2153942, z:0}, scale:{x:1, y:0.7169965, z:1}, file:'Tree_05.fbx'},
        {name:'Block2', position:{x:63.63, y:4.2, z:76.13}, rotation:{x:0, y:0.9998109, z:0}, scale:{x:1, y:1, z:1}, file:'Block2.fbx'},
        {name:'Tree_Pine_3', position:{x:82.05141, y:3.643084, z:75.66679}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_3.fbx'},
        {name:'RigPelvisGizmo', position:{x:81.19567, y:5.67361, z:106.5791}, rotation:{x:0.6033012, y:-0.6033012, z:0.3688193}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigMouthUp', position:{x:81.30674, y:6.862649, z:106.7959}, rotation:{x:0.8531971, y:2.687017E-07, z:0.5215893}, scale:{x:1, y:1, z:1}, file:'RigMouthUp.fbx'},
        {name:'RigTongue07', position:{x:80.95444, y:6.482122, z:106.1081}, rotation:{x:0.8402352, y:-0.1481559, z:0.5136653}, scale:{x:1, y:1, z:1}, file:'RigTongue07.fbx'},
        {name:'RigTongue06', position:{x:80.9938, y:6.482122, z:106.185}, rotation:{x:0.853197, y:3.046612E-07, z:0.5215893}, scale:{x:1, y:1, z:1}, file:'RigTongue06.fbx'},
        {name:'RigTongue05', position:{x:81.03725, y:6.537145, z:106.2698}, rotation:{x:0.8241251, y:0.2208239, z:0.5038165}, scale:{x:1, y:1, z:1}, file:'RigTongue05.fbx'},
        {name:'RigTongue04', position:{x:81.09766, y:6.585379, z:106.3877}, rotation:{x:0.8402349, y:0.1481564, z:0.5136651}, scale:{x:1, y:1, z:1}, file:'RigTongue04.fbx'},
        {name:'RigTongue03', position:{x:81.15431, y:6.618672, z:106.4983}, rotation:{x:0.8458977, y:0.1113648, z:0.5171269}, scale:{x:1, y:1, z:1}, file:'RigTongue03.fbx'},
        {name:'RigTongue02', position:{x:81.2215, y:6.631566, z:106.6295}, rotation:{x:0.8523849, y:0.03721622, z:0.5210928}, scale:{x:1, y:1, z:1}, file:'RigTongue02.fbx'},
        {name:'RigTongue01', position:{x:81.28733, y:6.579009, z:106.758}, rotation:{x:0.840235, y:-0.1481559, z:0.5136652}, scale:{x:1, y:1, z:1}, file:'RigTongue01.fbx'},
        {name:'RigMouthLow', position:{x:81.30488, y:6.625517, z:106.7923}, rotation:{x:0.8402349, y:0.1481564, z:0.5136651}, scale:{x:1, y:1, z:1}, file:'RigMouthLow.fbx'},
        {name:'RigHead', position:{x:81.39945, y:6.704024, z:106.9769}, rotation:{x:0.8531971, y:2.687017E-07, z:0.5215893}, scale:{x:1, y:1, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck9', position:{x:81.44035, y:6.650122, z:107.0568}, rotation:{x:0.8221685, y:-0.2279987, z:0.5026209}, scale:{x:1, y:0.9999993, z:1}, file:'RigNeck9.fbx'},
        {name:'RigNeck8', position:{x:81.47534, y:6.556345, z:107.1251}, rotation:{x:0.7710495, y:-0.3652773, z:0.4713696}, scale:{x:0.9999983, y:1.000001, z:1}, file:'RigNeck8.fbx'},
        {name:'RigNeck7', position:{x:81.49119, y:6.38937, z:107.156}, rotation:{x:0.6619057, y:-0.5383546, z:0.4046457}, scale:{x:0.9999999, y:1, z:1}, file:'RigNeck7.fbx'},
        {name:'RigNeck6', position:{x:81.46658, y:6.246624, z:107.108}, rotation:{x:0.4850143, y:-0.7019302, z:0.2965064}, scale:{x:0.9999992, y:1.000001, z:1}, file:'RigNeck6.fbx'},
        {name:'RigNeck5', position:{x:81.42017, y:6.124754, z:107.0174}, rotation:{x:0.3614291, y:-0.7728609, z:0.2209544}, scale:{x:1.000001, y:0.999999, z:1}, file:'RigNeck5.fbx'},
        {name:'RigNeck4', position:{x:81.35445, y:6.050213, z:106.8891}, rotation:{x:0.2016672, y:-0.8290206, z:0.1232863}, scale:{x:0.9999996, y:1, z:1}, file:'RigNeck4.fbx'},
        {name:'RigNeck3', position:{x:81.30321, y:5.984418, z:106.789}, rotation:{x:0.2232812, y:-0.8234625, z:0.1364997}, scale:{x:1, y:1, z:1}, file:'RigNeck3.fbx'},
        {name:'RigNeck2', position:{x:81.2516, y:5.932699, z:106.6883}, rotation:{x:0.1814414, y:-0.8336809, z:0.1109216}, scale:{x:0.9999998, y:0.9999998, z:1}, file:'RigNeck2.fbx'},
        {name:'RigNeck1', position:{x:81.2215, y:5.827593, z:106.6295}, rotation:{x:0.4127378, y:-0.746721, z:0.2523212}, scale:{x:1, y:1, z:1}, file:'RigNeck1.fbx'},
        {name:'RigLegMidRight10', position:{x:79.25175, y:5.56251, z:107.5327}, rotation:{x:-0.9721178, y:-0.002282535, z:0.2344812}, scale:{x:0.9999998, y:0.9999998, z:1}, file:'RigLegMidRight10.fbx'},
        {name:'RigLegMidRight9', position:{x:79.47126, y:5.563478, z:107.4202}, rotation:{x:-0.9721187, y:-0.001907236, z:0.2344814}, scale:{x:0.9999998, y:0.9999998, z:1}, file:'RigLegMidRight9.fbx'},
        {name:'RigLegMidRight8', position:{x:79.67606, y:5.564447, z:107.3153}, rotation:{x:-0.9721184, y:-0.00204523, z:0.2344813}, scale:{x:0.9999998, y:0.9999998, z:1}, file:'RigLegMidRight8.fbx'},
        {name:'RigLegMidRight7', position:{x:79.84637, y:5.565439, z:107.2281}, rotation:{x:-0.9721174, y:-0.002519834, z:0.234481}, scale:{x:0.9999998, y:0.9999998, z:1}, file:'RigLegMidRight7.fbx'},
        {name:'RigLegMidRight6', position:{x:80.05599, y:5.566404, z:107.1207}, rotation:{x:-0.9721184, y:-0.001991868, z:0.2344813}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight6.fbx'},
        {name:'RigLegMidRight5', position:{x:80.25097, y:5.567432, z:107.0209}, rotation:{x:-0.9721178, y:-0.002282535, z:0.2344812}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight5.fbx'},
        {name:'RigLegMidRight4', position:{x:80.43697, y:5.568414, z:106.9256}, rotation:{x:-0.9721178, y:-0.002282535, z:0.2344812}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight4.fbx'},
        {name:'RigLegMidRight3', position:{x:80.62299, y:5.569396, z:106.8303}, rotation:{x:-0.9721178, y:-0.002282535, z:0.2344812}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight3.fbx'},
        {name:'RigLegMidRight2', position:{x:80.78381, y:5.570369, z:106.7479}, rotation:{x:-0.9721171, y:-0.002618127, z:0.234481}, scale:{x:1, y:1, z:1}, file:'RigLegMidRight2.fbx'},
        {name:'RigLegMidRight1', position:{x:80.97309, y:5.570843, z:106.651}, rotation:{x:-0.9721199, y:-0.00108407, z:0.2344818}, scale:{x:1, y:1, z:1}, file:'RigLegMidRight1.fbx'},
        {name:'RigLegMidLeft10', position:{x:83.1039, y:5.554863, z:105.5595}, rotation:{x:-0.2344797, y:-0.001044797, z:-0.9721108}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft10.fbx'},
        {name:'RigLegMidLeft9', position:{x:82.8828, y:5.556733, z:105.6728}, rotation:{x:-0.2344803, y:-0.0008828793, z:-0.9721135}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft9.fbx'},
        {name:'RigLegMidLeft8', position:{x:82.69569, y:5.558607, z:105.7686}, rotation:{x:-0.2344797, y:-0.001044797, z:-0.9721108}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft8.fbx'},
        {name:'RigLegMidLeft7', position:{x:82.50858, y:5.56048, z:105.8645}, rotation:{x:-0.2344797, y:-0.001044797, z:-0.9721108}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft7.fbx'},
        {name:'RigLegMidLeft6', position:{x:82.32146, y:5.562353, z:105.9603}, rotation:{x:-0.2344797, y:-0.001044797, z:-0.9721108}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft6.fbx'},
        {name:'RigLegMidLeft5', position:{x:82.13435, y:5.564226, z:106.0562}, rotation:{x:-0.2344797, y:-0.001044797, z:-0.9721108}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft5.fbx'},
        {name:'RigLegMidLeft4', position:{x:81.94724, y:5.5661, z:106.152}, rotation:{x:-0.2344797, y:-0.001044797, z:-0.9721108}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft4.fbx'},
        {name:'RigLegMidLeft3', position:{x:81.76012, y:5.567973, z:106.2478}, rotation:{x:-0.2344797, y:-0.001044797, z:-0.9721108}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft3.fbx'},
        {name:'RigLegMidLeft2', position:{x:81.57301, y:5.569846, z:106.3437}, rotation:{x:-0.2344797, y:-0.001044797, z:-0.9721108}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft2.fbx'},
        {name:'RigLegMidLeft1', position:{x:81.38408, y:5.570843, z:106.4405}, rotation:{x:-0.2344813, y:-0.000550696, z:-0.9721178}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft1.fbx'},
        {name:'RigLegFrontRight10', position:{x:79.1202, y:5.562832, z:105.8789}, rotation:{x:0.987657, y:0.002227635, z:0.1566172}, scale:{x:1, y:1, z:1}, file:'RigLegFrontRight10.fbx'},
        {name:'RigLegFrontRight9', position:{x:79.36871, y:5.563763, z:105.9596}, rotation:{x:0.9877213, y:0.001761093, z:0.1562165}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight9.fbx'},
        {name:'RigLegFrontRight8', position:{x:79.56787, y:5.564708, z:106.0243}, rotation:{x:0.987657, y:0.002227628, z:0.1566174}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight8.fbx'},
        {name:'RigLegFrontRight7', position:{x:79.76703, y:5.565652, z:106.0891}, rotation:{x:0.987657, y:0.00222762, z:0.1566171}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight7.fbx'},
        {name:'RigLegFrontRight6', position:{x:79.96619, y:5.566596, z:106.1539}, rotation:{x:0.987657, y:0.002227635, z:0.1566168}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight6.fbx'},
        {name:'RigLegFrontRight5', position:{x:80.16534, y:5.56754, z:106.2187}, rotation:{x:0.9876571, y:0.00222762, z:0.1566165}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight5.fbx'},
        {name:'RigLegFrontRight4', position:{x:80.36449, y:5.568484, z:106.2835}, rotation:{x:0.9876571, y:0.002227628, z:0.1566161}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight4.fbx'},
        {name:'RigLegFrontRight3', position:{x:80.56365, y:5.569428, z:106.3483}, rotation:{x:0.9876572, y:0.00222762, z:0.1566159}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight3.fbx'},
        {name:'RigLegFrontRight2', position:{x:80.75075, y:5.570373, z:106.4033}, rotation:{x:0.9898128, y:0.002387889, z:0.1423555}, scale:{x:1, y:1, z:1}, file:'RigLegFrontRight2.fbx'},
        {name:'RigLegFrontRight1', position:{x:80.958, y:5.570843, z:106.4721}, rotation:{x:0.9871765, y:0.001065966, z:0.1596295}, scale:{x:1, y:1, z:1}, file:'RigLegFrontRight1.fbx'},
        {name:'RigLegFrontLeft10', position:{x:81.83945, y:5.554863, z:104.4884}, rotation:{x:0.5886441, y:0.002622664, z:0.80838}, scale:{x:1, y:0.9999999, z:0.9999998}, file:'RigLegFrontLeft10.fbx'},
        {name:'RigLegFrontLeft9', position:{x:81.76662, y:5.556737, z:104.7271}, rotation:{x:0.5950741, y:0.002180693, z:0.8036622}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft9.fbx'},
        {name:'RigLegFrontLeft8', position:{x:81.70209, y:5.55861, z:104.9272}, rotation:{x:0.5886441, y:0.00262267, z:0.8083801}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft8.fbx'},
        {name:'RigLegFrontLeft7', position:{x:81.63755, y:5.560483, z:105.1273}, rotation:{x:0.5886437, y:0.002622692, z:0.8083804}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft7.fbx'},
        {name:'RigLegFrontLeft6', position:{x:81.57301, y:5.562357, z:105.3274}, rotation:{x:0.5886432, y:0.0026227, z:0.8083807}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft6.fbx'},
        {name:'RigLegFrontLeft5', position:{x:81.50848, y:5.56423, z:105.5275}, rotation:{x:0.5886427, y:0.00262267, z:0.8083811}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft5.fbx'},
        {name:'RigLegFrontLeft4', position:{x:81.44393, y:5.566103, z:105.7276}, rotation:{x:0.5886422, y:0.002622743, z:0.8083814}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft4.fbx'},
        {name:'RigLegFrontLeft3', position:{x:81.37939, y:5.567977, z:105.9276}, rotation:{x:0.5886417, y:0.002622728, z:0.8083817}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft3.fbx'},
        {name:'RigLegFrontLeft2', position:{x:81.3148, y:5.56985, z:106.1139}, rotation:{x:0.5798218, y:0.002835925, z:0.8147295}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft2.fbx'},
        {name:'RigLegFrontLeft1', position:{x:81.24772, y:5.570843, z:106.3237}, rotation:{x:0.5896561, y:0.001322523, z:0.8076513}, scale:{x:1, y:1, z:1}, file:'RigLegFrontLeft1.fbx'},
        {name:'RigLegFront10', position:{x:80.19329, y:5.568751, z:104.6221}, rotation:{x:0.8531967, y:7.105851E-07, z:0.5215892}, scale:{x:1, y:1, z:1}, file:'RigLegFront10.fbx'},
        {name:'RigLegFront9', position:{x:80.3045, y:5.568751, z:104.8392}, rotation:{x:0.8531967, y:4.862952E-07, z:0.5215892}, scale:{x:1, y:1, z:1}, file:'RigLegFront9.fbx'},
        {name:'RigLegFront8', position:{x:80.40329, y:5.56875, z:105.0321}, rotation:{x:0.8531968, y:2.909716E-07, z:0.5215892}, scale:{x:1, y:1, z:1}, file:'RigLegFront8.fbx'},
        {name:'RigLegFront7', position:{x:80.49859, y:5.56875, z:105.2182}, rotation:{x:0.8531968, y:2.521662E-07, z:0.521589}, scale:{x:1, y:1, z:1}, file:'RigLegFront7.fbx'},
        {name:'RigLegFront6', position:{x:80.59818, y:5.56875, z:105.4126}, rotation:{x:0.8531968, y:2.637337E-07, z:0.5215892}, scale:{x:1, y:1, z:1}, file:'RigLegFront6.fbx'},
        {name:'RigLegFront5', position:{x:80.69246, y:5.56875, z:105.5967}, rotation:{x:0.8531967, y:2.686698E-07, z:0.5215892}, scale:{x:1, y:1, z:1}, file:'RigLegFront5.fbx'},
        {name:'RigLegFront4', position:{x:80.79114, y:5.56875, z:105.7893}, rotation:{x:0.8531969, y:3.114392E-07, z:0.5215891}, scale:{x:1, y:1, z:1}, file:'RigLegFront4.fbx'},
        {name:'RigLegFront3', position:{x:80.88849, y:5.56875, z:105.9794}, rotation:{x:0.8531969, y:3.074387E-07, z:0.5215891}, scale:{x:1, y:1, z:1}, file:'RigLegFront3.fbx'},
        {name:'RigLegFront2', position:{x:80.97218, y:5.56875, z:106.1428}, rotation:{x:0.853157, y:-0.005042238, z:0.5215647}, scale:{x:1, y:1, z:1}, file:'RigLegFront2.fbx'},
        {name:'RigLegFront1', position:{x:81.07924, y:5.570843, z:106.3518}, rotation:{x:0.8531883, y:0.003801299, z:0.521584}, scale:{x:1, y:1, z:1}, file:'RigLegFront1.fbx'},
        {name:'RigLegBackRight10', position:{x:80.51622, y:5.554862, z:108.607}, rotation:{x:-0.8083818, y:-0.003601247, z:0.5886419}, scale:{x:0.9999998, y:1, z:1}, file:'RigLegBackRight10.fbx'},
        {name:'RigLegBackRight9', position:{x:80.58715, y:5.556736, z:108.3615}, rotation:{x:-0.7992325, y:-0.00299654, z:0.6010107}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegBackRight9.fbx'},
        {name:'RigLegBackRight8', position:{x:80.6623, y:5.558609, z:108.1411}, rotation:{x:-0.8132403, y:-0.00323016, z:0.5819142}, scale:{x:1, y:1, z:1}, file:'RigLegBackRight8.fbx'},
        {name:'RigLegBackRight7', position:{x:80.72684, y:5.560482, z:107.9411}, rotation:{x:-0.8083817, y:-0.003601447, z:0.5886417}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegBackRight7.fbx'},
        {name:'RigLegBackRight6', position:{x:80.79138, y:5.562356, z:107.741}, rotation:{x:-0.8083818, y:-0.003601415, z:0.5886417}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegBackRight6.fbx'},
        {name:'RigLegBackRight5', position:{x:80.85592, y:5.564229, z:107.5409}, rotation:{x:-0.8083819, y:-0.003601556, z:0.5886417}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegBackRight5.fbx'},
        {name:'RigLegBackRight4', position:{x:80.92046, y:5.566102, z:107.3408}, rotation:{x:-0.8083818, y:-0.003601549, z:0.5886416}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegBackRight4.fbx'},
        {name:'RigLegBackRight3', position:{x:80.98499, y:5.567975, z:107.1407}, rotation:{x:-0.8083819, y:-0.003601592, z:0.5886416}, scale:{x:1, y:1, z:1}, file:'RigLegBackRight3.fbx'},
        {name:'RigLegBackRight2', position:{x:81.03477, y:5.569849, z:106.9734}, rotation:{x:-0.8016127, y:-0.004377964, z:0.5978194}, scale:{x:1, y:1, z:1}, file:'RigLegBackRight2.fbx'},
        {name:'RigLegBackRight1', position:{x:81.08834, y:5.570843, z:106.8268}, rotation:{x:-0.819464, y:-0.002526811, z:0.5731218}, scale:{x:1, y:1, z:1}, file:'RigLegBackRight1.fbx'},
        {name:'RigLegBackLeft10', position:{x:83.2384, y:5.554861, z:107.2127}, rotation:{x:-0.1553791, y:-0.0006922425, z:0.987845}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegBackLeft10.fbx'},
        {name:'RigLegBackLeft9', position:{x:82.99773, y:5.556735, z:107.1268}, rotation:{x:-0.1705589, y:-0.0005157306, z:0.9853405}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegBackLeft9.fbx'},
        {name:'RigLegBackLeft8', position:{x:82.77497, y:5.558608, z:107.059}, rotation:{x:-0.1471767, y:-0.0006614933, z:0.9891021}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegBackLeft8.fbx'},
        {name:'RigLegBackLeft7', position:{x:82.57489, y:5.560482, z:106.9945}, rotation:{x:-0.1553793, y:-0.0006921466, z:0.9878448}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegBackLeft7.fbx'},
        {name:'RigLegBackLeft6', position:{x:82.37481, y:5.562355, z:106.9299}, rotation:{x:-0.1553794, y:-0.0006921676, z:0.9878449}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegBackLeft6.fbx'},
        {name:'RigLegBackLeft5', position:{x:82.17473, y:5.564229, z:106.8654}, rotation:{x:-0.1553796, y:-0.0006921166, z:0.9878448}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegBackLeft5.fbx'},
        {name:'RigLegBackLeft4', position:{x:81.97465, y:5.566102, z:106.8008}, rotation:{x:-0.1553797, y:-0.000692127, z:0.9878448}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegBackLeft4.fbx'},
        {name:'RigLegBackLeft3', position:{x:81.77457, y:5.567975, z:106.7363}, rotation:{x:-0.1553798, y:-0.000692105, z:0.9878448}, scale:{x:1, y:1, z:1}, file:'RigLegBackLeft3.fbx'},
        {name:'RigLegBackLeft2', position:{x:81.60973, y:5.569849, z:106.6789}, rotation:{x:-0.1666343, y:-0.0007687752, z:0.9860042}, scale:{x:1, y:1, z:1}, file:'RigLegBackLeft2.fbx'},
        {name:'RigLegBackLeft1', position:{x:81.4594, y:5.570843, z:106.6367}, rotation:{x:-0.1365144, y:-0.0005790359, z:0.990633}, scale:{x:1, y:1, z:1}, file:'RigLegBackLeft1.fbx'},
        {name:'RigLegBack10', position:{x:82.16513, y:5.56874, z:108.4718}, rotation:{x:-0.5215889, y:-3.305116E-06, z:0.853197}, scale:{x:1, y:1, z:1}, file:'RigLegBack10.fbx'},
        {name:'RigLegBack9', position:{x:82.05392, y:5.568741, z:108.2546}, rotation:{x:-0.521589, y:-1.476081E-06, z:0.8531969}, scale:{x:1, y:1, z:1}, file:'RigLegBack9.fbx'},
        {name:'RigLegBack8', position:{x:81.95513, y:5.568741, z:108.0618}, rotation:{x:-0.521589, y:3.100052E-07, z:0.8531969}, scale:{x:1, y:1, z:1}, file:'RigLegBack8.fbx'},
        {name:'RigLegBack7', position:{x:81.85983, y:5.568741, z:107.8757}, rotation:{x:-0.5215891, y:9.655374E-07, z:0.853197}, scale:{x:1, y:1, z:1}, file:'RigLegBack7.fbx'},
        {name:'RigLegBack6', position:{x:81.76025, y:5.568741, z:107.6813}, rotation:{x:-0.521589, y:6.867813E-07, z:0.8531969}, scale:{x:1, y:1, z:1}, file:'RigLegBack6.fbx'},
        {name:'RigLegBack5', position:{x:81.66595, y:5.568741, z:107.4972}, rotation:{x:-0.521589, y:6.834808E-07, z:0.8531969}, scale:{x:1, y:1, z:1}, file:'RigLegBack5.fbx'},
        {name:'RigLegBack4', position:{x:81.56728, y:5.568741, z:107.3046}, rotation:{x:-0.5215891, y:5.890571E-07, z:0.853197}, scale:{x:1, y:1, z:1}, file:'RigLegBack4.fbx'},
        {name:'RigLegBack3', position:{x:81.46992, y:5.568741, z:107.1145}, rotation:{x:-0.5215891, y:5.89947E-07, z:0.853197}, scale:{x:1, y:1, z:1}, file:'RigLegBack3.fbx'},
        {name:'RigLegBack2', position:{x:81.38624, y:5.568741, z:106.9511}, rotation:{x:-0.5215647, y:-0.008247634, z:0.853157}, scale:{x:1, y:1, z:1}, file:'RigLegBack2.fbx'},
        {name:'RigLegBack1', position:{x:81.30285, y:5.570843, z:106.7883}, rotation:{x:-0.5215805, y:-0.002997489, z:0.8531828}, scale:{x:1, y:1, z:1}, file:'RigLegBack1.fbx'},
        {name:'RigPelvis', position:{x:81.19567, y:5.67361, z:106.5791}, rotation:{x:0.6033012, y:-0.6033012, z:0.3688193}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigMouthUpGizmo', position:{x:81.30674, y:6.86265, z:106.7959}, rotation:{x:0.8531969, y:5.077859E-08, z:0.5215892}, scale:{x:1, y:1, z:1}, file:'RigMouthUpGizmo.fbx'},
        {name:'RigMouthLowGizmo', position:{x:81.30488, y:6.625518, z:106.7923}, rotation:{x:-0.8402349, y:-0.1481561, z:-0.513665}, scale:{x:1, y:0.9999999, z:1}, file:'RigMouthLowGizmo.fbx'},
        {name:'RigLegMidRight10Gizmo', position:{x:79.25175, y:5.562511, z:107.5327}, rotation:{x:-0.9721178, y:-0.002282504, z:0.2344812}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight10Gizmo.fbx'},
        {name:'RigLegMidRight9Gizmo', position:{x:79.47126, y:5.563478, z:107.4202}, rotation:{x:-0.9721187, y:-0.001907246, z:0.2344814}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight9Gizmo.fbx'},
        {name:'RigLegMidRight8Gizmo', position:{x:79.67606, y:5.564447, z:107.3153}, rotation:{x:-0.9721184, y:-0.002045171, z:0.2344814}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight8Gizmo.fbx'},
        {name:'RigLegMidRight7Gizmo', position:{x:79.84637, y:5.565439, z:107.2281}, rotation:{x:-0.9721173, y:-0.002519837, z:0.2344811}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight7Gizmo.fbx'},
        {name:'RigLegMidRight6Gizmo_1', position:{x:80.05599, y:5.566404, z:107.1207}, rotation:{x:-0.9721185, y:-0.00199183, z:0.2344814}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight6Gizmo_1.fbx'},
        {name:'RigLegMidRight6Gizmo', position:{x:80.05599, y:5.566404, z:107.1207}, rotation:{x:-0.9721185, y:-0.00199183, z:0.2344814}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight6Gizmo.fbx'},
        {name:'RigLegMidRight5Gizmo', position:{x:80.25097, y:5.567433, z:107.0209}, rotation:{x:-0.9721178, y:-0.002282504, z:0.2344812}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight5Gizmo.fbx'},
        {name:'RigLegMidRight4Gizmo_1', position:{x:80.43697, y:5.568414, z:106.9256}, rotation:{x:-0.9721178, y:-0.002282504, z:0.2344812}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight4Gizmo_1.fbx'},
        {name:'RigLegMidRight4Gizmo', position:{x:80.43697, y:5.568414, z:106.9256}, rotation:{x:-0.9721178, y:-0.002282504, z:0.2344812}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight4Gizmo.fbx'},
        {name:'RigLegMidRight3Gizmo', position:{x:80.62299, y:5.569396, z:106.8303}, rotation:{x:-0.9721178, y:-0.002282504, z:0.2344812}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLegMidRight3Gizmo.fbx'},
        {name:'RigLegMidRight2Gizmo', position:{x:80.78381, y:5.570369, z:106.7479}, rotation:{x:-0.972117, y:-0.002618143, z:0.234481}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegMidRight2Gizmo.fbx'},
        {name:'RigLegMidRight1Gizmo', position:{x:80.97309, y:5.570843, z:106.651}, rotation:{x:-0.9721199, y:-0.001084027, z:0.2344818}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegMidRight1Gizmo.fbx'},
        {name:'RigLegMidLeft10Gizmo', position:{x:83.1039, y:5.554863, z:105.5595}, rotation:{x:0.2344797, y:0.001044756, z:0.9721109}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft10Gizmo.fbx'},
        {name:'RigLegMidLeft9Gizmo', position:{x:82.8828, y:5.556733, z:105.6728}, rotation:{x:0.2344804, y:0.0008828391, z:0.9721135}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegMidLeft9Gizmo.fbx'},
        {name:'RigLegMidLeft8Gizmo', position:{x:82.69569, y:5.558607, z:105.7686}, rotation:{x:0.2344797, y:0.001044756, z:0.9721109}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft8Gizmo.fbx'},
        {name:'RigLegMidLeft7Gizmo', position:{x:82.50858, y:5.56048, z:105.8645}, rotation:{x:0.2344797, y:0.001044756, z:0.9721109}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft7Gizmo.fbx'},
        {name:'RigLegMidLeft6Gizmo', position:{x:82.32146, y:5.562353, z:105.9603}, rotation:{x:0.2344797, y:0.001044756, z:0.9721109}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft6Gizmo.fbx'},
        {name:'RigLegMidLeft5Gizmo', position:{x:82.13435, y:5.564226, z:106.0562}, rotation:{x:0.2344797, y:0.001044756, z:0.9721109}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft5Gizmo.fbx'},
        {name:'RigLegMidLeft4Gizmo', position:{x:81.94724, y:5.5661, z:106.152}, rotation:{x:0.2344797, y:0.001044756, z:0.9721109}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft4Gizmo.fbx'},
        {name:'RigLegMidLeft3Gizmo', position:{x:81.76012, y:5.567973, z:106.2478}, rotation:{x:0.2344797, y:0.001044756, z:0.9721109}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft3Gizmo.fbx'},
        {name:'RigLegMidLeft2Gizmo', position:{x:81.57301, y:5.569846, z:106.3437}, rotation:{x:0.2344797, y:0.001044756, z:0.9721109}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft2Gizmo.fbx'},
        {name:'RigLegMidLeft1Gizmo_1', position:{x:81.38408, y:5.570843, z:106.4405}, rotation:{x:0.2344814, y:0.0005506434, z:0.9721178}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft1Gizmo_1.fbx'},
        {name:'RigLegMidLeft1Gizmo', position:{x:81.38408, y:5.570843, z:106.4405}, rotation:{x:0.2344814, y:0.0005506434, z:0.9721178}, scale:{x:1, y:1, z:1}, file:'RigLegMidLeft1Gizmo.fbx'},
        {name:'RigLegFrontRight10Gizmo', position:{x:79.1202, y:5.562833, z:105.8789}, rotation:{x:-0.987657, y:-0.002227561, z:-0.1566166}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontRight10Gizmo.fbx'},
        {name:'RigLegFrontRight9Gizmo', position:{x:79.36871, y:5.563764, z:105.9596}, rotation:{x:-0.9877216, y:-0.001761027, z:-0.1562147}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegFrontRight9Gizmo.fbx'},
        {name:'RigLegFrontRight8Gizmo', position:{x:79.56787, y:5.564708, z:106.0243}, rotation:{x:-0.9876571, y:-0.002227556, z:-0.1566159}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight8Gizmo.fbx'},
        {name:'RigLegFrontRight7Gizmo', position:{x:79.76703, y:5.565652, z:106.0891}, rotation:{x:-0.9876571, y:-0.002227556, z:-0.1566159}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight7Gizmo.fbx'},
        {name:'RigLegFrontRight6Gizmo', position:{x:79.96619, y:5.566596, z:106.1539}, rotation:{x:-0.9876571, y:-0.002227556, z:-0.1566159}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight6Gizmo.fbx'},
        {name:'RigLegFrontRight5Gizmo', position:{x:80.16534, y:5.56754, z:106.2187}, rotation:{x:-0.9876571, y:-0.002227556, z:-0.1566159}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight5Gizmo.fbx'},
        {name:'RigLegFrontRight4Gizmo', position:{x:80.36449, y:5.568484, z:106.2835}, rotation:{x:-0.9876571, y:-0.002227556, z:-0.1566159}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight4Gizmo.fbx'},
        {name:'RigLegFrontRight3Gizmo', position:{x:80.56365, y:5.569428, z:106.3483}, rotation:{x:-0.9876571, y:-0.002227556, z:-0.1566159}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFrontRight3Gizmo.fbx'},
        {name:'RigLegFrontRight2Gizmo', position:{x:80.75075, y:5.570373, z:106.4033}, rotation:{x:-0.9898127, y:-0.002387863, z:-0.1423554}, scale:{x:1, y:1, z:1}, file:'RigLegFrontRight2Gizmo.fbx'},
        {name:'RigLegFrontRight1Gizmo', position:{x:80.958, y:5.570843, z:106.4721}, rotation:{x:-0.9871764, y:-0.001065909, z:-0.1596294}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegFrontRight1Gizmo.fbx'},
        {name:'RigLegFrontLeft10Gizmo', position:{x:81.83945, y:5.554864, z:104.4884}, rotation:{x:0.5886429, y:0.002622629, z:0.808381}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigLegFrontLeft10Gizmo.fbx'},
        {name:'RigLegFrontLeft9Gizmo', position:{x:81.76662, y:5.556737, z:104.7271}, rotation:{x:0.5950715, y:0.002180596, z:0.8036641}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft9Gizmo.fbx'},
        {name:'RigLegFrontLeft8Gizmo', position:{x:81.70209, y:5.55861, z:104.9272}, rotation:{x:0.588642, y:0.002622618, z:0.8083816}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft8Gizmo.fbx'},
        {name:'RigLegFrontLeft7Gizmo', position:{x:81.63755, y:5.560484, z:105.1273}, rotation:{x:0.588642, y:0.002622618, z:0.8083816}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft7Gizmo.fbx'},
        {name:'RigLegFrontLeft6Gizmo', position:{x:81.57301, y:5.562357, z:105.3274}, rotation:{x:0.588642, y:0.002622618, z:0.8083816}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft6Gizmo.fbx'},
        {name:'RigLegFrontLeft5Gizmo', position:{x:81.50848, y:5.56423, z:105.5275}, rotation:{x:0.588642, y:0.002622618, z:0.8083816}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft5Gizmo.fbx'},
        {name:'RigLegFrontLeft4Gizmo', position:{x:81.44393, y:5.566103, z:105.7276}, rotation:{x:0.588642, y:0.002622618, z:0.8083816}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft4Gizmo.fbx'},
        {name:'RigLegFrontLeft3Gizmo', position:{x:81.37939, y:5.567977, z:105.9276}, rotation:{x:0.588642, y:0.002622618, z:0.8083816}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft3Gizmo.fbx'},
        {name:'RigLegFrontLeft2Gizmo', position:{x:81.3148, y:5.56985, z:106.1139}, rotation:{x:0.5798218, y:0.002835819, z:0.8147295}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegFrontLeft2Gizmo.fbx'},
        {name:'RigLegFrontLeft1Gizmo', position:{x:81.24772, y:5.570843, z:106.3237}, rotation:{x:0.5896562, y:0.001322435, z:0.8076513}, scale:{x:1, y:1, z:0.9999999}, file:'RigLegFrontLeft1Gizmo.fbx'},
        {name:'RigLegFront10Gizmo', position:{x:80.19329, y:5.568751, z:104.6221}, rotation:{x:0.8531969, y:-1.365009E-07, z:0.5215892}, scale:{x:1, y:1, z:1}, file:'RigLegFront10Gizmo.fbx'},
        {name:'RigLegFront9Gizmo', position:{x:80.3045, y:5.568751, z:104.8392}, rotation:{x:0.8531969, y:-1.365008E-07, z:0.5215892}, scale:{x:1, y:1, z:1}, file:'RigLegFront9Gizmo.fbx'},
        {name:'RigLegFront8Gizmo', position:{x:80.40329, y:5.568751, z:105.0321}, rotation:{x:0.8531969, y:-1.365007E-07, z:0.5215892}, scale:{x:1, y:1, z:1}, file:'RigLegFront8Gizmo.fbx'},
        {name:'RigLegFront7Gizmo', position:{x:80.49859, y:5.568751, z:105.2182}, rotation:{x:0.8531969, y:4.318476E-07, z:0.5215892}, scale:{x:1, y:1, z:1}, file:'RigLegFront7Gizmo.fbx'},
        {name:'RigLegFront6Gizmo', position:{x:80.59818, y:5.568751, z:105.4126}, rotation:{x:0.8531969, y:2.550281E-07, z:0.5215892}, scale:{x:1, y:1, z:1}, file:'RigLegFront6Gizmo.fbx'},
        {name:'RigLegFront5Gizmo', position:{x:80.69246, y:5.568751, z:105.5967}, rotation:{x:0.8531969, y:3.434378E-07, z:0.5215892}, scale:{x:1, y:1, z:1}, file:'RigLegFront5Gizmo.fbx'},
        {name:'RigLegFront4Gizmo', position:{x:80.79114, y:5.568751, z:105.7893}, rotation:{x:0.8531969, y:2.80288E-07, z:0.5215892}, scale:{x:1, y:1, z:1}, file:'RigLegFront4Gizmo.fbx'},
        {name:'RigLegFront3Gizmo', position:{x:80.88849, y:5.568751, z:105.9794}, rotation:{x:0.8531969, y:2.92918E-07, z:0.5215892}, scale:{x:1, y:1, z:1}, file:'RigLegFront3Gizmo.fbx'},
        {name:'RigLegFront2Gizmo', position:{x:80.97218, y:5.568751, z:106.1428}, rotation:{x:0.853157, y:-0.005042152, z:0.5215648}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFront2Gizmo.fbx'},
        {name:'RigLegFront1Gizmo', position:{x:81.07924, y:5.570843, z:106.3518}, rotation:{x:-0.8531883, y:-0.003801204, z:-0.5215839}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegFront1Gizmo.fbx'},
        {name:'RigLegBackRight10Gizmo', position:{x:80.51622, y:5.554862, z:108.607}, rotation:{x:-0.8083818, y:-0.003601857, z:0.5886416}, scale:{x:0.9999998, y:1, z:0.9999999}, file:'RigLegBackRight10Gizmo.fbx'},
        {name:'RigLegBackRight9Gizmo', position:{x:80.58715, y:5.556735, z:108.3615}, rotation:{x:-0.7992326, y:-0.002996541, z:0.6010107}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegBackRight9Gizmo.fbx'},
        {name:'RigLegBackRight8Gizmo', position:{x:80.6623, y:5.558609, z:108.1411}, rotation:{x:-0.8132403, y:-0.003230377, z:0.5819141}, scale:{x:1, y:1, z:1}, file:'RigLegBackRight8Gizmo.fbx'},
        {name:'RigLegBackRight7Gizmo', position:{x:80.72684, y:5.560482, z:107.9411}, rotation:{x:-0.8083818, y:-0.003601563, z:0.5886416}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegBackRight7Gizmo.fbx'},
        {name:'RigLegBackRight6Gizmo', position:{x:80.79138, y:5.562355, z:107.741}, rotation:{x:-0.8083818, y:-0.003601563, z:0.5886416}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegBackRight6Gizmo.fbx'},
        {name:'RigLegBackRight5Gizmo', position:{x:80.85592, y:5.564229, z:107.5409}, rotation:{x:-0.8083818, y:-0.003601563, z:0.5886416}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegBackRight5Gizmo.fbx'},
        {name:'RigLegBackRight4Gizmo', position:{x:80.92046, y:5.566102, z:107.3408}, rotation:{x:-0.8083818, y:-0.003601563, z:0.5886416}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegBackRight4Gizmo.fbx'},
        {name:'RigLegBackRight3Gizmo', position:{x:80.98499, y:5.567975, z:107.1407}, rotation:{x:-0.8083818, y:-0.003601563, z:0.5886416}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegBackRight3Gizmo.fbx'},
        {name:'RigLegBackRight2Gizmo', position:{x:81.03477, y:5.569849, z:106.9734}, rotation:{x:-0.8016129, y:-0.004377994, z:0.5978194}, scale:{x:1, y:1, z:1}, file:'RigLegBackRight2Gizmo.fbx'},
        {name:'RigLegBackRight1Gizmo', position:{x:81.08834, y:5.570843, z:106.8268}, rotation:{x:-0.819464, y:-0.002526796, z:0.5731218}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegBackRight1Gizmo.fbx'},
        {name:'RigLegBackLeft10Gizmo', position:{x:83.2384, y:5.554862, z:107.2127}, rotation:{x:-0.1553799, y:-0.000692165, z:0.9878448}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegBackLeft10Gizmo.fbx'},
        {name:'RigLegBackLeft9Gizmo', position:{x:82.99773, y:5.556735, z:107.1268}, rotation:{x:-0.1705595, y:-0.0005156407, z:0.9853405}, scale:{x:1, y:1, z:1}, file:'RigLegBackLeft9Gizmo.fbx'},
        {name:'RigLegBackLeft8Gizmo', position:{x:82.77497, y:5.558609, z:107.059}, rotation:{x:-0.1471772, y:-0.0006615008, z:0.989102}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegBackLeft8Gizmo.fbx'},
        {name:'RigLegBackLeft7Gizmo', position:{x:82.57489, y:5.560482, z:106.9945}, rotation:{x:-0.1553798, y:-0.0006921563, z:0.9878448}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLegBackLeft7Gizmo.fbx'},
        {name:'RigLegBackLeft6Gizmo', position:{x:82.37481, y:5.562355, z:106.9299}, rotation:{x:-0.1553798, y:-0.0006921563, z:0.9878448}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLegBackLeft6Gizmo.fbx'},
        {name:'RigLegBackLeft5Gizmo', position:{x:82.17473, y:5.564229, z:106.8654}, rotation:{x:-0.1553798, y:-0.0006921563, z:0.9878448}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLegBackLeft5Gizmo.fbx'},
        {name:'RigLegBackLeft4Gizmo', position:{x:81.97465, y:5.566102, z:106.8008}, rotation:{x:-0.1553798, y:-0.0006921563, z:0.9878448}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLegBackLeft4Gizmo.fbx'},
        {name:'RigLegBackLeft3Gizmo', position:{x:81.77457, y:5.567975, z:106.7363}, rotation:{x:-0.1553798, y:-0.0006921563, z:0.9878448}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLegBackLeft3Gizmo.fbx'},
        {name:'RigLegBackLeft2Gizmo', position:{x:81.60973, y:5.569849, z:106.6789}, rotation:{x:-0.1666343, y:-0.0007688226, z:0.9860041}, scale:{x:0.9999999, y:1, z:1}, file:'RigLegBackLeft2Gizmo.fbx'},
        {name:'RigLegBackLeft1Gizmo', position:{x:81.4594, y:5.570843, z:106.6367}, rotation:{x:-0.1365144, y:-0.000579112, z:0.990633}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLegBackLeft1Gizmo.fbx'},
        {name:'RigLegBack10Gizmo', position:{x:82.16513, y:5.568741, z:108.4718}, rotation:{x:-0.5215892, y:1.168065E-06, z:0.8531969}, scale:{x:1, y:1, z:1}, file:'RigLegBack10Gizmo.fbx'},
        {name:'RigLegBack9Gizmo', position:{x:82.05392, y:5.568741, z:108.2546}, rotation:{x:-0.5215892, y:1.168065E-06, z:0.8531969}, scale:{x:1, y:1, z:1}, file:'RigLegBack9Gizmo.fbx'},
        {name:'RigLegBack8Gizmo', position:{x:81.95513, y:5.568741, z:108.0618}, rotation:{x:-0.5215892, y:1.168064E-06, z:0.8531969}, scale:{x:1, y:1, z:1}, file:'RigLegBack8Gizmo.fbx'},
        {name:'RigLegBack7Gizmo', position:{x:81.85983, y:5.568741, z:107.8757}, rotation:{x:-0.5215892, y:2.383804E-07, z:0.8531969}, scale:{x:1, y:1, z:1}, file:'RigLegBack7Gizmo.fbx'},
        {name:'RigLegBack6Gizmo', position:{x:81.76025, y:5.568741, z:107.6813}, rotation:{x:-0.5215892, y:5.276154E-07, z:0.8531969}, scale:{x:1, y:1, z:1}, file:'RigLegBack6Gizmo.fbx'},
        {name:'RigLegBack5Gizmo', position:{x:81.66595, y:5.568741, z:107.4972}, rotation:{x:-0.5215892, y:3.829979E-07, z:0.8531969}, scale:{x:1, y:1, z:1}, file:'RigLegBack5Gizmo.fbx'},
        {name:'RigLegBack4Gizmo', position:{x:81.56728, y:5.568741, z:107.3046}, rotation:{x:-0.5215892, y:4.862961E-07, z:0.8531969}, scale:{x:1, y:1, z:1}, file:'RigLegBack4Gizmo.fbx'},
        {name:'RigLegBack3Gizmo', position:{x:81.46992, y:5.568741, z:107.1145}, rotation:{x:-0.5215892, y:4.656364E-07, z:0.8531969}, scale:{x:1, y:1, z:1}, file:'RigLegBack3Gizmo.fbx'},
        {name:'RigLegBack2Gizmo', position:{x:81.38624, y:5.568741, z:106.9511}, rotation:{x:-0.5215648, y:-0.008247788, z:0.853157}, scale:{x:1, y:0.9999999, z:1}, file:'RigLegBack2Gizmo.fbx'},
        {name:'RigLegBack1Gizmo', position:{x:81.30285, y:5.570843, z:106.7883}, rotation:{x:-0.5215805, y:-0.002997514, z:0.8531828}, scale:{x:1, y:1, z:1}, file:'RigLegBack1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:81.39945, y:6.704025, z:106.9769}, rotation:{x:0.8531969, y:5.077858E-08, z:0.5215892}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'Rig', position:{x:81.17933, y:5.491224, z:106.5472}, rotation:{x:0.6033012, y:-0.6033012, z:0.3688193}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Carnivorous_Plant', position:{x:81.17933, y:5.491224, z:106.5472}, rotation:{x:-0.2826494, y:-0.6481584, z:-0.6481584}, scale:{x:1, y:1, z:1}, file:'Carnivorous_Plant.fbx'},
        {name:'Carnivorous_Plant_Green_(1)', position:{x:81.17933, y:5.491224, z:106.5472}, rotation:{x:0, y:-0.9721205, z:0}, scale:{x:1, y:1, z:1}, file:'Carnivorous_Plant_Green_(1).fbx'},
        {name:'Bush_09', position:{x:91.50079, y:3.304708, z:88.34555}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_09.fbx'},
        {name:'Stair_el8', position:{x:63.91, y:3.9, z:76.84}, rotation:{x:0, y:0.6932201, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el8.fbx'},
        {name:'Barrier4', position:{x:63.3262, y:2.078316, z:57.93}, rotation:{x:0, y:-0.664185, z:0}, scale:{x:1, y:1, z:1}, file:'Barrier4.fbx'},
        {name:'Ground_Decal_grass1', position:{x:65.12084, y:3.405359, z:94.72554}, rotation:{x:0, y:0.3655059, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_grass1.fbx'},
        {name:'Bush_02', position:{x:84.42348, y:3.378644, z:83.94916}, rotation:{x:0, y:-0.799679, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_02.fbx'},
        {name:'Bush_03', position:{x:94.44687, y:3.325317, z:88.57921}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_03.fbx'},
        {name:'Tree_Pine_3', position:{x:67.93289, y:3.405499, z:76.7053}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_3.fbx'},
        {name:'Ivy2', position:{x:76.1496, y:6.045284, z:97.6292}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Ivy2.fbx'},
        {name:'Floor5', position:{x:82.22552, y:3.405895, z:82.62324}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Floor5.fbx'},
        {name:'Plant_04', position:{x:60.5527, y:3.428288, z:99.97662}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_04.fbx'},
        {name:'Tree_Pine_1', position:{x:79.1311, y:5.498551, z:69.84406}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Rock_17C', position:{x:86.60806, y:3.405881, z:72.95821}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_17C.fbx'},
        {name:'Floor4', position:{x:66.0966, y:3.405359, z:78.27216}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Floor4.fbx'},
        {name:'Plant_07', position:{x:89.11578, y:1.997139, z:93.56435}, rotation:{x:0, y:-0.9659691, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_07.fbx'},
        {name:'Rock_02', position:{x:75.678, y:5.497, z:73.052}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_02.fbx'},
        {name:'Tree_06', position:{x:69.53551, y:3.320145, z:98.6113}, rotation:{x:0, y:-0.6290725, z:0}, scale:{x:1, y:0.7557086, z:1}, file:'Tree_06.fbx'},
        {name:'Bush_05', position:{x:95.34272, y:3.351763, z:75.9065}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_05.fbx'},
        {name:'Trunk_01', position:{x:66.42413, y:3.405359, z:100.8659}, rotation:{x:0, y:-0.845603, z:0}, scale:{x:2.629081, y:2.629081, z:2.629081}, file:'Trunk_01.fbx'},
        {name:'Rock_05', position:{x:74.58929, y:5.501591, z:72.11662}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_05.fbx'},
        {name:'Tree_06', position:{x:96.1774, y:3.39, z:74.68729}, rotation:{x:0, y:0.7569888, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_06.fbx'},
        {name:'Plasma_fx', position:{x:69.62778, y:4.271625, z:89.67365}, rotation:{x:-0.6532816, y:0.2705981, z:0.2705981}, scale:{x:1, y:1, z:1}, file:'Plasma_fx.fbx'},
        {name:'Barrel', position:{x:69.23666, y:4.268842, z:89.28295}, rotation:{x:0, y:0.3826834, z:0}, scale:{x:1, y:1, z:1}, file:'Barrel.fbx'},
        {name:'Turret', position:{x:69.01862, y:3.964797, z:89.06449}, rotation:{x:0, y:0.3826834, z:0}, scale:{x:1, y:1, z:1}, file:'Turret.fbx'},
        {name:'Base', position:{x:69.20004, y:3.494217, z:89.24591}, rotation:{x:0, y:0.3826834, z:0}, scale:{x:1, y:1, z:1}, file:'Base.fbx'},
        {name:'Tow_Plasma3', position:{x:69.10374, y:3.494217, z:89.1496}, rotation:{x:0, y:0.3826834, z:0}, scale:{x:1, y:1, z:1}, file:'Tow_Plasma3.fbx'},
        {name:'Plant_07', position:{x:88.12968, y:1.997781, z:89.74046}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_07.fbx'},
        {name:'Plant_01', position:{x:67.29393, y:3.429039, z:78.23785}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_01.fbx'},
        {name:'Plant_07', position:{x:62.04384, y:1.997678, z:57.47152}, rotation:{x:0, y:0.3239234, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_07.fbx'},
        {name:'Tree_Dead1', position:{x:69.59551, y:3.405627, z:87.36269}, rotation:{x:0, y:0.320518, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Dead1.fbx'},
        {name:'Tree_Fall2', position:{x:72.01625, y:5.766111, z:108.4826}, rotation:{x:-0.01100786, y:-0.5267884, z:-0.2106594}, scale:{x:1, y:1, z:1}, file:'Tree_Fall2.fbx'},
        {name:'Mushroom1', position:{x:64.54722, y:3.405359, z:100.4472}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom1.fbx'},
        {name:'Ivy3', position:{x:69.16729, y:5.872687, z:102.2059}, rotation:{x:0, y:-0.9317321, z:0}, scale:{x:1.112361, y:1.112361, z:1.112361}, file:'Ivy3.fbx'},
        {name:'Bush_01', position:{x:82.93476, y:5.514738, z:100.995}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_01.fbx'},
        {name:'Rock_14C', position:{x:68.71179, y:3.33574, z:101.1973}, rotation:{x:0, y:-0.9953403, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_14C.fbx'},
        {name:'Rune_particle2', position:{x:80.5599, y:5.632977, z:106.7532}, rotation:{x:-0.2207446, y:0.6717678, z:0.6717678}, scale:{x:1, y:1, z:1}, file:'Rune_particle2.fbx'},
        {name:'Rune_particle1', position:{x:80.5599, y:5.612618, z:106.7532}, rotation:{x:-0.2207446, y:0.6717678, z:0.6717678}, scale:{x:1, y:1, z:1}, file:'Rune_particle1.fbx'},
        {name:'Rune_01', position:{x:80.49459, y:5.492279, z:106.6935}, rotation:{x:0, y:0.950023, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_01.fbx'},
        {name:'Plant_05', position:{x:82.73547, y:6.085576, z:94.33722}, rotation:{x:-0.1618953, y:-0.0511985, z:-0.05637022}, scale:{x:1, y:1, z:1}, file:'Plant_05.fbx'},
        {name:'Tree_03', position:{x:79.32755, y:3.405406, z:76.06319}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_03.fbx'},
        {name:'Rock_04', position:{x:76.13744, y:5.433283, z:97.52623}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_04.fbx'},
        {name:'Bush_07', position:{x:86.22852, y:5.515348, z:101.7756}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_07.fbx'},
        {name:'Jar2B', position:{x:77.48, y:3.91, z:80.78}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Jar2B.fbx'},
        {name:'Mushroom1B', position:{x:63.64433, y:3.405359, z:100.8362}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom1B.fbx'},
        {name:'Stair_el8', position:{x:75.07054, y:3.282719, z:88.10319}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el8.fbx'},
        {name:'Mushroom4A', position:{x:76.65662, y:5.492705, z:107.4383}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom4A.fbx'},
        {name:'Block4', position:{x:65.33, y:3.5, z:76.2}, rotation:{x:0, y:0.683574, z:0}, scale:{x:1, y:1, z:1}, file:'Block4.fbx'},
        {name:'Rock_13', position:{x:99.3, y:1.96, z:96.14}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_13.fbx'},
        {name:'Ivy2', position:{x:82.52, y:5.73, z:71.03}, rotation:{x:0, y:0.3758662, z:0}, scale:{x:1.112361, y:1.112361, z:1.112361}, file:'Ivy2.fbx'},
        {name:'Plant_06', position:{x:83.97803, y:1.997678, z:90.60445}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_06.fbx'},
        {name:'Block3', position:{x:87.77982, y:4.51, z:109.7603}, rotation:{x:0, y:0.909279, z:0}, scale:{x:1, y:1, z:1}, file:'Block3.fbx'},
        {name:'Rock_12', position:{x:78.27541, y:5.49094, z:101.5023}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_12.fbx'},
        {name:'Tree_Pine_3', position:{x:96.54484, y:3.643313, z:88.73179}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_3.fbx'},
        {name:'Tree_Pine_1', position:{x:59.35423, y:3.405532, z:107.5299}, rotation:{x:0, y:-0.9403921, z:0}, scale:{x:1, y:0.5364352, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Ivy1', position:{x:65.43045, y:6.258499, z:90.76314}, rotation:{x:0, y:-0.9928473, z:0}, scale:{x:1, y:1, z:1}, file:'Ivy1.fbx'},
        {name:'Tree_Pine_3', position:{x:92.90186, y:3.642495, z:77.14208}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_3.fbx'},
        {name:'Barrier2', position:{x:86.99, y:3, z:95.77}, rotation:{x:0, y:-0.4435384, z:0}, scale:{x:1, y:1, z:1}, file:'Barrier2.fbx'},
        {name:'Stair_el5', position:{x:80.83616, y:2.568664, z:85.73244}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el5.fbx'},
        {name:'Crystal5', position:{x:92.30131, y:3.405747, z:78.53011}, rotation:{x:0, y:0, z:0}, scale:{x:0.5454153, y:0.5454153, z:0.5454153}, file:'Crystal5.fbx'},
        {name:'Ground_Decal_grass1', position:{x:67.1513, y:3.405359, z:92.45912}, rotation:{x:0, y:0.2691114, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_grass1.fbx'},
        {name:'Crystal1', position:{x:85.46048, y:4.17531, z:94.17901}, rotation:{x:-0.2466484, y:-0.3288455, z:0.1197979}, scale:{x:1, y:1, z:1}, file:'Crystal1.fbx'},
        {name:'Bush_08', position:{x:72.9985, y:5.515551, z:110.8339}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_08.fbx'},
        {name:'Bush_03', position:{x:80.55398, y:3.323729, z:74.94871}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_03.fbx'},
        {name:'Tree_Dead1', position:{x:92.10047, y:3.406367, z:76.22784}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Dead1.fbx'},
        {name:'Stair_el4', position:{x:90.15, y:3.56, z:110.42}, rotation:{x:0, y:0.933022, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el4.fbx'},
        {name:'Ground_Decal_Leaves2', position:{x:62.19202, y:3.40597, z:106.1122}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_Leaves2.fbx'},
        {name:'Block1', position:{x:95.52, y:3.9, z:79.52}, rotation:{x:0, y:0.007107861, z:0}, scale:{x:1, y:1, z:1}, file:'Block1.fbx'},
        {name:'Plant_07', position:{x:82.35603, y:1.997678, z:89.65392}, rotation:{x:0, y:-0.6304059, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_07.fbx'},
        {name:'Bush_06', position:{x:65.03992, y:3.429039, z:99.49578}, rotation:{x:0, y:-0.6844693, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_06.fbx'},
        {name:'Ground_Decal_grass1', position:{x:83.83211, y:3.44039, z:84.50846}, rotation:{x:0, y:0.1983761, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_grass1.fbx'},
        {name:'Wagon_gate1', position:{x:74.51, y:3.33, z:73.49}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_gate1.fbx'},
        {name:'Stair_el4', position:{x:77.70739, y:2.16128, z:83.07585}, rotation:{x:0, y:0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el4.fbx'},
        {name:'Tree_Dead1', position:{x:80.22773, y:1.973998, z:89.79751}, rotation:{x:-0.02054665, y:-0.008357511, z:0.03852196}, scale:{x:1, y:1, z:1}, file:'Tree_Dead1.fbx'},
        {name:'Mushroom2A', position:{x:83.45399, y:3.405391, z:75.74627}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom2A.fbx'},
        {name:'Ground_Decal_grass1', position:{x:63.40672, y:3.405359, z:100.93}, rotation:{x:0, y:0.4519472, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_grass1.fbx'},
        {name:'Tree_Pine_1', position:{x:88.72005, y:3.388946, z:98.63065}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Rune_particle2', position:{x:80.35798, y:5.632977, z:102.8411}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_particle2.fbx'},
        {name:'Rune_particle1', position:{x:80.35798, y:5.612618, z:102.8411}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_particle1.fbx'},
        {name:'Rune_04', position:{x:80.44601, y:5.492279, z:102.8505}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_04.fbx'},
        {name:'Plant_06', position:{x:75.38334, y:1.997678, z:80.85378}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_06.fbx'},
        {name:'RigTail6Gizmo', position:{x:75.89088, y:3.982241, z:89.6223}, rotation:{x:0.1783631, y:-0.6842416, z:0.178363}, scale:{x:1, y:1, z:1}, file:'RigTail6Gizmo.fbx'},
        {name:'RigTail5Gizmo', position:{x:75.89088, y:3.956716, z:89.54308}, rotation:{x:0.1097589, y:-0.6985363, z:0.1097588}, scale:{x:1, y:1, z:1}, file:'RigTail5Gizmo.fbx'},
        {name:'RigTail4Gizmo', position:{x:75.89088, y:4.003536, z:89.36759}, rotation:{x:-0.09191876, y:-0.7011068, z:-0.09191881}, scale:{x:1, y:1, z:1}, file:'RigTail4Gizmo.fbx'},
        {name:'RigTail3Gizmo', position:{x:75.89088, y:4.058589, z:89.25464}, rotation:{x:-0.158973, y:-0.6890047, z:-0.1589731}, scale:{x:1, y:0.9999999, z:1}, file:'RigTail3Gizmo.fbx'},
        {name:'RigTail2Gizmo', position:{x:75.89088, y:4.10231, z:89.13874}, rotation:{x:-0.1268487, y:-0.6956359, z:-0.1268487}, scale:{x:0.9999999, y:1, z:1}, file:'RigTail2Gizmo.fbx'},
        {name:'RigRLegPlatform_1', position:{x:75.72044, y:3.410702, z:88.4504}, rotation:{x:0.5108584, y:-0.5108584, z:0.4889005}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigRLegPlatform_1.fbx'},
        {name:'RigRLegPlatform', position:{x:75.72823, y:3.410702, z:89.11506}, rotation:{x:0.5018147, y:-0.5018147, z:0.4981787}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLegPlatform.fbx'},
        {name:'RigRLegPalmGizmo_1', position:{x:75.74406, y:3.668427, z:89.20988}, rotation:{x:-0.4680451, y:-0.5709977, z:0.3753951}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigRLegPalmGizmo_1.fbx'},
        {name:'RigRLegPalmGizmo', position:{x:75.72047, y:3.51685, z:88.56071}, rotation:{x:-0.2628601, y:-0.6573262, z:0.2728069}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLegPalmGizmo.fbx'},
        {name:'RigRLeg2Gizmo_1', position:{x:75.73161, y:3.762941, z:88.60303}, rotation:{x:-0.4446055, y:-0.5114234, z:0.4649611}, scale:{x:1.000001, y:0.9999985, z:0.9999999}, file:'RigRLeg2Gizmo_1.fbx'},
        {name:'RigRLeg2Gizmo', position:{x:75.73718, y:3.765596, z:89.12089}, rotation:{x:-0.6009607, y:-0.2279281, z:0.6885519}, scale:{x:1, y:1, z:1}, file:'RigRLeg2Gizmo.fbx'},
        {name:'RigRLeg1Gizmo_1', position:{x:75.75954, y:4.027176, z:89.10089}, rotation:{x:-0.493681, y:-0.4138147, z:0.5350664}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRLeg1Gizmo_1.fbx'},
        {name:'RigRLeg1Gizmo', position:{x:75.71964, y:3.967756, z:88.36698}, rotation:{x:-0.635634, y:-0.2152702, z:0.6875911}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigRLeg1Gizmo.fbx'},
        {name:'RigRibcageGizmo', position:{x:75.89088, y:4.095464, z:88.44189}, rotation:{x:0.7040228, y:-0.06596837, z:0.7040229}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRibcageGizmo.fbx'},
        {name:'RigPelvisGizmo', position:{x:75.89088, y:4.097646, z:89.03922}, rotation:{x:0.6960413, y:-0.1246048, z:0.6960415}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigTail6', position:{x:75.89088, y:3.982241, z:89.6223}, rotation:{x:0.1783631, y:-0.6842417, z:0.178363}, scale:{x:1, y:1, z:1}, file:'RigTail6.fbx'},
        {name:'RigTail5', position:{x:75.89088, y:3.956716, z:89.54308}, rotation:{x:0.1097589, y:-0.6985363, z:0.1097588}, scale:{x:1, y:1, z:1}, file:'RigTail5.fbx'},
        {name:'RigTail4', position:{x:75.89088, y:4.003536, z:89.36759}, rotation:{x:-0.09191877, y:-0.7011069, z:-0.09191881}, scale:{x:1, y:1, z:1}, file:'RigTail4.fbx'},
        {name:'RigTail3', position:{x:75.89088, y:4.058589, z:89.25464}, rotation:{x:-0.158973, y:-0.6890047, z:-0.158973}, scale:{x:1, y:1, z:1}, file:'RigTail3.fbx'},
        {name:'RigTail2', position:{x:75.89088, y:4.10231, z:89.13874}, rotation:{x:-0.1268487, y:-0.6956359, z:-0.1268487}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigTail2.fbx'},
        {name:'RigTail1', position:{x:75.89088, y:4.095658, z:89.03996}, rotation:{x:0.02376621, y:-0.7067072, z:0.02376616}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigTail1.fbx'},
        {name:'RigRLegDigit02_1', position:{x:75.72479, y:3.441885, z:88.40174}, rotation:{x:0.040165, y:0.7034495, z:0.03794467}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLegDigit02_1.fbx'},
        {name:'RigRLegDigit01_1', position:{x:75.72189, y:3.440683, z:88.48325}, rotation:{x:-0.01197048, y:0.7195649, z:-0.00179045}, scale:{x:1, y:1, z:1}, file:'RigRLegDigit01_1.fbx'},
        {name:'RigRLegPalm_1', position:{x:75.72047, y:3.51685, z:88.56071}, rotation:{x:0.2628596, y:0.6573266, z:-0.2728064}, scale:{x:0.9999979, y:1.000001, z:1.000002}, file:'RigRLegPalm_1.fbx'},
        {name:'RigRLeg2_1', position:{x:75.73161, y:3.762941, z:88.60303}, rotation:{x:0.4445878, y:0.511409, z:-0.464977}, scale:{x:1.000001, y:0.9999986, z:0.9999999}, file:'RigRLeg2_1.fbx'},
        {name:'RigRLeg1_1', position:{x:75.71964, y:3.967756, z:88.36698}, rotation:{x:0.6356341, y:0.2152705, z:-0.687591}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRLeg1_1.fbx'},
        {name:'RigRLegCollarbone', position:{x:75.87141, y:3.97505, z:88.33688}, rotation:{x:0.9943945, y:0.02041724, z:-0.09837016}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigRLegCollarbone.fbx'},
        {name:'DummyUpMouth', position:{x:75.89088, y:3.912198, z:87.9278}, rotation:{x:0.1398765, y:0.6931339, z:-0.1398767}, scale:{x:1.47288, y:0.4398545, z:0.9999999}, file:'DummyUpMouth.fbx'},
        {name:'DummyLowMouth', position:{x:75.89088, y:3.796478, z:88.02854}, rotation:{x:0.2075863, y:0.6759496, z:-0.2075865}, scale:{x:1.47288, y:0.4398545, z:0.9999999}, file:'DummyLowMouth.fbx'},
        {name:'RigHead', position:{x:75.89088, y:4.007034, z:88.05911}, rotation:{x:0.6821334, y:0.1862635, z:0.6821335}, scale:{x:1, y:0.9999999, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck5', position:{x:75.89088, y:4.051351, z:88.12709}, rotation:{x:0.6778145, y:0.2014138, z:0.6778147}, scale:{x:0.9999998, y:0.9999999, z:1}, file:'RigNeck5.fbx'},
        {name:'RigNeck4', position:{x:75.89088, y:4.084549, z:88.20115}, rotation:{x:0.6914658, y:0.1479026, z:0.6914659}, scale:{x:1, y:1, z:1}, file:'RigNeck4.fbx'},
        {name:'RigNeck3', position:{x:75.89088, y:4.102588, z:88.28027}, rotation:{x:0.7026703, y:0.07908529, z:0.7026705}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigNeck3.fbx'},
        {name:'RigNeck2', position:{x:75.89088, y:4.105551, z:88.36137}, rotation:{x:0.706989, y:0.01290959, z:0.706989}, scale:{x:1, y:1, z:1}, file:'RigNeck2.fbx'},
        {name:'RigNeck1', position:{x:75.89088, y:4.095464, z:88.44189}, rotation:{x:0.7057346, y:-0.04402955, z:0.7057347}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigNeck1.fbx'},
        {name:'RigLLegDigit02_1', position:{x:76.06378, y:3.442512, z:88.40267}, rotation:{x:0.005586334, y:-0.699666, z:-0.005325315}, scale:{x:1, y:1, z:1}, file:'RigLLegDigit02_1.fbx'},
        {name:'RigLLegDigit01_1', position:{x:76.06458, y:3.440683, z:88.48328}, rotation:{x:4.453212E-05, y:-0.7034324, z:-0.01592016}, scale:{x:1, y:1, z:1}, file:'RigLLegDigit01_1.fbx'},
        {name:'RigLLegPalm_1', position:{x:76.06783, y:3.517088, z:88.56046}, rotation:{x:-0.2697295, y:-0.6428397, z:0.2679993}, scale:{x:0.9999985, y:1.000002, z:1}, file:'RigLLegPalm_1.fbx'},
        {name:'RigLLeg2_1', position:{x:76.05492, y:3.76302, z:88.60318}, rotation:{x:-0.4613414, y:-0.5707819, z:0.4472523}, scale:{x:0.9999968, y:1.000003, z:1}, file:'RigLLeg2_1.fbx'},
        {name:'RigLLeg1_1', position:{x:76.06523, y:3.967756, z:88.36698}, rotation:{x:-0.6863438, y:-0.2791256, z:0.6368628}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLLeg1_1.fbx'},
        {name:'RigLLegCollarbone', position:{x:75.91034, y:3.97505, z:88.33688}, rotation:{x:-0.09644717, y:-0.03296498, z:0.9945908}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigLLegCollarbone.fbx'},
        {name:'RigRibcage', position:{x:75.89088, y:4.095464, z:88.44189}, rotation:{x:0.7040229, y:-0.06596836, z:0.704023}, scale:{x:1, y:1, z:1}, file:'RigRibcage.fbx'},
        {name:'RigSpine6', position:{x:75.89088, y:4.081942, z:88.54101}, rotation:{x:0.7054824, y:-0.04790188, z:0.7054825}, scale:{x:1, y:1, z:1}, file:'RigSpine6.fbx'},
        {name:'RigSpine5', position:{x:75.89088, y:4.075622, z:88.64085}, rotation:{x:0.7067536, y:-0.0223477, z:0.7067537}, scale:{x:1, y:0.9999999, z:1}, file:'RigSpine5.fbx'},
        {name:'RigSpine4', position:{x:75.89088, y:4.086748, z:88.72388}, rotation:{x:0.7055392, y:0.04705844, z:0.7055392}, scale:{x:0.9999998, y:0.9999998, z:0.9999999}, file:'RigSpine4.fbx'},
        {name:'RigSpine3', position:{x:75.89088, y:4.098341, z:88.83971}, rotation:{x:0.7062274, y:0.03525483, z:0.7062275}, scale:{x:0.9999998, y:1, z:1}, file:'RigSpine3.fbx'},
        {name:'RigSpine2', position:{x:75.89088, y:4.105, z:88.93945}, rotation:{x:0.7067138, y:0.02356723, z:0.706714}, scale:{x:1, y:1, z:1}, file:'RigSpine2.fbx'},
        {name:'RigSpine1', position:{x:75.89088, y:4.097646, z:89.03922}, rotation:{x:0.7066283, y:-0.02600861, z:0.7066284}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigSpine1.fbx'},
        {name:'RigRLegDigit02', position:{x:75.73105, y:3.445896, z:89.03049}, rotation:{x:-0.007562384, y:-0.7128305, z:-0.004983648}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRLegDigit02.fbx'},
        {name:'RigRLegDigit01', position:{x:75.72842, y:3.442421, z:89.14162}, rotation:{x:0.0139759, y:-0.7153519, z:-0.008047592}, scale:{x:0.9999997, y:1, z:0.9999999}, file:'RigRLegDigit01.fbx'},
        {name:'RigRLegPalm', position:{x:75.74406, y:3.668427, z:89.20988}, rotation:{x:-0.4680439, y:-0.5709983, z:0.3753939}, scale:{x:0.9999974, y:1.000002, z:0.9999998}, file:'RigRLegPalm.fbx'},
        {name:'RigRLeg2', position:{x:75.73718, y:3.765596, z:89.12089}, rotation:{x:-0.6009606, y:-0.227928, z:0.688552}, scale:{x:1, y:1, z:1}, file:'RigRLeg2.fbx'},
        {name:'RigRLeg1', position:{x:75.75954, y:4.027176, z:89.10089}, rotation:{x:-0.4936811, y:-0.4138148, z:0.5350664}, scale:{x:1, y:0.9999999, z:1}, file:'RigRLeg1.fbx'},
        {name:'RigLLegDigit02', position:{x:76.05825, y:3.443975, z:89.03084}, rotation:{x:0.001600951, y:-0.7067917, z:-0.01120621}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLLegDigit02.fbx'},
        {name:'RigLLegDigit01', position:{x:76.05688, y:3.442421, z:89.14162}, rotation:{x:0.002982482, y:-0.7114522, z:-0.006963097}, scale:{x:0.9999999, y:1, z:1}, file:'RigLLegDigit01.fbx'},
        {name:'RigLLegPalm', position:{x:76.03563, y:3.668261, z:89.20891}, rotation:{x:-0.3652841, y:-0.5602601, z:0.4806279}, scale:{x:0.9999989, y:1.000002, z:0.9999993}, file:'RigLLegPalm.fbx'},
        {name:'RigLLeg2', position:{x:76.03947, y:3.7649, z:89.11916}, rotation:{x:-0.6814875, y:-0.3369937, z:0.609803}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLLeg2.fbx'},
        {name:'RigLLeg1', position:{x:76.02533, y:4.027176, z:89.10089}, rotation:{x:-0.5427068, y:-0.5403301, z:0.4846811}, scale:{x:1, y:1, z:1}, file:'RigLLeg1.fbx'},
        {name:'RigPelvis', position:{x:75.89088, y:4.097646, z:89.03922}, rotation:{x:0.6960413, y:-0.1246048, z:0.6960415}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigLLegPlatform_1', position:{x:76.05664, y:3.410702, z:89.11506}, rotation:{x:0.502222, y:-0.502222, z:0.4977681}, scale:{x:1, y:1, z:0.9999999}, file:'RigLLegPlatform_1.fbx'},
        {name:'RigLLegPlatform', position:{x:76.06443, y:3.410702, z:88.4504}, rotation:{x:0.5011535, y:-0.5011535, z:0.4988438}, scale:{x:1, y:1, z:1}, file:'RigLLegPlatform.fbx'},
        {name:'RigLLegPalmGizmo_1', position:{x:76.06783, y:3.517088, z:88.56046}, rotation:{x:-0.2697287, y:-0.6428397, z:0.2679986}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLLegPalmGizmo_1.fbx'},
        {name:'RigLLegPalmGizmo', position:{x:76.03563, y:3.668261, z:89.20891}, rotation:{x:-0.3652848, y:-0.5602594, z:0.4806286}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigLLegPalmGizmo.fbx'},
        {name:'RigLLeg2Gizmo_1', position:{x:76.05492, y:3.76302, z:88.60318}, rotation:{x:-0.4613368, y:-0.5707779, z:0.447257}, scale:{x:0.9999963, y:1.000003, z:1}, file:'RigLLeg2Gizmo_1.fbx'},
        {name:'RigLLeg2Gizmo', position:{x:76.03947, y:3.7649, z:89.11916}, rotation:{x:-0.6814867, y:-0.3369914, z:0.6098043}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLLeg2Gizmo.fbx'},
        {name:'RigLLeg1Gizmo_1', position:{x:76.02533, y:4.027176, z:89.10089}, rotation:{x:-0.5427065, y:-0.5403296, z:0.4846816}, scale:{x:1, y:1, z:1}, file:'RigLLeg1Gizmo_1.fbx'},
        {name:'RigLLeg1Gizmo', position:{x:76.06523, y:3.967756, z:88.36698}, rotation:{x:-0.6863437, y:-0.2791255, z:0.6368627}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLLeg1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:75.89088, y:4.007034, z:88.05911}, rotation:{x:0.6821333, y:0.1862635, z:0.6821334}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'Rig', position:{x:75.89088, y:3.410702, z:88.71452}, rotation:{x:0.5, y:-0.5, z:0.5000001}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Forest_Wolf', position:{x:75.89088, y:3.410702, z:88.71452}, rotation:{x:0, y:-0.7071068, z:-0.7071068}, scale:{x:1, y:1, z:1}, file:'Forest_Wolf.fbx'},
        {name:'Forest_Wolf___Grey', position:{x:75.89088, y:3.410702, z:88.71452}, rotation:{x:0, y:-1, z:0}, scale:{x:1, y:1, z:1}, file:'Forest_Wolf___Grey.fbx'},
        {name:'Stair_el7', position:{x:90.28, y:5.2, z:106.96}, rotation:{x:0, y:0.933022, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el7.fbx'},
        {name:'Rock_11', position:{x:60.60131, y:3.875279, z:93.04993}, rotation:{x:0.1912586, y:0.1950223, z:-0.08892621}, scale:{x:1, y:1, z:1}, file:'Rock_11.fbx'},
        {name:'Plant_06', position:{x:90.33776, y:1.998073, z:95.04286}, rotation:{x:0, y:0.6102305, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_06.fbx'},
        {name:'Block2', position:{x:96.36, y:4.2, z:79.66}, rotation:{x:0, y:0.007107861, z:0}, scale:{x:1, y:1, z:1}, file:'Block2.fbx'},
        {name:'Bush_04', position:{x:87.82275, y:3.332384, z:82.10712}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_04.fbx'},
        {name:'Bush_05', position:{x:77.72835, y:5.514903, z:99.11279}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_05.fbx'},
        {name:'Plant_07', position:{x:69.71487, y:1.997678, z:69.69643}, rotation:{x:0, y:0.6149501, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_07.fbx'},
        {name:'Wagon_gate1', position:{x:82.9, y:3.33, z:71.34}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_gate1.fbx'},
        {name:'RigTail4Gizmo', position:{x:82.46288, y:6.314646, z:89.84097}, rotation:{x:-0.6935197, y:-0.1379511, z:0.6935197}, scale:{x:0.9999999, y:1, z:1}, file:'RigTail4Gizmo.fbx'},
        {name:'RigTail3Gizmo', position:{x:82.46288, y:6.4212, z:89.75859}, rotation:{x:-0.6347459, y:-0.3116048, z:0.634746}, scale:{x:1, y:1, z:1}, file:'RigTail3Gizmo.fbx'},
        {name:'RigTail2Gizmo', position:{x:82.46288, y:6.57726, z:89.80061}, rotation:{x:-0.4301221, y:-0.5612441, z:0.4301222}, scale:{x:1, y:1, z:1}, file:'RigTail2Gizmo.fbx'},
        {name:'RigTail1Gizmo', position:{x:82.46288, y:6.669015, z:89.93826}, rotation:{x:-0.2048915, y:-0.6767713, z:0.2048917}, scale:{x:1, y:0.9999999, z:1}, file:'RigTail1Gizmo.fbx'},
        {name:'RigRwing5Gizmo', position:{x:83.01865, y:6.913783, z:89.8839}, rotation:{x:0.05938099, y:0.6732821, z:0.6984207}, scale:{x:1, y:1, z:1}, file:'RigRwing5Gizmo.fbx'},
        {name:'RigRwing4Gizmo', position:{x:82.87241, y:6.872551, z:89.95017}, rotation:{x:0.05938099, y:0.6732821, z:0.6984207}, scale:{x:1, y:1, z:1}, file:'RigRwing4Gizmo.fbx'},
        {name:'RigRwing3Gizmo', position:{x:82.77005, y:6.843689, z:89.99657}, rotation:{x:0.05938099, y:0.6732821, z:0.6984207}, scale:{x:1, y:1, z:1}, file:'RigRwing3Gizmo.fbx'},
        {name:'RigRwing2Gizmo', position:{x:82.66622, y:6.814415, z:90.04362}, rotation:{x:0.05938099, y:0.6732821, z:0.6984207}, scale:{x:1, y:1, z:1}, file:'RigRwing2Gizmo.fbx'},
        {name:'RigRwing1Gizmo', position:{x:82.56094, y:6.784729, z:90.09133}, rotation:{x:0.05938099, y:0.6732821, z:0.6984207}, scale:{x:1, y:1, z:1}, file:'RigRwing1Gizmo.fbx'},
        {name:'RigRMouthGizmo', position:{x:82.4987, y:6.571927, z:90.29574}, rotation:{x:-0.6933219, y:-0.1389416, z:0.6933219}, scale:{x:1, y:0.9999999, z:1}, file:'RigRMouthGizmo.fbx'},
        {name:'RigRMidArm3Gizmo', position:{x:82.54513, y:6.469668, z:90.09932}, rotation:{x:0.5474507, y:0.447546, z:0.547451}, scale:{x:1, y:0.9999999, z:1}, file:'RigRMidArm3Gizmo.fbx'},
        {name:'RigRMidArm2Gizmo', position:{x:82.54513, y:6.528981, z:90.04942}, rotation:{x:0.2984017, y:0.6410589, z:0.2984017}, scale:{x:1, y:1, z:1}, file:'RigRMidArm2Gizmo.fbx'},
        {name:'RigRMidArm1Gizmo', position:{x:82.54513, y:6.605733, z:90.03513}, rotation:{x:0.4519503, y:0.5438206, z:0.4519503}, scale:{x:0.9999999, y:1, z:1}, file:'RigRMidArm1Gizmo.fbx'},
        {name:'RigRFrontArm3Gizmo', position:{x:82.54513, y:6.486186, z:90.24265}, rotation:{x:0.5155984, y:0.4838989, z:0.5155987}, scale:{x:1, y:1, z:1}, file:'RigRFrontArm3Gizmo.fbx'},
        {name:'RigRFrontArm2Gizmo', position:{x:82.54513, y:6.525576, z:90.13619}, rotation:{x:0.1246409, y:0.696035, z:0.1246408}, scale:{x:0.9999999, y:1, z:1}, file:'RigRFrontArm2Gizmo.fbx'},
        {name:'RigRFrontArm1Gizmo', position:{x:82.54513, y:6.605733, z:90.08817}, rotation:{x:0.3485898, y:0.6152114, z:0.3485897}, scale:{x:1, y:1, z:1}, file:'RigRFrontArm1Gizmo.fbx'},
        {name:'RigRFeelerMainGizmo', position:{x:82.50671, y:6.723749, z:90.47115}, rotation:{x:-0.5915582, y:-0.3873742, z:0.5915583}, scale:{x:1, y:0.9999999, z:1}, file:'RigRFeelerMainGizmo.fbx'},
        {name:'RigRBackArm3Gizmo', position:{x:82.54513, y:6.458867, z:90.00507}, rotation:{x:0.5437887, y:0.4519885, z:0.5437889}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRBackArm3Gizmo.fbx'},
        {name:'RigRBackArm2Gizmo', position:{x:82.54513, y:6.531878, z:89.97172}, rotation:{x:0.3822926, y:0.594855, z:0.3822927}, scale:{x:1, y:0.9999999, z:1}, file:'RigRBackArm2Gizmo.fbx'},
        {name:'RigRBackArm1Gizmo', position:{x:82.54513, y:6.616853, z:89.98089}, rotation:{x:0.5261339, y:0.4724225, z:0.5261339}, scale:{x:1, y:1, z:1}, file:'RigRBackArm1Gizmo.fbx'},
        {name:'RigLwing5Gizmo', position:{x:81.91149, y:6.912546, z:89.88589}, rotation:{x:0.6984206, y:-0.2353163, z:0.0593808}, scale:{x:0.9999999, y:1, z:1}, file:'RigLwing5Gizmo.fbx'},
        {name:'RigLwing4Gizmo', position:{x:82.05772, y:6.871315, z:89.95216}, rotation:{x:0.6984206, y:-0.2353163, z:0.0593808}, scale:{x:0.9999999, y:1, z:1}, file:'RigLwing4Gizmo.fbx'},
        {name:'RigLwing3Gizmo', position:{x:82.15862, y:6.842865, z:89.99789}, rotation:{x:0.6984206, y:-0.2353163, z:0.0593808}, scale:{x:0.9999999, y:1, z:1}, file:'RigLwing3Gizmo.fbx'},
        {name:'RigLwing2Gizmo', position:{x:82.25953, y:6.814415, z:90.04362}, rotation:{x:0.6984206, y:-0.2353163, z:0.0593808}, scale:{x:0.9999999, y:1, z:1}, file:'RigLwing2Gizmo.fbx'},
        {name:'RigLwing1Gizmo', position:{x:82.36481, y:6.784729, z:90.09133}, rotation:{x:0.6984206, y:-0.2353163, z:0.0593808}, scale:{x:0.9999999, y:1, z:1}, file:'RigLwing1Gizmo.fbx'},
        {name:'RigLMouthGizmo', position:{x:82.42705, y:6.571927, z:90.29574}, rotation:{x:-0.6933219, y:-0.1389416, z:0.6933219}, scale:{x:1, y:0.9999999, z:1}, file:'RigLMouthGizmo.fbx'},
        {name:'RigLMidArm3Gizmo', position:{x:82.38062, y:6.469668, z:90.09932}, rotation:{x:0.547451, y:0.4475461, z:0.5474508}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLMidArm3Gizmo.fbx'},
        {name:'RigLMidArm2Gizmo', position:{x:82.38062, y:6.528981, z:90.04942}, rotation:{x:0.2984017, y:0.6410587, z:0.2984017}, scale:{x:1, y:1, z:1}, file:'RigLMidArm2Gizmo.fbx'},
        {name:'RigLMidArm1Gizmo', position:{x:82.38062, y:6.605733, z:90.03513}, rotation:{x:0.4519502, y:0.5438207, z:0.4519503}, scale:{x:0.9999999, y:1, z:1}, file:'RigLMidArm1Gizmo.fbx'},
        {name:'RigLFrontArm3Gizmo', position:{x:82.38062, y:6.486186, z:90.24265}, rotation:{x:0.5155986, y:0.4838989, z:0.5155984}, scale:{x:0.9999999, y:1, z:1}, file:'RigLFrontArm3Gizmo.fbx'},
        {name:'RigLFrontArm2Gizmo', position:{x:82.38062, y:6.525576, z:90.13619}, rotation:{x:0.1246409, y:0.6960349, z:0.1246408}, scale:{x:0.9999999, y:1, z:1}, file:'RigLFrontArm2Gizmo.fbx'},
        {name:'RigLFrontArm1Gizmo', position:{x:82.38062, y:6.605733, z:90.08817}, rotation:{x:0.3485897, y:0.6152115, z:0.3485898}, scale:{x:1, y:1, z:1}, file:'RigLFrontArm1Gizmo.fbx'},
        {name:'RigLFeelerMainGizmo', position:{x:82.41904, y:6.723749, z:90.47115}, rotation:{x:0.5915584, y:0.3873744, z:-0.5915581}, scale:{x:0.9999999, y:1, z:1}, file:'RigLFeelerMainGizmo.fbx'},
        {name:'RigLBackArm3Gizmo', position:{x:82.38062, y:6.458867, z:90.00507}, rotation:{x:0.5437889, y:0.4519886, z:0.5437887}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLBackArm3Gizmo.fbx'},
        {name:'RigLBackArm2Gizmo', position:{x:82.38062, y:6.531878, z:89.97172}, rotation:{x:0.3822926, y:0.5948547, z:0.3822926}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLBackArm2Gizmo.fbx'},
        {name:'RigLBackArm1Gizmo', position:{x:82.38062, y:6.616853, z:89.98089}, rotation:{x:0.5261339, y:0.4724225, z:0.526134}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLBackArm1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:82.46288, y:6.63251, z:90.23377}, rotation:{x:-0.6933219, y:-0.1389416, z:0.6933219}, scale:{x:1, y:0.9999999, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'RigBodyGizmo', position:{x:82.46288, y:6.689612, z:90.04824}, rotation:{x:-0.7069939, y:0.01263003, z:0.706994}, scale:{x:1, y:1, z:1}, file:'RigBodyGizmo.fbx'},
        {name:'RigTail4', position:{x:82.46288, y:6.314646, z:89.84097}, rotation:{x:0.6935197, y:0.1379511, z:-0.6935197}, scale:{x:1, y:1, z:1}, file:'RigTail4.fbx'},
        {name:'RigTail3', position:{x:82.46288, y:6.4212, z:89.75859}, rotation:{x:0.6347459, y:0.3116048, z:-0.634746}, scale:{x:1, y:1, z:1}, file:'RigTail3.fbx'},
        {name:'RigTail2', position:{x:82.46288, y:6.57726, z:89.80061}, rotation:{x:0.430122, y:0.5612441, z:-0.4301222}, scale:{x:1, y:1, z:1}, file:'RigTail2.fbx'},
        {name:'RigTail1', position:{x:82.46288, y:6.669015, z:89.93826}, rotation:{x:0.2048915, y:0.6767713, z:-0.2048917}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigTail1.fbx'},
        {name:'RigRwing5', position:{x:83.01865, y:6.913783, z:89.8839}, rotation:{x:0.05938116, y:0.6732821, z:0.6984208}, scale:{x:1, y:1, z:0.9999999}, file:'RigRwing5.fbx'},
        {name:'RigRwing4', position:{x:82.87241, y:6.872551, z:89.95017}, rotation:{x:0.0593811, y:0.6732821, z:0.6984206}, scale:{x:1, y:1, z:0.9999999}, file:'RigRwing4.fbx'},
        {name:'RigRwing3', position:{x:82.77005, y:6.843689, z:89.99657}, rotation:{x:0.05938104, y:0.6732821, z:0.6984206}, scale:{x:1, y:1, z:0.9999999}, file:'RigRwing3.fbx'},
        {name:'RigRwing2', position:{x:82.66622, y:6.814415, z:90.04362}, rotation:{x:0.05938104, y:0.6732822, z:0.6984206}, scale:{x:1, y:1, z:0.9999999}, file:'RigRwing2.fbx'},
        {name:'RigRwing1', position:{x:82.56094, y:6.784729, z:90.09133}, rotation:{x:0.05938101, y:0.6732821, z:0.6984206}, scale:{x:1, y:1, z:1}, file:'RigRwing1.fbx'},
        {name:'RigRMidArm3', position:{x:82.54513, y:6.469668, z:90.09932}, rotation:{x:0.5474508, y:0.4475461, z:0.5474511}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRMidArm3.fbx'},
        {name:'RigRMidArm2', position:{x:82.54513, y:6.528981, z:90.04942}, rotation:{x:0.2984017, y:0.6410589, z:0.2984017}, scale:{x:1, y:1, z:1}, file:'RigRMidArm2.fbx'},
        {name:'RigRMidArm1', position:{x:82.54513, y:6.605733, z:90.03513}, rotation:{x:0.4519503, y:0.5438206, z:0.4519503}, scale:{x:1, y:1, z:1}, file:'RigRMidArm1.fbx'},
        {name:'RigRFrontArm3', position:{x:82.54513, y:6.486186, z:90.24265}, rotation:{x:0.5155985, y:0.4838989, z:0.5155987}, scale:{x:1, y:1, z:1}, file:'RigRFrontArm3.fbx'},
        {name:'RigRFrontArm2', position:{x:82.54513, y:6.525576, z:90.13619}, rotation:{x:0.1246409, y:0.696035, z:0.1246409}, scale:{x:1, y:1, z:1}, file:'RigRFrontArm2.fbx'},
        {name:'RigRFrontArm1', position:{x:82.54513, y:6.605733, z:90.08817}, rotation:{x:0.3485898, y:0.6152114, z:0.3485897}, scale:{x:1, y:1, z:1}, file:'RigRFrontArm1.fbx'},
        {name:'RigRBackArm3', position:{x:82.54513, y:6.458867, z:90.00507}, rotation:{x:-0.5437887, y:-0.4519886, z:-0.5437889}, scale:{x:0.9999998, y:0.9999998, z:1}, file:'RigRBackArm3.fbx'},
        {name:'RigRBackArm2', position:{x:82.54513, y:6.531878, z:89.97172}, rotation:{x:-0.3822927, y:-0.594855, z:-0.3822926}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRBackArm2.fbx'},
        {name:'RigRBackArm1', position:{x:82.54513, y:6.616853, z:89.98089}, rotation:{x:-0.5261339, y:-0.4724225, z:-0.5261339}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRBackArm1.fbx'},
        {name:'RigRMouth', position:{x:82.4987, y:6.571927, z:90.29574}, rotation:{x:-0.6933219, y:-0.1389415, z:0.6933219}, scale:{x:1, y:1, z:1}, file:'RigRMouth.fbx'},
        {name:'RigRFeelerMain', position:{x:82.50671, y:6.723749, z:90.47115}, rotation:{x:-0.5915583, y:-0.3873742, z:0.5915585}, scale:{x:1, y:0.9999997, z:1}, file:'RigRFeelerMain.fbx'},
        {name:'RigRFeeler4', position:{x:82.50671, y:6.774066, z:90.42722}, rotation:{x:-0.6437516, y:-0.2925473, z:0.6437517}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRFeeler4.fbx'},
        {name:'RigRFeeler3', position:{x:82.50671, y:6.823372, z:90.34582}, rotation:{x:-0.6810533, y:-0.1901747, z:0.6810533}, scale:{x:1, y:0.9999998, z:1}, file:'RigRFeeler3.fbx'},
        {name:'RigRFeeler2', position:{x:82.50671, y:6.81179, z:90.26256}, rotation:{x:-0.7054188, y:0.04882967, z:0.7054189}, scale:{x:0.9999999, y:1, z:1}, file:'RigRFeeler2.fbx'},
        {name:'RigRFeeler1', position:{x:82.50671, y:6.707632, z:90.24165}, rotation:{x:-0.5469946, y:0.4481039, z:0.5469946}, scale:{x:1, y:1, z:1}, file:'RigRFeeler1.fbx'},
        {name:'RigLMouth', position:{x:82.42705, y:6.571927, z:90.29574}, rotation:{x:-0.6933219, y:-0.1389415, z:0.6933219}, scale:{x:1, y:1, z:1}, file:'RigLMouth.fbx'},
        {name:'RigLFeelerMain', position:{x:82.41904, y:6.723749, z:90.47115}, rotation:{x:-0.5915585, y:-0.3873744, z:0.5915583}, scale:{x:1, y:0.9999998, z:1}, file:'RigLFeelerMain.fbx'},
        {name:'RigLFeeler4', position:{x:82.41904, y:6.774066, z:90.42722}, rotation:{x:-0.6437517, y:-0.2925474, z:0.6437516}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLFeeler4.fbx'},
        {name:'RigLFeeler3', position:{x:82.41904, y:6.823372, z:90.34582}, rotation:{x:-0.6810533, y:-0.1901748, z:0.6810533}, scale:{x:0.9999999, y:0.9999997, z:1}, file:'RigLFeeler3.fbx'},
        {name:'RigLFeeler2', position:{x:82.41904, y:6.81179, z:90.26256}, rotation:{x:-0.7054188, y:0.04882951, z:0.7054188}, scale:{x:0.9999999, y:1, z:1}, file:'RigLFeeler2.fbx'},
        {name:'RigLFeeler1', position:{x:82.41904, y:6.707632, z:90.24165}, rotation:{x:-0.5469944, y:0.4481039, z:0.5469947}, scale:{x:1, y:1, z:1}, file:'RigLFeeler1.fbx'},
        {name:'RigHead', position:{x:82.46288, y:6.63251, z:90.23377}, rotation:{x:-0.6933219, y:-0.1389415, z:0.6933219}, scale:{x:1, y:1, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck', position:{x:82.46288, y:6.69025, z:90.04681}, rotation:{x:-0.6991903, y:-0.1055105, z:0.6991909}, scale:{x:1, y:1, z:1}, file:'RigNeck.fbx'},
        {name:'RigLwing5', position:{x:81.91149, y:6.912546, z:89.88589}, rotation:{x:-0.6984206, y:0.2353164, z:-0.05938068}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLwing5.fbx'},
        {name:'RigLwing4', position:{x:82.05772, y:6.871315, z:89.95216}, rotation:{x:-0.6984206, y:0.2353164, z:-0.05938071}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLwing4.fbx'},
        {name:'RigLwing3', position:{x:82.15862, y:6.842865, z:89.99789}, rotation:{x:-0.6984207, y:0.2353164, z:-0.05938068}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLwing3.fbx'},
        {name:'RigLwing2', position:{x:82.25953, y:6.814415, z:90.04362}, rotation:{x:-0.6984206, y:0.2353162, z:-0.05938071}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLwing2.fbx'},
        {name:'RigLwing1', position:{x:82.36481, y:6.784729, z:90.09133}, rotation:{x:-0.6984206, y:0.2353162, z:-0.0593808}, scale:{x:1, y:1, z:1}, file:'RigLwing1.fbx'},
        {name:'RigLMidArm3', position:{x:82.38062, y:6.469668, z:90.09932}, rotation:{x:0.5474511, y:0.4475462, z:0.5474508}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLMidArm3.fbx'},
        {name:'RigLMidArm2', position:{x:82.38062, y:6.528981, z:90.04942}, rotation:{x:0.2984017, y:0.6410587, z:0.2984017}, scale:{x:1, y:1, z:1}, file:'RigLMidArm2.fbx'},
        {name:'RigLMidArm1', position:{x:82.38062, y:6.605733, z:90.03513}, rotation:{x:0.4519503, y:0.5438207, z:0.4519503}, scale:{x:1, y:1, z:1}, file:'RigLMidArm1.fbx'},
        {name:'RigLFrontArm3', position:{x:82.38062, y:6.486186, z:90.24265}, rotation:{x:0.5155987, y:0.483899, z:0.5155985}, scale:{x:1, y:1, z:1}, file:'RigLFrontArm3.fbx'},
        {name:'RigLFrontArm2', position:{x:82.38062, y:6.525576, z:90.13619}, rotation:{x:0.1246409, y:0.6960349, z:0.1246408}, scale:{x:1, y:1, z:1}, file:'RigLFrontArm2.fbx'},
        {name:'RigLFrontArm1', position:{x:82.38062, y:6.605733, z:90.08817}, rotation:{x:0.3485898, y:0.6152115, z:0.3485898}, scale:{x:1, y:1, z:1}, file:'RigLFrontArm1.fbx'},
        {name:'RigLBackArm3', position:{x:82.38062, y:6.458867, z:90.00507}, rotation:{x:-0.5437889, y:-0.4519887, z:-0.5437887}, scale:{x:0.9999998, y:0.9999998, z:1}, file:'RigLBackArm3.fbx'},
        {name:'RigLBackArm2', position:{x:82.38062, y:6.531878, z:89.97172}, rotation:{x:-0.3822927, y:-0.5948547, z:-0.3822926}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLBackArm2.fbx'},
        {name:'RigLBackArm1', position:{x:82.38062, y:6.616853, z:89.98089}, rotation:{x:-0.5261339, y:-0.4724226, z:-0.5261339}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLBackArm1.fbx'},
        {name:'RigBody', position:{x:82.46288, y:6.689612, z:90.04824}, rotation:{x:-0.7069939, y:0.01263003, z:0.706994}, scale:{x:1, y:1, z:1}, file:'RigBody.fbx'},
        {name:'Rig', position:{x:82.46288, y:6.257816, z:89.89854}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Monster_Bee', position:{x:82.46288, y:6.257816, z:89.91274}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Monster_Bee.fbx'},
        {name:'Monster_Bee_Yellow', position:{x:82.46288, y:6.257816, z:89.91274}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Monster_Bee_Yellow.fbx'},
        {name:'Rock_02', position:{x:76.40835, y:3.641355, z:93.58425}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_02.fbx'},
        {name:'Bones_02', position:{x:79.21936, y:5.491364, z:107.9373}, rotation:{x:0, y:0.5666382, z:0}, scale:{x:1, y:1, z:1}, file:'Bones_02.fbx'},
        {name:'Rock_14B', position:{x:64.75562, y:3.283053, z:99.66446}, rotation:{x:-0.04564867, y:-0.4697144, z:0.2892318}, scale:{x:1, y:1, z:1}, file:'Rock_14B.fbx'},
        {name:'Tree_Fall5', position:{x:92.92775, y:3.382367, z:91.36629}, rotation:{x:0.06506516, y:0.04073196, z:0.02363602}, scale:{x:1, y:1, z:1}, file:'Tree_Fall5.fbx'},
        {name:'Bush_03', position:{x:94.79803, y:3.323119, z:71.7866}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_03.fbx'},
        {name:'RigRLegPlatform', position:{x:81.22562, y:3.407505, z:81.84053}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigRLegPlatform.fbx'},
        {name:'RigRibcageGizmo', position:{x:81.68612, y:6.821755, z:81.31698}, rotation:{x:0.731541, y:-0.6817976, z:2.980232E-08}, scale:{x:1, y:0.9999999, z:1}, file:'RigRibcageGizmo.fbx'},
        {name:'RigRArmThumb2Gizmo', position:{x:81.04042, y:6.763813, z:84.57942}, rotation:{x:0.09146129, y:0.359057, z:0.0901445}, scale:{x:1, y:0.9999996, z:1}, file:'RigRArmThumb2Gizmo.fbx'},
        {name:'RigRArmThumb1Gizmo', position:{x:81.23351, y:6.800618, z:84.43915}, rotation:{x:0.06045076, y:0.3106808, z:0.06065959}, scale:{x:1, y:0.9999999, z:0.9999998}, file:'RigRArmThumb1Gizmo.fbx'},
        {name:'RigRArmRest3Gizmo', position:{x:81.77307, y:6.789551, z:85.29546}, rotation:{x:0.03993737, y:0.7076592, z:0.03565277}, scale:{x:0.9999999, y:1, z:1}, file:'RigRArmRest3Gizmo.fbx'},
        {name:'RigRArmRest2Gizmo', position:{x:81.77355, y:6.797593, z:85.14374}, rotation:{x:0.02066949, y:0.7057998, z:0.0167768}, scale:{x:0.9999998, y:1, z:1}, file:'RigRArmRest2Gizmo.fbx'},
        {name:'RigRArmRest1Gizmo', position:{x:81.77481, y:6.805634, z:84.97663}, rotation:{x:0.01875338, y:0.704273, z:0.01525706}, scale:{x:0.9999998, y:1, z:0.9999999}, file:'RigRArmRest1Gizmo.fbx'},
        {name:'RigRArmPoint3Gizmo', position:{x:81.36282, y:6.79441, z:85.3353}, rotation:{x:0.04061742, y:0.7211114, z:0.03487616}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmPoint3Gizmo.fbx'},
        {name:'RigRArmPoint2Gizmo', position:{x:81.36137, y:6.802451, z:85.15313}, rotation:{x:0.01801607, y:0.7097898, z:0.01314998}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRArmPoint2Gizmo.fbx'},
        {name:'RigRArmPoint1Gizmo', position:{x:81.36166, y:6.810492, z:84.96811}, rotation:{x:0.01734733, y:0.7064117, z:0.01336429}, scale:{x:0.9999998, y:1, z:0.9999999}, file:'RigRArmPoint1Gizmo.fbx'},
        {name:'RigRArmPalmGizmo', position:{x:81.6675, y:6.879744, z:84.30103}, rotation:{x:0.03937208, y:0.6964975, z:0.03627618}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmPalmGizmo.fbx'},
        {name:'RigRArm2Gizmo', position:{x:81.71403, y:6.901418, z:83.25913}, rotation:{x:-0.5065673, y:0.4827702, z:0.4946037}, scale:{x:0.9999999, y:1, z:1}, file:'RigRArm2Gizmo.fbx'},
        {name:'RigRArm1Gizmo', position:{x:81.68612, y:6.911406, z:82.50333}, rotation:{x:-0.4873688, y:0.5058357, z:0.5124263}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRArm1Gizmo.fbx'},
        {name:'RigPelvisGizmo', position:{x:81.46587, y:5.181617, z:81.31698}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigRArmThumb2', position:{x:81.04042, y:6.763811, z:84.57942}, rotation:{x:-0.09146027, y:-0.3590569, z:-0.09014739}, scale:{x:1.000001, y:0.9999992, z:0.9999998}, file:'RigRArmThumb2.fbx'},
        {name:'RigRArmThumb1', position:{x:81.23351, y:6.800616, z:84.43915}, rotation:{x:-0.06045129, y:-0.3106807, z:-0.06066055}, scale:{x:1.000001, y:0.9999999, z:0.9999995}, file:'RigRArmThumb1.fbx'},
        {name:'RigRArmRest3', position:{x:81.77307, y:6.789543, z:85.29546}, rotation:{x:-0.03994093, y:-0.7076587, z:-0.03565868}, scale:{x:0.9999998, y:1, z:0.9999999}, file:'RigRArmRest3.fbx'},
        {name:'RigRArmRest2', position:{x:81.77355, y:6.797587, z:85.14374}, rotation:{x:-0.02067187, y:-0.7057995, z:-0.01678075}, scale:{x:0.9999995, y:1.000001, z:0.9999998}, file:'RigRArmRest2.fbx'},
        {name:'RigRArmRest1', position:{x:81.77481, y:6.805631, z:84.97663}, rotation:{x:-0.01875447, y:-0.704273, z:-0.01525919}, scale:{x:0.9999995, y:1.000001, z:0.9999999}, file:'RigRArmRest1.fbx'},
        {name:'RigRArmPoint3', position:{x:81.36282, y:6.794403, z:85.3353}, rotation:{x:-0.04062093, y:-0.7211105, z:-0.03488192}, scale:{x:1, y:1, z:0.9999998}, file:'RigRArmPoint3.fbx'},
        {name:'RigRArmPoint2', position:{x:81.36137, y:6.802445, z:85.15313}, rotation:{x:-0.01801848, y:-0.7097895, z:-0.01315381}, scale:{x:0.9999996, y:1.000001, z:0.9999998}, file:'RigRArmPoint2.fbx'},
        {name:'RigRArmPoint1', position:{x:81.36166, y:6.810488, z:84.96811}, rotation:{x:-0.01734841, y:-0.7064117, z:-0.01336638}, scale:{x:0.9999994, y:1.000001, z:0.9999998}, file:'RigRArmPoint1.fbx'},
        {name:'RigRArmPalm', position:{x:81.6675, y:6.879743, z:84.30103}, rotation:{x:-0.03937191, y:-0.6964977, z:-0.0362764}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmPalm.fbx'},
        {name:'RigRArm2', position:{x:81.71403, y:6.901418, z:83.25913}, rotation:{x:0.5065675, y:-0.4827702, z:-0.4946038}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArm2.fbx'},
        {name:'RigRArm1', position:{x:81.68612, y:6.911406, z:82.50333}, rotation:{x:0.487369, y:-0.5058357, z:-0.5124265}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArm1.fbx'},
        {name:'RigRArmCollarbone', position:{x:81.15296, y:6.821754, z:81.79013}, rotation:{x:0.3578247, y:-0.6252453, z:-0.6379164}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigRArmCollarbone.fbx'},
        {name:'RigHead', position:{x:80.82297, y:6.865964, z:81.31698}, rotation:{x:0.7071069, y:-0.7071069, z:8.940697E-08}, scale:{x:0.9999998, y:0.9999999, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck', position:{x:81.68612, y:6.821754, z:81.31698}, rotation:{x:0.9996728, y:-0.02558461, z:-5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigNeck.fbx'},
        {name:'RigLArmThumb2', position:{x:81.04042, y:6.763815, z:78.05454}, rotation:{x:-0.09146006, y:-0.359057, z:0.0901415}, scale:{x:0.9999988, y:1.000001, z:1}, file:'RigLArmThumb2.fbx'},
        {name:'RigLArmThumb1', position:{x:81.23351, y:6.800618, z:78.19481}, rotation:{x:-0.06044779, y:-0.310681, z:0.06065857}, scale:{x:0.9999992, y:1, z:1}, file:'RigLArmThumb1.fbx'},
        {name:'RigLArmRest3', position:{x:81.77308, y:6.789562, z:77.3385}, rotation:{x:-0.03993174, y:-0.7076605, z:0.03564572}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLArmRest3.fbx'},
        {name:'RigLArmRest2', position:{x:81.77355, y:6.797601, z:77.49022}, rotation:{x:-0.02066513, y:-0.7058004, z:0.01677169}, scale:{x:1, y:0.9999995, z:0.9999999}, file:'RigLArmRest2.fbx'},
        {name:'RigLArmRest1', position:{x:81.77481, y:6.80564, z:77.65733}, rotation:{x:-0.01875021, y:-0.7042736, z:0.01525391}, scale:{x:1, y:0.9999995, z:0.9999999}, file:'RigLArmRest1.fbx'},
        {name:'RigLArmPoint3', position:{x:81.36282, y:6.79442, z:77.29865}, rotation:{x:-0.04061186, y:-0.7211125, z:0.0348691}, scale:{x:1, y:1, z:0.9999998}, file:'RigLArmPoint3.fbx'},
        {name:'RigLArmPoint2', position:{x:81.36137, y:6.802458, z:77.48083}, rotation:{x:-0.01801156, y:-0.7097904, z:0.01314474}, scale:{x:1.000001, y:0.9999995, z:0.9999999}, file:'RigLArmPoint2.fbx'},
        {name:'RigLArmPoint1', position:{x:81.36166, y:6.810496, z:77.66585}, rotation:{x:-0.0173441, y:-0.7064123, z:0.01336108}, scale:{x:1.000001, y:0.9999994, z:0.9999999}, file:'RigLArmPoint1.fbx'},
        {name:'RigLArmPalm', position:{x:81.6675, y:6.879744, z:78.33292}, rotation:{x:-0.03937, y:-0.6964979, z:0.03627487}, scale:{x:1, y:1, z:1}, file:'RigLArmPalm.fbx'},
        {name:'RigLArm2', position:{x:81.71403, y:6.901418, z:79.37482}, rotation:{x:0.5065675, y:-0.4827704, z:0.4946038}, scale:{x:1, y:1, z:1}, file:'RigLArm2.fbx'},
        {name:'RigLArm1', position:{x:81.68612, y:6.911406, z:80.13062}, rotation:{x:0.4873689, y:-0.5058359, z:0.5124265}, scale:{x:1, y:1, z:1}, file:'RigLArm1.fbx'},
        {name:'RigLArmCollarbone', position:{x:81.15296, y:6.821754, z:80.84383}, rotation:{x:0.3578244, y:-0.6252454, z:0.6379164}, scale:{x:1, y:1, z:0.9999999}, file:'RigLArmCollarbone.fbx'},
        {name:'RigRibcage', position:{x:81.68612, y:6.821754, z:81.31698}, rotation:{x:0.7315411, y:-0.6817976, z:5.960464E-08}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRibcage.fbx'},
        {name:'RigSpine5', position:{x:81.61268, y:6.284623, z:81.31698}, rotation:{x:0.6574719, y:-0.7534794, z:2.980232E-08}, scale:{x:0.9999998, y:0.9999999, z:1}, file:'RigSpine5.fbx'},
        {name:'RigSpine4', position:{x:81.48239, y:5.916834, z:81.31698}, rotation:{x:0.5770969, y:-0.8166758, z:2.980232E-08}, scale:{x:1, y:1, z:1}, file:'RigSpine4.fbx'},
        {name:'RigSpine3', position:{x:81.45877, y:5.747663, z:81.31698}, rotation:{x:0.6563956, y:-0.754417, z:8.940697E-08}, scale:{x:1, y:1, z:1}, file:'RigSpine3.fbx'},
        {name:'RigSpine2', position:{x:81.43013, y:5.539371, z:81.31698}, rotation:{x:0.6571801, y:-0.7537338, z:5.960464E-08}, scale:{x:0.9999996, y:0.9999996, z:0.9999999}, file:'RigSpine2.fbx'},
        {name:'RigSpine1', position:{x:81.46587, y:5.190597, z:81.31698}, rotation:{x:0.7422766, y:-0.6700938, z:-5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigSpine1.fbx'},
        {name:'RigRLegFoot1', position:{x:81.14995, y:3.498408, z:81.84054}, rotation:{x:7.581494E-08, y:-8.940697E-08, z:-8.478761E-09}, scale:{x:1, y:1, z:1}, file:'RigRLegFoot1.fbx'},
        {name:'RigRLegAnkle', position:{x:81.46246, y:3.858295, z:81.83961}, rotation:{x:-0.000482142, y:0.0008057058, z:0.5222528}, scale:{x:0.9999998, y:0.9999997, z:1}, file:'RigRLegAnkle.fbx'},
        {name:'RigRLeg2', position:{x:81.41975, y:4.521252, z:81.8181}, rotation:{x:0.004516423, y:0.02846557, z:0.7289163}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLeg2.fbx'},
        {name:'RigRLeg1', position:{x:81.46587, y:5.181617, z:81.80013}, rotation:{x:-0.01745471, y:0.002276391, z:0.6820393}, scale:{x:1, y:1, z:1}, file:'RigRLeg1.fbx'},
        {name:'RigLLegFoot1', position:{x:81.14995, y:3.498408, z:80.79342}, rotation:{x:4.642739E-08, y:-5.960464E-08, z:-3.786631E-08}, scale:{x:1, y:1, z:1}, file:'RigLLegFoot1.fbx'},
        {name:'RigLLegAnkle', position:{x:81.46246, y:3.858295, z:80.79435}, rotation:{x:0.0004821718, y:-0.0008058846, z:0.5222527}, scale:{x:0.9999998, y:0.9999998, z:1}, file:'RigLLegAnkle.fbx'},
        {name:'RigLLeg2', position:{x:81.41975, y:4.521252, z:80.81586}, rotation:{x:-0.004516751, y:-0.02846548, z:0.728916}, scale:{x:1, y:1, z:0.9999997}, file:'RigLLeg2.fbx'},
        {name:'RigLLeg1', position:{x:81.46587, y:5.181617, z:80.83382}, rotation:{x:0.01745468, y:-0.00227657, z:0.6820392}, scale:{x:1, y:1, z:1}, file:'RigLLeg1.fbx'},
        {name:'RigPelvis', position:{x:81.46587, y:5.181617, z:81.31698}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigLLegPlatform', position:{x:81.22562, y:3.407505, z:80.79343}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigLLegPlatform.fbx'},
        {name:'RigLArmThumb2Gizmo', position:{x:81.04043, y:6.763815, z:78.05454}, rotation:{x:-0.09145905, y:-0.3590567, z:0.0901441}, scale:{x:0.9999994, y:1.000001, z:1}, file:'RigLArmThumb2Gizmo.fbx'},
        {name:'RigLArmThumb1Gizmo', position:{x:81.23351, y:6.800618, z:78.19481}, rotation:{x:-0.06044847, y:-0.3106807, z:0.06065927}, scale:{x:0.9999997, y:1, z:1}, file:'RigLArmThumb1Gizmo.fbx'},
        {name:'RigLArmRest3Gizmo', position:{x:81.77308, y:6.789556, z:77.3385}, rotation:{x:-0.03993547, y:-0.7076594, z:0.03565142}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigLArmRest3Gizmo.fbx'},
        {name:'RigLArmRest2Gizmo', position:{x:81.77355, y:6.797597, z:77.49022}, rotation:{x:-0.02066759, y:-0.7058001, z:0.01677547}, scale:{x:1, y:0.9999996, z:1}, file:'RigLArmRest2Gizmo.fbx'},
        {name:'RigLArmRest1Gizmo', position:{x:81.77481, y:6.805638, z:77.65733}, rotation:{x:-0.01875148, y:-0.7042733, z:0.01525574}, scale:{x:1, y:0.9999997, z:0.9999999}, file:'RigLArmRest1Gizmo.fbx'},
        {name:'RigLArmPoint3Gizmo', position:{x:81.36282, y:6.794415, z:77.29865}, rotation:{x:-0.04061555, y:-0.7211117, z:0.03487477}, scale:{x:1, y:1, z:0.9999999}, file:'RigLArmPoint3Gizmo.fbx'},
        {name:'RigLArmPoint2Gizmo', position:{x:81.36137, y:6.802454, z:77.48083}, rotation:{x:-0.01801417, y:-0.70979, z:0.01314864}, scale:{x:1, y:0.9999997, z:0.9999999}, file:'RigLArmPoint2Gizmo.fbx'},
        {name:'RigLArmPoint1Gizmo', position:{x:81.36166, y:6.810494, z:77.66585}, rotation:{x:-0.01734543, y:-0.706412, z:0.01336296}, scale:{x:1, y:0.9999996, z:1}, file:'RigLArmPoint1Gizmo.fbx'},
        {name:'RigLArmPalmGizmo', position:{x:81.6675, y:6.879745, z:78.33292}, rotation:{x:-0.03937016, y:-0.6964977, z:0.03627486}, scale:{x:1, y:1, z:0.9999999}, file:'RigLArmPalmGizmo.fbx'},
        {name:'RigLArm2Gizmo', position:{x:81.71403, y:6.901418, z:79.37482}, rotation:{x:0.5065674, y:-0.4827703, z:0.4946037}, scale:{x:0.9999999, y:1, z:1}, file:'RigLArm2Gizmo.fbx'},
        {name:'RigLArm1Gizmo', position:{x:81.68612, y:6.911406, z:80.13062}, rotation:{x:0.4873688, y:-0.5058358, z:0.5124263}, scale:{x:1, y:1, z:0.9999999}, file:'RigLArm1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:80.82297, y:6.865965, z:81.31698}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'Rig', position:{x:81.30564, y:3.407505, z:81.31698}, rotation:{x:0.7071068, y:-0.7071068, z:5.960464E-08}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Forest_Golem', position:{x:81.43523, y:3.407505, z:81.31698}, rotation:{x:-0.5000001, y:-0.5000001, z:-0.5000001}, scale:{x:1, y:1, z:1}, file:'Forest_Golem.fbx'},
        {name:'Forest_Golem_Green', position:{x:81.43523, y:3.407505, z:81.31698}, rotation:{x:0, y:-0.7071068, z:0}, scale:{x:2, y:2, z:2}, file:'Forest_Golem_Green.fbx'},
        {name:'Stair_el6', position:{x:75.71603, y:3.120674, z:87.71255}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el6.fbx'},
        {name:'Rock_07', position:{x:71.1544, y:4.97023, z:109.9913}, rotation:{x:-0.04860994, y:0.6290164, z:-0.09246758}, scale:{x:1, y:1, z:1}, file:'Rock_07.fbx'},
        {name:'Fence1', position:{x:82.04021, y:3.405817, z:77.35659}, rotation:{x:0, y:0.2672937, z:0}, scale:{x:1, y:1, z:1}, file:'Fence1.fbx'},
        {name:'Wagon_Tracks1', position:{x:69.07941, y:3.41732, z:78.86053}, rotation:{x:0.08542208, y:0.9005686, z:-0.2062272}, scale:{x:1, y:1, z:1}, file:'Wagon_Tracks1.fbx'},
        {name:'RigRLegPlatform', position:{x:68.84212, y:3.405363, z:93.65822}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigRLegPlatform.fbx'},
        {name:'RigRibcageGizmo', position:{x:68.90378, y:3.751815, z:93.55142}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigRibcageGizmo.fbx'},
        {name:'RigRArmPalmGizmo', position:{x:68.69426, y:3.810845, z:93.92255}, rotation:{x:-0.6106661, y:0.3461029, z:0.3515466}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRArmPalmGizmo.fbx'},
        {name:'RigRArm2Gizmo', position:{x:68.74674, y:3.810442, z:93.82841}, rotation:{x:-0.6060258, y:0.3618138, z:0.3544537}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRArm2Gizmo.fbx'},
        {name:'RigRArm1Gizmo', position:{x:68.80005, y:3.812172, z:93.73108}, rotation:{x:-0.6060968, y:0.3553196, z:0.3654621}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArm1Gizmo.fbx'},
        {name:'RigPelvisGizmo', position:{x:68.90378, y:3.621812, z:93.55142}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigRArmDigit12', position:{x:68.62887, y:3.809748, z:94.03317}, rotation:{x:0.6106643, y:-0.346104, z:-0.351551}, scale:{x:0.9999998, y:1, z:0.9999999}, file:'RigRArmDigit12.fbx'},
        {name:'RigRArmDigit11', position:{x:68.661, y:3.810014, z:93.97816}, rotation:{x:0.6103942, y:-0.3519578, z:-0.3520819}, scale:{x:0.9999998, y:0.9999999, z:1}, file:'RigRArmDigit11.fbx'},
        {name:'RigRArmPalm', position:{x:68.69426, y:3.810845, z:93.92255}, rotation:{x:0.610666, y:-0.3461028, z:-0.3515467}, scale:{x:0.9999998, y:0.9999999, z:1}, file:'RigRArmPalm.fbx'},
        {name:'RigRArm2', position:{x:68.74674, y:3.810442, z:93.82841}, rotation:{x:0.6060259, y:-0.3618138, z:-0.3544538}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRArm2.fbx'},
        {name:'RigRArm1', position:{x:68.80005, y:3.812172, z:93.73108}, rotation:{x:0.6060969, y:-0.3553195, z:-0.3654621}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArm1.fbx'},
        {name:'RigRArmCollarbone', position:{x:68.74396, y:3.792548, z:93.55632}, rotation:{x:0.4213933, y:-0.6259223, z:-0.510066}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmCollarbone.fbx'},
        {name:'RigMouth', position:{x:68.81235, y:3.824303, z:93.49863}, rotation:{x:0.965912, y:-0.005191769, z:0.2588153}, scale:{x:1, y:1, z:1}, file:'RigMouth.fbx'},
        {name:'RigHead', position:{x:68.90378, y:3.974382, z:93.55142}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck', position:{x:68.90378, y:3.751815, z:93.55142}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigNeck.fbx'},
        {name:'RigLArmDigit12', position:{x:69.18353, y:3.809749, z:93.07247}, rotation:{x:0.3530847, y:-0.6094571, z:0.6097772}, scale:{x:0.9999998, y:1, z:0.9999998}, file:'RigLArmDigit12.fbx'},
        {name:'RigLArmDigit11', position:{x:69.15195, y:3.810014, z:93.12779}, rotation:{x:0.3525814, y:-0.6128558, z:0.6101047}, scale:{x:0.9999998, y:1, z:0.9999998}, file:'RigLArmDigit11.fbx'},
        {name:'RigLArmPalm', position:{x:69.12042, y:3.810845, z:93.18442}, rotation:{x:0.3530809, y:-0.6094575, z:0.60978}, scale:{x:0.9999998, y:1, z:0.9999999}, file:'RigLArmPalm.fbx'},
        {name:'RigLArm2', position:{x:69.06513, y:3.810443, z:93.27693}, rotation:{x:0.3476068, y:-0.6200103, z:0.6099789}, scale:{x:1, y:1, z:1}, file:'RigLArm2.fbx'},
        {name:'RigLArm1', position:{x:69.00751, y:3.812172, z:93.37177}, rotation:{x:0.3421641, y:-0.6130158, z:0.6195479}, scale:{x:1, y:1, z:1}, file:'RigLArm1.fbx'},
        {name:'RigLArmCollarbone', position:{x:68.82811, y:3.792548, z:93.41056}, rotation:{x:0.1099042, y:-0.7485117, z:0.6524267}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArmCollarbone.fbx'},
        {name:'RigRibcage', position:{x:68.90378, y:3.751815, z:93.55142}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigRibcage.fbx'},
        {name:'RigSpineUp', position:{x:68.90378, y:3.686814, z:93.55142}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigSpineUp.fbx'},
        {name:'RigSpineLow', position:{x:68.90378, y:3.621812, z:93.55142}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigSpineLow.fbx'},
        {name:'RigRLegAnkle', position:{x:68.83965, y:3.420174, z:93.65678}, rotation:{x:-0.1320807, y:-0.2226005, z:0.4930047}, scale:{x:0.9999998, y:1, z:1}, file:'RigRLegAnkle.fbx'},
        {name:'RigRLeg2', position:{x:68.83637, y:3.486308, z:93.65536}, rotation:{x:-0.1734411, y:-0.1672023, z:0.7047836}, scale:{x:1, y:0.9999999, z:1}, file:'RigRLeg2.fbx'},
        {name:'RigRLeg1', position:{x:68.84167, y:3.544606, z:93.659}, rotation:{x:-0.1685692, y:-0.1946618, z:0.6457509}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLeg1.fbx'},
        {name:'RigLLegAnkle', position:{x:68.96294, y:3.420174, z:93.44321}, rotation:{x:-0.1321524, y:-0.2225215, z:0.493139}, scale:{x:0.9999998, y:1, z:1}, file:'RigLLegAnkle.fbx'},
        {name:'RigLLeg2', position:{x:68.96008, y:3.486308, z:93.44109}, rotation:{x:-0.2021473, y:-0.1888535, z:0.6970899}, scale:{x:1, y:0.9999999, z:0.9999996}, file:'RigLLeg2.fbx'},
        {name:'RigLLeg1', position:{x:68.96589, y:3.544606, z:93.44384}, rotation:{x:-0.1769274, y:-0.1907917, z:0.6435133}, scale:{x:1, y:1, z:1}, file:'RigLLeg1.fbx'},
        {name:'RigPelvis', position:{x:68.90378, y:3.621812, z:93.55142}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigMouthGizmo', position:{x:68.81235, y:3.824303, z:93.49863}, rotation:{x:-0.9659119, y:0.005191839, z:-0.2588154}, scale:{x:1, y:1, z:1}, file:'RigMouthGizmo.fbx'},
        {name:'RigLLegPlatform', position:{x:68.96543, y:3.405363, z:93.44463}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigLLegPlatform.fbx'},
        {name:'RigLArmPalmGizmo', position:{x:69.12042, y:3.810845, z:93.18442}, rotation:{x:0.3530808, y:-0.6094574, z:0.60978}, scale:{x:0.9999999, y:1, z:1}, file:'RigLArmPalmGizmo.fbx'},
        {name:'RigLArm2Gizmo', position:{x:69.06513, y:3.810443, z:93.27693}, rotation:{x:0.3476068, y:-0.6200102, z:0.6099789}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLArm2Gizmo.fbx'},
        {name:'RigLArm1Gizmo', position:{x:69.00751, y:3.812172, z:93.37177}, rotation:{x:0.3421641, y:-0.6130158, z:0.6195478}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArm1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:68.90378, y:3.974382, z:93.55142}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'Rig', position:{x:68.90378, y:3.405363, z:93.55142}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Evil_Mushroom', position:{x:68.90378, y:3.405363, z:93.55142}, rotation:{x:-0.3535534, y:-0.6123725, z:-0.6123725}, scale:{x:1, y:1, z:1}, file:'Evil_Mushroom.fbx'},
        {name:'Evil_Mushroom_Green', position:{x:68.90378, y:3.405363, z:93.55142}, rotation:{x:0, y:-0.8660254, z:0}, scale:{x:1, y:1, z:1}, file:'Evil_Mushroom_Green.fbx'},
        {name:'Ivy5', position:{x:92.1993, y:3.224745, z:72.88947}, rotation:{x:-0.04880375, y:-0.902574, z:0.02309597}, scale:{x:1, y:1, z:1}, file:'Ivy5.fbx'},
        {name:'Tree_Pine_1', position:{x:80.95, y:5.49, z:96.67}, rotation:{x:0, y:-0.9961278, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Tree_Pine_1', position:{x:79.12643, y:3.405359, z:92.48437}, rotation:{x:0, y:0.04540851, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Tree_Dead1', position:{x:87.64257, y:1.963199, z:87.03864}, rotation:{x:0, y:-0.2284899, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Dead1.fbx'},
        {name:'Crystal2', position:{x:85.10841, y:3.409632, z:93.62912}, rotation:{x:-0.1433563, y:-0.08644611, z:-0.08029429}, scale:{x:1, y:1, z:1}, file:'Crystal2.fbx'},
        {name:'Ivy2', position:{x:68.84683, y:5.450318, z:103.325}, rotation:{x:0, y:-0.945317, z:0}, scale:{x:1, y:1, z:1}, file:'Ivy2.fbx'},
        {name:'Tree_Small_01', position:{x:75.02632, y:5.492837, z:106.1072}, rotation:{x:0, y:0.808777, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Small_01.fbx'},
        {name:'Tree_Dead1', position:{x:85.36813, y:1.973998, z:86.61713}, rotation:{x:0.09329768, y:0.8412083, z:0.114658}, scale:{x:1, y:1, z:1}, file:'Tree_Dead1.fbx'},
        {name:'Tree_Fall4', position:{x:72.26025, y:4.651697, z:97.80058}, rotation:{x:-0.05987984, y:-0.1891588, z:0.2957982}, scale:{x:1, y:1, z:1}, file:'Tree_Fall4.fbx'},
        {name:'Rock_06', position:{x:71.04298, y:2.672322, z:83.6094}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_17C', position:{x:83.8859, y:5.491403, z:102.2644}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_17C.fbx'},
        {name:'Rock_05', position:{x:63.25441, y:3.40597, z:84.78988}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_05.fbx'},
        {name:'Rock_10', position:{x:72.43, y:1.29, z:71.74}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_10.fbx'},
        {name:'Stair_el7', position:{x:78.16843, y:3.334921, z:81.65438}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el7.fbx'},
        {name:'Stair_el2', position:{x:90.56, y:4.34, z:108.21}, rotation:{x:0, y:-0.3598194, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el2.fbx'},
        {name:'RigTongueHeadGizmo', position:{x:72.71947, y:6.016947, z:93.223}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigTongueHeadGizmo.fbx'},
        {name:'RigTail15Gizmo', position:{x:78.94638, y:5.932188, z:96.81811}, rotation:{x:-0.006355948, y:-0.965905, z:-0.001702979}, scale:{x:1, y:1, z:1}, file:'RigTail15Gizmo.fbx'},
        {name:'RigTail14Gizmo', position:{x:78.66753, y:5.935967, z:96.65711}, rotation:{x:-0.005668433, y:-0.9659092, z:-0.001518746}, scale:{x:1, y:0.9999999, z:1}, file:'RigTail14Gizmo.fbx'},
        {name:'RigTail13Gizmo', position:{x:78.38432, y:5.939761, z:96.4936}, rotation:{x:-0.005602438, y:-0.9659096, z:-0.001501069}, scale:{x:1, y:1, z:1}, file:'RigTail13Gizmo.fbx'},
        {name:'RigTail12Gizmo', position:{x:78.05708, y:5.943557, z:96.30466}, rotation:{x:-0.004851732, y:-0.9659137, z:-0.001299917}, scale:{x:1, y:1, z:1}, file:'RigTail12Gizmo.fbx'},
        {name:'RigTail11Gizmo', position:{x:77.81922, y:5.947352, z:96.16734}, rotation:{x:-0.006673537, y:-0.9659028, z:-0.001788069}, scale:{x:1, y:0.9999999, z:1}, file:'RigTail11Gizmo.fbx'},
        {name:'RigTail10Gizmo', position:{x:77.58643, y:5.951144, z:96.03294}, rotation:{x:-0.006812175, y:-0.9659017, z:-0.001825216}, scale:{x:1, y:1, z:1}, file:'RigTail10Gizmo.fbx'},
        {name:'RigTail9Gizmo', position:{x:77.34543, y:5.954937, z:95.89379}, rotation:{x:-0.006581805, y:-0.9659034, z:-0.001763489}, scale:{x:1, y:1, z:1}, file:'RigTail9Gizmo.fbx'},
        {name:'RigTail8Gizmo', position:{x:77.11265, y:5.958728, z:95.7594}, rotation:{x:-0.006811505, y:-0.9659019, z:-0.001825037}, scale:{x:1, y:1, z:1}, file:'RigTail8Gizmo.fbx'},
        {name:'RigTail7Gizmo', position:{x:76.87003, y:5.962514, z:95.61932}, rotation:{x:-0.006527268, y:-0.9659038, z:-0.001748876}, scale:{x:0.9999999, y:1, z:1}, file:'RigTail7Gizmo.fbx'},
        {name:'RigTail6Gizmo', position:{x:76.64917, y:5.966311, z:95.49181}, rotation:{x:-0.007189455, y:-0.9658991, z:-0.001926308}, scale:{x:1, y:1, z:1}, file:'RigTail6Gizmo.fbx'},
        {name:'RigTail5Gizmo', position:{x:76.40446, y:5.9701, z:95.35053}, rotation:{x:-0.006475536, y:-0.9659041, z:-0.001735014}, scale:{x:1, y:1, z:1}, file:'RigTail5Gizmo.fbx'},
        {name:'RigTail4Gizmo', position:{x:76.18111, y:5.973878, z:95.22158}, rotation:{x:-0.007075131, y:-0.9658999, z:-0.001895675}, scale:{x:1, y:1, z:1}, file:'RigTail4Gizmo.fbx'},
        {name:'RigTail3Gizmo', position:{x:75.94357, y:5.977622, z:95.08443}, rotation:{x:-0.006591767, y:-0.9659033, z:-0.001766158}, scale:{x:1, y:1, z:1}, file:'RigTail3Gizmo.fbx'},
        {name:'RigTail2Gizmo', position:{x:75.70092, y:5.981447, z:94.94434}, rotation:{x:-0.006591767, y:-0.9659033, z:-0.001766158}, scale:{x:1, y:1, z:1}, file:'RigTail2Gizmo.fbx'},
        {name:'RigTail1Gizmo', position:{x:75.46375, y:5.985051, z:94.80741}, rotation:{x:-0.00635595, y:-0.965905, z:-0.001702971}, scale:{x:1, y:1, z:1}, file:'RigTail1Gizmo.fbx'},
        {name:'RigRLegPlatform', position:{x:74.98602, y:4.703058, z:94.87218}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigRLegPlatform.fbx'},
        {name:'RigRibcageGizmo', position:{x:74.66624, y:6.118726, z:94.34697}, rotation:{x:-0.9612086, y:0.09534511, z:-0.2575551}, scale:{x:1, y:1, z:1}, file:'RigRibcageGizmo.fbx'},
        {name:'RigRArmPalmGizmo', position:{x:73.50227, y:7.707986, z:96.16254}, rotation:{x:-0.05077971, y:0.6559949, z:-0.332581}, scale:{x:1, y:1, z:0.9999996}, file:'RigRArmPalmGizmo.fbx'},
        {name:'RigRArmFingerC2Gizmo', position:{x:74.89496, y:7.480735, z:96.95091}, rotation:{x:-0.3856651, y:-0.8849125, z:0.1112137}, scale:{x:0.999997, y:1.000002, z:1.000001}, file:'RigRArmFingerC2Gizmo.fbx'},
        {name:'RigRArmFingerC1Gizmo', position:{x:73.57104, y:7.767246, z:96.23792}, rotation:{x:-0.1610837, y:-0.926321, z:0.2727066}, scale:{x:0.999999, y:1.000001, z:1.000001}, file:'RigRArmFingerC1Gizmo.fbx'},
        {name:'RigRArmFingerB2Gizmo', position:{x:74.78783, y:8.320662, z:98.55161}, rotation:{x:-0.286862, y:-0.9018238, z:0.1391223}, scale:{x:0.9999975, y:1.000002, z:1.000001}, file:'RigRArmFingerB2Gizmo.fbx'},
        {name:'RigRArmFingerB1Gizmo', position:{x:73.62797, y:7.835239, z:96.45278}, rotation:{x:0.01310105, y:0.8312115, z:-0.2147573}, scale:{x:0.9999992, y:0.9999996, z:1.000001}, file:'RigRArmFingerB1Gizmo.fbx'},
        {name:'RigRArmFingerA3Gizmo', position:{x:74.79128, y:9.264318, z:99.68938}, rotation:{x:-0.139528, y:-0.8956686, z:0.1460817}, scale:{x:0.9999982, y:1, z:1.000001}, file:'RigRArmFingerA3Gizmo.fbx'},
        {name:'RigRArmFingerA2Gizmo', position:{x:73.74715, y:8.837571, z:97.8972}, rotation:{x:0.0543542, y:0.8140023, z:-0.2897187}, scale:{x:0.999999, y:1, z:1}, file:'RigRArmFingerA2Gizmo.fbx'},
        {name:'RigRArm2Gizmo', position:{x:74.94281, y:7.004502, z:95.86988}, rotation:{x:-0.851031, y:0.2394824, z:-0.02579319}, scale:{x:1, y:1, z:0.9999997}, file:'RigRArm2Gizmo.fbx'},
        {name:'RigRArm1Gizmo', position:{x:74.55923, y:6.331752, z:94.53234}, rotation:{x:-0.4538139, y:0.7372993, z:0.2836946}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigRArm1Gizmo.fbx'},
        {name:'RigPelvisGizmo', position:{x:75.15721, y:5.985051, z:94.63042}, rotation:{x:0.6186658, y:-0.7417987, z:0.1657711}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigTail15', position:{x:78.94638, y:5.932188, z:96.81811}, rotation:{x:-0.006355967, y:-0.9659051, z:-0.001702969}, scale:{x:1, y:1, z:1}, file:'RigTail15.fbx'},
        {name:'RigTail14', position:{x:78.66753, y:5.935967, z:96.65711}, rotation:{x:-0.005668404, y:-0.9659094, z:-0.001518738}, scale:{x:1, y:1, z:1}, file:'RigTail14.fbx'},
        {name:'RigTail13', position:{x:78.38432, y:5.939761, z:96.4936}, rotation:{x:-0.005602412, y:-0.9659097, z:-0.001501055}, scale:{x:1, y:1, z:1}, file:'RigTail13.fbx'},
        {name:'RigTail12', position:{x:78.05708, y:5.943557, z:96.30466}, rotation:{x:-0.004851746, y:-0.9659138, z:-0.001299915}, scale:{x:1, y:1, z:1}, file:'RigTail12.fbx'},
        {name:'RigTail11', position:{x:77.81922, y:5.947352, z:96.16734}, rotation:{x:-0.006673511, y:-0.9659028, z:-0.001788055}, scale:{x:1, y:1, z:1}, file:'RigTail11.fbx'},
        {name:'RigTail10', position:{x:77.58644, y:5.951144, z:96.03294}, rotation:{x:-0.006812116, y:-0.9659019, z:-0.001825173}, scale:{x:1, y:1, z:1}, file:'RigTail10.fbx'},
        {name:'RigTail9', position:{x:77.34543, y:5.954937, z:95.8938}, rotation:{x:-0.006581723, y:-0.9659035, z:-0.001763483}, scale:{x:1, y:1, z:1}, file:'RigTail9.fbx'},
        {name:'RigTail8', position:{x:77.11265, y:5.958728, z:95.7594}, rotation:{x:-0.006811454, y:-0.9659017, z:-0.001825039}, scale:{x:1, y:1, z:1}, file:'RigTail8.fbx'},
        {name:'RigTail7', position:{x:76.87003, y:5.962515, z:95.61932}, rotation:{x:-0.006527263, y:-0.9659038, z:-0.001748847}, scale:{x:1, y:1, z:1}, file:'RigTail7.fbx'},
        {name:'RigTail6', position:{x:76.64917, y:5.966311, z:95.49181}, rotation:{x:-0.007189464, y:-0.965899, z:-0.001926283}, scale:{x:1, y:1, z:1}, file:'RigTail6.fbx'},
        {name:'RigTail5', position:{x:76.40446, y:5.9701, z:95.35053}, rotation:{x:-0.006475494, y:-0.9659041, z:-0.001734997}, scale:{x:1, y:1, z:1}, file:'RigTail5.fbx'},
        {name:'RigTail4', position:{x:76.18111, y:5.973878, z:95.22158}, rotation:{x:-0.007075067, y:-0.9658999, z:-0.00189563}, scale:{x:1, y:1, z:1}, file:'RigTail4.fbx'},
        {name:'RigTail3', position:{x:75.94357, y:5.977623, z:95.08443}, rotation:{x:-0.006591697, y:-0.9659033, z:-0.001766155}, scale:{x:1, y:1, z:1}, file:'RigTail3.fbx'},
        {name:'RigTail2', position:{x:75.70092, y:5.981447, z:94.94434}, rotation:{x:-0.006591697, y:-0.9659033, z:-0.001766155}, scale:{x:1, y:1, z:1}, file:'RigTail2.fbx'},
        {name:'RigTail1', position:{x:75.46375, y:5.985051, z:94.80741}, rotation:{x:-0.006355926, y:-0.965905, z:-0.001702959}, scale:{x:1, y:1, z:1}, file:'RigTail1.fbx'},
        {name:'RigRArmFingerC2', position:{x:74.89496, y:7.480753, z:96.9509}, rotation:{x:-0.3856597, y:-0.8849134, z:0.1112286}, scale:{x:0.9999943, y:1.000004, z:1.000002}, file:'RigRArmFingerC2.fbx'},
        {name:'RigRArmFingerC1', position:{x:73.57104, y:7.767238, z:96.23792}, rotation:{x:-0.1610788, y:-0.9263192, z:0.2727194}, scale:{x:0.999998, y:1.000001, z:1.000001}, file:'RigRArmFingerC1.fbx'},
        {name:'RigRArmFingerB2', position:{x:74.78783, y:8.320684, z:98.55159}, rotation:{x:-0.2868572, y:-0.9018251, z:0.1391277}, scale:{x:0.999995, y:1.000004, z:1.000002}, file:'RigRArmFingerB2.fbx'},
        {name:'RigRArmFingerB1', position:{x:73.62796, y:7.835233, z:96.45279}, rotation:{x:-0.01309673, y:-0.831212, z:0.2147591}, scale:{x:0.9999983, y:0.9999994, z:1.000003}, file:'RigRArmFingerB1.fbx'},
        {name:'RigRArmFingerA3', position:{x:74.79127, y:9.264345, z:99.68937}, rotation:{x:-0.1395247, y:-0.8956685, z:0.1460895}, scale:{x:0.9999962, y:1.000001, z:1.000003}, file:'RigRArmFingerA3.fbx'},
        {name:'RigRArmFingerA2', position:{x:73.74714, y:8.837573, z:97.89721}, rotation:{x:-0.05435117, y:-0.8140016, z:0.2897227}, scale:{x:0.9999985, y:1.000001, z:1.000001}, file:'RigRArmFingerA2.fbx'},
        {name:'RigRArmFingerA1', position:{x:73.41101, y:7.797689, z:96.23082}, rotation:{x:0.05057169, y:-0.678255, z:0.3525742}, scale:{x:1, y:1.000001, z:0.9999992}, file:'RigRArmFingerA1.fbx'},
        {name:'RigRArmPalm', position:{x:73.50227, y:7.707977, z:96.16255}, rotation:{x:0.05078437, y:-0.6559967, z:0.3325776}, scale:{x:1, y:1.000001, z:0.9999992}, file:'RigRArmPalm.fbx'},
        {name:'RigRArm2', position:{x:74.94281, y:7.004504, z:95.86988}, rotation:{x:0.8510314, y:-0.2394811, z:0.02579123}, scale:{x:1.000001, y:1, z:0.9999992}, file:'RigRArm2.fbx'},
        {name:'RigRArm1', position:{x:74.55923, y:6.331752, z:94.53234}, rotation:{x:0.4538141, y:-0.7372992, z:-0.283695}, scale:{x:1, y:1, z:1}, file:'RigRArm1.fbx'},
        {name:'RigTongueHead', position:{x:72.71947, y:6.016944, z:93.223}, rotation:{x:0.6830128, y:-0.6830131, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigTongueHead.fbx'},
        {name:'RigTongue8', position:{x:72.75726, y:5.902906, z:93.24481}, rotation:{x:0.7957531, y:-0.547531, z:0.2132215}, scale:{x:0.9999999, y:0.9999998, z:1}, file:'RigTongue8.fbx'},
        {name:'RigTongue7', position:{x:72.8767, y:5.875149, z:93.31377}, rotation:{x:0.9611669, y:-0.09576771, z:0.2575439}, scale:{x:0.9999981, y:1.000002, z:1}, file:'RigTongue7.fbx'},
        {name:'RigTongue6', position:{x:72.94917, y:5.940626, z:93.35561}, rotation:{x:0.9131918, y:0.3147925, z:0.2446888}, scale:{x:0.9999989, y:1.000001, z:0.9999998}, file:'RigTongue6.fbx'},
        {name:'RigTongue5', position:{x:72.99483, y:5.997805, z:93.38198}, rotation:{x:0.8847342, y:0.3876324, z:0.2370636}, scale:{x:0.9999952, y:1.000005, z:1}, file:'RigTongue5.fbx'},
        {name:'RigTongue4', position:{x:73.05512, y:6.047171, z:93.41679}, rotation:{x:0.9203497, y:0.2932057, z:0.2466072}, scale:{x:1.000011, y:0.999989, z:0.9999999}, file:'RigTongue4.fbx'},
        {name:'RigTongue3', position:{x:73.12239, y:6.074382, z:93.45563}, rotation:{x:0.9522498, y:0.1619665, z:0.2551549}, scale:{x:1.000001, y:0.9999989, z:1}, file:'RigTongue3.fbx'},
        {name:'RigTongue2', position:{x:73.2102, y:6.109612, z:93.50632}, rotation:{x:0.9524527, y:0.1607701, z:0.2552086}, scale:{x:0.9999998, y:1, z:1}, file:'RigTongue2.fbx'},
        {name:'RigTongue1', position:{x:73.30572, y:6.126929, z:93.56147}, rotation:{x:0.9629997, y:0.07513081, z:0.258035}, scale:{x:1, y:1, z:1}, file:'RigTongue1.fbx'},
        {name:'RigMouthUp', position:{x:73.25259, y:6.220531, z:93.53079}, rotation:{x:0.96338, y:-0.07008585, z:0.258137}, scale:{x:1, y:1, z:1}, file:'RigMouthUp.fbx'},
        {name:'RigMouthDown', position:{x:73.26912, y:6.030587, z:93.54034}, rotation:{x:0.9236818, y:0.2825328, z:0.2474998}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigMouthDown.fbx'},
        {name:'RigHead', position:{x:73.18347, y:6.114302, z:93.49089}, rotation:{x:0.9658619, y:0.01111969, z:0.2588019}, scale:{x:1, y:1, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck5', position:{x:73.49253, y:6.135815, z:93.66932}, rotation:{x:0.9654882, y:0.02907455, z:0.2587018}, scale:{x:0.9999998, y:0.9999997, z:1}, file:'RigNeck5.fbx'},
        {name:'RigNeck4', position:{x:73.77198, y:6.165247, z:93.83067}, rotation:{x:0.9649273, y:0.04391543, z:0.2585514}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigNeck4.fbx'},
        {name:'RigNeck3', position:{x:74.05052, y:6.181, z:93.99148}, rotation:{x:0.9656367, y:0.02363378, z:0.2587415}, scale:{x:0.9999999, y:1, z:1}, file:'RigNeck3.fbx'},
        {name:'RigNeck2', position:{x:74.30726, y:6.168498, z:94.13971}, rotation:{x:0.9657115, y:-0.02035447, z:0.2587616}, scale:{x:1, y:1, z:1}, file:'RigNeck2.fbx'},
        {name:'RigNeck1', position:{x:74.66624, y:6.118726, z:94.34697}, rotation:{x:0.9642023, y:-0.05767875, z:0.2583573}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigNeck1.fbx'},
        {name:'RigLArmFingerC2', position:{x:77.03569, y:7.480711, z:93.24306}, rotation:{x:-0.2784016, y:-0.8844929, z:-0.2891357}, scale:{x:1.000006, y:0.9999956, z:0.9999985}, file:'RigLArmFingerC2.fbx'},
        {name:'RigLArmFingerC1', position:{x:75.75626, y:7.767256, z:92.45304}, rotation:{x:-0.003162295, y:-0.9042175, z:-0.3167041}, scale:{x:1.000002, y:0.9999991, z:0.9999988}, file:'RigLArmFingerC1.fbx'},
        {name:'RigLArmFingerB2', position:{x:78.36837, y:8.320634, z:92.34995}, rotation:{x:-0.1788761, y:-0.9268352, z:-0.2639123}, scale:{x:1.000005, y:0.9999967, z:0.9999982}, file:'RigLArmFingerB2.fbx'},
        {name:'RigLArmFingerB1', position:{x:75.97079, y:7.835248, z:92.3949}, rotation:{x:0.09602644, y:-0.9761684, z:-0.1925379}, scale:{x:1.000002, y:1.000001, z:0.9999976}, file:'RigLArmFingerB1.fbx'},
        {name:'RigLArmFingerA3', position:{x:79.35544, y:9.264284, z:91.78406}, rotation:{x:-0.04780239, y:-0.9737697, z:-0.1962703}, scale:{x:1.000004, y:0.9999992, z:0.9999972}, file:'RigLArmFingerA3.fbx'},
        {name:'RigLArmFingerA2', position:{x:77.2813, y:8.837569, z:91.77591}, rotation:{x:0.09778105, y:-0.955202, z:-0.27808}, scale:{x:1.000002, y:0.9999995, z:0.9999989}, file:'RigLArmFingerA2.fbx'},
        {name:'RigLArmFingerA1', position:{x:75.67008, y:7.797712, z:92.318}, rotation:{x:0.2200772, y:-0.9087515, z:-0.2800586}, scale:{x:0.9999998, y:0.9999996, z:1.000001}, file:'RigLArmFingerA1.fbx'},
        {name:'RigLArmPalm', position:{x:75.65659, y:7.707997, z:92.43117}, rotation:{x:0.210264, y:-0.9059241, z:-0.2626402}, scale:{x:0.9999995, y:0.9999995, z:1.000001}, file:'RigLArmPalm.fbx'},
        {name:'RigLArm2', position:{x:76.12341, y:7.004497, z:93.82502}, rotation:{x:0.7499121, y:-0.4407076, z:0.4031734}, scale:{x:0.999999, y:0.9999999, z:1.000001}, file:'RigLArm2.fbx'},
        {name:'RigLArm1', position:{x:74.77327, y:6.331752, z:94.1616}, rotation:{x:0.2511661, y:-0.8446521, z:0.4725924}, scale:{x:1, y:1, z:0.9999999}, file:'RigLArm1.fbx'},
        {name:'RigRibcage', position:{x:74.66624, y:6.118726, z:94.34697}, rotation:{x:0.9612087, y:-0.09534509, z:0.2575552}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigRibcage.fbx'},
        {name:'RigSpine3', position:{x:74.82964, y:6.072549, z:94.44131}, rotation:{x:0.9589783, y:-0.115644, z:0.2569573}, scale:{x:0.9999998, y:0.9999998, z:1}, file:'RigSpine3.fbx'},
        {name:'RigSpine2', position:{x:74.99482, y:6.035738, z:94.53667}, rotation:{x:0.9615401, y:-0.09194207, z:0.2576439}, scale:{x:1, y:1, z:1}, file:'RigSpine2.fbx'},
        {name:'RigSpine1', position:{x:75.15721, y:5.985051, z:94.63042}, rotation:{x:0.9575227, y:-0.1271334, z:0.2565675}, scale:{x:1, y:1, z:1}, file:'RigSpine1.fbx'},
        {name:'RigRLegFoot2', position:{x:74.84001, y:4.703076, z:94.78789}, rotation:{x:6.029126E-06, y:-0.2588187, z:-2.09689E-05}, scale:{x:1, y:1, z:1}, file:'RigRLegFoot2.fbx'},
        {name:'RigRLegFoot1', position:{x:74.98601, y:4.703067, z:94.87218}, rotation:{x:6.029126E-06, y:-0.2588187, z:-2.09689E-05}, scale:{x:1, y:1, z:1}, file:'RigRLegFoot1.fbx'},
        {name:'RigRLegAnkle', position:{x:75.16595, y:5.083396, z:94.97607}, rotation:{x:-0.1320398, y:-0.222604, z:0.4927815}, scale:{x:1.00001, y:0.99999, z:1}, file:'RigRLegAnkle.fbx'},
        {name:'RigRLeg2', position:{x:74.84354, y:5.44805, z:94.79392}, rotation:{x:-0.2408511, y:-0.1099679, z:0.8933581}, scale:{x:1.000021, y:0.999979, z:0.9999999}, file:'RigRLeg2.fbx'},
        {name:'RigRLeg1', position:{x:75.00784, y:5.985051, z:94.88915}, rotation:{x:-0.1453244, y:-0.2087676, z:0.5587456}, scale:{x:1, y:1, z:1}, file:'RigRLeg1.fbx'},
        {name:'RigLLegFoot2', position:{x:75.13499, y:4.703049, z:94.277}, rotation:{x:-5.279535E-06, y:-0.2588187, z:2.131355E-05}, scale:{x:1, y:1, z:1}, file:'RigLLegFoot2.fbx'},
        {name:'RigLLegFoot1', position:{x:75.28098, y:4.703058, z:94.36129}, rotation:{x:-5.279535E-06, y:-0.2588187, z:2.131355E-05}, scale:{x:1, y:1, z:1}, file:'RigLLegFoot1.fbx'},
        {name:'RigLLegAnkle', position:{x:75.46091, y:5.083407, z:94.46517}, rotation:{x:-0.1320495, y:-0.2225983, z:0.4928178}, scale:{x:0.9999898, y:1.00001, z:0.9999999}, file:'RigLLegAnkle.fbx'},
        {name:'RigLLeg2', position:{x:75.14198, y:5.448046, z:94.27705}, rotation:{x:-0.2380967, y:-0.086294, z:0.8940958}, scale:{x:0.9999789, y:1.000021, z:1}, file:'RigLLeg2.fbx'},
        {name:'RigLLeg1', position:{x:75.30659, y:5.985051, z:94.3717}, rotation:{x:-0.1535206, y:-0.2138843, z:0.5565585}, scale:{x:1, y:1, z:1}, file:'RigLLeg1.fbx'},
        {name:'RigPelvis', position:{x:75.15721, y:5.985051, z:94.63042}, rotation:{x:0.6186658, y:-0.7417987, z:0.1657711}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigMouthUpGizmo', position:{x:73.25259, y:6.220531, z:93.53079}, rotation:{x:-0.9633799, y:0.07008585, z:-0.2581369}, scale:{x:0.9999999, y:1, z:1}, file:'RigMouthUpGizmo.fbx'},
        {name:'RigMouthDownGizmo', position:{x:73.26912, y:6.030588, z:93.54034}, rotation:{x:-0.9236817, y:-0.2825327, z:-0.2474997}, scale:{x:1, y:1, z:1}, file:'RigMouthDownGizmo.fbx'},
        {name:'RigLLegPlatform', position:{x:75.28098, y:4.703058, z:94.36129}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigLLegPlatform.fbx'},
        {name:'RigLArmPalmGizmo', position:{x:75.65659, y:7.707989, z:92.43116}, rotation:{x:0.2102662, y:-0.9059255, z:-0.262635}, scale:{x:0.9999998, y:0.9999998, z:1}, file:'RigLArmPalmGizmo.fbx'},
        {name:'RigLArmFingerC2Gizmo', position:{x:77.03568, y:7.480731, z:93.24307}, rotation:{x:-0.2783895, y:-0.8844924, z:-0.2891459}, scale:{x:1.000003, y:0.9999977, z:0.9999992}, file:'RigLArmFingerC2Gizmo.fbx'},
        {name:'RigLArmFingerC1Gizmo', position:{x:75.75626, y:7.767249, z:92.45303}, rotation:{x:-0.003151625, y:-0.9042135, z:-0.316713}, scale:{x:1.000001, y:0.9999996, z:0.9999993}, file:'RigLArmFingerC1Gizmo.fbx'},
        {name:'RigLArmFingerB2Gizmo', position:{x:78.36836, y:8.320656, z:92.34995}, rotation:{x:-0.1788693, y:-0.9268353, z:-0.2639146}, scale:{x:1.000003, y:0.9999983, z:0.999999}, file:'RigLArmFingerB2Gizmo.fbx'},
        {name:'RigLArmFingerB1Gizmo', position:{x:75.97079, y:7.835242, z:92.39489}, rotation:{x:0.09603103, y:-0.9761679, z:-0.1925374}, scale:{x:1.000001, y:1, z:0.9999988}, file:'RigLArmFingerB1Gizmo.fbx'},
        {name:'RigLArmFingerA3Gizmo', position:{x:79.35543, y:9.264312, z:91.78405}, rotation:{x:-0.04779582, y:-0.9737686, z:-0.1962755}, scale:{x:1.000002, y:0.9999996, z:0.9999986}, file:'RigLArmFingerA3Gizmo.fbx'},
        {name:'RigLArmFingerA2Gizmo', position:{x:77.2813, y:8.837572, z:91.7759}, rotation:{x:0.0977855, y:-0.9552009, z:-0.278082}, scale:{x:1.000001, y:0.9999999, z:0.9999994}, file:'RigLArmFingerA2Gizmo.fbx'},
        {name:'RigLArmFingerA1Gizmo', position:{x:75.67008, y:7.797703, z:92.31799}, rotation:{x:0.2200797, y:-0.9087516, z:-0.2800561}, scale:{x:0.9999999, y:0.9999998, z:1}, file:'RigLArmFingerA1Gizmo.fbx'},
        {name:'RigLArm2Gizmo', position:{x:76.12341, y:7.004499, z:93.82502}, rotation:{x:0.7499115, y:-0.4407066, z:0.4031752}, scale:{x:0.9999995, y:0.9999999, z:1}, file:'RigLArm2Gizmo.fbx'},
        {name:'RigLArm1Gizmo', position:{x:74.77327, y:6.331752, z:94.1616}, rotation:{x:0.2511661, y:-0.8446518, z:0.4725928}, scale:{x:1, y:1, z:1}, file:'RigLArm1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:73.18347, y:6.114303, z:93.49089}, rotation:{x:-0.9658618, y:-0.01111966, z:-0.2588019}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'Rig', position:{x:74.97538, y:4.703058, z:94.52544}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Fantasy_Dragon', position:{x:75.26987, y:4.703058, z:94.69547}, rotation:{x:-0.3535534, y:-0.6123725, z:-0.6123725}, scale:{x:1, y:1, z:1}, file:'Fantasy_Dragon.fbx'},
        {name:'Fantasy_Dragon_Red', position:{x:75.26987, y:4.703058, z:94.69547}, rotation:{x:0, y:-0.8660254, z:0}, scale:{x:1, y:1, z:1}, file:'Fantasy_Dragon_Red.fbx'},
        {name:'Rock_17A', position:{x:91.07327, y:3.405359, z:77.11009}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_17A.fbx'},
        {name:'Barrier1', position:{x:64.5, y:2.8, z:58.9}, rotation:{x:0, y:-0.5852707, z:0}, scale:{x:1, y:1, z:1}, file:'Barrier1.fbx'},
        {name:'Rune_particle2', position:{x:78.77789, y:5.632403, z:106.3376}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_particle2.fbx'},
        {name:'Rune_particle1', position:{x:78.77789, y:5.612045, z:106.3376}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_particle1.fbx'},
        {name:'Rune_03', position:{x:78.86592, y:5.491705, z:106.347}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_03.fbx'},
        {name:'Rock_14A', position:{x:72.51548, y:3.40576, z:86.4554}, rotation:{x:0, y:0.4386663, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_14A.fbx'},
        {name:'Stair_el5', position:{x:77.31909, y:3.143352, z:89.24825}, rotation:{x:-0.006079934, y:-0.3826352, z:0.01467826}, scale:{x:1, y:1, z:1}, file:'Stair_el5.fbx'},
        {name:'Bush_03', position:{x:61.02, y:5.42368, z:74.18}, rotation:{x:0, y:-0.5389422, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_03.fbx'},
        {name:'Plant_07', position:{x:75.39702, y:1.997678, z:80.12803}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_07.fbx'},
        {name:'Bush_05', position:{x:80.14614, y:3.352236, z:76.7976}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_05.fbx'},
        {name:'Bush_06', position:{x:86.86328, y:3.429062, z:95.44125}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_06.fbx'},
        {name:'Stair_el7', position:{x:72.14331, y:5.196955, z:105.4555}, rotation:{x:0, y:-0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el7.fbx'},
        {name:'Bush_thorned1', position:{x:96.90452, y:3.428694, z:70.83514}, rotation:{x:0, y:-0.2490608, z:0}, scale:{x:0.8521718, y:0.8521718, z:0.8521718}, file:'Bush_thorned1.fbx'},
        {name:'Banner', position:{x:71.40971, y:3.543048, z:85.38278}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Banner.fbx'},
        {name:'Stair_el1', position:{x:80.88763, y:2.89942, z:84.97256}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el1.fbx'},
        {name:'Block2', position:{x:96.43, y:4.2, z:84.4}, rotation:{x:0, y:0.007107861, z:0}, scale:{x:1, y:1, z:1}, file:'Block2.fbx'},
        {name:'Ivy2', position:{x:75.40681, y:5.924901, z:96.18697}, rotation:{x:0, y:-0.8704813, z:0}, scale:{x:1.112361, y:1.112361, z:1.112361}, file:'Ivy2.fbx'},
        {name:'Tree_Pine_2', position:{x:94.97945, y:3.412229, z:77.50838}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_2.fbx'},
        {name:'Stair_el8', position:{x:96.14, y:3.9, z:83.9}, rotation:{x:0, y:-0.702063, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el8.fbx'},
        {name:'Floor3', position:{x:60.07806, y:5.491553, z:79.43414}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Floor3.fbx'},
        {name:'Block3', position:{x:62.83, y:4.5, z:76.2}, rotation:{x:0, y:0.9998109, z:0}, scale:{x:1, y:1, z:1}, file:'Block3.fbx'},
        {name:'Ivy2', position:{x:68.58678, y:5.415411, z:102.8151}, rotation:{x:0, y:-0.4965193, z:0}, scale:{x:1, y:1, z:1}, file:'Ivy2.fbx'},
        {name:'Rock_05', position:{x:73.23006, y:4.252077, z:98.18828}, rotation:{x:-0.3391958, y:-0.2600396, z:0.3731661}, scale:{x:1, y:1, z:1}, file:'Rock_05.fbx'},
        {name:'Stair_el2', position:{x:88.77, y:5.19, z:108.14}, rotation:{x:0, y:0.933022, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el2.fbx'},
        {name:'Fence1', position:{x:67.78297, y:3.405359, z:102.1432}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Fence1.fbx'},
        {name:'Plant_06', position:{x:69.57276, y:1.997678, z:68.57345}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_06.fbx'},
        {name:'Mushroom3A', position:{x:84.94767, y:5.492176, z:102.8334}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom3A.fbx'},
        {name:'Bush_04', position:{x:65.93754, y:3.429039, z:98.05338}, rotation:{x:0, y:0.5802195, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_04.fbx'},
        {name:'Mushroom2A', position:{x:82.85183, y:5.491522, z:108.8689}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom2A.fbx'},
        {name:'Floor6', position:{x:64.40027, y:3.405712, z:143.3056}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Floor6.fbx'},
        {name:'Rock_14B', position:{x:72.35776, y:3.49619, z:97.22052}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_14B.fbx'},
        {name:'Tree_03', position:{x:54.56, y:5.5, z:88.43}, rotation:{x:0, y:-0.5389422, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_03.fbx'},
        {name:'Rock_02', position:{x:72.69267, y:5.40084, z:106.9401}, rotation:{x:0.05324773, y:0.05998303, z:0.2494023}, scale:{x:1, y:1, z:1}, file:'Rock_02.fbx'},
        {name:'Tree_Pine_3', position:{x:86.72508, y:3.642601, z:71.40851}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_3.fbx'},
        {name:'Tree_Dead1', position:{x:75.17627, y:1.973998, z:77.38676}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Dead1.fbx'},
        {name:'Stair_el1', position:{x:96.11, y:3.9, z:81.63}, rotation:{x:0, y:-0.702063, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el1.fbx'},
        {name:'Tree_Pine_1', position:{x:78.27444, y:5.5042, z:97.15366}, rotation:{x:0, y:-0.9047635, z:0}, scale:{x:1, y:0.7229945, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Block1', position:{x:64.53, y:3.9, z:76.27}, rotation:{x:0, y:0.9998109, z:0}, scale:{x:1, y:1, z:1}, file:'Block1.fbx'},
        {name:'Fern1', position:{x:74.01591, y:5.515959, z:104.0825}, rotation:{x:0, y:0.8534364, z:0}, scale:{x:1.069844, y:1.069844, z:1.069844}, file:'Fern1.fbx'},
        {name:'Ground_Decal1', position:{x:64.50191, y:3.405819, z:86.48553}, rotation:{x:0, y:0.6455026, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal1.fbx'},
        {name:'Wagon_wagon', position:{x:67.82258, y:3.449532, z:82.08486}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_wagon.fbx'},
        {name:'Tree_06', position:{x:88.26, y:3.39, z:83.44}, rotation:{x:0, y:0.2942935, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_06.fbx'},
        {name:'Ivy2', position:{x:69.81152, y:6.224043, z:102.3667}, rotation:{x:0, y:0.1040261, z:0}, scale:{x:1, y:1, z:1}, file:'Ivy2.fbx'},
        {name:'Ivy2', position:{x:79.23916, y:5.492624, z:104.144}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Ivy2.fbx'},
        {name:'Wagon_wagon', position:{x:93.82616, y:3.74179, z:79.36852}, rotation:{x:-0.1182035, y:-0.1722502, z:0.5533341}, scale:{x:1, y:1, z:1}, file:'Wagon_wagon.fbx'},
        {name:'Stair_el4', position:{x:95.33, y:3.6, z:81.16}, rotation:{x:0, y:-0.7121149, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el4.fbx'},
        {name:'Tree_Fall2', position:{x:85.9984, y:5.491358, z:102.9598}, rotation:{x:0, y:-0.5574281, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Fall2.fbx'},
        {name:'Plant_04', position:{x:85.2201, y:5.51559, z:101.3737}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_04.fbx'},
        {name:'Block1', position:{x:67.68327, y:3.865326, z:104.1894}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Block1.fbx'},
        {name:'Tree_03', position:{x:81.68469, y:5.487145, z:100.1968}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_03.fbx'},
        {name:'Rock_03', position:{x:76.59686, y:5.841122, z:96.41943}, rotation:{x:0, y:-0.8312181, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Rock_08', position:{x:78.77, y:2.91, z:73.66}, rotation:{x:0, y:-0.1814869, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_08.fbx'},
        {name:'Stair_el6', position:{x:79.23246, y:2.57413, z:84.19611}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el6.fbx'},
        {name:'Wagon_pickaxe', position:{x:67.82183, y:3.405385, z:83.24109}, rotation:{x:-0.2235611, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_pickaxe.fbx'},
        {name:'Rock_10', position:{x:61.56479, y:3.321074, z:89.647}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_10.fbx'},
        {name:'Plant_06', position:{x:91.64584, y:1.982832, z:92.10281}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_06.fbx'},
        {name:'Rock_14A', position:{x:78.93679, y:3.405359, z:93.31451}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_14A.fbx'},
        {name:'Stair_el4', position:{x:79.39892, y:3.266362, z:82.74966}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el4.fbx'},
        {name:'Rock_13', position:{x:85.6, y:0.1500001, z:84.9}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_13.fbx'},
        {name:'Bush_01', position:{x:85.20192, y:3.418263, z:72.45475}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_01.fbx'},
        {name:'Rock_13', position:{x:61.99, y:1.81, z:84.91}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_13.fbx'},
        {name:'Rock_13', position:{x:72.02119, y:2.920474, z:108.21}, rotation:{x:0, y:-0.9134675, z:0}, scale:{x:0.8287101, y:0.8287101, z:0.82871}, file:'Rock_13.fbx'},
        {name:'Bush_01', position:{x:90.13771, y:5.515233, z:105.3387}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_01.fbx'},
        {name:'Block1', position:{x:82.4, y:3.2, z:85.74}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Block1.fbx'},
        {name:'Bush_06', position:{x:85.32652, y:5.515348, z:111.1672}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_06.fbx'},
        {name:'Crystal1', position:{x:83.41771, y:4.112818, z:93.54988}, rotation:{x:-0.4147522, y:-0.1736614, z:-0.2525207}, scale:{x:1, y:1, z:1}, file:'Crystal1.fbx'},
        {name:'Mushroom3A', position:{x:62.85596, y:3.405359, z:102.3142}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom3A.fbx'},
        {name:'Ground_Decal_Leaves1', position:{x:82.02071, y:5.491518, z:108.9512}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_Leaves1.fbx'},
        {name:'RigTail6Gizmo', position:{x:78.08906, y:3.570563, z:81.18735}, rotation:{x:0.178363, y:-0.6842417, z:-0.1783631}, scale:{x:1, y:1, z:1}, file:'RigTail6Gizmo.fbx'},
        {name:'RigTail5Gizmo', position:{x:78.08906, y:3.545038, z:81.26656}, rotation:{x:0.1097588, y:-0.6985364, z:-0.1097589}, scale:{x:1, y:1, z:1}, file:'RigTail5Gizmo.fbx'},
        {name:'RigTail4Gizmo', position:{x:78.08906, y:3.591858, z:81.44205}, rotation:{x:-0.09191881, y:-0.701107, z:0.09191876}, scale:{x:1, y:1, z:1}, file:'RigTail4Gizmo.fbx'},
        {name:'RigTail3Gizmo', position:{x:78.08906, y:3.646911, z:81.55501}, rotation:{x:-0.1589731, y:-0.6890048, z:0.158973}, scale:{x:1, y:0.9999999, z:1}, file:'RigTail3Gizmo.fbx'},
        {name:'RigTail2Gizmo', position:{x:78.08906, y:3.690632, z:81.67091}, rotation:{x:-0.1268487, y:-0.695636, z:0.1268487}, scale:{x:0.9999999, y:1, z:1}, file:'RigTail2Gizmo.fbx'},
        {name:'RigRLegPlatform_1', position:{x:78.25949, y:2.999023, z:82.35925}, rotation:{x:0.4889005, y:-0.4889005, z:-0.5108584}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigRLegPlatform_1.fbx'},
        {name:'RigRLegPlatform', position:{x:78.2517, y:2.999023, z:81.69459}, rotation:{x:0.4981787, y:-0.4981787, z:-0.5018147}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLegPlatform.fbx'},
        {name:'RigRLegPalmGizmo_1', position:{x:78.23587, y:3.256749, z:81.59976}, rotation:{x:0.3753951, y:0.5603338, z:0.4680451}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigRLegPalmGizmo_1.fbx'},
        {name:'RigRLegPalmGizmo', position:{x:78.25946, y:3.105172, z:82.24893}, rotation:{x:0.2728069, y:0.6514624, z:0.2628601}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLegPalmGizmo.fbx'},
        {name:'RigRLeg2Gizmo_1', position:{x:78.24833, y:3.351262, z:82.20661}, rotation:{x:0.4649611, y:0.569722, z:0.4446055}, scale:{x:1.000001, y:0.9999985, z:0.9999999}, file:'RigRLeg2Gizmo_1.fbx'},
        {name:'RigRLeg2Gizmo', position:{x:78.24275, y:3.353918, z:81.68876}, rotation:{x:0.6885519, y:0.3358441, z:0.6009607}, scale:{x:1, y:1, z:1}, file:'RigRLeg2Gizmo.fbx'},
        {name:'RigRLeg1Gizmo_1', position:{x:78.22039, y:3.615497, z:81.70876}, rotation:{x:0.5350664, y:0.5465714, z:0.493681}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRLeg1Gizmo_1.fbx'},
        {name:'RigRLeg1Gizmo', position:{x:78.26029, y:3.556078, z:82.44267}, rotation:{x:0.6875911, y:0.2772123, z:0.635634}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigRLeg1Gizmo.fbx'},
        {name:'RigRibcageGizmo', position:{x:78.08906, y:3.683786, z:82.36775}, rotation:{x:0.7040229, y:-0.06596842, z:-0.7040228}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRibcageGizmo.fbx'},
        {name:'RigPelvisGizmo', position:{x:78.08906, y:3.685968, z:81.77043}, rotation:{x:0.6960415, y:-0.1246048, z:-0.6960413}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigTail6', position:{x:78.08906, y:3.570562, z:81.18735}, rotation:{x:0.178363, y:-0.6842417, z:-0.1783631}, scale:{x:1, y:1, z:1}, file:'RigTail6.fbx'},
        {name:'RigTail5', position:{x:78.08906, y:3.545038, z:81.26656}, rotation:{x:0.1097588, y:-0.6985364, z:-0.1097589}, scale:{x:1, y:1, z:1}, file:'RigTail5.fbx'},
        {name:'RigTail4', position:{x:78.08906, y:3.591858, z:81.44205}, rotation:{x:-0.09191881, y:-0.701107, z:0.09191877}, scale:{x:1, y:1, z:1}, file:'RigTail4.fbx'},
        {name:'RigTail3', position:{x:78.08906, y:3.646911, z:81.55501}, rotation:{x:-0.158973, y:-0.6890048, z:0.158973}, scale:{x:1, y:1, z:1}, file:'RigTail3.fbx'},
        {name:'RigTail2', position:{x:78.08906, y:3.690632, z:81.67091}, rotation:{x:-0.1268487, y:-0.695636, z:0.1268487}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigTail2.fbx'},
        {name:'RigTail1', position:{x:78.08906, y:3.68398, z:81.76969}, rotation:{x:0.02376616, y:-0.7067073, z:-0.02376621}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigTail1.fbx'},
        {name:'RigRLegDigit02_1', position:{x:78.25514, y:3.030207, z:82.40791}, rotation:{x:0.03794467, y:-0.7085945, z:-0.040165}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLegDigit02_1.fbx'},
        {name:'RigRLegDigit01_1', position:{x:78.25805, y:3.029005, z:82.3264}, rotation:{x:-0.00179045, y:-0.69432, z:0.01197048}, scale:{x:1, y:1, z:1}, file:'RigRLegDigit01_1.fbx'},
        {name:'RigRLegPalm_1', position:{x:78.25946, y:3.105172, z:82.24893}, rotation:{x:-0.2728064, y:-0.6514626, z:-0.2628596}, scale:{x:0.9999979, y:1.000001, z:1.000002}, file:'RigRLegPalm_1.fbx'},
        {name:'RigRLeg2_1', position:{x:78.24833, y:3.351262, z:82.20661}, rotation:{x:-0.464977, y:-0.5697359, z:-0.4445878}, scale:{x:1.000001, y:0.9999986, z:0.9999999}, file:'RigRLeg2_1.fbx'},
        {name:'RigRLeg1_1', position:{x:78.26029, y:3.556078, z:82.44267}, rotation:{x:-0.687591, y:-0.2772119, z:-0.6356341}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRLeg1_1.fbx'},
        {name:'RigRLegCollarbone', position:{x:78.10852, y:3.563372, z:82.47276}, rotation:{x:-0.09837016, y:-0.03295835, z:-0.9943945}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigRLegCollarbone.fbx'},
        {name:'DummyUpMouth', position:{x:78.08906, y:3.50052, z:82.88184}, rotation:{x:-0.1398767, y:-0.6931341, z:-0.1398765}, scale:{x:1.47288, y:0.4398545, z:0.9999999}, file:'DummyUpMouth.fbx'},
        {name:'DummyLowMouth', position:{x:78.08906, y:3.384799, z:82.78111}, rotation:{x:-0.2075865, y:-0.6759498, z:-0.2075863}, scale:{x:1.47288, y:0.4398545, z:0.9999999}, file:'DummyLowMouth.fbx'},
        {name:'RigHead', position:{x:78.08906, y:3.595356, z:82.75053}, rotation:{x:0.6821335, y:0.1862635, z:-0.6821334}, scale:{x:1, y:0.9999999, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck5', position:{x:78.08906, y:3.639673, z:82.68256}, rotation:{x:0.6778147, y:0.2014137, z:-0.6778145}, scale:{x:0.9999998, y:0.9999999, z:1}, file:'RigNeck5.fbx'},
        {name:'RigNeck4', position:{x:78.08906, y:3.672871, z:82.6085}, rotation:{x:0.6914659, y:0.1479025, z:-0.6914658}, scale:{x:1, y:1, z:1}, file:'RigNeck4.fbx'},
        {name:'RigNeck3', position:{x:78.08906, y:3.69091, z:82.52937}, rotation:{x:0.7026705, y:0.07908528, z:-0.7026703}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigNeck3.fbx'},
        {name:'RigNeck2', position:{x:78.08906, y:3.693872, z:82.44828}, rotation:{x:0.706989, y:0.01290952, z:-0.706989}, scale:{x:1, y:1, z:1}, file:'RigNeck2.fbx'},
        {name:'RigNeck1', position:{x:78.08906, y:3.683785, z:82.36775}, rotation:{x:0.7057347, y:-0.04402959, z:-0.7057346}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigNeck1.fbx'},
        {name:'RigLLegDigit02_1', position:{x:77.91615, y:3.030834, z:82.40697}, rotation:{x:-0.005325315, y:0.714429, z:-0.005586334}, scale:{x:1, y:1, z:1}, file:'RigLLegDigit02_1.fbx'},
        {name:'RigLLegDigit01_1', position:{x:77.91535, y:3.029004, z:82.32637}, rotation:{x:-0.01592016, y:0.7105839, z:-4.453212E-05}, scale:{x:1, y:1, z:1}, file:'RigLLegDigit01_1.fbx'},
        {name:'RigLLegPalm_1', position:{x:77.91211, y:3.10541, z:82.24918}, rotation:{x:0.2679993, y:0.6649662, z:0.2697295}, scale:{x:0.9999985, y:1.000002, z:1}, file:'RigLLegPalm_1.fbx'},
        {name:'RigLLeg2_1', position:{x:77.92502, y:3.351342, z:82.20647}, rotation:{x:0.4472523, y:0.5112122, z:0.4613414}, scale:{x:0.9999968, y:1.000003, z:1}, file:'RigLLeg2_1.fbx'},
        {name:'RigLLeg1_1', position:{x:77.9147, y:3.556078, z:82.44267}, rotation:{x:0.6368628, y:0.2131366, z:0.6863438}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLLeg1_1.fbx'},
        {name:'RigLLegCollarbone', position:{x:78.0696, y:3.563372, z:82.47276}, rotation:{x:0.9945908, y:0.02001803, z:0.09644717}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigLLegCollarbone.fbx'},
        {name:'RigRibcage', position:{x:78.08906, y:3.683785, z:82.36775}, rotation:{x:0.704023, y:-0.06596839, z:-0.7040229}, scale:{x:1, y:1, z:1}, file:'RigRibcage.fbx'},
        {name:'RigSpine6', position:{x:78.08906, y:3.670263, z:82.26864}, rotation:{x:0.7054825, y:-0.04790193, z:-0.7054824}, scale:{x:1, y:1, z:1}, file:'RigSpine6.fbx'},
        {name:'RigSpine5', position:{x:78.08906, y:3.663943, z:82.1688}, rotation:{x:0.7067537, y:-0.02234772, z:-0.7067536}, scale:{x:1, y:0.9999999, z:1}, file:'RigSpine5.fbx'},
        {name:'RigSpine4', position:{x:78.08906, y:3.675069, z:82.08576}, rotation:{x:0.7055392, y:0.04705842, z:-0.7055392}, scale:{x:0.9999998, y:0.9999998, z:0.9999999}, file:'RigSpine4.fbx'},
        {name:'RigSpine3', position:{x:78.08906, y:3.686662, z:81.96993}, rotation:{x:0.7062275, y:0.03525468, z:-0.7062274}, scale:{x:0.9999998, y:1, z:1}, file:'RigSpine3.fbx'},
        {name:'RigSpine2', position:{x:78.08906, y:3.693322, z:81.8702}, rotation:{x:0.706714, y:0.02356722, z:-0.7067138}, scale:{x:1, y:1, z:1}, file:'RigSpine2.fbx'},
        {name:'RigSpine1', position:{x:78.08906, y:3.685968, z:81.77043}, rotation:{x:0.7066284, y:-0.02600864, z:-0.7066283}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigSpine1.fbx'},
        {name:'RigRLegDigit02', position:{x:78.24889, y:3.034217, z:81.77915}, rotation:{x:-0.004983648, y:0.7012779, z:0.007562384}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigRLegDigit02.fbx'},
        {name:'RigRLegDigit01', position:{x:78.25151, y:3.030743, z:81.66802}, rotation:{x:-0.008047592, y:0.6985784, z:-0.0139759}, scale:{x:0.9999997, y:1, z:0.9999999}, file:'RigRLegDigit01.fbx'},
        {name:'RigRLegPalm', position:{x:78.23587, y:3.256749, z:81.59976}, rotation:{x:0.3753939, y:0.560335, z:0.4680439}, scale:{x:0.9999974, y:1.000002, z:0.9999998}, file:'RigRLegPalm.fbx'},
        {name:'RigRLeg2', position:{x:78.24275, y:3.353918, z:81.68876}, rotation:{x:0.688552, y:0.3358442, z:0.6009606}, scale:{x:1, y:1, z:1}, file:'RigRLeg2.fbx'},
        {name:'RigRLeg1', position:{x:78.22039, y:3.615497, z:81.70876}, rotation:{x:0.5350664, y:0.5465714, z:0.4936811}, scale:{x:1, y:0.9999999, z:1}, file:'RigRLeg1.fbx'},
        {name:'RigLLegDigit02', position:{x:77.92168, y:3.032297, z:81.77881}, rotation:{x:-0.01120621, y:0.7073312, z:-0.001600951}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLLegDigit02.fbx'},
        {name:'RigLLegDigit01', position:{x:77.92306, y:3.030742, z:81.66802}, rotation:{x:-0.006963097, y:0.7026938, z:-0.002982482}, scale:{x:0.9999999, y:1, z:1}, file:'RigLLegDigit01.fbx'},
        {name:'RigLLegPalm', position:{x:77.94431, y:3.256583, z:81.60074}, rotation:{x:0.4806279, y:0.5671622, z:0.3652841}, scale:{x:0.9999989, y:1.000002, z:0.9999993}, file:'RigLLegPalm.fbx'},
        {name:'RigLLeg2', position:{x:77.94046, y:3.353222, z:81.69048}, rotation:{x:0.609803, y:0.2239428, z:0.6814875}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLLeg2.fbx'},
        {name:'RigLLeg1', position:{x:77.95461, y:3.615497, z:81.70876}, rotation:{x:0.4846811, y:0.4226074, z:0.5427068}, scale:{x:1, y:1, z:1}, file:'RigLLeg1.fbx'},
        {name:'RigPelvis', position:{x:78.08906, y:3.685968, z:81.77043}, rotation:{x:0.6960415, y:-0.1246048, z:-0.6960413}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigLLegPlatform_1', position:{x:77.92329, y:2.999023, z:81.69459}, rotation:{x:0.4977681, y:-0.4977681, z:-0.502222}, scale:{x:1, y:1, z:0.9999999}, file:'RigLLegPlatform_1.fbx'},
        {name:'RigLLegPlatform', position:{x:77.9155, y:2.999023, z:82.35925}, rotation:{x:0.4988438, y:-0.4988438, z:-0.5011535}, scale:{x:1, y:1, z:1}, file:'RigLLegPlatform.fbx'},
        {name:'RigLLegPalmGizmo_1', position:{x:77.91211, y:3.10541, z:82.24918}, rotation:{x:0.2679986, y:0.6649664, z:0.2697287}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLLegPalmGizmo_1.fbx'},
        {name:'RigLLegPalmGizmo', position:{x:77.94431, y:3.256583, z:81.60074}, rotation:{x:0.4806286, y:0.5671619, z:0.3652848}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigLLegPalmGizmo.fbx'},
        {name:'RigLLeg2Gizmo_1', position:{x:77.92502, y:3.351342, z:82.20647}, rotation:{x:0.447257, y:0.5112165, z:0.4613368}, scale:{x:0.9999963, y:1.000003, z:1}, file:'RigLLeg2Gizmo_1.fbx'},
        {name:'RigLLeg2Gizmo', position:{x:77.94046, y:3.353222, z:81.69048}, rotation:{x:0.6098043, y:0.2239454, z:0.6814867}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLLeg2Gizmo.fbx'},
        {name:'RigLLeg1Gizmo_1', position:{x:77.95461, y:3.615497, z:81.70876}, rotation:{x:0.4846816, y:0.4226078, z:0.5427065}, scale:{x:1, y:1, z:1}, file:'RigLLeg1Gizmo_1.fbx'},
        {name:'RigLLeg1Gizmo', position:{x:77.9147, y:3.556078, z:82.44267}, rotation:{x:0.6368627, y:0.2131365, z:0.6863437}, scale:{x:0.9999999, y:0.9999999, z:0.9999999}, file:'RigLLeg1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:78.08906, y:3.595356, z:82.75053}, rotation:{x:0.6821334, y:0.1862635, z:-0.6821333}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'Rig', position:{x:78.08906, y:2.999023, z:82.09513}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Forest_Wolf', position:{x:78.08906, y:2.999023, z:82.09513}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Forest_Wolf.fbx'},
        {name:'Forest_Wolf___Purple', position:{x:78.08906, y:2.999023, z:82.09513}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Forest_Wolf___Purple.fbx'},
        {name:'Wagon_Tracks1', position:{x:92.48, y:3.4, z:84.41}, rotation:{x:0, y:0.3785092, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_Tracks1.fbx'},
        {name:'Rock_03', position:{x:73.52732, y:3.463642, z:96.78095}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Rock_17A', position:{x:68.68197, y:3.405359, z:95.77098}, rotation:{x:0, y:0.5032104, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_17A.fbx'},
        {name:'Tree_Pine_1', position:{x:64.91948, y:3.429401, z:57.58984}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Rock_10', position:{x:70.21808, y:3.207552, z:101.7316}, rotation:{x:0, y:-0.9979947, z:0}, scale:{x:0.7431519, y:0.7431519, z:0.7431519}, file:'Rock_10.fbx'},
        {name:'Point_light', position:{x:77.77122, y:4.946576, z:90.76752}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Point_light.fbx'},
        {name:'Fire2', position:{x:77.77122, y:4.660012, z:90.76752}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire2.fbx'},
        {name:'Fire_long', position:{x:77.77122, y:5.732855, z:90.74911}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire_long.fbx'},
        {name:'Fire_sparks', position:{x:77.77122, y:4.932202, z:90.76752}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire_sparks.fbx'},
        {name:'Fire2', position:{x:77.77122, y:4.780843, z:90.76752}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire2.fbx'},
        {name:'Fire_and_Light', position:{x:77.77122, y:4.780843, z:90.76752}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire_and_Light.fbx'},
        {name:'Bush_08', position:{x:67.73424, y:3.429497, z:89.27132}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_08.fbx'},
        {name:'Rock_14C', position:{x:77.16827, y:3.405359, z:92.4164}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_14C.fbx'},
        {name:'Sparks', position:{x:67.51637, y:8.029192, z:88.42197}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Sparks.fbx'},
        {name:'Ground_Decal_Leaves1', position:{x:62.62725, y:3.405359, z:102.7458}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_Leaves1.fbx'},
        {name:'Stair_el2', position:{x:78.60236, y:2.89942, z:82.73545}, rotation:{x:0, y:-0.3191732, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el2.fbx'},
        {name:'Block4', position:{x:69.45266, y:5.100206, z:102.5291}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Block4.fbx'},
        {name:'Tree_Pine_1', position:{x:67.8158, y:3.405353, z:99.80107}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Banner', position:{x:83.91, y:3.41, z:84.73}, rotation:{x:0, y:0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Banner.fbx'},
        {name:'Wall_wood_el3', position:{x:63.53021, y:3.405287, z:90.66457}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Wall_wood_el3.fbx'},
        {name:'Stair_el6', position:{x:91.77, y:3.51, z:108.86}, rotation:{x:0, y:-0.3598194, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el6.fbx'},
        {name:'Ground_Decal_grass1', position:{x:62.63992, y:3.405359, z:97.43421}, rotation:{x:0, y:0.4185852, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_grass1.fbx'},
        {name:'Column3B', position:{x:73.54203, y:5.489035, z:105.7327}, rotation:{x:0, y:-0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Column3B.fbx'},
        {name:'Wall_Stone_el2', position:{x:94.508, y:3.52, z:71.364}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Wall_Stone_el2.fbx'},
        {name:'Wagon_tracks2', position:{x:92.48, y:3.4, z:84.41}, rotation:{x:0, y:0.9255975, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_tracks2.fbx'},
        {name:'Stair_el1', position:{x:63.81, y:3.9, z:79.04}, rotation:{x:0, y:0.6932201, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el1.fbx'},
        {name:'Bush_01', position:{x:72.79359, y:5.51716, z:100.1608}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_01.fbx'},
        {name:'Bush_07', position:{x:60.68, y:5.42368, z:85.71}, rotation:{x:0, y:-0.5389422, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_07.fbx'},
        {name:'Stair_el2', position:{x:62.01, y:5.2, z:78.96}, rotation:{x:0, y:-0.7207258, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el2.fbx'},
        {name:'Rock_13', position:{x:76.55, y:2.51, z:74.29}, rotation:{x:0, y:-0.754504, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_13.fbx'},
        {name:'Ground_Decal1', position:{x:85.31326, y:3.405968, z:80.96391}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal1.fbx'},
        {name:'Bush_01', position:{x:69.09753, y:3.42911, z:96.77408}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_01.fbx'},
        {name:'Fence3', position:{x:66.56114, y:3.405359, z:85.43367}, rotation:{x:0, y:-0.4321901, z:0}, scale:{x:1, y:1, z:1}, file:'Fence3.fbx'},
        {name:'RigPelvisGizmo', position:{x:73.78391, y:3.645562, z:92.53348}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigEarR', position:{x:73.68185, y:4.328616, z:92.71023}, rotation:{x:0.7069825, y:-0.618893, z:0.0132564}, scale:{x:1, y:1, z:1}, file:'RigEarR.fbx'},
        {name:'RigEarL', position:{x:73.88596, y:4.328616, z:92.35673}, rotation:{x:0.6188929, y:-0.7069826, z:0.3420111}, scale:{x:1, y:1, z:1}, file:'RigEarL.fbx'},
        {name:'RigHead', position:{x:73.78391, y:3.981192, z:92.53348}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck', position:{x:73.78391, y:3.645562, z:92.53348}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigNeck.fbx'},
        {name:'RigPelvis_1', position:{x:73.78391, y:3.645562, z:92.53348}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigPelvis_1.fbx'},
        {name:'RigTail1', position:{x:74.22529, y:3.710772, z:92.77998}, rotation:{x:-0.04251982, y:-0.9649895, z:-0.01139306}, scale:{x:1, y:1, z:1}, file:'RigTail1.fbx'},
        {name:'RigREar03', position:{x:73.57248, y:4.492074, z:92.73593}, rotation:{x:0.6732888, y:-0.7130247, z:-0.1215567}, scale:{x:1, y:1, z:0.9999999}, file:'RigREar03.fbx'},
        {name:'RigREar02', position:{x:73.56977, y:4.402132, z:92.70429}, rotation:{x:0.6840928, y:-0.7094903, z:-0.1039061}, scale:{x:1, y:1, z:1}, file:'RigREar02.fbx'},
        {name:'RigREar01', position:{x:73.589, y:4.315773, z:92.66865}, rotation:{x:0.7382273, y:-0.6297406, z:-0.05163962}, scale:{x:1, y:1, z:1}, file:'RigREar01.fbx'},
        {name:'RigNose', position:{x:73.20232, y:3.928979, z:92.18938}, rotation:{x:0.4694051, y:-0.8441988, z:0.1257769}, scale:{x:1, y:1, z:1}, file:'RigNose.fbx'},
        {name:'RigLEar03', position:{x:73.86073, y:4.492074, z:92.23666}, rotation:{x:0.5223066, y:-0.6941491, z:0.4419159}, scale:{x:1, y:1, z:0.9999999}, file:'RigLEar03.fbx'},
        {name:'RigLEar02', position:{x:73.83198, y:4.402132, z:92.25014}, rotation:{x:0.5404884, y:-0.6812279, z:0.432032}, scale:{x:1, y:0.9999999, z:0.9999999}, file:'RigLEar02.fbx'},
        {name:'RigLEar01', position:{x:73.81072, y:4.315773, z:92.28461}, rotation:{x:0.6135036, y:-0.663461, z:0.4138352}, scale:{x:1, y:1, z:1}, file:'RigLEar01.fbx'},
        {name:'RigHead_1', position:{x:73.69986, y:4.07596, z:92.47663}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830129}, scale:{x:1, y:1, z:1}, file:'RigHead_1.fbx'},
        {name:'RigNeck2', position:{x:73.69986, y:3.824838, z:92.47663}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830129}, scale:{x:1, y:1, z:1}, file:'RigNeck2.fbx'},
        {name:'RigNeck1', position:{x:73.69986, y:3.573716, z:92.47663}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830129}, scale:{x:1, y:1, z:1}, file:'RigNeck1.fbx'},
        {name:'RigPelvis', position:{x:73.69986, y:3.573716, z:92.47663}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830129}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigHeadGizmo', position:{x:73.78391, y:3.981192, z:92.53348}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'RigEarRGizmo', position:{x:73.68185, y:4.328616, z:92.71023}, rotation:{x:-0.7069825, y:0.618893, z:-0.01325634}, scale:{x:1, y:1, z:1}, file:'RigEarRGizmo.fbx'},
        {name:'RigEarLGizmo', position:{x:73.88596, y:4.328616, z:92.35673}, rotation:{x:0.6188928, y:-0.7069826, z:0.342011}, scale:{x:1, y:1, z:1}, file:'RigEarLGizmo.fbx'},
        {name:'Rig', position:{x:73.78391, y:3.405357, z:92.53348}, rotation:{x:0.6830127, y:-0.6830127, z:0.1830128}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Forest_Bunny', position:{x:73.78391, y:3.405357, z:92.53348}, rotation:{x:-0.3535534, y:-0.6123725, z:-0.6123725}, scale:{x:1, y:1, z:1}, file:'Forest_Bunny.fbx'},
        {name:'Forest_Bunny_Black', position:{x:73.78391, y:3.405357, z:92.53348}, rotation:{x:0, y:-0.8660254, z:0}, scale:{x:2, y:2, z:2}, file:'Forest_Bunny_Black.fbx'},
        {name:'Bush_02', position:{x:65.1101, y:3.429228, z:88.53404}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_02.fbx'},
        {name:'Stair_el3', position:{x:77.71207, y:2.339292, z:88.14812}, rotation:{x:0, y:-0.3214427, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el3.fbx'},
        {name:'Barrel', position:{x:73.90192, y:3.709204, z:76.2498}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Barrel.fbx'},
        {name:'Turret', position:{x:73.9, y:3.438644, z:75.44}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Turret.fbx'},
        {name:'Base', position:{x:73.9, y:3.16, z:75.44}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Base.fbx'},
        {name:'Tow_Laser3', position:{x:73.9, y:3.16, z:75.44}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tow_Laser3.fbx'},
        {name:'Tree_Fall3', position:{x:68.86804, y:3.356456, z:83.11738}, rotation:{x:-0.02375933, y:-0.9440474, z:0.06930377}, scale:{x:1, y:1, z:1}, file:'Tree_Fall3.fbx'},
        {name:'Plant_07', position:{x:74.34441, y:2.371254, z:77.39462}, rotation:{x:0, y:0.9039797, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_07.fbx'},
        {name:'Fence2', position:{x:66.56401, y:3.405359, z:103.4747}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Fence2.fbx'},
        {name:'Ivy2', position:{x:82.7, y:5.73, z:72.14}, rotation:{x:0, y:-0.9085782, z:0}, scale:{x:1.112361, y:1.112361, z:1.112361}, file:'Ivy2.fbx'},
        {name:'Tree_Pine_1', position:{x:76.51418, y:5.491668, z:98.82595}, rotation:{x:0, y:-0.772687, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Plant_06', position:{x:87.22919, y:1.981241, z:93.04785}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_06.fbx'},
        {name:'Crystal3', position:{x:83.23209, y:3.475192, z:93.03765}, rotation:{x:-0.2185266, y:-0.2025885, z:-0.1708681}, scale:{x:1, y:1, z:1}, file:'Crystal3.fbx'},
        {name:'Crystal5', position:{x:73.35199, y:3.436316, z:94.52414}, rotation:{x:-0.007930059, y:-0.9740809, z:-0.03457695}, scale:{x:0.5454153, y:0.5454153, z:0.5454152}, file:'Crystal5.fbx'},
        {name:'Bush_04', position:{x:66.75012, y:3.429039, z:92.89645}, rotation:{x:0, y:-0.8230845, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_04.fbx'},
        {name:'Crystal1', position:{x:83.77709, y:4.257504, z:94.00532}, rotation:{x:-0.1741552, y:0.3433264, z:-0.4521536}, scale:{x:1, y:1, z:1}, file:'Crystal1.fbx'},
        {name:'Rock_14B', position:{x:57.29719, y:3.336652, z:106.8643}, rotation:{x:-0.01379229, y:-0.434033, z:0.2327827}, scale:{x:1, y:1, z:1}, file:'Rock_14B.fbx'},
        {name:'RigRLegPlatform', position:{x:65.36843, y:6.207243, z:92.67129}, rotation:{x:0.2705981, y:-0.2705981, z:-0.6532815}, scale:{x:1, y:1, z:1}, file:'RigRLegPlatform.fbx'},
        {name:'RigRibcageGizmo', position:{x:65.28123, y:6.553695, z:92.75848}, rotation:{x:0.2705981, y:-0.2705981, z:-0.6532815}, scale:{x:1, y:1, z:1}, file:'RigRibcageGizmo.fbx'},
        {name:'RigRArmPalmGizmo', position:{x:65.57967, y:6.612725, z:92.45424}, rotation:{x:0.2688312, y:0.6593232, z:0.6513277}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRArmPalmGizmo.fbx'},
        {name:'RigRArm2Gizmo', position:{x:65.50461, y:6.612322, z:92.53159}, rotation:{x:0.2723191, y:0.6553192, z:0.6471066}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRArm2Gizmo.fbx'},
        {name:'RigRArm1Gizmo', position:{x:65.42793, y:6.614052, z:92.61179}, rotation:{x:0.283224, y:0.6517546, z:0.6486139}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArm1Gizmo.fbx'},
        {name:'RigPelvisGizmo', position:{x:65.28123, y:6.423692, z:92.75848}, rotation:{x:0.2705981, y:-0.2705981, z:-0.6532815}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigRArmDigit12', position:{x:65.67146, y:6.611628, z:92.3643}, rotation:{x:-0.2688358, y:-0.659322, z:-0.6513266}, scale:{x:0.9999998, y:1, z:0.9999999}, file:'RigRArmDigit12.fbx'},
        {name:'RigRArmDigit11', position:{x:65.6262, y:6.611894, z:92.40912}, rotation:{x:-0.2693974, y:-0.6567723, z:-0.6511281}, scale:{x:0.9999998, y:0.9999999, z:1}, file:'RigRArmDigit11.fbx'},
        {name:'RigRArmPalm', position:{x:65.57967, y:6.612725, z:92.45424}, rotation:{x:-0.2688313, y:-0.6593233, z:-0.6513277}, scale:{x:0.9999998, y:0.9999999, z:1}, file:'RigRArmPalm.fbx'},
        {name:'RigRArm2', position:{x:65.50461, y:6.612322, z:92.53159}, rotation:{x:-0.2723191, y:-0.6553192, z:-0.6471068}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRArm2.fbx'},
        {name:'RigRArm1', position:{x:65.42793, y:6.614052, z:92.61179}, rotation:{x:-0.283224, y:-0.6517546, z:-0.648614}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArm1.fbx'},
        {name:'RigRArmCollarbone', position:{x:65.43688, y:6.594428, z:92.79511}, rotation:{x:-0.4506994, y:-0.4910611, z:-0.4843653}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmCollarbone.fbx'},
        {name:'RigMouth', position:{x:65.35589, y:6.626184, z:92.83314}, rotation:{x:0.3826779, y:-0.002056984, z:-0.9238663}, scale:{x:1, y:1, z:1}, file:'RigMouth.fbx'},
        {name:'RigHead', position:{x:65.28123, y:6.776262, z:92.75848}, rotation:{x:0.2705981, y:-0.2705981, z:-0.6532815}, scale:{x:1, y:1, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck', position:{x:65.28123, y:6.553695, z:92.75848}, rotation:{x:0.2705981, y:-0.2705981, z:-0.6532815}, scale:{x:1, y:1, z:1}, file:'RigNeck.fbx'},
        {name:'RigLArmDigit12', position:{x:64.88705, y:6.611629, z:93.14871}, rotation:{x:0.6506472, y:0.2807488, z:-0.2704721}, scale:{x:0.9999998, y:1, z:0.9999998}, file:'RigLArmDigit12.fbx'},
        {name:'RigLArmDigit11', position:{x:64.93187, y:6.611895, z:93.10345}, rotation:{x:0.6509063, y:0.2745318, z:-0.2699305}, scale:{x:0.9999998, y:1, z:0.9999998}, file:'RigLArmDigit11.fbx'},
        {name:'RigLArmPalm', position:{x:64.97699, y:6.612725, z:93.05692}, rotation:{x:0.6506496, y:0.280747, z:-0.270468}, scale:{x:0.9999998, y:1, z:0.9999999}, file:'RigLArmPalm.fbx'},
        {name:'RigLArm2', position:{x:65.05434, y:6.612322, z:92.98186}, rotation:{x:0.6501322, y:0.2663369, z:-0.2650148}, scale:{x:1, y:1, z:1}, file:'RigLArm2.fbx'},
        {name:'RigLArm1', position:{x:65.13454, y:6.614052, z:92.90517}, rotation:{x:0.658909, y:0.2681164, z:-0.2583697}, scale:{x:1, y:1, z:1}, file:'RigLArm1.fbx'},
        {name:'RigLArmCollarbone', position:{x:65.31786, y:6.594428, z:92.91413}, rotation:{x:0.6611905, y:-0.05346659, z:-0.02380517}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArmCollarbone.fbx'},
        {name:'RigRibcage', position:{x:65.28123, y:6.553695, z:92.75848}, rotation:{x:0.2705981, y:-0.2705981, z:-0.6532815}, scale:{x:1, y:1, z:1}, file:'RigRibcage.fbx'},
        {name:'RigSpineUp', position:{x:65.28123, y:6.488693, z:92.75848}, rotation:{x:0.2705981, y:-0.2705981, z:-0.6532815}, scale:{x:1, y:1, z:1}, file:'RigSpineUp.fbx'},
        {name:'RigSpineLow', position:{x:65.28123, y:6.423692, z:92.75848}, rotation:{x:0.2705981, y:-0.2705981, z:-0.6532815}, scale:{x:1, y:1, z:1}, file:'RigSpineLow.fbx'},
        {name:'RigRLegAnkle', position:{x:65.37045, y:6.222054, z:92.67332}, rotation:{x:0.4715471, y:0.7944711, z:0.1953007}, scale:{x:0.9999998, y:1, z:1}, file:'RigRLegAnkle.fbx'},
        {name:'RigRLeg2', position:{x:65.37325, y:6.288188, z:92.67554}, rotation:{x:0.6761155, y:0.6397316, z:0.26395}, scale:{x:1, y:0.9999999, z:1}, file:'RigRLeg2.fbx'},
        {name:'RigRLeg1', position:{x:65.36907, y:6.346486, z:92.67065}, rotation:{x:0.6182237, y:0.6872585, z:0.2514145}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLeg1.fbx'},
        {name:'RigLLegAnkle', position:{x:65.19608, y:6.222054, z:92.8477}, rotation:{x:0.4716708, y:0.794412, z:0.1953893}, scale:{x:0.9999998, y:1, z:1}, file:'RigLLegAnkle.fbx'},
        {name:'RigLLeg2', position:{x:65.19829, y:6.288188, z:92.85049}, rotation:{x:0.6647407, y:0.6311562, z:0.2914063}, scale:{x:1, y:0.9999999, z:0.9999996}, file:'RigLLeg2.fbx'},
        {name:'RigLLeg1', position:{x:65.1934, y:6.346486, z:92.84632}, rotation:{x:0.6149144, y:0.6887896, z:0.2594091}, scale:{x:1, y:1, z:1}, file:'RigLLeg1.fbx'},
        {name:'RigPelvis', position:{x:65.28123, y:6.423692, z:92.75848}, rotation:{x:0.2705981, y:-0.2705981, z:-0.6532815}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigMouthGizmo', position:{x:65.35589, y:6.626184, z:92.83314}, rotation:{x:-0.3826779, y:0.002057019, z:0.9238663}, scale:{x:1, y:1, z:1}, file:'RigMouthGizmo.fbx'},
        {name:'RigLLegPlatform', position:{x:65.19404, y:6.207243, z:92.84568}, rotation:{x:0.2705981, y:-0.2705981, z:-0.6532815}, scale:{x:1, y:1, z:1}, file:'RigLLegPlatform.fbx'},
        {name:'RigLArmPalmGizmo', position:{x:64.97699, y:6.612725, z:93.05692}, rotation:{x:0.6506495, y:0.2807471, z:-0.2704679}, scale:{x:0.9999999, y:1, z:1}, file:'RigLArmPalmGizmo.fbx'},
        {name:'RigLArm2Gizmo', position:{x:65.05434, y:6.612322, z:92.98186}, rotation:{x:0.6501321, y:0.2663369, z:-0.2650148}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLArm2Gizmo.fbx'},
        {name:'RigLArm1Gizmo', position:{x:65.13454, y:6.614052, z:92.90517}, rotation:{x:0.6589089, y:0.2681164, z:-0.2583697}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArm1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:65.28123, y:6.776262, z:92.75848}, rotation:{x:0.2705981, y:-0.2705981, z:-0.6532815}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'Rig', position:{x:65.28123, y:6.207243, z:92.75848}, rotation:{x:0.2705981, y:-0.2705981, z:-0.6532815}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Evil_Mushroom', position:{x:65.28123, y:6.207243, z:92.75848}, rotation:{x:-0.6532816, y:0.2705981, z:0.2705981}, scale:{x:1, y:1, z:1}, file:'Evil_Mushroom.fbx'},
        {name:'Evil_Mushroom_Red', position:{x:65.28123, y:6.207243, z:92.75848}, rotation:{x:0, y:0.3826834, z:0}, scale:{x:1, y:1, z:1}, file:'Evil_Mushroom_Red.fbx'},
        {name:'Tree_Pine_1', position:{x:60.07669, y:5.48915, z:90.63803}, rotation:{x:0, y:0.9849479, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Stair_el4', position:{x:68.68573, y:3.559257, z:105.502}, rotation:{x:0, y:-0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el4.fbx'},
        {name:'Stair_el2', position:{x:76.48538, y:3.326717, z:89.58229}, rotation:{x:0, y:0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el2.fbx'},
        {name:'Plant_07', position:{x:73.87424, y:1.998379, z:83.47312}, rotation:{x:0, y:-0.993823, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_07.fbx'},
        {name:'Crystal5', position:{x:84.97228, y:3.814866, z:92.12214}, rotation:{x:0, y:0, z:0}, scale:{x:0.5454153, y:0.5454153, z:0.5454153}, file:'Crystal5.fbx'},
        {name:'Wall_Stone_el2', position:{x:94.45, y:3.52, z:75.08}, rotation:{x:0, y:0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Wall_Stone_el2.fbx'},
        {name:'Ivy1', position:{x:93.136, y:6.209, z:72.401}, rotation:{x:0, y:0.920302, z:0}, scale:{x:1, y:1, z:1}, file:'Ivy1.fbx'},
        {name:'Stair_el7', position:{x:88.96, y:4.35, z:109.59}, rotation:{x:0, y:-0.4543204, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el7.fbx'},
        {name:'Tree_Pine_3', position:{x:71.27399, y:1.995462, z:81.01698}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_3.fbx'},
        {name:'Bush_02', position:{x:70.04202, y:3.429039, z:85.60592}, rotation:{x:0, y:-0.4650975, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_02.fbx'},
        {name:'RigRLegPlatform', position:{x:66.2422, y:6.207243, z:91.64024}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'RigRLegPlatform.fbx'},
        {name:'RigRibcageGizmo', position:{x:66.11889, y:6.553695, z:91.64024}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'RigRibcageGizmo.fbx'},
        {name:'RigRArmPalmGizmo', position:{x:66.54504, y:6.612725, z:91.63612}, rotation:{x:-0.0008847262, y:0.7095084, z:0.7046256}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRArmPalmGizmo.fbx'},
        {name:'RigRArm2Gizmo', position:{x:66.43728, y:6.612322, z:91.63774}, rotation:{x:0.003953096, y:0.7120751, z:0.7020606}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRArm2Gizmo.fbx'},
        {name:'RigRArm1Gizmo', position:{x:66.32634, y:6.614052, z:91.64024}, rotation:{x:0.01345105, y:0.7064548, z:0.7076263}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArm1Gizmo.fbx'},
        {name:'RigPelvisGizmo', position:{x:66.11889, y:6.423692, z:91.64024}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'RigPelvisGizmo.fbx'},
        {name:'RigRArmDigit12', position:{x:66.67355, y:6.611628, z:91.63744}, rotation:{x:0.0008800328, y:-0.7095078, z:-0.7046263}, scale:{x:0.9999998, y:1, z:0.9999999}, file:'RigRArmDigit12.fbx'},
        {name:'RigRArmDigit11', position:{x:66.60985, y:6.611894, z:91.63712}, rotation:{x:0.0002851784, y:-0.7095401, z:-0.7046578}, scale:{x:0.9999998, y:0.9999999, z:1}, file:'RigRArmDigit11.fbx'},
        {name:'RigRArmPalm', position:{x:66.54504, y:6.612725, z:91.63612}, rotation:{x:0.0008846223, y:-0.7095084, z:-0.7046256}, scale:{x:0.9999998, y:0.9999999, z:1}, file:'RigRArmPalm.fbx'},
        {name:'RigRArm2', position:{x:66.43728, y:6.612322, z:91.63774}, rotation:{x:-0.003953069, y:-0.7120751, z:-0.7020607}, scale:{x:0.9999999, y:0.9999999, z:1}, file:'RigRArm2.fbx'},
        {name:'RigRArm1', position:{x:66.32634, y:6.614052, z:91.64024}, rotation:{x:-0.01345104, y:-0.7064548, z:-0.7076263}, scale:{x:1, y:0.9999999, z:1}, file:'RigRArm1.fbx'},
        {name:'RigRArmCollarbone', position:{x:66.20304, y:6.594428, z:91.77619}, rotation:{x:-0.2310334, y:-0.670538, z:-0.6199703}, scale:{x:1, y:1, z:0.9999999}, file:'RigRArmCollarbone.fbx'},
        {name:'RigMouth', position:{x:66.11889, y:6.626184, z:91.74581}, rotation:{x:0.7070966, y:-0.00380072, z:-0.7070966}, scale:{x:1, y:1, z:1}, file:'RigMouth.fbx'},
        {name:'RigHead', position:{x:66.11889, y:6.776262, z:91.64024}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'RigHead.fbx'},
        {name:'RigNeck', position:{x:66.11889, y:6.553695, z:91.64024}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'RigNeck.fbx'},
        {name:'RigLArmDigit12', position:{x:65.56422, y:6.611629, z:91.63744}, rotation:{x:0.7046248, y:0.009992003, z:-0.0008917451}, scale:{x:0.9999998, y:1, z:0.9999998}, file:'RigLArmDigit12.fbx'},
        {name:'RigLArmDigit11', position:{x:65.62793, y:6.611895, z:91.63712}, rotation:{x:0.7046568, y:0.003249586, z:-0.000292182}, scale:{x:0.9999998, y:1, z:0.9999998}, file:'RigLArmDigit11.fbx'},
        {name:'RigLArmPalm', position:{x:65.69273, y:6.612725, z:91.63612}, rotation:{x:0.7046254, y:0.009990215, z:-0.0008870363}, scale:{x:0.9999998, y:1, z:0.9999999}, file:'RigLArmPalm.fbx'},
        {name:'RigLArm2', position:{x:65.8005, y:6.612322, z:91.63774}, rotation:{x:0.7020606, y:-0.006670237, z:0.003953099}, scale:{x:1, y:1, z:1}, file:'RigLArm2.fbx'},
        {name:'RigLArm1', position:{x:65.91144, y:6.614052, z:91.64024}, rotation:{x:0.7076263, y:-0.002416074, z:0.0134511}, scale:{x:1, y:1, z:1}, file:'RigLArm1.fbx'},
        {name:'RigLArmCollarbone', position:{x:66.03474, y:6.594428, z:91.77619}, rotation:{x:0.6199702, y:-0.3356177, z:0.2310335}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArmCollarbone.fbx'},
        {name:'RigRibcage', position:{x:66.11889, y:6.553695, z:91.64024}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'RigRibcage.fbx'},
        {name:'RigSpineUp', position:{x:66.11889, y:6.488693, z:91.64024}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'RigSpineUp.fbx'},
        {name:'RigSpineLow', position:{x:66.11889, y:6.423692, z:91.64024}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'RigSpineLow.fbx'},
        {name:'RigRLegAnkle', position:{x:66.2422, y:6.222054, z:91.6431}, rotation:{x:0.3609143, y:0.6080486, z:0.3608876}, scale:{x:0.9999998, y:1, z:1}, file:'RigRLegAnkle.fbx'},
        {name:'RigRLeg2', position:{x:66.24261, y:6.288188, z:91.64664}, rotation:{x:0.52364, y:0.4942668, z:0.5025962}, scale:{x:1, y:0.9999999, z:1}, file:'RigRLeg2.fbx'},
        {name:'RigRLeg1', position:{x:66.24311, y:6.346486, z:91.64024}, rotation:{x:0.4749521, y:0.5251825, z:0.4688606}, scale:{x:1, y:1, z:0.9999999}, file:'RigRLeg1.fbx'},
        {name:'RigLLegAnkle', position:{x:65.99558, y:6.222054, z:91.6431}, rotation:{x:0.3609948, y:0.6080275, z:0.3610168}, scale:{x:0.9999998, y:1, z:1}, file:'RigLLegAnkle.fbx'},
        {name:'RigLLeg2', position:{x:65.99518, y:6.288188, z:91.64664}, rotation:{x:0.5026239, y:0.4784192, z:0.5236096}, scale:{x:1, y:0.9999999, z:0.9999996}, file:'RigLLeg2.fbx'},
        {name:'RigLLeg1', position:{x:65.99467, y:6.346486, z:91.64024}, rotation:{x:0.4688352, y:0.5280137, z:0.4749803}, scale:{x:1, y:1, z:1}, file:'RigLLeg1.fbx'},
        {name:'RigPelvis', position:{x:66.11889, y:6.423692, z:91.64024}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'RigPelvis.fbx'},
        {name:'RigMouthGizmo', position:{x:66.11889, y:6.626184, z:91.74581}, rotation:{x:-0.7070966, y:0.003800777, z:0.7070966}, scale:{x:1, y:1, z:1}, file:'RigMouthGizmo.fbx'},
        {name:'RigLLegPlatform', position:{x:65.99557, y:6.207243, z:91.64024}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'RigLLegPlatform.fbx'},
        {name:'RigLArmPalmGizmo', position:{x:65.69273, y:6.612725, z:91.63612}, rotation:{x:0.7046253, y:0.009990316, z:-0.000887003}, scale:{x:0.9999999, y:1, z:1}, file:'RigLArmPalmGizmo.fbx'},
        {name:'RigLArm2Gizmo', position:{x:65.8005, y:6.612322, z:91.63774}, rotation:{x:0.7020605, y:-0.006670165, z:0.003953065}, scale:{x:0.9999999, y:1, z:0.9999999}, file:'RigLArm2Gizmo.fbx'},
        {name:'RigLArm1Gizmo', position:{x:65.91144, y:6.614052, z:91.64024}, rotation:{x:0.7076262, y:-0.002416019, z:0.01345109}, scale:{x:1, y:0.9999999, z:1}, file:'RigLArm1Gizmo.fbx'},
        {name:'RigHeadGizmo', position:{x:66.11889, y:6.776262, z:91.64024}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'RigHeadGizmo.fbx'},
        {name:'Rig', position:{x:66.11889, y:6.207243, z:91.64024}, rotation:{x:0.5000001, y:-0.5000001, z:-0.5}, scale:{x:1, y:1, z:1}, file:'Rig.fbx'},
        {name:'Evil_Mushroom', position:{x:66.11889, y:6.207243, z:91.64024}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Evil_Mushroom.fbx'},
        {name:'Evil_Mushroom_Green_(1)', position:{x:66.11889, y:6.207243, z:91.64024}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Evil_Mushroom_Green_(1).fbx'},
        {name:'Rock_09', position:{x:60.67311, y:3.13623, z:91.19799}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_09.fbx'},
        {name:'Tree_Fall5', position:{x:73.97109, y:5.927749, z:100.797}, rotation:{x:-0.1239961, y:0.05181785, z:-0.1974259}, scale:{x:1, y:1, z:1}, file:'Tree_Fall5.fbx'},
        {name:'Fern1', position:{x:76.66846, y:5.515141, z:100.6339}, rotation:{x:0, y:0, z:0}, scale:{x:1.069844, y:1.069844, z:1.069844}, file:'Fern1.fbx'},
        {name:'Bush_06', position:{x:79.60841, y:5.515348, z:101.1248}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_06.fbx'},
        {name:'Plant_03', position:{x:92.23615, y:3.369312, z:90.67365}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_03.fbx'},
        {name:'Stair_el6', position:{x:95.42, y:3.5, z:83.34}, rotation:{x:0, y:-0.702063, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el6.fbx'},
        {name:'Brickwall1', position:{x:71.29577, y:5.697761, z:101.1255}, rotation:{x:0, y:0.3711501, z:0}, scale:{x:1, y:1, z:1}, file:'Brickwall1.fbx'},
        {name:'Wagon_Tracks1', position:{x:65.56157, y:3.405359, z:85.91092}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_Tracks1.fbx'},
        {name:'Plant_07', position:{x:83.00546, y:1.997678, z:87.40858}, rotation:{x:0, y:-0.937546, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_07.fbx'},
        {name:'Tree_Pine_1', position:{x:57.68, y:5.5, z:81.58}, rotation:{x:0, y:-0.5389422, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Tree_Fall4', position:{x:54.82848, y:4.478014, z:49.33131}, rotation:{x:0.2208452, y:-0.01388491, z:0.4188578}, scale:{x:1, y:1, z:1}, file:'Tree_Fall4.fbx'},
        {name:'Tree_Pine_2', position:{x:80.7881, y:1.955942, z:90.85817}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_2.fbx'},
        {name:'Stair_el4', position:{x:73.97101, y:3.315702, z:86.81224}, rotation:{x:0, y:0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el4.fbx'},
        {name:'Wagon_pickaxe', position:{x:73.00621, y:3.306783, z:95.88081}, rotation:{x:0.2470331, y:0.5015929, z:-0.1248835}, scale:{x:1, y:1, z:1}, file:'Wagon_pickaxe.fbx'},
        {name:'Wagon_Tracks1', position:{x:72.63264, y:3.405359, z:92.98198}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_Tracks1.fbx'},
        {name:'Plant_07', position:{x:84.978, y:1.997678, z:90.5798}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_07.fbx'},
        {name:'Ground_Decal1_(2)', position:{x:91.22441, y:3.405968, z:73.56353}, rotation:{x:0, y:0.8674709, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal1_(2).fbx'},
        {name:'Stair_el2', position:{x:80.22176, y:2.172296, z:85.8459}, rotation:{x:0, y:0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el2.fbx'},
        {name:'Wall_wood_el3', position:{x:63.57112, y:3.405287, z:92.50595}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Wall_wood_el3.fbx'},
        {name:'Wagon_tracks2', position:{x:65.56157, y:3.406834, z:82.37539}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_tracks2.fbx'},
        {name:'Tree_Pine_3', position:{x:88.7114, y:5.491891, z:104.611}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_3.fbx'},
        {name:'Tree_03', position:{x:61.05858, y:3.405359, z:95.81229}, rotation:{x:0, y:-0.8841972, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_03.fbx'},
        {name:'Ivy1', position:{x:75.73097, y:5.92695, z:94.4896}, rotation:{x:0, y:-0.9883071, z:0}, scale:{x:1, y:1, z:1}, file:'Ivy1.fbx'},
        {name:'Tree_Pine_4', position:{x:85.26263, y:5.492235, z:98.70498}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_4.fbx'},
        {name:'Tree_Pine_3', position:{x:88.48018, y:3.642823, z:70.68153}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_3.fbx'},
        {name:'Block2', position:{x:76.90404, y:2.763849, z:81.34502}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Block2.fbx'},
        {name:'Crystal2', position:{x:76.68828, y:4.266412, z:94.30389}, rotation:{x:0.002326634, y:0.05866634, z:0.3049119}, scale:{x:1, y:1, z:1}, file:'Crystal2.fbx'},
        {name:'Rock_02', position:{x:59.8878, y:3.569391, z:94.67577}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_02.fbx'},
        {name:'Rune_particle2', position:{x:78.01643, y:5.632369, z:105.0002}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_particle2.fbx'},
        {name:'Rune_particle1', position:{x:78.01643, y:5.61201, z:105.0002}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_particle1.fbx'},
        {name:'Rune_02', position:{x:78.10447, y:5.491671, z:105.0096}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_02.fbx'},
        {name:'Lightshafts_moon', position:{x:70.27242, y:8.125824, z:89.26971}, rotation:{x:0.3017327, y:-0.11828, z:-0.1895732}, scale:{x:2.072971, y:2.072971, z:2.072971}, file:'Lightshafts_moon.fbx'},
        {name:'Block3', position:{x:77.82765, y:3.155451, z:90.81675}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Block3.fbx'},
        {name:'Tree_Pine_3', position:{x:78.18054, y:3.643315, z:78.18838}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_3.fbx'},
        {name:'Rock_10', position:{x:81.59, y:3.68, z:94.39}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_10.fbx'},
        {name:'Plant_06', position:{x:88.93981, y:1.995082, z:89.66437}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_06.fbx'},
        {name:'Fence1', position:{x:80.0297, y:3.405359, z:92.33553}, rotation:{x:0, y:-0.3853284, z:0}, scale:{x:1, y:1, z:1}, file:'Fence1.fbx'},
        {name:'Wagon_beam1', position:{x:73.12, y:3.43, z:74.89}, rotation:{x:0, y:0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_beam1.fbx'},
        {name:'Bush_04', position:{x:78.64349, y:5.514128, z:111.0437}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_04.fbx'},
        {name:'Trunk_02', position:{x:73.02967, y:5.491668, z:101.1909}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Trunk_02.fbx'},
        {name:'Rune_particle2', position:{x:81.57674, y:5.632231, z:105.8933}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_particle2.fbx'},
        {name:'Rune_particle1', position:{x:81.65566, y:5.611872, z:105.8933}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_particle1.fbx'},
        {name:'Rune_06', position:{x:81.66477, y:5.491532, z:105.9027}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_06.fbx'},
        {name:'Stair_el1', position:{x:90, y:3.9, z:109.52}, rotation:{x:0, y:-0.3598194, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el1.fbx'},
        {name:'Crystal1', position:{x:75.57561, y:3.720281, z:96.34029}, rotation:{x:-0.3860436, y:0.6292074, z:-0.0886713}, scale:{x:1, y:1, z:1}, file:'Crystal1.fbx'},
        {name:'Mushroom3A', position:{x:87.99826, y:3.405359, z:96.34678}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom3A.fbx'},
        {name:'Stair_el4', position:{x:64.59, y:3.6, z:79.57}, rotation:{x:0, y:-0.7207258, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el4.fbx'},
        {name:'Lightshaft_sparks', position:{x:79.70727, y:7.479589, z:104.7842}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Lightshaft_sparks.fbx'},
        {name:'Lightshafts_Magic', position:{x:79.70727, y:4.65731, z:105.0168}, rotation:{x:0, y:0, z:0}, scale:{x:2.072971, y:2.072971, z:2.072971}, file:'Lightshafts_Magic.fbx'},
        {name:'Tree_Pine_5', position:{x:66.09039, y:3.405359, z:102.3803}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_5.fbx'},
        {name:'Plant_07', position:{x:87.14684, y:1.997678, z:92.13083}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_07.fbx'},
        {name:'Tree_Pine_1', position:{x:57.1129, y:5.48915, z:90.9514}, rotation:{x:0, y:0.2228887, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Stair_el5', position:{x:88.77, y:4.76, z:109}, rotation:{x:0, y:-0.3598194, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el5.fbx'},
        {name:'Bush_05', position:{x:90.47675, y:3.321925, z:89.34466}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_05.fbx'},
        {name:'Mushroom3A', position:{x:92.14181, y:3.406021, z:74.44716}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Mushroom3A.fbx'},
        {name:'Ground_Decal_grass1', position:{x:66.15331, y:3.405359, z:98.30038}, rotation:{x:0, y:0.3655059, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_grass1.fbx'},
        {name:'Stair_el5', position:{x:70.02907, y:4.764298, z:104.0512}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el5.fbx'},
        {name:'Turret', position:{x:76.21, y:5.86, z:102.29}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Turret.fbx'},
        {name:'Base', position:{x:76.21, y:5.86, z:102.29}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Base.fbx'},
        {name:'Tow_Hammer3', position:{x:76.21, y:5.86, z:102.29}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tow_Hammer3.fbx'},
        {name:'Bush_03', position:{x:79.77921, y:5.515348, z:99.09672}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_03.fbx'},
        {name:'Tree_Pine_2', position:{x:85.66666, y:3.410157, z:71.5899}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_2.fbx'},
        {name:'Tree_Pine_1', position:{x:65.51616, y:3.405359, z:103.878}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Plant_06', position:{x:75.18598, y:1.997678, z:80.24622}, rotation:{x:0, y:0.6418939, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_06.fbx'},
        {name:'Wagon_Tracks1', position:{x:72.63, y:3.41, z:75.31}, rotation:{x:0, y:0.9238795, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_Tracks1.fbx'},
        {name:'Rune_particle2', position:{x:82.13941, y:5.63184, z:103.7378}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_particle2.fbx'},
        {name:'Rune_particle1', position:{x:82.13941, y:5.611481, z:103.7378}, rotation:{x:-0.7071068, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_particle1.fbx'},
        {name:'Rune_05', position:{x:82.22744, y:5.491141, z:103.7472}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rune_05.fbx'},
        {name:'Wagon_gate1', position:{x:83.3, y:3.41, z:93.59}, rotation:{x:0, y:0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_gate1.fbx'},
        {name:'Stair_el4', position:{x:90.36, y:4.76, z:107.57}, rotation:{x:0, y:-0.3598194, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el4.fbx'},
        {name:'Ivy3', position:{x:94.713, y:6.18, z:72.885}, rotation:{x:0, y:0.924158, z:0}, scale:{x:1, y:1, z:1}, file:'Ivy3.fbx'},
        {name:'Wagon_Tracks1', position:{x:69.09711, y:3.405359, z:89.44645}, rotation:{x:0, y:0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Wagon_Tracks1.fbx'},
        {name:'Rock_05', position:{x:69.02044, y:3.780374, z:102.55}, rotation:{x:-0.06723478, y:0.2415269, z:0.2488781}, scale:{x:1, y:1, z:1}, file:'Rock_05.fbx'},
        {name:'Tree_03', position:{x:84.50203, y:5.491531, z:109.9298}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_03.fbx'},
        {name:'Plant_06', position:{x:98.03384, y:1.997678, z:98.89085}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_06.fbx'},
        {name:'Rock_17A', position:{x:85.78957, y:5.491364, z:107.8082}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_17A.fbx'},
        {name:'Bush_04', position:{x:87.05489, y:3.331787, z:70.45988}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_04.fbx'},
        {name:'Plant_01', position:{x:70.45654, y:6.078605, z:108.8063}, rotation:{x:-0.1420312, y:0.4435493, z:-0.02759929}, scale:{x:1, y:1, z:1}, file:'Plant_01.fbx'},
        {name:'Bush_04', position:{x:68.63486, y:3.429451, z:82.08594}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bush_04.fbx'},
        {name:'Crystal3', position:{x:75.81075, y:3.526438, z:95.74571}, rotation:{x:-0.2968245, y:-0.0348709, z:0.2065042}, scale:{x:1.295223, y:1.295223, z:1.295223}, file:'Crystal3.fbx'},
        {name:'Tree_Dead1', position:{x:76.07391, y:1.982952, z:80.57531}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Dead1.fbx'},
        {name:'Stair_el7', position:{x:62.19, y:5.2, z:77.07}, rotation:{x:0, y:-0.7207258, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el7.fbx'},
        {name:'Fire2', position:{x:71.71754, y:3.780805, z:95.67659}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire2.fbx'},
        {name:'Fire_long', position:{x:71.71754, y:4.900286, z:95.65818}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire_long.fbx'},
        {name:'Fire_sparks', position:{x:71.71754, y:4.052996, z:95.67659}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire_sparks.fbx'},
        {name:'Fire2', position:{x:71.71754, y:3.901636, z:95.67659}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Fire2.fbx'},
        {name:'Campfire_particle', position:{x:71.71754, y:3.901636, z:95.67659}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Campfire_particle.fbx'},
        {name:'Rock_09', position:{x:71.16639, y:3.15128, z:101.2314}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_09.fbx'},
        {name:'Point_light', position:{x:78.62137, y:5.32909, z:91.09583}, rotation:{x:0, y:0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Point_light.fbx'},
        {name:'Particle_Glow', position:{x:78.6057, y:5.577087, z:91.10392}, rotation:{x:-0.2705981, y:0.6532816, z:0.6532816}, scale:{x:1, y:1, z:1}, file:'Particle_Glow.fbx'},
        {name:'Lantern1', position:{x:77.8201, y:3.405359, z:91.88952}, rotation:{x:0, y:0.9238796, z:0}, scale:{x:1, y:1, z:1}, file:'Lantern1.fbx'},
        {name:'Ground_Decal_Leaves1', position:{x:87.0484, y:3.40597, z:82.43607}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Ground_Decal_Leaves1.fbx'},
        {name:'Stair_el2', position:{x:70.92056, y:4.341208, z:105.8007}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el2.fbx'},
        {name:'Tree_Pine_1', position:{x:74.1013, y:5.491668, z:107.8832}, rotation:{x:0, y:-0.5785058, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_1.fbx'},
        {name:'Tree_Pine_3', position:{x:66.02917, y:3.405765, z:83.85493}, rotation:{x:0, y:-0.9576513, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_Pine_3.fbx'},
        {name:'Tree_06', position:{x:68.90058, y:3.40597, z:84.83424}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Tree_06.fbx'},
        {name:'Stair_el6', position:{x:64.59, y:3.5, z:77.27}, rotation:{x:0, y:0.6932201, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_el6.fbx'},
        {name:'Plant_04', position:{x:80.10493, y:5.515367, z:103.7547}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Plant_04.fbx'},
        {name:'Rock_Group_02', position:{x:125.03, y:3.21, z:42.78}, rotation:{x:0, y:-0.6961808, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:107.11, y:5.43, z:6.5}, rotation:{x:0, y:-0.1407901, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Cube', position:{x:65.57, y:4.01, z:116.43}, rotation:{x:0, y:0, z:0.3284845}, scale:{x:3.651472, y:1, z:4.157451}, file:'Cube.fbx'},
        {name:'Wall_stone_grup3x', position:{x:115.39, y:5.61, z:31.07}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Wall_stone_grup3x.fbx'},
        {name:'Rock_Group_01', position:{x:107.62, y:2.55, z:110.72}, rotation:{x:0, y:-0.7143304, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:57.72, y:4.4, z:45.93}, rotation:{x:0, y:-0.9655522, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:147.8, y:7.16, z:8.93}, rotation:{x:0, y:-0.5034494, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:64.74, y:3.49, z:42.83}, rotation:{x:0, y:0.4532481, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:145.45, y:3.06, z:54.45}, rotation:{x:0, y:-0.7636103, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:9.16, y:5.23, z:77.75}, rotation:{x:0, y:0.8985027, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:58.71, y:4.78, z:51.17}, rotation:{x:0, y:-0.4464365, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:64.25829, y:3.489836, z:48.90583}, rotation:{x:0, y:0.7383559, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:3.27, y:7.43, z:130.66}, rotation:{x:0, y:0.7811326, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_06', position:{x:79.14726, y:5.260094, z:72.76404}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_06', position:{x:61.8333, y:4.65, z:86.14497}, rotation:{x:0, y:0.5376362, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_03', position:{x:61.60536, y:5.13, z:87.11562}, rotation:{x:0, y:0.2772383, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Rock_Group_01', position:{x:8.950001, y:5.1, z:106.75}, rotation:{x:0, y:0.6257726, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:8.07, y:4.7, z:109.75}, rotation:{x:0, y:0.2569015, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:117.96, y:5.29, z:148.59}, rotation:{x:0, y:0.9773483, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:71.6, y:3.4, z:51.9}, rotation:{x:0, y:-0.759058, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:99.82, y:3.02, z:35.53}, rotation:{x:0, y:0.05658093, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:9.189999, y:5.14, z:128.76}, rotation:{x:0, y:0.6300601, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_04', position:{x:86.00903, y:3.685871, z:95.75197}, rotation:{x:0, y:0.6468362, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_04.fbx'},
        {name:'Rock_Group_01', position:{x:11.12, y:5.26, z:132}, rotation:{x:0, y:0.9860386, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:152.74, y:4.97, z:118.93}, rotation:{x:0, y:-0.9633958, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:122.51, y:3.4, z:61.87}, rotation:{x:0, y:0.9053105, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:80.91, y:5.4, z:121.65}, rotation:{x:0, y:0.8671442, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:52.04, y:5.09, z:8.900002}, rotation:{x:0, y:-0.1407901, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:68.13, y:3.11, z:62.79}, rotation:{x:0, y:-0.8105339, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:85.64, y:3.32, z:95.82}, rotation:{x:0, y:0.6468362, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_06', position:{x:62.53471, y:3.486092, z:86.5737}, rotation:{x:0, y:0.2772383, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_Group_01', position:{x:95.42, y:5.41, z:151.65}, rotation:{x:0, y:0.9920384, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:57.93103, y:3.45, z:151.9251}, rotation:{x:0, y:0.9766384, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:8.880001, y:5.24, z:22.85}, rotation:{x:0, y:0.7706103, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:113.99, y:3.41, z:32.64}, rotation:{x:0, y:-0.4517855, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_06', position:{x:84.39119, y:4.87, z:94.97485}, rotation:{x:0, y:0.8361035, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_Group_01', position:{x:149.26, y:5.07, z:61.67}, rotation:{x:0, y:-0.599681, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:56.8, y:5, z:48.89}, rotation:{x:0, y:-0.8683574, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:152.05, y:5.48, z:102.12}, rotation:{x:0, y:-0.7578298, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_03', position:{x:86.84003, y:5.11, z:99.91886}, rotation:{x:0, y:0.4291917, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Rock_Group_01', position:{x:89.72, y:3.1, z:101.52}, rotation:{x:0, y:0.8819894, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:43.85, y:5.24, z:52.19}, rotation:{x:0, y:-0.6471959, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:15.03, y:5.06, z:12.21}, rotation:{x:0, y:-0.574076, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:104.78, y:2.04, z:130.89}, rotation:{x:0, y:0.4307, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:7.860001, y:4.7, z:39.47}, rotation:{x:0, y:0.2347421, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:114.5, y:2.19, z:102.98}, rotation:{x:0, y:0.9976233, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:34.78, y:5.09, z:8.760002}, rotation:{x:0, y:-0.1407901, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:100.15, y:5.25, z:148.6}, rotation:{x:0, y:0.9890977, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:74.74, y:4.81, z:117.95}, rotation:{x:0, y:0.9199792, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:151.22, y:5.09, z:125.06}, rotation:{x:0, y:-0.9999167, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:9.34, y:4.89, z:12.72}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:86.91, y:5.41, z:151.65}, rotation:{x:0, y:0.9920384, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:80.74, y:4.74, z:7.800003}, rotation:{x:0, y:-0.5291227, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:152.91, y:4.97, z:106.01}, rotation:{x:0, y:-0.9500583, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_07', position:{x:62.96528, y:3.126397, z:81.56807}, rotation:{x:0, y:0.5172848, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_07.fbx'},
        {name:'Rock_Group_01', position:{x:27.53, y:5.12, z:151.93}, rotation:{x:0, y:0.9875919, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:147.44, y:4.78, z:151.65}, rotation:{x:0, y:-0.9997686, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:96.35, y:3.71, z:109.48}, rotation:{x:0, y:-0.9030288, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_04', position:{x:61.68535, y:4.639235, z:74.87706}, rotation:{x:0, y:0.3812959, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_04.fbx'},
        {name:'Rock_Group_01', position:{x:93.63, y:3.34, z:49.5}, rotation:{x:0, y:0.4981798, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:47.06, y:5.34, z:10.85}, rotation:{x:0, y:0.2932959, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Cube', position:{x:40.22, y:4.04, z:120.11}, rotation:{x:-0.2816771, y:-0.8811629, z:0.1156287}, scale:{x:3.651472, y:1, z:6.586564}, file:'Cube.fbx'},
        {name:'Rock_Group_01', position:{x:54.08, y:2.5, z:33.00999}, rotation:{x:0, y:-0.7170592, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Cube', position:{x:88.38, y:3.92, z:60.84}, rotation:{x:-0.2813086, y:-0.8800107, z:0.1165219}, scale:{x:3.651472, y:1, z:4.157451}, file:'Cube.fbx'},
        {name:'Rock_Group_02', position:{x:56.98, y:1.96, z:58.43}, rotation:{x:0, y:0.8229625, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:152.0464, y:5.48, z:115.4391}, rotation:{x:0, y:-0.7578298, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Wall_wood_grup2x', position:{x:48.67056, y:5.490307, z:135.0033}, rotation:{x:0, y:0.7071068, z:0}, scale:{x:1, y:1, z:1}, file:'Wall_wood_grup2x.fbx'},
        {name:'Rock_Group_01', position:{x:86.46, y:3.68, z:68.62}, rotation:{x:0, y:0.3631077, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:97.57, y:3.1, z:75.17}, rotation:{x:0, y:-0.9616589, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Lanterns', position:{x:37.95441, y:5.45632, z:103.1935}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Lanterns.fbx'},
        {name:'Rock_Group_01', position:{x:59.16, y:5.49, z:43.21}, rotation:{x:0, y:0.9966075, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:16, y:4.87, z:132.79}, rotation:{x:0, y:0.8826093, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_02', position:{x:62.13451, y:4.710529, z:86.09348}, rotation:{x:0, y:0.2772383, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_02.fbx'},
        {name:'Rock_04', position:{x:87.37672, y:4.659235, z:101.3464}, rotation:{x:0, y:0.4291917, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_04.fbx'},
        {name:'Rock_Group_02', position:{x:79.98, y:5.02, z:30.74}, rotation:{x:0, y:-0.9964858, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_01', position:{x:62.90957, y:3.445662, z:83.23596}, rotation:{x:0, y:0.5172848, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_01.fbx'},
        {name:'Rock_Group_01', position:{x:77.98, y:5.41, z:33.22}, rotation:{x:0, y:-0.7707729, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:145.98, y:4.94, z:128.99}, rotation:{x:0, y:-0.990467, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:55.22, y:4.74, z:7.889999}, rotation:{x:0, y:-0.5034494, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:150.72, y:5.07, z:64.82}, rotation:{x:0, y:-0.9339931, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:19.72, y:5.31, z:133.77}, rotation:{x:0, y:0.8822935, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:64.73, y:2.97, z:38.81}, rotation:{x:0, y:0.3300526, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:58.69772, y:5.494334, z:35.25519}, rotation:{x:0, y:-0.150716, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Cube', position:{x:23.55, y:5.94, z:136.35}, rotation:{x:-0.2816771, y:-0.8811629, z:0.1156287}, scale:{x:3.651472, y:1, z:6.586564}, file:'Cube.fbx'},
        {name:'Rock_Group_01', position:{x:65.42, y:2.05, z:61.29}, rotation:{x:0, y:-0.530863, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_03', position:{x:98.6496, y:2.032217, z:97.85214}, rotation:{x:0, y:-0.7842733, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Rock_Group_01', position:{x:106.49, y:3.45, z:133.74}, rotation:{x:0, y:0.9961414, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:145.61, y:5.23, z:151.73}, rotation:{x:0, y:0.6386502, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_01', position:{x:86.01684, y:5.40984, z:96.97365}, rotation:{x:0, y:0.6468362, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_01.fbx'},
        {name:'Rock_Group_02', position:{x:136.95, y:5.02, z:148.95}, rotation:{x:0, y:-0.9983431, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:30.44, y:7.25, z:157.56}, rotation:{x:0, y:0.5611197, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:149.54, y:5.12, z:36.84}, rotation:{x:0, y:-0.6215494, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:51.63, y:4.4, z:50.21}, rotation:{x:0, y:-0.4351374, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_01', position:{x:79.88, y:5.46, z:72}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_01.fbx'},
        {name:'Rock_Group_02', position:{x:13.77, y:3.37, z:97.44}, rotation:{x:0, y:0.3567306, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_06', position:{x:86.74574, y:4.63, z:98.92628}, rotation:{x:0, y:0.6671009, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_Group_02', position:{x:156.28, y:7.1, z:28.93}, rotation:{x:0, y:-0.6617097, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Level_Environment', position:{x:0, y:0, z:0}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Level_Environment.fbx'},
        {name:'Rock_04', position:{x:62.32582, y:3.465871, z:87.87088}, rotation:{x:0, y:0.2772383, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_04.fbx'},
        {name:'Rock_Group_01', position:{x:43.46, y:5.53, z:37.08}, rotation:{x:0, y:-0.9780651, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:112.25, y:3.21, z:29.95}, rotation:{x:0, y:-0.6961808, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:152.9143, y:4.97, z:112.3263}, rotation:{x:0, y:-0.9500583, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:150.95, y:5.07, z:71.37}, rotation:{x:0, y:-0.9339931, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:126.94, y:5.09, z:8.809998}, rotation:{x:0, y:-0.11105, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:9.880001, y:7.65, z:152.28}, rotation:{x:0, y:0.9920384, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:148.51, y:5.27, z:126.45}, rotation:{x:0, y:-0.9990688, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:91.61, y:3.08, z:104.76}, rotation:{x:0, y:0.4524823, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:108.77, y:4.75, z:152.28}, rotation:{x:0, y:0.9009256, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:60.98, y:4.81, z:153.18}, rotation:{x:0, y:0.9445595, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:111.99, y:4.69, z:105.06}, rotation:{x:0, y:1, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:95.28, y:3.49, z:22.63}, rotation:{x:0, y:0.999475, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:54.21, y:2, z:30.01}, rotation:{x:0, y:-0.8897167, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rocks', position:{x:37.95441, y:5.45632, z:103.1935}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rocks.fbx'},
        {name:'Rock_Group_01', position:{x:151.88, y:7.51, z:24.4}, rotation:{x:0, y:-0.7321725, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:10.07321, y:5.1, z:87.11205}, rotation:{x:0, y:0.6257726, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:74.04, y:5.09, z:8.809998}, rotation:{x:0, y:-0.11105, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:13.09, y:3.2, z:102.97}, rotation:{x:0, y:0.2620218, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:89.98, y:3.06, z:53.27}, rotation:{x:0, y:-0.02306624, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:129.83, y:7.16, z:3.709999}, rotation:{x:0, y:-0.8343484, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:52.78, y:2.96, z:150.56}, rotation:{x:0, y:0.8099586, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:92.22, y:2.99, z:123.24}, rotation:{x:0, y:0.8177891, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:152.27, y:7.16, z:9.470001}, rotation:{x:0, y:-0.9581736, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:151.05, y:5.47, z:27.96}, rotation:{x:0, y:-0.6644565, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:9.84, y:5.06, z:123.52}, rotation:{x:0, y:0.7584476, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:27.38, y:4.74, z:8.220001}, rotation:{x:0, y:-0.4907927, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_02', position:{x:87.01469, y:4.690529, z:98.78121}, rotation:{x:0, y:0.4291917, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_02.fbx'},
        {name:'Rock_Group_02', position:{x:117.76, y:4.74, z:7.800003}, rotation:{x:0, y:-0.5291227, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:8.709999, y:4.89, z:120.8}, rotation:{x:0, y:0.409483, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:56.38, y:2, z:48.71}, rotation:{x:0, y:-0.9541935, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:7.52, y:5.1, z:70.4}, rotation:{x:0, y:0.5837017, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_06', position:{x:91.68643, y:3.466092, z:103.5828}, rotation:{x:0, y:0.4524823, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_Group_02', position:{x:81.77, y:4.88, z:152.41}, rotation:{x:0, y:0.8814658, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:131.56, y:3.73, z:50.43}, rotation:{x:0, y:-0.348978, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:25.4, y:5.29, z:10.74}, rotation:{x:0, y:0.1048764, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:130.93, y:7.51, z:6.690002}, rotation:{x:0, y:-0.5401732, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:42.99921, y:2, z:57.09836}, rotation:{x:0, y:-0.2704379, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:65.68, y:3.11, z:147.46}, rotation:{x:0, y:-0.9342926, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Stairs', position:{x:37.95441, y:5.45632, z:103.1935}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Stairs.fbx'},
        {name:'Rock_Group_01', position:{x:153.08, y:7.26, z:27.61}, rotation:{x:0, y:-0.4572366, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Cube', position:{x:70.31, y:4.01, z:105.4}, rotation:{x:0.1187772, y:0.3715678, z:0.2803638}, scale:{x:3.651472, y:1, z:4.157452}, file:'Cube.fbx'},
        {name:'Rock_03', position:{x:89.84248, y:3.868641, z:102.2205}, rotation:{x:0, y:0.8819894, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Cube', position:{x:63.48, y:4.01, z:78.44}, rotation:{x:0.3044858, y:0.9525151, z:-0.0005633955}, scale:{x:3.651472, y:1, z:4.157452}, file:'Cube.fbx'},
        {name:'Rock_Group_01', position:{x:27.05, y:5.36, z:141.23}, rotation:{x:0, y:0.8633299, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:109.27, y:5.01, z:114.23}, rotation:{x:0, y:-0.7693007, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:152.91, y:4.97, z:92.19}, rotation:{x:0, y:-0.9500583, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_01', position:{x:87.16421, y:5.16984, z:101.4685}, rotation:{x:0, y:0.4291917, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_01.fbx'},
        {name:'Rock_Group_01', position:{x:95.5, y:3.36, z:115.84}, rotation:{x:0, y:-0.9269713, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:102.18, y:4.43, z:114.86}, rotation:{x:0, y:0.2322943, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:69.24, y:3.05, z:45.84}, rotation:{x:0, y:-0.9855656, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:104.09, y:4.75, z:130.4}, rotation:{x:0, y:0.3527536, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Cube', position:{x:119.5, y:4, z:39.84}, rotation:{x:-0.1165218, y:-0.3645125, z:-0.2813088}, scale:{x:3.651472, y:1, z:6.586564}, file:'Cube.fbx'},
        {name:'Rock_01', position:{x:97.19213, y:2.635662, z:92.67235}, rotation:{x:0, y:-0.7444211, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_01.fbx'},
        {name:'Rock_Group_02', position:{x:124.77, y:4.75, z:152.28}, rotation:{x:0, y:0.9009256, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Vegitation', position:{x:0, y:0, z:0}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Vegitation.fbx'},
        {name:'Rock_Group_01', position:{x:20.84, y:7.36, z:152.56}, rotation:{x:0, y:0.9875919, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:104.19, y:2.08, z:108.76}, rotation:{x:0, y:0.8317456, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:131.04, y:3.24, z:63.73}, rotation:{x:0, y:0.7631086, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:90.28, y:4.88, z:152.41}, rotation:{x:0, y:0.8814658, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Cube', position:{x:65.27, y:5.71, z:31.76}, rotation:{x:0.3284847, y:0.9445093, z:1.114105E-07}, scale:{x:3.651472, y:1, z:2.684845}, file:'Cube.fbx'},
        {name:'Rock_Group_01', position:{x:48.73, y:4.7, z:55.04}, rotation:{x:0, y:-0.06585008, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:113.44, y:2.68, z:99.53}, rotation:{x:0, y:0.1196229, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_01', position:{x:90.10637, y:3.135662, z:102.0151}, rotation:{x:0, y:0.8819894, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_01.fbx'},
        {name:'Cube', position:{x:70.53, y:4, z:40.45}, rotation:{x:0.005990653, y:0.01874043, z:0.3044274}, scale:{x:3.651472, y:1, z:4.157451}, file:'Cube.fbx'},
        {name:'Rock_Group_02', position:{x:12.84, y:7.12, z:153.04}, rotation:{x:0, y:0.8814658, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_03', position:{x:88.93837, y:4.311269, z:102.0458}, rotation:{x:0, y:0.8819894, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Rock_Group_02', position:{x:152.33, y:7.16, z:15.01}, rotation:{x:0, y:-0.9450714, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:108.7533, y:3.43, z:64.80569}, rotation:{x:0, y:0.9874821, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:109.72, y:2.89, z:134.17}, rotation:{x:0, y:0.9378293, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:94.93913, y:3.400913, z:40.1349}, rotation:{x:0, y:0.05642789, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Cube', position:{x:136.53, y:5.92, z:23.7}, rotation:{x:-0.1165218, y:-0.3645125, z:-0.2813088}, scale:{x:3.651471, y:1, z:6.586564}, file:'Cube.fbx'},
        {name:'Rock_Group_01', position:{x:64.75101, y:3.21, z:151.0877}, rotation:{x:0, y:-0.9907824, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_01', position:{x:72.82615, y:3.405651, z:85.46667}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_01.fbx'},
        {name:'Rock_04', position:{x:85.45409, y:4.558132, z:95.70425}, rotation:{x:0, y:0.6468362, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_04.fbx'},
        {name:'Rock_Group_01', position:{x:151.48, y:5.07, z:49.09}, rotation:{x:0, y:-0.8641176, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:116.4, y:5.42, z:122.89}, rotation:{x:0, y:0.1703488, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:142.71, y:3.32, z:51.84}, rotation:{x:0, y:-0.600829, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:145.43, y:5.43, z:131.99}, rotation:{x:0, y:-0.6544805, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:147.95, y:4.96, z:134.49}, rotation:{x:0, y:-0.6500543, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_06', position:{x:62.25477, y:4.391106, z:81.35936}, rotation:{x:0, y:0.5172848, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_Group_02', position:{x:49.81, y:2.1, z:33.85}, rotation:{x:0, y:-0.404134, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:116.09, y:4.98, z:110.16}, rotation:{x:0, y:0.4429888, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:61.39, y:4.96, z:34.1}, rotation:{x:0, y:-0.271394, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Bushes', position:{x:37.95441, y:5.48, z:103.1935}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Bushes.fbx'},
        {name:'Rock_07', position:{x:97.75104, y:2.316397, z:94.24479}, rotation:{x:0, y:-0.7444211, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_07.fbx'},
        {name:'Rock_Group_01', position:{x:152.05, y:5.48, z:82.68}, rotation:{x:0, y:-0.7578298, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:110.57, y:2.09, z:127.52}, rotation:{x:0, y:-0.9198041, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:86.89, y:4.74, z:7.889999}, rotation:{x:0, y:-0.5034494, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:88.81, y:5.07, z:36.61}, rotation:{x:0, y:0.5386894, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:61.8, y:3.06, z:65.37}, rotation:{x:0, y:0.596976, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Cube', position:{x:92.91, y:4.06, z:18.87}, rotation:{x:0.3044865, y:0.9525167, z:1.135257E-07}, scale:{x:3.651471, y:1, z:6.586564}, file:'Cube.fbx'},
        {name:'Cube', position:{x:24.4, y:4.04, z:95.68}, rotation:{x:0.2111482, y:0.6605293, z:0.2193817}, scale:{x:3.651472, y:1, z:7.823368}, file:'Cube.fbx'},
        {name:'Rock_Group_01', position:{x:63.74, y:5.34, z:10.16}, rotation:{x:0, y:0.2932959, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:152.05, y:5.48, z:89.5}, rotation:{x:0, y:-0.7578298, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_03', position:{x:62.29263, y:3.422217, z:75.1412}, rotation:{x:0, y:0.3812959, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Rock_Group_02', position:{x:6.66, y:4.7, z:67.16}, rotation:{x:0, y:0.2347421, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:104.72, y:3.69, z:9.520004}, rotation:{x:0, y:-0.2950708, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:58.41, y:2.4, z:51.76}, rotation:{x:0, y:-0.5153156, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_01', position:{x:61.9853, y:5.19, z:82.12234}, rotation:{x:0, y:0.5172848, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_01.fbx'},
        {name:'Rock_Group_02', position:{x:61.44, y:5.11, z:44.94}, rotation:{x:0, y:0.4862036, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:41.53, y:4.88, z:152.41}, rotation:{x:0, y:0.8814658, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:29.39, y:7.35, z:154.35}, rotation:{x:0, y:0.7986843, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:55.96, y:2, z:43.22}, rotation:{x:0, y:-0.910601, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:132.06, y:5.62, z:12.14}, rotation:{x:0, y:-0.7803914, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:110.28, y:2.07, z:100.47}, rotation:{x:0, y:-0.2487342, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:8.470001, y:5.23, z:117.73}, rotation:{x:0, y:0.7093136, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:8.700001, y:5.18, z:131.67}, rotation:{x:0, y:0.3500396, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:151.43, y:5.07, z:53.01}, rotation:{x:0, y:-0.9640943, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:54.52, y:4.6, z:28.59}, rotation:{x:0, y:-0.8031139, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_02', position:{x:86.30814, y:4.375493, z:96.67949}, rotation:{x:0, y:0.6468362, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_02.fbx'},
        {name:'Ivy', position:{x:37.95441, y:5.45632, z:103.1935}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Ivy.fbx'},
        {name:'Rock_Group_01', position:{x:58.26, y:5.22, z:26.33}, rotation:{x:0, y:0.9929063, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:108, y:2.09, z:102.46}, rotation:{x:0, y:0.1176299, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:104.42, y:4.62, z:132.31}, rotation:{x:0, y:0.5724492, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:112.2, y:5.24, z:151.14}, rotation:{x:0, y:-0.9930395, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:9.6, y:4.7, z:102.47}, rotation:{x:0, y:0.1389723, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:99.57, y:4.53, z:108.52}, rotation:{x:0, y:0.8999479, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:101.36, y:4.82, z:133.22}, rotation:{x:0, y:-0.4732079, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_01', position:{x:61.91371, y:3.738918, z:92.03554}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_01.fbx'},
        {name:'Rock_Group_02', position:{x:9.189564, y:4.7, z:90.11557}, rotation:{x:0, y:0.2569015, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:142.2006, y:4.97, z:26.65802}, rotation:{x:0, y:-0.6246803, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:108.06, y:3.34, z:9.540001}, rotation:{x:0, y:-0.6333362, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_01', position:{x:59.66798, y:3.268558, z:106.177}, rotation:{x:-0.02658115, y:-0.9188675, z:0.06284933}, scale:{x:1, y:1, z:1}, file:'Rock_01.fbx'},
        {name:'Rock_Group_01', position:{x:7.68, y:5.1, z:76.01}, rotation:{x:0, y:0.6077786, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:111.04, y:4.43, z:118.4}, rotation:{x:0, y:-0.9398049, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Wall_wood_grup3x', position:{x:15.67003, y:5.49097, z:111.0253}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Wall_wood_grup3x.fbx'},
        {name:'Rock_03', position:{x:79.9473, y:4.891269, z:72.34795}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Rock_Group_02', position:{x:133.05, y:4.98, z:15.89}, rotation:{x:0, y:-0.9421609, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:110.09, y:3.17, z:13.66}, rotation:{x:0, y:-0.9400592, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:96.36964, y:3.34, z:8.333839}, rotation:{x:0, y:-0.3844211, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:46.98, y:5.33, z:36.37}, rotation:{x:0, y:0.9911275, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:101.41, y:5.43, z:6.5}, rotation:{x:0, y:0.1208349, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:11.8, y:5.31, z:85.34}, rotation:{x:0, y:0.2989583, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:149.98, y:5.07, z:33.72}, rotation:{x:0, y:-0.9050372, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:10.74, y:4.84, z:79.58}, rotation:{x:0, y:0.7691767, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Stair_Collisions', position:{x:0, y:0, z:0}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Stair_Collisions.fbx'},
        {name:'Rock_Group_01', position:{x:15.13, y:5.15, z:27.91}, rotation:{x:0, y:0.7320544, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Cube', position:{x:39.96, y:4.01, z:97.26}, rotation:{x:0.2164647, y:0.6771607, z:0.2141377}, scale:{x:3.651472, y:1, z:4.157452}, file:'Cube.fbx'},
        {name:'Rock_Group_01', position:{x:110.19, y:2.03, z:132.27}, rotation:{x:0, y:0.9986318, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Wall_wood_grup3x', position:{x:44.60337, y:5.461911, z:128.2844}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Wall_wood_grup3x.fbx'},
        {name:'Rock_Group_01', position:{x:68.42479, y:3.511595, z:36.85332}, rotation:{x:0, y:-0.2584114, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:8.16, y:7.36, z:146.98}, rotation:{x:0, y:0.5605654, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:150.4944, y:5.492092, z:136.1636}, rotation:{x:0, y:-0.4168878, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:75.91, y:5.41, z:151.65}, rotation:{x:0, y:0.9920384, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_03', position:{x:84.96593, y:5.35, z:95.78958}, rotation:{x:0, y:0.6468362, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Rock_07', position:{x:97.45078, y:3.046397, z:72.46632}, rotation:{x:0, y:-0.9132124, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_07.fbx'},
        {name:'Rock_Group_02', position:{x:88.22, y:3.06, z:115.07}, rotation:{x:0, y:0.3724693, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:9.15, y:7.65, z:136.06}, rotation:{x:0, y:0.5863063, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_06', position:{x:90.8764, y:4.63, z:103.4426}, rotation:{x:0, y:0.6862054, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_Group_01', position:{x:99.52806, y:3.69, z:8.442261}, rotation:{x:0, y:0.02172461, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:111.15, y:2.96, z:63.77}, rotation:{x:0, y:0.9998294, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_03', position:{x:62.19238, y:3.462217, z:89.03041}, rotation:{x:0, y:0.2772383, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Rock_Group_02', position:{x:125.51, y:3.07, z:60.59}, rotation:{x:0, y:0.9153609, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:10.19676, y:5.1, z:93.31288}, rotation:{x:0, y:0.6021104, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:57.97084, y:5.03, z:9.437744}, rotation:{x:0, y:-0.6667299, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:5.429676, y:7.17, z:132.4566}, rotation:{x:0, y:0.7938615, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:144.73, y:7.51, z:9.770004}, rotation:{x:0, y:-0.11105, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_06', position:{x:62.32117, y:4.990094, z:83.12626}, rotation:{x:0, y:0.5172848, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_Group_02', position:{x:53.07, y:2.32, z:27.11}, rotation:{x:0, y:-0.371379, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:150.81, y:5.07, z:31.16}, rotation:{x:0, y:-0.9648454, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:152.14, y:5.08, z:144.75}, rotation:{x:0, y:-0.967824, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:109.17, y:4.43, z:111.11}, rotation:{x:0, y:-0.905867, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:103.68, y:2.55, z:115.93}, rotation:{x:0, y:0.5414755, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:113.27, y:4.99, z:5.910004}, rotation:{x:0, y:-0.7570598, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Cube', position:{x:96.98, y:2.11, z:102.36}, rotation:{x:0.1956745, y:0.902506, z:-0.08129334}, scale:{x:3.651472, y:1, z:3.225871}, file:'Cube.fbx'},
        {name:'_Directional_light_(RealTime)', position:{x:46.34441, y:5.59632, z:126.8435}, rotation:{x:0.09457744, y:-0.9282981, z:0.3575814}, scale:{x:1, y:1, z:1}, file:'_Directional_light_(RealTime).fbx'},
        {name:'Rock_Group_02', position:{x:7.68, y:4.82, z:113.09}, rotation:{x:0, y:0.2391638, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_01', position:{x:79.32364, y:3.715662, z:73.33599}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_01.fbx'},
        {name:'Rock_Group_01', position:{x:98.12, y:5.14, z:131.61}, rotation:{x:0, y:-0.4832908, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_02', position:{x:61.83057, y:4.155493, z:88.71022}, rotation:{x:0, y:0.2772383, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_02.fbx'},
        {name:'Rock_Group_01', position:{x:35.67, y:5.41, z:151.65}, rotation:{x:0, y:0.9920384, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Wall_wood_grup3x', position:{x:31.37741, y:5.461911, z:115.0585}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1, y:1, z:1}, file:'Wall_wood_grup3x.fbx'},
        {name:'Cube', position:{x:51.63, y:4.04, z:141.28}, rotation:{x:0.2795772, y:0.9601232, z:-1.401257E-07}, scale:{x:3.651472, y:1, z:6.586565}, file:'Cube.fbx'},
        {name:'Rock_Group_01', position:{x:150.83, y:7.51, z:9.849998}, rotation:{x:0, y:-0.11105, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:8.560001, y:7.12, z:138.96}, rotation:{x:0, y:0.2579734, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:49.97, y:5, z:39.85}, rotation:{x:0, y:-0.5787788, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:88.69, y:3.42, z:111.84}, rotation:{x:0, y:0.7436762, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_03', position:{x:88.0083, y:3.442217, z:101.5456}, rotation:{x:0, y:0.4291917, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Rock_Group_01', position:{x:126.77, y:3.41, z:45.47}, rotation:{x:0, y:-0.4517855, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:151.06, y:5.07, z:39.93}, rotation:{x:0, y:-0.9127635, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:7.200001, y:4.7, z:55.35}, rotation:{x:0, y:0.2347421, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:7.68, y:5.1, z:64.2}, rotation:{x:0, y:0.6077786, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:111.71, y:1.95, z:104.42}, rotation:{x:0, y:0.9607173, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:46.39, y:5.07, z:153.66}, rotation:{x:0, y:0.6846763, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:9.27, y:4.7, z:96.31}, rotation:{x:0, y:0.3171865, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:11.54, y:5.28, z:10.4}, rotation:{x:0, y:0.2226778, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:14.71, y:3.29, z:95.07}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:61.83, y:3.06, z:73.82}, rotation:{x:0, y:0.3812959, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_06', position:{x:87.54739, y:3.466092, z:99.10831}, rotation:{x:0, y:0.4291917, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_01', position:{x:61.46123, y:5.14984, z:74.97625}, rotation:{x:0, y:0.3812959, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_01.fbx'},
        {name:'Rock_Group_01', position:{x:8.619999, y:7.65, z:144.15}, rotation:{x:0, y:0.5863063, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Trees', position:{x:37.95441, y:5.45632, z:103.1935}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Trees.fbx'},
        {name:'Rock_Group_02', position:{x:6.84, y:4.7, z:61.54}, rotation:{x:0, y:0.2054987, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:151.85, y:5.07, z:46.42}, rotation:{x:0, y:-0.9379885, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:151.59, y:5.48, z:121.95}, rotation:{x:0, y:-0.7870125, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_07', position:{x:80.82628, y:3.396397, z:72.61003}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_07.fbx'},
        {name:'Rock_Group_01', position:{x:116.77, y:3.41, z:62.94}, rotation:{x:0, y:0.7202106, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:48.43, y:3.06, z:60.99}, rotation:{x:0, y:0.9654934, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:110.16, y:2.04, z:121.42}, rotation:{x:0, y:-0.925159, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:141.7, y:7.16, z:8.849998}, rotation:{x:0, y:-0.5034494, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:54.13, y:2.38, z:57.31}, rotation:{x:0, y:0.998423, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_03', position:{x:62.6031, y:4.178641, z:83.10223}, rotation:{x:0, y:0.5172848, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Rock_Group_02', position:{x:49.07, y:4.74, z:7.800003}, rotation:{x:0, y:-0.5291227, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:41.58, y:2, z:59.1}, rotation:{x:0, y:0.429128, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:105.66, y:2.01, z:102.79}, rotation:{x:0, y:-0.5452096, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_04', position:{x:87.76411, y:3.445871, z:100.4042}, rotation:{x:0, y:0.4291917, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_04.fbx'},
        {name:'Rock_Group_01', position:{x:60.39, y:3.33, z:60.79}, rotation:{x:0, y:0.7801048, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:100.5, y:4.82, z:125.32}, rotation:{x:0, y:0.8882742, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:65.60651, y:3.420048, z:36.17381}, rotation:{x:0, y:-0.2572339, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:77.11, y:4.74, z:7.970001}, rotation:{x:0, y:-0.5034494, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:8.35, y:5.05, z:16.4}, rotation:{x:0, y:0.4938125, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:67.83, y:5.09, z:8.900002}, rotation:{x:0, y:-0.1407901, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:108.83, y:2.04, z:114.24}, rotation:{x:0, y:-0.8879265, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:17.99, y:3.2, z:94.16}, rotation:{x:0, y:-0.5023544, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:63.02, y:5.51, z:28.9}, rotation:{x:0, y:0.9243881, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Cube', position:{x:67.04, y:4.04, z:141.28}, rotation:{x:0.0008053332, y:0.002519015, z:0.3044854}, scale:{x:3.651472, y:1, z:6.586565}, file:'Cube.fbx'},
        {name:'Rock_Group_01', position:{x:145.13, y:3.27, z:64.09}, rotation:{x:0, y:-0.8228056, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:98.6, y:3.15, z:24.59}, rotation:{x:0, y:0.6395269, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Cube', position:{x:108.37, y:4.06, z:18.87}, rotation:{x:-1.268353E-07, y:4.054482E-08, z:0.3044865}, scale:{x:3.651471, y:1, z:6.586564}, file:'Cube.fbx'},
        {name:'Rock_Group_01', position:{x:17.92, y:5.36, z:13.83}, rotation:{x:0, y:-0.1481439, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:150.68, y:4.97, z:81.47}, rotation:{x:0, y:-0.7265146, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Cube', position:{x:120.51, y:4, z:62.96}, rotation:{x:-0.2185698, y:-0.6837459, z:0.2119887}, scale:{x:3.651472, y:1, z:4.180297}, file:'Cube.fbx'},
        {name:'Rock_Group_02', position:{x:142.6185, y:3.09, z:65.99858}, rotation:{x:0, y:0.8909072, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Tow_Crystal1', position:{x:69.35796, y:3.405506, z:97.42545}, rotation:{x:0, y:0, z:0}, scale:{x:1.020504, y:1.020504, z:1.020504}, file:'Tow_Crystal1.fbx'},
        {name:'Rock_Group_02', position:{x:37.96, y:4.74, z:7.75}, rotation:{x:0, y:-0.5034494, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:78.77, y:5.12, z:151.93}, rotation:{x:0, y:0.9875919, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:83.71, y:5.09, z:8.900002}, rotation:{x:0, y:-0.1407901, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:50.37, y:2, z:39.93}, rotation:{x:0, y:-0.3914054, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:145.71, y:2.84, z:60.72}, rotation:{x:0, y:-0.9341762, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:70.77, y:4.88, z:152.41}, rotation:{x:0, y:0.8814658, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_02', position:{x:61.86954, y:4.115493, z:74.90792}, rotation:{x:0, y:0.3812959, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_02.fbx'},
        {name:'Rock_Group_01', position:{x:97.53, y:3.33, z:71.36}, rotation:{x:0, y:-0.9132124, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_03', position:{x:86.78778, y:3.682217, z:96.62138}, rotation:{x:0, y:0.6468362, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Rock_06', position:{x:89.72893, y:4.680094, z:102.4797}, rotation:{x:0, y:0.8819894, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_Group_01', position:{x:105.13, y:2.55, z:127.96}, rotation:{x:0, y:0.6760722, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:152.91, y:4.97, z:86.39}, rotation:{x:0, y:-0.9500583, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:51.86, y:2, z:47.1}, rotation:{x:0, y:-0.4713275, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:79.11, y:4.95, z:36.57}, rotation:{x:0, y:-0.9204648, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:87.41, y:3.08, z:100.28}, rotation:{x:0, y:0.4291917, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:117.69, y:3.33, z:102.2}, rotation:{x:0, y:0.9979119, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Cube', position:{x:89.48, y:4.01, z:119.3}, rotation:{x:0.3044291, y:0.9523376, z:-0.00590456}, scale:{x:3.651472, y:1, z:4.157451}, file:'Cube.fbx'},
        {name:'Rock_Group_02', position:{x:132.72, y:4.98, z:9.07}, rotation:{x:0, y:-0.9421609, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:110.29, y:5.08, z:5.489998}, rotation:{x:0, y:-0.5034494, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:138.5234, y:7.51, z:9.856438}, rotation:{x:0, y:-0.1407901, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:104.65, y:2.55, z:121.38}, rotation:{x:0, y:0.6399301, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:152.33, y:7.16, z:21.26}, rotation:{x:0, y:-0.9450714, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Cube', position:{x:40.9, y:5.87, z:44.91}, rotation:{x:0.005990653, y:0.01874043, z:0.3044274}, scale:{x:3.651472, y:1, z:4.157451}, file:'Cube.fbx'},
        {name:'Towers', position:{x:37.95441, y:5.45632, z:103.1935}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Towers.fbx'},
        {name:'Rock_Group_02', position:{x:103.78, y:2.04, z:117.67}, rotation:{x:0, y:0.3868901, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_06', position:{x:97.05866, y:3.486092, z:76.23314}, rotation:{x:0, y:-0.9616589, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_Group_01', position:{x:14.59, y:3.48, z:105.04}, rotation:{x:0, y:0.8491769, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Cube', position:{x:68.42, y:4, z:57.99}, rotation:{x:-0.1164424, y:-0.3642634, z:0.2813416}, scale:{x:3.651472, y:1, z:4.157451}, file:'Cube.fbx'},
        {name:'Rock_Group_02', position:{x:99.74, y:1.67, z:98.73}, rotation:{x:0, y:-0.7842733, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:94.96, y:2.95, z:119.99}, rotation:{x:0, y:-0.9477318, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:62.00261, y:4.78, z:50.77099}, rotation:{x:0, y:-0.2189776, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:150.39, y:4.76, z:58.65}, rotation:{x:0, y:-0.9979289, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:141.92, y:4.77, z:147.12}, rotation:{x:0, y:0.8771759, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:42.46, y:2.2, z:62.56}, rotation:{x:0, y:0.6385479, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:51.26, y:2.5, z:43.61}, rotation:{x:0, y:-0.6436893, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_04', position:{x:61.99318, y:4.338132, z:87.42411}, rotation:{x:0, y:0.2772383, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_04.fbx'},
        {name:'Rock_Group_02', position:{x:131.86, y:4.75, z:152.28}, rotation:{x:0, y:0.9009256, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:55.48, y:2, z:36.55}, rotation:{x:0, y:-0.8897167, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:123.91, y:4.74, z:7.889999}, rotation:{x:0, y:-0.5034494, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:49.55, y:4.4, z:42.96}, rotation:{x:0, y:-0.3537284, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:133.3913, y:5.51, z:19.11853}, rotation:{x:0, y:-0.572562, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Cube', position:{x:94.58, y:4, z:43.62}, rotation:{x:-0.3284847, y:-0.9445093, z:-1.401275E-07}, scale:{x:3.651472, y:1, z:4.157451}, file:'Cube.fbx'},
        {name:'Rock_Group_01', position:{x:120.73, y:5.09, z:8.900002}, rotation:{x:0, y:-0.1407901, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Wall', position:{x:37.95441, y:5.45632, z:103.1935}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Wall.fbx'},
        {name:'Rock_Group_01', position:{x:108.04, y:2.37, z:107.84}, rotation:{x:0, y:-0.9165469, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:75.93, y:4.81, z:130.49}, rotation:{x:0, y:-0.4738553, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:54.85, y:2.5, z:39.58}, rotation:{x:0, y:-0.7496846, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:7.48, y:7.12, z:149.95}, rotation:{x:0, y:0.2579734, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:98.28, y:3.1, z:87.2}, rotation:{x:0, y:-0.943831, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:89.92, y:5.09, z:8.809998}, rotation:{x:0, y:-0.11105, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:146, y:3.26, z:56.76}, rotation:{x:0, y:-0.8914706, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'GameProps', position:{x:37.95441, y:5.45632, z:103.1935}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'GameProps.fbx'},
        {name:'Rock_Group_01', position:{x:46.06125, y:3.18, z:99.18458}, rotation:{x:0, y:-0.007163717, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:85.71, y:5.05, z:29.59}, rotation:{x:0, y:0.8477823, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:116.53, y:3.03, z:34.6}, rotation:{x:0, y:-0.7577242, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'_Water', position:{x:76.68118, y:2.26159, z:83.12304}, rotation:{x:0, y:0.3384277, z:0}, scale:{x:3.790676, y:1, z:12.77587}, file:'_Water.fbx'},
        {name:'Rock_Group_01', position:{x:82.12, y:5.05, z:127.36}, rotation:{x:0, y:0.5666959, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:150.66, y:5.34, z:147.28}, rotation:{x:0, y:-0.9398283, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:7.369999, y:4.79, z:19.33}, rotation:{x:0, y:0.2942343, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:130.01, y:4.74, z:7.970001}, rotation:{x:0, y:-0.5034494, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:150.41, y:5.07, z:67.5}, rotation:{x:0, y:-0.8583491, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:101, y:3.04, z:29.02}, rotation:{x:0, y:0.6624738, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:117.69, y:4.78, z:119.01}, rotation:{x:0, y:0.2583166, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:98.88, y:3.1, z:68.82}, rotation:{x:0, y:-0.9864755, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:102.25, y:3.34, z:7.540001}, rotation:{x:0, y:-0.6562489, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:30.53, y:4.88, z:152.41}, rotation:{x:0, y:0.8814658, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:119.09, y:3.04, z:99.78}, rotation:{x:0, y:-0.9721903, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:102.39, y:3.21, z:67.15}, rotation:{x:0, y:0.894978, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:110.09, y:3.17, z:24.65}, rotation:{x:0, y:-0.9400592, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:72.25, y:3.13, z:55.54}, rotation:{x:0, y:0.9776053, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:110.59, y:5.01, z:121.49}, rotation:{x:0, y:-0.8228354, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:114.44, y:4.94, z:149.79}, rotation:{x:0, y:0.9919312, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:96.83, y:3.33, z:77.95}, rotation:{x:0, y:-0.861361, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:7.549999, y:4.7, z:49.26}, rotation:{x:0, y:0.2347421, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:62.01, y:2.4, z:51.43}, rotation:{x:0, y:-0.294912, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:71.15, y:5.27, z:128.38}, rotation:{x:0, y:-0.6754769, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:29.81, y:3.5, z:97.47}, rotation:{x:0, y:-0.5545812, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:55.1, y:4.8, z:30.46}, rotation:{x:0, y:-0.9251689, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:62.03, y:3.1, z:87.64}, rotation:{x:0, y:0.2772383, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:18.11, y:3.12, z:109.89}, rotation:{x:0, y:0.8788792, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Wall_stone_grup3x', position:{x:128.72, y:5.61, z:44.4}, rotation:{x:0, y:-0.3826835, z:0}, scale:{x:1.116525, y:1, z:1}, file:'Wall_stone_grup3x.fbx'},
        {name:'Rock_01', position:{x:88.68652, y:4.88, z:102.2952}, rotation:{x:0, y:0.8819894, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_01.fbx'},
        {name:'Camera', position:{x:64.5, y:16.2, z:91}, rotation:{x:0.3062552, y:0.6362954, z:-0.3187692}, scale:{x:1, y:1, z:1}, file:'Camera.fbx'},
        {name:'Rock_Group_01', position:{x:109.98, y:2.06, z:130.41}, rotation:{x:0, y:-0.7186909, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Cube', position:{x:90.31, y:4.01, z:108.71}, rotation:{x:0.2813086, y:0.8800107, z:0.1165219}, scale:{x:3.651472, y:1, z:4.157451}, file:'Cube.fbx'},
        {name:'Rock_Group_02', position:{x:104.14, y:5.08, z:5.400002}, rotation:{x:0, y:-0.5291227, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Mushrooms', position:{x:37.95441, y:5.45632, z:103.1935}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Mushrooms.fbx'},
        {name:'Rock_Group_02', position:{x:92.99, y:4.74, z:7.970001}, rotation:{x:0, y:-0.5034494, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Particles', position:{x:15.8809, y:7.668495, z:143.3089}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Particles.fbx'},
        {name:'Rock_Group_01', position:{x:8.880001, y:5.1, z:36.50999}, rotation:{x:0, y:0.6077786, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:9.950001, y:4.94, z:34.33}, rotation:{x:0, y:-0.09695529, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:96.82, y:3.06, z:113.43}, rotation:{x:0, y:-0.9100198, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:115.61, y:5.24, z:107}, rotation:{x:0, y:0.8333856, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:152.26, y:4.96, z:138.45}, rotation:{x:0, y:-0.9025935, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:102.29, y:4.96, z:150.73}, rotation:{x:0, y:0.6661534, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:10.61301, y:5.1, z:98.9097}, rotation:{x:0, y:0.6257726, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_07', position:{x:87.92089, y:3.136397, z:111.0408}, rotation:{x:0, y:0.7436762, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_07.fbx'},
        {name:'Rock_Group_01', position:{x:53.37, y:5.02, z:154.57}, rotation:{x:0, y:0.9800095, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:55.75, y:2.5, z:45.8}, rotation:{x:0, y:-0.7496846, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'_Terrain', position:{x:0, y:0, z:0}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'_Terrain.fbx'},
        {name:'Rock_Group_01', position:{x:151.88, y:7.51, z:18.15}, rotation:{x:0, y:-0.7321725, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:133.92, y:5.23, z:150.2}, rotation:{x:0, y:-0.9562846, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:151.56, y:7.51, z:12.56}, rotation:{x:0, y:-0.7606044, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:50.27, y:2.87, z:94.73}, rotation:{x:0, y:-0.1701789, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:56.8, y:5.01, z:154.79}, rotation:{x:0, y:0.9196789, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:96.9, y:4.91, z:150.31}, rotation:{x:0, y:0.9998209, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:64.37, y:5.15, z:152.12}, rotation:{x:0, y:0.9978372, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'CampFire', position:{x:37.95441, y:5.45632, z:103.1935}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'CampFire.fbx'},
        {name:'Rock_Group_02', position:{x:147.42, y:4.89, z:76.69}, rotation:{x:0, y:-0.9887239, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_04', position:{x:61.65759, y:4.679235, z:88.63981}, rotation:{x:0, y:0.2772383, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_04.fbx'},
        {name:'Rock_Group_02', position:{x:129.31, y:3.03, z:47.43}, rotation:{x:0, y:-0.7577242, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Cube', position:{x:84.5, y:5.71, z:42.42}, rotation:{x:-0.2172597, y:-0.6246983, z:-0.2463747}, scale:{x:3.651472, y:1, z:4.157451}, file:'Cube.fbx'},
        {name:'Rock_03', position:{x:79.29958, y:4.448641, z:73.00248}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Rock_Group_02', position:{x:103.7278, y:2.036858, z:112.1178}, rotation:{x:0, y:0.2715858, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:32.96, y:3.03, z:99.32}, rotation:{x:0, y:-0.4580622, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:63.16, y:3.41, z:82.66}, rotation:{x:0, y:0.5172848, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:62.77, y:3.33, z:69.12}, rotation:{x:0, y:0.6022403, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:36.02, y:3.52, z:99.47}, rotation:{x:0, y:0.2267585, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:149.94, y:5.26, z:73.58}, rotation:{x:0, y:-0.990697, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Cube', position:{x:136, y:4, z:64.23}, rotation:{x:-0.2153045, y:-0.673531, z:0.2153043}, scale:{x:3.651472, y:1, z:7.677238}, file:'Cube.fbx'},
        {name:'Rock_Group_01', position:{x:151.05, y:5.07, z:42.62}, rotation:{x:0, y:-0.8286052, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:53.24, y:2.5, z:50.56}, rotation:{x:0, y:-0.7089061, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_01', position:{x:61.4172, y:5.18984, z:88.68756}, rotation:{x:0, y:0.2772383, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_01.fbx'},
        {name:'Rock_Group_02', position:{x:60.62, y:4.93, z:28.35}, rotation:{x:0, y:0.7173909, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:6.299999, y:4.7, z:73.35}, rotation:{x:0, y:0.2054987, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:87.65, y:4.93, z:39.3}, rotation:{x:0, y:0.1634751, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_03', position:{x:62.32466, y:4.621269, z:82.2245}, rotation:{x:0, y:0.5172848, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_03.fbx'},
        {name:'Rock_02', position:{x:87.56313, y:4.135493, z:101.3578}, rotation:{x:0, y:0.4291917, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_02.fbx'},
        {name:'Rock_Group_02', position:{x:7.5, y:4.7, z:45.66}, rotation:{x:0, y:0.2054987, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:152.91, y:4.97, z:99.01}, rotation:{x:0, y:-0.9500583, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:70.46, y:3.09, z:49.75}, rotation:{x:0, y:-0.7371139, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:13.92, y:4.86, z:8.110001}, rotation:{x:0, y:-0.4907927, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:45.97, y:2.2, z:56.8}, rotation:{x:0, y:0.003356813, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:16.4, y:3.47, z:108}, rotation:{x:0, y:0.7608837, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:110.09, y:3.17, z:10.46}, rotation:{x:0, y:-0.9400592, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:43.65, y:3.33, z:61.29}, rotation:{x:0, y:0.9999651, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:51.49, y:4.7, z:52.61}, rotation:{x:0, y:-0.2293914, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:150.62, y:5.07, z:55.58}, rotation:{x:0, y:-0.9038244, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:10.67, y:4.78, z:25.2}, rotation:{x:0, y:0.8530998, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_04', position:{x:87.30611, y:4.318132, z:100.0872}, rotation:{x:0, y:0.4291917, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_04.fbx'},
        {name:'Rock_Group_01', position:{x:97.87, y:3.33, z:90.05}, rotation:{x:0, y:-0.8299362, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:71.01, y:4.74, z:7.889999}, rotation:{x:0, y:-0.5034494, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:101.76, y:2.07, z:101.48}, rotation:{x:0, y:-0.4436246, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:17.98, y:7.65, z:152.28}, rotation:{x:0, y:0.9920384, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:72.2, y:5.14, z:119.13}, rotation:{x:0, y:0.8535333, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:9.6, y:4.75, z:126.33}, rotation:{x:0, y:0.2046696, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:141.26, y:3.07, z:50.1}, rotation:{x:0, y:-0.3971357, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:48.93, y:2, z:55.78}, rotation:{x:0, y:-0.339913, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:128.84, y:2.95, z:61.43}, rotation:{x:0, y:0.7446203, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:8.73, y:5.1, z:42.7}, rotation:{x:0, y:0.5837017, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Cube', position:{x:136.29, y:4, z:51.51}, rotation:{x:-0.219439, y:-0.6864654, z:-0.2110889}, scale:{x:3.651472, y:1, z:4.547101}, file:'Cube.fbx'},
        {name:'Rock_Group_01', position:{x:8.060001, y:5.1, z:58.59}, rotation:{x:0, y:0.5837017, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:147.27, y:5.48, z:79.76}, rotation:{x:0, y:-0.4075722, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:13.15, y:4.86, z:32.00999}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:82.69, y:5.37, z:28.94}, rotation:{x:0, y:0.9910514, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:94.77, y:3.04, z:13}, rotation:{x:0, y:0.342739, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_06', position:{x:88.14493, y:4.081105, z:101.694}, rotation:{x:0, y:0.8819894, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_Group_02', position:{x:22.44, y:4.81, z:11.74}, rotation:{x:0, y:-0.1622319, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:52.82, y:2.4, z:53.48}, rotation:{x:0, y:-0.411641, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:27.6, y:4.85, z:145.01}, rotation:{x:0, y:0.3698429, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Wall_stone_grup3x', position:{x:143.64, y:5.61, z:48.73}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Wall_stone_grup3x.fbx'},
        {name:'Rock_Group_02', position:{x:70.32, y:3, z:65.24}, rotation:{x:0, y:-0.7125661, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:67.77, y:5.12, z:151.93}, rotation:{x:0, y:0.9875919, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:97.17, y:2.6, z:93.3}, rotation:{x:0, y:-0.7444211, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:41.29, y:5.03, z:10.13}, rotation:{x:0, y:-0.6667299, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:109.4, y:4.7, z:108.71}, rotation:{x:0, y:-0.9761943, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:113.9, y:3.19, z:61.46}, rotation:{x:0, y:0.9623563, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:63.08469, y:3.417889, z:45.51817}, rotation:{x:0, y:0.18944, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:148.67, y:4.96, z:27.28}, rotation:{x:0, y:-0.4155281, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_01', position:{x:76.00952, y:3.405359, z:93.56242}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_01.fbx'},
        {name:'Rock_06', position:{x:87.21432, y:4.401106, z:111.2625}, rotation:{x:0, y:0.7436762, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_Group_01', position:{x:28.41, y:5.28, z:150.39}, rotation:{x:0, y:0.6530183, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:88.44, y:4.82, z:32.91}, rotation:{x:0, y:0.3291143, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_06', position:{x:80.6808, y:4.661106, z:71.88393}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Rock_02', position:{x:84.55259, y:4.930529, z:94.71538}, rotation:{x:0, y:0.6468362, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_02.fbx'},
        {name:'Rock_04', position:{x:86.14067, y:4.899235, z:96.76215}, rotation:{x:0, y:0.6468362, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_04.fbx'},
        {name:'Rock_Group_02', position:{x:23.84, y:7.12, z:153.04}, rotation:{x:0, y:0.8814658, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:51.46, y:5, z:47.11}, rotation:{x:0, y:-0.6486939, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:152.05, y:5.48, z:95.3}, rotation:{x:0, y:-0.7578298, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:71.68, y:4.93, z:122.98}, rotation:{x:0, y:-0.9736044, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:31.81, y:4.74, z:7.93}, rotation:{x:0, y:-0.5291227, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:103.91, y:2.04, z:124.35}, rotation:{x:0, y:0.4307, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:64.86, y:4.74, z:7.800003}, rotation:{x:0, y:-0.5291227, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_02', position:{x:49.27, y:2.32, z:27.85}, rotation:{x:0, y:-0.2842751, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_07', position:{x:88.66769, y:2.816397, z:101.1695}, rotation:{x:0, y:0.8819894, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_07.fbx'},
        {name:'Rock_Group_02', position:{x:42.26, y:5.49, z:40.58}, rotation:{x:0, y:-0.9169154, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:38.53, y:5.12, z:151.93}, rotation:{x:0, y:0.9875919, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:43.15, y:4.79, z:49.22}, rotation:{x:0, y:-0.8014171, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:103.31, y:5.01, z:111.95}, rotation:{x:0, y:0.4707248, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Wall_wood_grup3x', position:{x:48.63461, y:5.491126, z:149.8746}, rotation:{x:0, y:0.7071068, z:0}, scale:{x:1, y:1, z:1}, file:'Wall_wood_grup3x.fbx'},
        {name:'Rock_07', position:{x:97.00105, y:3.046397, z:79.04588}, rotation:{x:0, y:-0.861361, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_07.fbx'},
        {name:'Rock_Group_01', position:{x:152.05, y:5.48, z:109.12}, rotation:{x:0, y:-0.7578298, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:145.34, y:5.42, z:27.97}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:106.33, y:1.66, z:132.83}, rotation:{x:0, y:0.9127797, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Cube', position:{x:23.75, y:4.04, z:108.25}, rotation:{x:-0.2153044, y:-0.6735311, z:0.2153044}, scale:{x:3.651472, y:1, z:6.586564}, file:'Cube.fbx'},
        {name:'Rock_Group_01', position:{x:50.34, y:2.1, z:30.93}, rotation:{x:0, y:-0.7044833, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:135.554, y:7.16, z:8.756523}, rotation:{x:0, y:-0.5291227, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_Group_01', position:{x:80.26, y:5.36, z:39.73}, rotation:{x:0, y:-0.4785399, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_02', position:{x:50.74, y:1.96, z:59.47}, rotation:{x:0, y:0.9995773, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_02.fbx'},
        {name:'Rock_06', position:{x:85.17741, y:3.706092, z:94.73476}, rotation:{x:0, y:0.6468362, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_06.fbx'},
        {name:'Wall_stone_grup2x', position:{x:111.89, y:5.61, z:12.02}, rotation:{x:0, y:0.7071068, z:0}, scale:{x:1, y:1, z:1}, file:'Wall_stone_grup2x.fbx'},
        {name:'Cube', position:{x:96.58, y:3.92, z:81.81}, rotation:{x:-0.003661276, y:-0.01145351, z:0.3044641}, scale:{x:3.651472, y:1, z:4.157452}, file:'Cube.fbx'},
        {name:'Rock_Group_01', position:{x:151.73, y:5.27, z:141.42}, rotation:{x:0, y:-0.7869104, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:97.82, y:4.94, z:125.35}, rotation:{x:0, y:0.9992003, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Lights', position:{x:37.95441, y:5.45632, z:103.1935}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Lights.fbx'},
        {name:'Rock_Group_01', position:{x:109.33, y:2.55, z:117.69}, rotation:{x:0, y:-0.7734738, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:79.95, y:3.68, z:73.29}, rotation:{x:0, y:0, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},
        {name:'Rock_Group_01', position:{x:8.220001, y:5.1, z:52.39}, rotation:{x:0, y:0.6077786, z:0}, scale:{x:1, y:1, z:1}, file:'Rock_Group_01.fbx'},

    ],

    mapObj:[],
    activeLayer:"Tree",
    hideLayerPanel:true,
    showpannel:false,
  });


  useEffect(async () => {
    const { scene } = config;
//    scene.background = new THREE.Color(0xcce0ff);
    scene.background = new THREE.Color(0x060016);
//    scene.fog = new THREE.Fog( 0xcce0ff, 5, 1000 );
//    scene.position.set(-3, 0, -5);


//    var camera = new THREE.PerspectiveCamera( 30, mount.current.offsetWidth/mount.current.offsetHeight, 0.1, 1000 );
    camera.position.z = 80;
    camera.position.y = 42;
//    camera.position.x = 8;
    camera.rotation.x = Math.PI / 4;
//    camera.rotation.y = Math.PI / 4;
//    var camera = new THREE.PerspectiveCamera( 75, window.innerWidth/window.innerHeight, 0.1, 1000 );
//    camera.position.z = 5;

    var renderer = new THREE.WebGLRenderer();
    renderer.setSize( mount.current.offsetWidth, mount.current.offsetHeight );
    renderer.gammaOutput = true
    renderer.shadowMap.enabled = true;
    // document.body.appendChild( renderer.domElement );
    // use ref as a mount point of the Three.js scene instead of the document.body
    mount.current.appendChild( renderer.domElement );
    
//    var geometry = new THREE.BoxGeometry( 1, 1, 1 );
    const tloader = new THREE.TextureLoader();
    const groundTexture = tloader.load( '../../img/grass50.jpg' );
    groundTexture.wrapS = groundTexture.wrapT = THREE.RepeatWrapping;
    const material = new THREE.MeshLambertMaterial( { map: groundTexture } );



//    var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
//    var cube = createCell({ material: material, x: 0, y: 0, z: 0 });
//    var cube = new THREE.Mesh( geometry, material );
//    scene.add( cube );
    
//    var material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );

    
//    scene.add( cube );

    var geometry = new THREE.BoxGeometry( 1, 1, 1 );

    var cube = new THREE.Mesh( geometry, material );
    cube.position.set(5, 5, 5);
    await scene.add( cube );
    
    await scene.add( new THREE.AmbientLight( 0x666666 ) );
    
//    const light = new THREE.PointLight(0xffffff, 1, 1000);
//    light.position.set(0, 10, 10);
//    scene.add( light );
    
    let controls = new OrbitControls(camera, renderer.domElement);

/*
    config.fbxloader.load("../models/Tree_C_1.fbx", function (model) {

          scene.add(model);
      }, undefined, function (error) {
          console.error(error);
      });*/



    
    await setConfig({ ...config, scene: scene});
    
    var animate = () => {
      requestAnimationFrame( animate );
//      cube.rotation.x += 0.01;
//      cube.rotation.y += 0.01;
      controls.update(camera);
      sceneupdate(scene);
      renderer.render( scene, camera );
    };
    await animate();
    
    await setConfig({ ...config, controls: controls});
    
    checkMap();
    return () => {
      
    }
  }, []);
  

  
  const createObject = () => {
    let reachDistance = 1;
    const curve = new THREE.EllipseCurve(
      0, 0,
      reachDistance, reachDistance,
      0, Math.PI * 2,
      false,
      0
    );

    let normalColor = new THREE.Color(0, 2, 0.5);

    const points = curve.getPoints(50);
    const ringGeometry = new THREE.BufferGeometry().setFromPoints(points);
    let _ringMaterial = new THREE.LineBasicMaterial({ color: normalColor });
    let reachEllipseRing = new THREE.Line(ringGeometry, _ringMaterial);
    reachEllipseRing.ignoreRaycast = true;

    const geometry = new THREE.PlaneGeometry(reachDistance * 2, reachDistance * 2);
    var uniforms = {
      radius: { type: 'float', value: reachDistance },
      radiusColor: { type: 'vec3', value: normalColor }
    }
    let _material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      fragmentShader: _fragmentShader(),
      vertexShader: _vertexShader(),
    })

    _material.transparent = true;
    let reachEllipse = new THREE.Mesh(geometry, _material);
    reachEllipse.ignoreRaycast = true;
    reachEllipse.add(reachEllipseRing);
    //reachEllipse.rotation.x = - Math.PI / 2;
    return reachEllipse;
  }

  const _vertexShader = () => {
    return `
      varying vec2 vUv;

      void main() 
      {
          vUv = uv;

          gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0); 
      }
    `
  }

  const _fragmentShader = () => {
    return `            
      uniform float radius;
      uniform vec3 radiusColor;

      varying vec2 vUv;            

      void main() 
      {
        float distanceToCenter = distance(vUv, vec2(0.5));
        float invertedDistanceToCenter = 1.0 - distanceToCenter;
        float circularMask = step(distanceToCenter, 0.5);
        float mask = circularMask - invertedDistanceToCenter;

        gl_FragColor = vec4(radiusColor * mask, mask - 0.3);
      }
    `
  }
  

    
  const selectItem = (item) => {
    const { fbxloader, scene } = config;
    if (selectedToBePlacedObject) {
      scene.remove( selectedToBePlacedObject );
    }
    if (selectedObject) {
      scene.remove( selectedObject );
      selectedObject = null;
      setConfig({ ...config, showpannel: false });
    }
    fbxloader.load(item.file, (model) => {
        if (item.scale) {
          model.scale.set(item.scale, item.scale, item.scale );
        }
        if (item.rotateY) {
          model.rotation.y = item.rotateY;
        }
          currentobject = model.clone();
          let circle =  createObject();
          circle.rotation.x = - Math.PI / 2;
          selectedToBePlacedObject = model.add(circle);
          selectedToBePlacedObject.objItem = item;
          scene.add(selectedToBePlacedObject);
      }, undefined, (error) => {
          console.error(error);
      });
  }

  const sceneupdate = (scene) => {

  }

  const _checkIfHoverPositionIsCorrect = (gridPosition) => {
    const { mapX, mapY } = config;
    if (gridPosition.x < 0 || gridPosition.y < 0) {
      return false;
    }
    if (gridPosition.x >= mapY || gridPosition.y >= mapY) {
      return false;
    }
    return true;
  }



  const createCell = ({ material, x, y, z }) => {
    const geometry = new THREE.BoxGeometry();

    const cube = new THREE.Mesh(geometry, material);
    cube.position.set(x, z, y);
    //cube.rotation.x = - Math.PI / 2.6;
    cube.receiveShadow = true;

    return cube;
  } 
  

  
  const checkMap = async () => {
    console.log("checkMap")

    const { fbxloader, scene, mapobjcts } = config;
      
      mapobjcts.map((fbxobjct) => {
        try {
          fbxloader.load("../expmodels/" + fbxobjct.file, (wcurrentobject) => {

                wcurrentobject.scale.copy({x:0.01, y:0.01, z:0.01});
                wcurrentobject.rotation.copy(fbxobjct.rotation);
                
                wcurrentobject.position.copy({x:fbxobjct.position.x-75, y:fbxobjct.position.y, z:fbxobjct.position.z-75});
                scene.add(wcurrentobject);
            }, undefined, (error) => {
                console.error(error);
            });
        } catch (err) {
          console.error(fbxobjct.file);
        }
    });


  }

  const handleMouseClick = (event) => { 
    const { controls } = config;
    console.log(controls.getPos());

  }



  return (
    <div className={props.darkTheme ? "markets" : "markets lightTheme"}>
      <div className="container">

      </div>
      <div className="relative container">
        <div className="mapeditor" id="mapcanvas" ref={mount} onClick={(ev)=> handleMouseClick(ev)}></div>
      </div>
    </div>
  );
}

export default withRouter(Testmap);
